<template>
    <div class="edit-view">
        <div class="edit-view__title">{{title}}: {{editData.name}}</div>
        <div class="object_tabs">
            <v-tabs
                v-model="model"
                slider-color="blue"
            >
                <v-tab
                    v-for="tab in tabs"
                    :key="tab.name"
                >
                    {{ tab.name }}
                </v-tab>
            </v-tabs>
        </div>
        <v-tabs-items v-model="model">
            <v-tab-item
                v-for="tab in tabs"
                :key="tab.name"
            >
                <component
                    :is="tab.component"
                    :editData="editData"
                    @close-edit="$emit('close-edit')"
                    @edit-item="onEditItem"
                    @create-attribute-open="$emit('create-attribute-open')"
                    @delete-attribute="$emit('delete-attribute', $event)"
                    @edit-attribute-open="$emit('edit-attribute-open', $event)"
                    @get-Template-Representation="$emit('get-Template-Representation', $event)"
                    @set-Template-Representation="$emit('set-Template-Representation', $event)"
                    @get-Template-Regulations="$emit('get-Template-Regulations')"
                    @create_Status_Meta_Attributes="$emit('create_Status_Meta_Attributes')"
                    @get-Detailed-Form-View-Meta-Attrs="$emit('get-Detailed-Form-View-Meta-Attrs')"
                    @get-Interface-Designer="$emit('get-Interface-Designer')"
                    @get-Detailed-Form-View-Xml-Desc-Response="$emit('get-Detailed-Form-View-Xml-Desc-Response')"
                    @save-Interface="$emit('save-Interface')"
                    @error="$emit('error', $event)"
                />
            </v-tab-item>
        </v-tabs-items>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import InterfaceDesigner from '@/components/InterfaceDesigner.vue';
import ObjectDescription from '@/components/ObjectDescription.vue';
import ObjectRepresentation from '@/components/ObjectRepresentation.vue';
import ObjectAtributes from '@/components/ObjectAtributes.vue';
import ObjectReglament from '@/components/ObjectReglament.vue';
export default {
    props: {
        editData: {
            type: Object,
            default: () => {}
        },
        title: {
            type: String,
            default: () => 'Редактирование'
        },
        dictinaryManagedGroups: {
            type: Array,
            default: () => []
        },
        activeTab: {
            type: Number,
            default: () => 0
        }
    },
    components: {
        InterfaceDesigner,
        ObjectDescription,
        ObjectRepresentation,
        ObjectAtributes,
        ObjectReglament,
    },
    data() {
        return {
            tabs: [
                {name:'Описание', component: 'ObjectDescription'}, 
                {name:'Атрибуты', component: 'ObjectAtributes'}, 
                {name: 'Представление', component: 'ObjectRepresentation'}, 
                {name: 'Регламент', component: 'ObjectReglament'}, 
                {name: 'Дизайнер интерфейса', component: 'InterfaceDesigner'}, 
            ],
            text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
            model: this.activeTab,
            loading: true,
        }
    },
    computed: {
        ...mapGetters(["DICTIONARY_LIST", "DICTIONARY_MANAGED_GROUPS"]),
    },
    methods: {
        onEditItem(item) {
            this.$emit('edit-item', item)
        },
    },
    mounted() {
    }
}
</script>

<style lang="scss" scoped>
.edit-view {
    height: 100%;
    display: flex;
    flex-direction: column;
    &__item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        &:not(:last-child) {
            margin-bottom: 10px;
        }
    }
    &__actions {
        display: flex;
        justify-content: flex-end;
        & .btn-close {
            margin-right: 8px;
            background-color:#f06548;
            color: #fff;
            padding: 10px 15px;
            border-radius: 5px;
            font-weight: 500;
        }
        & .btn-close:hover {
            background-color:#cc563d;
            transition: 0.3s all;
        }
        & .btn-save {
            margin-right: 8px;
            background-color:#299cdb;
            color: #fff;
            padding: 10px 15px;
            border-radius: 5px;
            font-weight: 500;
        }
        & .btn-save:hover {
            background-color:#2385ba;
            transition: 0.3s all;
        }
    }
}

.edit-view__container {
    padding: 20px;
}

.edit-view__item__title {
    font-weight: 500;
    width: 200px;
}

.edit-view__item__value {
    width: 100%;
}

.edit-view__item__input {
    width: 100%;
    padding: 5px;
}

.edit-view__item__textarea {
    width: 100%;
    padding: 5px;
    border: 1px solid black;
}

// .edit-view__item__table__container {
//     height: 400px;
// }
.object_tabs {
    height: 50px;
}
</style>