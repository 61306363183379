<template>
<div class="table-conteiner">
  <v-data-table
    class="roles_list_table"
    :headers="headers"
    :items="ADMIN_ROLE_ACCOUNT_MENU"
    :items-per-page="10000"
    hide-default-footer
    dense
    @dblclick:row="rowClick"
  > 
    <template v-slot:top>
      <p class="table__title">Роли</p>
    </template>
    <template v-slot:item.creationDate="{ item }">
      {{new Date(item.creationDate).toLocaleString()}}
    </template>
    <template v-slot:item.actions="{ item }">
      <div class="action-column">
        <i class="ri ri-pencil-fill" @click="showEditForm(item)"></i>
        <!-- <img
          style="cursor: pointer; margin-right: 10px;"
          :src="require('../assets/edit.png')"
          alt="edit"
          @click="showEditForm(item)"
        /> -->
        <!-- <img
          style="cursor: pointer"
          :src="require('../assets/trash.png')"
          alt="delete"
          @click="showDeleteForm(item)"
        /> -->
        <i class="ri ri-delete-bin-fill" @click="showDeleteForm(item)"></i>
      </div>
    </template>
  </v-data-table>
  <div class="buttons_conteiner">
    <button class="buttons_conteiner__button" @click="$emit('createRoleOpen')">Добавить</button>
  </div>
</div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
    props: {

    },
    data() {
      return {
        headers: [
          {text: 'Код', value: 'code', sortable: true,},  
          {text: 'Наименование', value: 'name', sortable: true,},
          {text: 'Описание', value: 'description', sortable: true},
          {text: 'Дата создания', value: 'creationDate', sortable: true},
          {text: 'Тип роли', value: 'typeRole.name', sortable: true},
          {text: '', value: 'actions', sortable: false},
        ],
      }
    },
    methods: { 
      rowClick(e, item) {
        this.$emit('table-row-dblclick', item.item)
      },
      showEditForm(item) {
        this.$emit('table-row-dblclick', item)
      },
      showDeleteForm(item) {
        this.$emit('delete-role-click', item)
      }
    },
    computed: {
      ...mapGetters(["ADMIN_ROLE_ACCOUNT_MENU"]),
    }
}
</script>
<style lang="scss">
  .roles_list_table .v-data-table__wrapper{
      height: calc(100vh - 160px);
      overflow-y: scroll;
      box-shadow: 0px 0px 8px 1px rgba(34, 60, 80, 0.2);
  }

  .buttons_conteiner {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .buttons_conteiner__button {
    margin-right: 8px;
    background-color:#daf4f0;
    color: #099885ff;
    padding: 10px 15px;
    border-radius: 5px;
    font-weight: 500;
  }

  .buttons_conteiner__button:hover {
    background-color:#099885;
    color: white;
    transition: 0.3s all;
  }

  .action-column {
    display: flex;
    justify-content: space-between;
  }

  .table__title {
    margin-bottom: 0 !important;
  }

</style>