<template>
    <v-app>
        <table>
            <tr>
                <th>id</th>
                <th>version</th>
                <th>versionDate</th>
                <th>creationDate</th>
                <th>code</th>
                <th>name</th>
                <th>description</th>
                <th>dictionaryType</th>
                <th>hasChildDictionaries</th>
                <th>hasManagedGroup</th>
                <th>managedGroupId</th>
            </tr>
            <tr v-for="data in tableData" :key="data.id">
                <td v-for="(field, index) of data" :key="`${data.id}-${index}`">
                    {{field}}
                </td>
            </tr> 
        </table>
    </v-app>
</template>

<script>
    export default {
        data() {
            return {
                panels: [
                    {
                        text: 'Тест 1', value: 'test1', content: [
                            {text: 'Вложение1 first', value: 'first1'},
                            {text: 'Вложение2 first', value: 'first2'},
                        ]
                    },
                    {
                        text: 'Тест 2', value: 'test2', content: [
                            {text: 'Вложение1 second', value: 'second1'},
                            {text: 'Вложение2 second', value: 'second2'},
                        ]
                    },
                    {
                        text: 'Тест 3', value: 'test3', content: [
                            {text: 'Вложение1 third', value: 'third1'},
                        ]
                    },
                ],
                selectedItem: {
                    title: '',
                    content: ''
                },
                tableData: [],
            }
        },
        methods: {
            onSelectPanelValue(title, content) {
                this.selectedItem.title = title;
                this.selectedItem.content = content;
            },
        },
        mounted() {
            const data = '<soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:reg="http://ru.petrosoft/register"><soapenv:Header/><soapenv:Body><reg:getDictionaries/></soapenv:Body></soapenv:Envelope>'
            let xhr = new XMLHttpRequest()
            xhr.open('POST', 'http://waste-cors.petrosoft.su/register-ws/RegisterTechnologistService/RegisterTechnologist?wsdl')
            xhr.setRequestHeader('Content-Type', 'text/xml');
            xhr.setRequestHeader('Authorization', 'Basic dGVjaDoxMjM=');
            xhr.send(data)
            let tableData = []
            xhr.onload = function() {
                const parser = new DOMParser();
                const xmlDoc = parser.parseFromString(xhr.response, "text/xml");

                const list = xmlDoc.getElementsByTagName("reg:getDictionariesResponse")[0].childNodes
                for (var i = 0; i < list.length; ++i) {
                    const fields = list[i].childNodes
                    const item = {}
                    for (var j = 0; j < fields.length; ++j) {
                        item[fields[j].tagName] = fields[j].textContent
                    }
                    tableData.push(item)
                }
            };
            this.tableData = tableData
            xhr.onerror = function() { // происходит, только когда запрос совсем не получилось выполнить
                console.log(`Ошибка соединения`);
            };

        },
    }
</script>
