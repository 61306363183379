<template>
<div class="table-conteiner">
    <v-data-table
        class="atributes_table"
        :headers="headers"
        fixed-header
        :items="OBJECTS_ATTRIBUTES"
        :items-per-page="10000"
        hide-default-footer
        dense
        height="100%"
        @dblclick:row="rowClick"
    >
        <template v-slot:item.actions="{ item }">
            <div>
                <!-- <img
                style="cursor: pointer"
                :src="require('../assets/edit.png')"
                alt="edit"
                @click="showEditForm(item)"
                />
                <img
                style="cursor: pointer"
                :src="require('../assets/trash.png')"
                alt="delete"
                @click="showDeleteForm(item)"
                /> -->
                <i class="ri ri-pencil-fill" @click="showEditForm(item)"></i>
                <i class="ri ri-delete-bin-fill" @click="showDeleteForm(item)"></i>

            </div>
        </template>
        <template v-slot:item.value="{ item }">
            <span>{{rowValues(item)}}</span>
        </template>
    </v-data-table>
    <div class="buttons_conteiner">
        <button class="buttons_conteiner__button" @click="$emit('create-attribute-open')">Добавить</button>
        <button class="buttons_conteiner__button" @click="$emit('close-edit')">Отмена</button>
    </div>
    <v-dialog
        v-model="deleteDialog"
        persistent
        max-width="290"
    >
        <v-card>
            <v-card-text>Вы действительно хотите удалить запись?</v-card-text>
            <v-card-actions>
                <!-- <v-btn
                    color="red darken-1"
                    text
                    @click="deleteAttribute"
                >Да
                </v-btn>
                <v-btn
                    color="green darken-1"
                    text
                    @click="deleteDialog = false"
                >Нет
                </v-btn> -->
                    <button
                        class="btn-save" 
                        @click="deleteAttribute"
                    >Да
                    </button>                           
                    <button 
                        class="btn-close" 
                        @click="deleteDialog = false"
                        >Нет
                    </button>
            </v-card-actions>
        </v-card>
    </v-dialog>
</div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    props: {
        editData: {
            type: Object,
            default: () => {}
        },
    },
    data() {
        return {
            headers: [
                {text: 'ATTRIBUTE ID', value: 'attributeObjectType.id'},  
                {text: 'METAATTRIBUTE ID', value: 'attributeObjectType.metaAttributeId'},
                {text: 'Наименование', value: 'attributeObjectType.name'},
                {text: 'Код', value: 'attributeObjectType.code'},
                {text: 'Тип', value: 'attributeObjectType.attributeType'},
                {text: 'Описание', value: 'attributeObjectType.description'},
                {text: 'Значение', value: 'value'},
                {text: 'Действия', value: 'actions'},
            ],
            deleteDialog: false,
            deletedItem: null,
        }
    },
    computed: {
        ...mapGetters(["OBJECTS_ATTRIBUTES"]),
    },
    methods: {
        rowValues(row) {
            if (row.value.dataList) {
                if (row.value.dataList.data) {
                    if (row.value.dataList.data.name) {
                        return row.value.dataList.data.name
                    } else {
                        return row.value.dataList.data
                    }
                } else {
                    return '';
                }
            } else {
                return '';
            }
        },
        rowClick(e, row) {
            this.showEditForm(row.item)
        },
        showDeleteForm(item) {
            this.deletedItem = item
            this.deleteDialog = true;
        },
        deleteAttribute() {
            this.$emit('delete-attribute', this.deletedItem)
            this.deleteDialog = false;
        },
        showEditForm(item) {

            this.$emit('edit-attribute-open', item)
        },
    },
    mounted() {

    }

}
</script>

<style lang="scss" scoped>
.edit-view {
    height: 100%;
    display: flex;
    flex-direction: column;
    &__item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        &:not(:last-child) {
            margin-bottom: 10px;
        }
    }
    &__actions {
        display: flex;
        justify-content: flex-end;
        & .btn-close {
            margin-right: 8px;
            background-color:#f06548;
            color: #fff;
            padding: 10px 15px;
            border-radius: 5px;
            font-weight: 500;
        }
        & .btn-close:hover {
            background-color:#cc563d;
            transition: 0.3s all;
        }
        & .btn-save {
            margin-right: 8px;
            background-color:#299cdb;
            color: #fff;
            padding: 10px 15px;
            border-radius: 5px;
            font-weight: 500;
        }
        & .btn-save:hover {
            background-color:#2385ba;
            transition: 0.3s all;
        }
    }
}

.edit-view__container {
    padding: 20px;
}

.edit-view__item__title {
    font-weight: 500;
    width: 200px;
}

.edit-view__item__value {
    width: 100%;
}

.edit-view__item__input {
    width: 100%;
    padding: 5px;
}

.edit-view__item__textarea {
    width: 100%;
    padding: 5px;
    border: 1px solid black;
}

// .edit-view__item__table__container {
//     height: 400px;
// }

.object_tabs {
    height: 50px;
}

.atributes_table .v-data-table__wrapper {
    height: calc(100vh - 240px);
    overflow-y: scroll;
}

.atributes_table {
    height: calc(100vh - 240px);
    overflow-y: scroll;
}
</style>