<template>

    <div
        class="types"
        ref="conteiner"
    >
        <!-- Не вытаскивыть tree-menu из враппера, т.к компонент вызывает сам себя
        и конструкция сломается -->
        <v-navigation-drawer class="tree" v-model="showNavigation" :mini-variant.sync="mini" permanent>
            <div class="navigation-top__conteiner">
                <v-btn
                    icon
                    @click.stop="mini = !mini"
                    >
                    <v-icon>{{mini ? 'mdi-chevron-right' : 'mdi-chevron-left'}}</v-icon>
                </v-btn>
            </div>
            <tree-menu 
                v-for="(node, index) in nodes" 
                :key="index"
                :label="node.label"
                :nodes="node.nodes"
                :nodeData="node.data"
                :depth="0"
                @label-clicked="showEdit($event)"
                type="dictionary"
                :showMainChildren="true"
                :selectedItem="activeItem"
            ></tree-menu>
        </v-navigation-drawer>
        <!--<div class="divider" @mousedown="onColumnMouseDown($event)"></div>--->
        <div class="view">
            <component
                :key="updateKey"
                @table-row-dblclick="showEdit"
                @close-edit="closeEdit"
                @close-create="closeCreate"
                @save-edit="saveEdit"
                @create-dictionary="createDictionary"
                @createDictionaryOpen="createDictionaryOpen"
                @createDictionaryGroupOpen="createDictionaryGroupOpen"
                @delete-dictionary-click="deleteDictionaryOpen"
                :is="activeView"
                :nodes="nodes"
                :editData="editData"
                :creationData="creationData"
                :headers="headers"
                :title="title"
                :dictinaryManagedGroups="dictinaryManagedGroups"
            />
        </div>
            <v-dialog
                v-model="deleteDialog"
                persistent
                max-width="290"
            >
            <v-card>

                <v-card-text>Вы действительно хотите удалить запись?</v-card-text>
                <v-card-actions>
                    <!-- <v-btn
                        color="red darken-1"
                        text
                        @click="deleteDictionary"
                    >Да
                    </v-btn> -->
                    <button 
                        class="btn-save" 
                        @click="deleteDictionary"
                    >Да
                    </button>
                    <!-- <v-btn
                        color="green darken-1"
                        text
                        @click="deleteDialog = false"
                    >Нет
                    </v-btn> -->
                    <button 
                        class="btn-close" 
                        @click="deleteDialog = false"
                    >Нет
                    </button>
                </v-card-actions>
            </v-card>
            </v-dialog>
            <v-snackbar
                v-model="info"
                :color="infoColor"
                :timeout="10000"
            >
                {{infotext}}
            </v-snackbar>
    </div>

</template>

<script>
import { mapGetters, mapActions } from "vuex";

import ListTable from '@/components/ListTable.vue';
import EditView from '@/components/EditView.vue';
import CreateView from '@/components/CreateView.vue';
export default {
    components: {
        ListTable,
        EditView,
        CreateView,
    },
    data() {
        return {
            updateKey: 1,
            editData: null,
            activeView: 'ListTable',
            deleteDialog: false,
            deletedItem: null,
            info: false,
            infotext: '',
            infoColor: '',
            activeItem: null,
            nodes: [
                {
                    label: 'Справочники',
                    data: {
                        id: 710,
                        label: 'Наследование',
                        changeDate: "11.12.1314",
                        version: 1,
                        register: null
                    },
                    nodes: [],
                },
            ],
            title: "",
            creationData: null,
            dictinaryManagedGroups: [],
            headers: [
                {text: 'id', value: 'id'}, 
                {text: 'Код', value: 'code'}, 
                {text: 'Наименование', value: 'name'},
                {text: 'Признак группы', value: 'hasChildDictionaries'},
                {text: 'Дата изменения', value: 'versionDate'},
                {text: 'Версия', value: 'version'},
                {text: 'Действия', value: 'actions'},
            ],
            objectWidth: 20,
            viewWidth: 80,
            point: null,
            showNavigation: true,
            mini: false,
        }
    },
    computed: {
        ...mapGetters(["DICTIONARY_LIST", "TOKEN", "GROUPED_DICTIONARIES", "GROUPED_DICTIONARIES_NEW"]),
    },
    methods: {
        ...mapActions([
            "fetchDictionaries", 
            "fetchInnerDictionaries", 
            "editDictionary", 
            "fetchDictionary", 
            "addRemoveGroupedDictionaries", 
            "createNewDictionary", 
            "deleteDictionaryItem",
            "fetchDictinaryManagedGroups",
            ]),
        closeEdit() {
            this.editData = null;
            this.activeView = 'ListTable';
        },
        closeCreate() {
            this.editData = null;
            this.activeView = 'ListTable'
        },
        saveEdit(data) {
            this.activeView = 'ListTable';
            this.editDictionary(data).then((res)=>{
                this.editData = null;
                this.nodes[0].nodes = res
            }).then(()=>{
                if (data.hasChildDictionaries === 'true') {
                    this.addRemoveGroupedDictionaries(data)
                }
            }).catch((e) => {
                this.editData = null;
                this.infoColor="error"
                this.infotext= e;
                this.info=true;
            })
        },
        showEdit(node) {
            this.activeItem = node;
            this.fetchDictionary(node).then((res)=> {
                this.title = 'Редактирование одиночного справочника'
                this.editData = { ...res.data};
                this.updateKey++;
                this.activeView = 'EditView';
            }).then(()=>{
                if (node.hasChildDictionaries === "true") {
                    return this.fetchInnerDictionaries(node)
                }
            }).then((response)=>{
                if (response) {
                    this.nodes[0].nodes = response
                }
            }).catch((e) => {
                this.infoColor="error"
                this.infotext= e;
                this.info=true;
            })
        },
        createDictionary(data) {
            this.createNewDictionary(data).then((res) => {
                this.nodes[0].nodes = res
                this.creationData = null;
                this.activeView = 'ListTable';
                this.updateKey++;
            }).catch((e) => {
                this.infoColor="error"
                this.infotext= e;
                this.info=true;
            })
        },
        createDictionaryOpen() {
            this.creationData = {
                code: "",
                name: "",
                description: "",
                dictionaryType: "DICTIONARY",
                hasChildDictionaries: "false",
                hasManagedGroup: "false",
                managedGroupId: "0",
            }
            this.title = 'Создание одиночного справочника'
            this.activeView = 'CreateView';
            this.updateKey++;
        },
        createDictionaryGroupOpen() {
            this.creationData = {
                code: "",
                name: "",
                description: "",
                dictionaryType: "DICTIONARY_GROUP",
                hasChildDictionaries: "false",
                hasManagingDictionary: "false",
            }
            this.title = 'Создание группы справочников'
            this.activeView = 'CreateView';
            this.updateKey++;
        },
        deleteDictionaryOpen(node) {
            this.deleteDialog = true;
            this.deletedItem = node
        },
        deleteDictionary() {
            this.deleteDictionaryItem(this.deletedItem).then((res) => {
                this.nodes[0].nodes = res
                this.deletedItem = null;
                this.deleteDialog = false;
            }).catch((e) => {
                this.infoColor="error"
                this.infotext= e;
                this.info=true;
                this.deletedItem = null;
                this.deleteDialog = false;
            })
        },
        onColumnMouseDown(event) {
            event.preventDefault();
            this.point = event.clientX - 18;
            document.onmousemove = this.elementDrag;
            document.onmouseup = this.closeDragElement;
        },
        elementDrag(e) {
            e = e || window.event;
            e.preventDefault();
            const conteinerWidth = this.$refs.conteiner.clientWidth;
            const leftWidth = this.objectWidth * (conteinerWidth/100)  + (e.clientX-18 - this.point);
            this.point = e.clientX-18;
            const rightWidth = conteinerWidth - leftWidth;
            const objectWidth = (leftWidth/conteinerWidth)*100;
            const viewWidth = ((rightWidth)/conteinerWidth)*100;
            if (objectWidth < 20 || viewWidth < 20) {
                return
            }
            this.objectWidth = objectWidth;
            this.viewWidth = viewWidth;
            },
        closeDragElement() {
            document.onmouseup = null;
            document.onmousemove = null;
        },
    },
    mounted() {
        this.fetchDictionaries().then((res)=>{
            this.nodes[0].nodes = res
        }).catch((e) => {
            this.infoColor="error"
            this.infotext= e;
            this.info=true;
        })

        this.fetchDictinaryManagedGroups().then((res)=> {
            const data = res.map((el) => {
                return {text: el.name, value: el.id}
            })
            this.dictinaryManagedGroups = data
        }).catch((e) => {
            this.infoColor="error"
            this.infotext= e;
            this.info=true;
        })
    }
}
</script>

<style lang="scss" scoped>
    .tree {
        width:20%;
        // border: 1px solid #000;
        overflow: auto;
        background-color: #0ab39c!important;
        color: #daf4f0;
        font-weight: 400;
        font-size: 0.875rem;
        height: calc(100vh - 70px)!important;
        padding-top: 15px
    }
    .view {
        width: 100%;
        margin-left: 20px
        // border: 1px solid #000;
    }
    .types {
        display: flex;
        // border: 1px solid #000;
        // background: #f3f3f8;
        padding: 10px 5px 5px 0;
        height: calc(100% - 48px); // 100% - 48px, где 48px - высота табов
        border-radius: 0px !important;
    }

    
    
    .divider {
        background-color: black;
        height: 100%;
        width: 3px;
        cursor: col-resize;
    }
    
    
</style>