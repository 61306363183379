<template>
<div class="table-conteiner">
  <v-data-table
    class="list_table"
    :headers="headers"
    :items="getItems"
    :items-per-page="10000"
    hide-default-footer
    dense
    @dblclick:row="rowClick"
  >
    <template v-slot:item.versionDate="{ item }">
      {{new Date(item.versionDate).toLocaleDateString()}}
    </template>
    <template v-slot:item.actions="{ item }">
      <div>
        <i class="ri ri-pencil-fill" @click="showEditForm(item)"></i>
        <!-- <img
          style="cursor: pointer; margin-right: 10px;"
          :src="require('../assets/edit.png')"
          alt="edit"
          @click="showEditForm(item)"
        /> -->
        <i class="ri ri-delete-bin-fill" @click="showDeleteForm(item)"></i>
        <!-- <img
          style="cursor: pointer"
          :src="require('../assets/trash.png')"
          alt="delete"
          @click="showDeleteForm(item)"
        /> -->
      </div>
    </template>
  </v-data-table>
  <div class="buttons_conteiner">
    <button class="buttons_conteiner__button" @click="$emit('createObjectOpen')">Добавить</button>
    <button class="buttons_conteiner__button" @click="$emit('createDictionaryGroupOpen')">Создать копию</button>
  </div>
</div>
</template>

<script>
export default {
  props: {
    nodes: {
      type: Array,
      default: () => [],
    },
    headers: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      availableHeaders: {
        id: 'ID',
        code: 'Код',
        label: 'Наименование',
        hasChildDictionaries: 'Признак группы',
        changeDate: 'Дата изменения',
        version: 'Версия',
        register: 'Реестр',
      },
    };
  },
  methods: {
    rowClick(e, item) {
      this.$emit('table-row-dblclick', item.item);
    },
    showEditForm(item) {
      this.$emit('table-row-dblclick', item);
    },
    showDeleteForm(item) {
      this.$emit('delete-dictionary-click', item);
    },
  },
  computed: {
    getHeaders() {
      let uniqHeaders = new Set();

      //получаем все уникальные хедеры
      this.nodes.forEach((el) => {
        el.nodes &&
          el.nodes.forEach((item) => {
            for (const [key] of Object.entries(item.data || {})) {
              uniqHeaders.add(key);
            }
          });
      });

      // сопоставляем русские название хедеров c value
      const headers = Array.from(uniqHeaders).map((el) => {
        return { text: this.availableHeaders[el], value: el };
      });

      return headers;
    },
    getItems() {
      const items = [];

      this.nodes.forEach((el) => {
        el.nodes &&
          el.nodes.forEach((item) => {
            items.push(item.data);
          });
      });

      return items;
    },
  },
};
</script>
<style lang="scss">
  .list_table .v-data-table__wrapper{
      height: calc(100vh - 140px);
      overflow-y: scroll;
  }

.buttons_conteiner {
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

  .buttons_conteiner__button {
    margin-right: 8px;
    background-color:#daf4f0;
    color: #099885ff;
    padding: 10px 15px;
    border-radius: 5px;
    font-weight: 500;
  }

  .buttons_conteiner__button:hover {
    background-color:lightblue;
  }


</style>
