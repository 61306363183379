import Vue from 'vue';
import Vuex from 'vuex';

import actions from './actions';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    dictinaryList: [],
    token: 'Basic dGVjaDoxMjM=',
    // token: токен для жилнадзора
    //   'Basic MGYwZGQyZjAtNjg1Yi00NjQwLWI1MjctMTEwOWI3MzFiMTk3OnVybjplaXM6em5hZHpvcg==',
    adminToken: window.btoa(localStorage.getItem('token') + ':urn:eis:reestr'),
    dictinaryManagedGroups: [{ name: 'Не задана', id: '0' }],
    groupedDictionaries: [],
    groupedDictionariesNew: [],
    reportsList: [],
    reportsSources: [],
    reportsTemplates: [],
    reportsTemplatesRegulations: [],
    reportStatuses: [],
    parametersProxies: [],
    dictionaryItemsHeaders: [],
    dictionaryItems: [],
    reportViews: [],
    templatesForReportViews: [],
    objects: [],
    editedObjects: null,
    objectsTypesNodes: [],
    objectTypesTemplates: [],
    objectsFirstLevelNodes: [],
    objectAttributes: [],
    objectTypesAttributes: [],
    objectTypesCopied: [],
    objectTypesGroupOperation: [],
    objectRegisterRelationParts: {},
    objectRelationalObjectAttributes: [],
    objectTemplateRepresentation: { linkedStatuses: [] },
    objectTemplateRegulations: {},
    statusMetaAttributes: [],
    statusMatrixRows: {},
    registerMenu: [],
    unregisteredTemplates: [],
    detailedFormViewMetaAttrs: [],
    detailedFormViewXmlDesc: [],
    detailedFormViewXmlDescMain: {},
    selectedFormViewXmlDescMain: [],
    adminUser: null,
    adminMenu: {},
    adminObjectStatusesRoles: [],
    adminUnAccessedTemplateRoles: [],
    adminPermissionsRolesProxies: [],
    adminAvailableStatuses: [],
    adminRoleAccountMenu: [],
    adminTypesRoles: [],
  },
  mutations: {
    SET_DICTIONARY_LIST: (state, dictinaryList) => {
      state.dictinaryList = dictinaryList;
    },
    SET_TOKEN: (state, token) => {
      state.token = token;
    },
    SET_DICTIONARY_MANAGED_GROUPS: (state, dictinaryManagedGroups) => {
      state.dictinaryManagedGroups = dictinaryManagedGroups;
    },
    ADD_DICTIONARY_MANAGED_GROUPS: (state, newDictinaryManagedGroup) => {
      state.dictinaryManagedGroups.push(newDictinaryManagedGroup);
    },
    SET_GROUPED_DICTIONARIES: (state, groupedDictionaries) => {
      state.groupedDictionaries = groupedDictionaries;
    },
    SET_GROUPED_DICTIONARIES_NEW: (state, groupedDictionariesNew) => {
      state.groupedDictionariesNew = groupedDictionariesNew;
    },
    SET_REPORTS_LIST: (state, reportsList) => {
      state.reportsList = reportsList;
    },
    SET_REPORTS_SOURCES: (state, reportsSources) => {
      state.reportsSources = reportsSources;
    },
    ADD_REPORT: (state, report) => {
      state.reportsList.push(report);
    },
    SET_REPORTS_TEMPLATES: (state, reportsTemplates) => {
      state.reportsTemplates = reportsTemplates;
    },
    DELETE_REPORT: (state, id) => {
      state.reportsList = state.reportsList.filter(
        (report) => report.id !== id
      );
    },
    SET_REPORTS_TEMPLATES_REGULATIONS: (state, reportsTemplatesRegulations) => {
      state.reportsTemplatesRegulations = reportsTemplatesRegulations;
    },
    EDIT_REPORT: (state, report) => {
      const index = state.reportsList.findIndex((rep) => rep.id === report.id);
      state.reportsList[index].nameReport = report.nameReport;
      state.reportsList[index].urlOuterSystem = report.urlOuterSystem;
    },
    SET_REPORTS_STATUSES: (state, reportStatuses) => {
      state.reportStatuses = reportStatuses;
    },
    SET_REPORTS_TEMPLATES_REGULATIONS_ALL_TRUE: (state) => {
      for (let i = 0; i < state.reportsTemplatesRegulations.length; i += 1) {
        state.reportsTemplatesRegulations[i].linked = true;
      }
    },
    SET_REPORTS_TEMPLATES_REGULATIONS_ALL_FALSE: (state) => {
      for (let i = 0; i < state.reportsTemplatesRegulations.length; i += 1) {
        state.reportsTemplatesRegulations[i].linked = false;
      }
    },
    SET_REPORTS_PARAMETERS_PROXIES: (state, parametersProxies) => {
      state.parametersProxies = parametersProxies;
    },
    ADD_REPORT_PARAMETER: (state, parameter) => {
      state.parametersProxies.push(parameter);
    },
    DELETE_REPORT_PARAMETER: (state, id) => {
      state.parametersProxies = state.parametersProxies.filter(
        (parameter) => parameter.id !== id
      );
    },
    UPDATE_REPORT_PARAMETER: (state, param) => {
      const index = state.parametersProxies.findIndex(
        (el) => el.id === param.id
      );
      for (let prop in param) {
        state.parametersProxies[index][prop] = param[prop];
      }
    },
    SET_DICTIONARY_ITEMS_HEADERS: (state, dictionaryItemsHeaders) => {
      state.dictionaryItemsHeaders = dictionaryItemsHeaders;
    },
    SET_DICTIONARY_ITEMS: (state, dictionaryItems) => {
      state.dictionaryItems = dictionaryItems;
    },
    SET_REPORT_VIEWS: (state, reportViews) => {
      state.reportViews = reportViews;
    },
    SORT_REPORT_VIEWS: (state, reportViews) => {
      console.log(reportViews);
      state.reportViews = reportViews;
    },
    SET_TEMPLATES_FOR_REPORT_VIEWS: (state, templatesForReportViews) => {
      state.templatesForReportViews = templatesForReportViews;
    },
    UPDATE_TEMPLATE_FOR_REPORT_VIEWS: (
      state,
      { reportAttributes, templateId, parentId }
    ) => {
      if (parentId) {
        const index = state.templatesForReportViews.findIndex(
          (el) => el.id === parentId
        );
        const innerIndex = state.templatesForReportViews[index].nodes.findIndex(
          (el) => el.id === templateId
        );
        state.templatesForReportViews[index].nodes[innerIndex].nodes =
          reportAttributes;
      } else {
        const index = state.templatesForReportViews.findIndex(
          (el) => el.id === templateId
        );
        state.templatesForReportViews[index].nodes = reportAttributes;
      }
    },
    SELECT_TEMPLATE_FOR_REPORT_VIEWS: (state, item) => {
      const index = state.templatesForReportViews.findIndex(
        (el) => el.id === item.parentId
      );
      state.templatesForReportViews[index].selected = true;
      if (item.columns) {
        const selectedItems = item.columns.map((el) => el.attributeId);
        state.templatesForReportViews[index].nodes.forEach((el) => {
          if (selectedItems.includes(el.id)) {
            el.selected = true;
            if (item.joinedGroupsColumns && el.nodes) {
              const joinedGroup = item.joinedGroupsColumns.find(
                (joinedEl) => joinedEl.linkAttributeId === el.id
              );
              const selectedJoinedGroupItems = joinedGroup.columns.map(
                (el) => el.attributeId
              );
              const innerIndex = state.templatesForReportViews[
                index
              ].nodes.findIndex((innerEl) => innerEl.id === el.id);
              state.templatesForReportViews[index].nodes[
                innerIndex
              ].nodes.forEach((innerEl) => {
                if (selectedJoinedGroupItems.includes(innerEl.id)) {
                  innerEl.selected = true;
                }
              });
            }
          }
        });
      }
    },
    RESET_ALL_SELECTION_TEMPLATE_FOR_REPORT_VIEWS: (state) => {
      for (let template of state.templatesForReportViews) {
        template.selected = false;
        for (let templateInner of template.nodes) {
          templateInner.selected = false;
          if (templateInner.nodes) {
            for (let child of templateInner.nodes) {
              child.selected = false;
            }
          }
        }
      }
    },
    UPDATE_REPORT_VIEW: (state, updatedItem) => {
      const index = state.reportViews.findIndex(
        (el) => el.id === updatedItem.id
      );
      for (let prop in updatedItem) {
        state.reportViews[index][prop] = updatedItem[prop];
      }
    },
    ADD_REPORT_VIEW: (state, createdItem) => {
      state.reportViews.push(createdItem);
    },
    DELETE_REPORT_VIEW: (state, deletedItemId) => {
      state.reportViews = state.reportViews.filter(
        (reportView) => reportView.id !== deletedItemId
      );
    },
    COLLAPSE_ALL_TEMPLATES_FOR_REPORT_VIEWS: (state) => {
      for (let template of state.templatesForReportViews) {
        template.expanded = [];
      }
    },
    SET_OBJECTS: (state, objects) => {
      state.objects = objects;
    },
    SET_CHILD_OBJECTS: (state, { tableData, node }) => {
      const index = state.objects.findIndex((obj) => obj.data.id === node.id);
      state.objects[index].nodes = tableData;
    },
    SET_EDITED_OBJECT: (state, object) => {
      state.editedObjects = object;
    },
    SET_OBJECTS_TYPES_NODES: (state, objectsTypesNodes) => {
      state.objectsTypesNodes = objectsTypesNodes;
    },
    SET_OBJECTS_TYPES_TEMPLATES: (state, objectTypesTemplates) => {
      state.objectTypesTemplates = objectTypesTemplates;
    },
    SET_OBJECTS_FIRST_LEVEL_NODES: (state, objectsFirstLevelNodes) => {
      state.objectsFirstLevelNodes = [
        { data: { name: 'ROOT', id: 1 }, label: 'ROOT', nodes: [] },
        ...objectsFirstLevelNodes,
      ];
    },
    SET_OBJECTS_FIRST_LEVEL_NODES_CHILDREN: (state, { tableData, node }) => {
      const index = state.objectsFirstLevelNodes[0].nodes.findIndex(
        (obj) => obj.data.id === node.id
      );
      state.objectsFirstLevelNodes[0].nodes[index].nodes = tableData;
    },
    DELETE_OBJECT: (state, deletedItemId) => {
      state.objects = state.objects.filter((el) => el.id !== deletedItemId);
    },
    UPDATE_OBJECT: (state, updatedObject) => {
      if (updatedObject.parentId == 1) {
        const index = state.objects.findIndex(
          (obj) => obj.data.id === updatedObject.id
        );
        state.objects[index].data = updatedObject;
        state.objects[index].label = updatedObject.name;
      } else {
        const parentIndex = state.objects.findIndex(
          (obj) => obj.data.id === updatedObject.parentId
        );
        const index = state.objects[parentIndex].nodes.findIndex(
          (obj) => obj.data.id === updatedObject.id
        );

        state.objects[parentIndex].nodes[index].data = updatedObject;
        state.objects[parentIndex].nodes[index].label = updatedObject.name;
      }
    },
    SET_OBJECTS_ATTRIBUTES: (state, objectAttributes) => {
      state.objectAttributes = objectAttributes;
    },
    SET_OBJECTS_TYPES_ATRIBUTES: (state, objectTypesAttributes) => {
      state.objectTypesAttributes = objectTypesAttributes;
    },
    SET_OBJECTS_TYPES_COPIED: (state, objectTypesCopied) => {
      state.objectTypesCopied = objectTypesCopied;
    },
    SET_OBJECTS_TYPES_GROUP_OPERATION: (state, objectTypesGroupOperation) => {
      state.objectTypesGroupOperation = objectTypesGroupOperation;
    },
    SET_OBJECTS_REGISTER_RELATION_PARTS: (
      state,
      objectRegisterRelationParts
    ) => {
      state.objectRegisterRelationParts = objectRegisterRelationParts;
    },
    SET_OBJECT_RELATIONAL_OBJECT_ATTRIBUTES: (
      state,
      objectRelationalObjectAttributes
    ) => {
      state.objectRelationalObjectAttributes = objectRelationalObjectAttributes;
    },
    DELETE_META_ATTRIBUTE: (state, deletedItem) => {
      state.objectAttributes = state.objectAttributes.filter(
        (el) => el.attributeObjectType.id !== deletedItem.attributeObjectType.id
      );
    },
    ADD_OBJECTS_TYPES_ATRIBUTES: (state, objectTypesAttribute) => {
      state.objectTypesAttributes.push(objectTypesAttribute);
    },
    SET_OBJECTS_TEMPLATE_REPRESENTATION: (
      state,
      objectTemplateRepresentation
    ) => {
      state.objectTemplateRepresentation = objectTemplateRepresentation;
    },
    SET_OBJECTS_TEMPLATE_REGULATIONS: (state, objectTemplateRegulations) => {
      state.objectTemplateRegulations = objectTemplateRegulations;
    },
    SET_STATUS_META_ATTRIBUTES: (state, statusMetaAttributes) => {
      console.log(328,statusMetaAttributes)
      state.statusMetaAttributes = statusMetaAttributes;
    },
    ADD_OBJECTS_TEMPLATE_REGULATIONS: (state, newItem) => {
      state.objectTemplateRegulations.linkedStatuses.push({ ...newItem });
    },
    CLEAR_STATUS_META_ATTRIBUTES: (state) => {
      state.statusMetaAttributes = {};
    },
    SET_STATUS_MATRIX_ROWS: (state, statusMatrixRows) => {
      state.statusMatrixRows = statusMatrixRows;
    },
    CLEAR_STATUS_MATRIX_ROWS: (state) => {
      state.statusMatrixRows = [];
    },
    SET_REGISTER_MENU: (state, registerMenu) => {
      state.registerMenu = registerMenu;
    },
    ADD_REGISTER_MENU: (state, newRegisterMenu) => {
      state.registerMenu.push(newRegisterMenu);
    },
    SET_UNREGISTERED_TEMPLATES: (state, unregisteredTemplates) => {
      state.unregisteredTemplates = unregisteredTemplates;
    },
    SET_DETAILED_FORM_VIEW_META_ATTRS: (state, detailedFormViewMetaAttrs) => {
      state.detailedFormViewMetaAttrs = detailedFormViewMetaAttrs;
    },
    SET_DETAILED_FORM_VIEW_XML_DESC: (state, detailedFormViewXmlDesc) => {
      state.detailedFormViewXmlDesc = detailedFormViewXmlDesc;
    },
    REMOVE_DETAILED_FORM_VIEW_XML_DESC: (state, item) => {
      console.log(item);
      state.detailedFormViewXmlDesc = state.detailedFormViewXmlDesc.filter(
        (el) => el.id !== item
      );
    },
    SET_DETAILED_FORM_VIEW_XML_DESC_MAIN: (state, detailedFormViewXmlDesc) => {
      state.detailedFormViewXmlDescMain = detailedFormViewXmlDesc;
    },
    SET_SELECTED_FORM_VIEW_XML_DESC_MAIN: (
      state,
      selectedFormViewXmlDescMain
    ) => {
      state.selectedFormViewXmlDescMain = selectedFormViewXmlDescMain;
    },
    REMOVE_SELECTED_FORM_VIEW_XML_DESC_MAIN: (state, attributeId) => {
      state.selectedFormViewXmlDescMain =
        state.selectedFormViewXmlDescMain.filter((el) => el !== attributeId);
    },
    LOGOUT: (state) => {
      state.token = null;
      localStorage.removeItem('token');
    },
    SET_ADMIN_USER: (state, payload) => {
      state.adminUser = payload;
    },
    SET_ADMIN_MENU: (state, payload) => {
      state.adminMenu = payload;
    },
    SET_ADMIN_OBJECT_STATUSES_ROLES: (state, payload) => {
      state.adminObjectStatusesRoles = payload;
    },
    SET_ADMIN_UN_ACCESSED_TEMPLATE_ROLES: (state, payload) => {
      state.adminUnAccessedTemplateRoles = payload;
    },
    SET_ADMIN_PERMISSIONS_ROLES_PROXIES: (state, payload) => {
      state.adminPermissionsRolesProxies = payload;
    },
    SET_ADMIN_AVAILABLE_STATUSES: (state, payload) => {
      state.adminAvailableStatuses = payload;
    },
    SET_ADMIN_ROLE_ACCOUNT_MENU: (state, payload) => {
      state.adminRoleAccountMenu = payload;
    },
    SET_ADMIN_TYPES_ROLE: (state, payload) => {
      state.adminTypesRoles = payload;
    },
  },
  actions,
  getters: {
    DICTIONARY_LIST: (state) => {
      return state.dictinaryList;
    },
    TOKEN: (state) => {
      return state.token;
    },
    DICTIONARY_MANAGED_GROUPS: (state) => {
      return state.dictinaryManagedGroups;
    },
    GROUPED_DICTIONARIES: (state) => {
      return state.groupedDictionaries;
    },
    GROUPED_DICTIONARIES_NEW: (state) => {
      return state.groupedDictionariesNew;
    },
    REPORTS_LIST: (state) => {
      return state.reportsList;
    },
    REPORTS_SOURCES: (state) => {
      return state.reportsSources;
    },
    REPORTS_TEMPLATES: (state) => {
      return state.reportsTemplates;
    },
    REPORTS_TEMPLATES_REGULATIONS: (state) => {
      return state.reportsTemplatesRegulations;
    },
    REPORTS_STATUSES: (state) => {
      return state.reportStatuses;
    },
    REPORTS_PARAMETERS_PROXIES: (state) => {
      return state.parametersProxies;
    },
    DICTIONARY_ITEMS_HEADERS: (state) => {
      return state.dictionaryItemsHeaders;
    },
    DICTIONARY_ITEMS: (state) => {
      return state.dictionaryItems;
    },
    REPORT_VIEWS: (state) => {
      return state.reportViews;
    },
    TEMPLATES_FOR_REPORT_VIEWS: (state) => {
      return state.templatesForReportViews;
    },
    OBJECTS: (state) => {
      return state.objects;
    },
    EDITED_OBJECT: (state) => {
      return state.editedObjects;
    },
    OBJECTS_TYPES_NODES: (state) => {
      return state.objectsTypesNodes;
    },
    OBJECTS_TYPES_TEMPLATES: (state) => {
      return state.objectTypesTemplates;
    },
    OBJECTS_FIRST_LEVEL_NODES: (state) => {
      return state.objectsFirstLevelNodes;
    },
    OBJECTS_ATTRIBUTES: (state) => {
      return state.objectAttributes;
    },
    OBJECTS_TYPES_ATRIBUTES: (state) => {
      return state.objectTypesAttributes;
    },
    OBJECTS_TYPES_COPIED: (state) => {
      return state.objectTypesCopied;
    },
    OBJECTS_TYPES_GROUP_OPERATION: (state) => {
      return state.objectTypesGroupOperation;
    },
    OBJECTS_REGISTER_RELATION_PARTS: (state) => {
      return state.objectRegisterRelationParts;
    },
    OBJECT_RELATIONAL_OBJECT_ATTRIBUTES: (state) => {
      return state.objectRelationalObjectAttributes;
    },
    OBJECTS_TEMPLATE_REPRESENTATION: (state) => {
      return state.objectTemplateRepresentation;
    },
    OBJECTS_TEMPLATE_REGULATIONS: (state) => {
      return state.objectTemplateRegulations;
    },
    STATUS_META_ATTRIBUTES: (state) => {
      return state.statusMetaAttributes;
    },
    STATUS_MATRIX_ROWS: (state) => {
      return state.statusMatrixRows;
    },
    REGISTER_MENU: (state) => {
      return state.registerMenu;
    },
    UNREGISTERED_TEMPLATES: (state) => {
      return state.unregisteredTemplates;
    },
    DETAILED_FORM_VIEW_META_ATTRS: (state) => {
      return state.detailedFormViewMetaAttrs;
    },
    DETAILED_FORM_VIEW_XML_DESC: (state) => {
      return state.detailedFormViewXmlDesc;
    },
    DETAILED_FORM_VIEW_XML_DESC_MAIN: (state) => {
      return state.detailedFormViewXmlDescMain;
    },
    SELECTED_FORM_VIEW_XML_DESC_MAIN: (state) => {
      return state.selectedFormViewXmlDescMain;
    },
    ADMIN_USER: (state) => {
      return state.adminUser;
    },
    ADMIN_MENU: (state) => {
      return state.adminMenu;
    },
    ADMIN_OBJECT_STATUSES_ROLES: (state) => {
      return state.adminObjectStatusesRoles;
    },
    ADMIN_UN_ACCESSED_TEMPLATE_ROLES: (state) => {
      return state.adminUnAccessedTemplateRoles;
    },
    ADMIN_PERMISSIONS_ROLES_PROXIES: (state) => {
      return state.adminPermissionsRolesProxies;
    },
    ADMIN_AVAILABLE_STATUSES: (state) => {
      return state.adminAvailableStatuses;
    },
    ADMIN_ROLE_ACCOUNT_MENU: (state) => {
      return state.adminRoleAccountMenu;
    },
    ADMIN_TYPES_ROLE: (state) => {
      return state.adminTypesRoles;
    },
  },
});
