<template>
  <div class="object-reglament">
    <div v-if="state === 'reglament'">
      <div class="object-reglament__top">
        <div class="object-reglament__top__item">
          <label for="version">Версия</label>
          <input
            id="version"
            disabled
            class="object-reglament__top__item__input"
            v-model="OBJECTS_TEMPLATE_REGULATIONS.version"
          />
        </div>
        <div class="object-reglament__top__item">
          <label for="date">Дата изменения</label>
          <input
            id="data"
            disabled
            class="object-reglament__top__item__input"
            :value="
              new Date(
                OBJECTS_TEMPLATE_REGULATIONS.versionDate
              ).toLocaleString()
            "
          />
        </div>
      </div>
      <div class="object-reglament__middle">
        <p>Список статусов:</p>
        <v-data-table
          class="object-reglament__middle__table"
          :headers="headers"
          :items="OBJECTS_TEMPLATE_REGULATIONS.linkedStatuses"
          :items-per-page="10000"
          hide-default-footer
          dense
          @dblclick:row="rowClick"
        >
          <template v-slot:item.transit="{ item }">
            {{
              item.availableStatuses
                ? item.availableStatuses.map((el) => el.name).join(', ')
                : ''
            }}
          </template>
          <template v-slot:item.initialState="{ item }">
            <input
              type="checkbox"
              :checked="item.statusType === 'BEGIN_STATUS'"
              disabled
            />
          </template>
          <template v-slot:item.finalState="{ item }">
            <input
              type="checkbox"
              :checked="item.statusType === 'FINAL_STATUS'"
              disabled
            />
          </template>
        </v-data-table>
      </div>
      <div class="object-reglament__bottom">
        <button class="buttons_conteiner__button" @click="openAddStatus">
          Добавить статус
        </button>
        <button class="buttons_conteiner__button" @click="openTransitTable">
          Редактировать таблицу переходов
        </button>
      </div>
    </div>

    <div v-if="state === 'addStatus'">
      <div class="object-reglament__top__add">
        <div class="object-reglament__top__item">
          <label for="name" class="object-reglament__top__item__label"
            >Наименование:</label
          >
          <input
            id="name"
            class="object-reglament__top__item__input"
            v-model="STATUS_META_ATTRIBUTES.name"
          />
        </div>
        <div class="object-reglament__top__item">
          <label for="action" class="object-reglament__top__item__label"
            >Действие:</label
          >
          <input
            id="action"
            class="object-reglament__top__item__input"
            v-model="STATUS_META_ATTRIBUTES.action"
          />
        </div>
        <div class="object-reglament__top__item">
          <label for="code" class="object-reglament__top__item__label"
            >Код:</label
          >
          <input
            id="code"
            class="object-reglament__top__item__input"
            v-model="STATUS_META_ATTRIBUTES.code"
          />
        </div>
        <div class="object-reglament__top__item">
          <label for="statusType" class="object-reglament__top__item__label"
            >Тип статуса:</label
          >
          <v-select
            id="statusType"
            v-model="STATUS_META_ATTRIBUTES.statusType"
            :items="[
              { name: 'Начальный', value: 'BEGIN_STATUS' },
              { name: 'Промежуточный', value: null },
              { name: 'Конечный', value: 'FINAL_STATUS' },
            ]"
            item-text="name"
            item-value="value"
          ></v-select>
        </div>
        <div class="object-reglament__top__item">
          <label for="controlterm" class="object-reglament__top__item__label"
            >Контрольный срок, дн.:</label
          >
          <v-text-field
            id="controlterm"
            v-model="STATUS_META_ATTRIBUTES.term"
            type="number"
          ></v-text-field>
        </div>
        <div class="object-reglament__top__item">
          <label for="deleted" class="object-reglament__top__item__label"
            >Удаленный:</label
          >
          <v-checkbox
            id="deleted"
            v-model="STATUS_META_ATTRIBUTES.deleted"
          ></v-checkbox>
        </div>
        <div class="object-reglament__top__item">
          <label for="archived" class="object-reglament__top__item__label"
            >Архивный:</label
          >
          <v-checkbox
            id="archived"
            v-model="STATUS_META_ATTRIBUTES.archival"
          ></v-checkbox>
        </div>
        <div v-if="STATUS_META_ATTRIBUTES.noticeTemplateType">
          <div class="object-reglament__top__item">
            <label for="archived" class="object-reglament__top__item__label"
              >Уведомление по email:</label
            >
            <v-checkbox
              id="archived"
              v-model="STATUS_META_ATTRIBUTES.noticeTemplateType.email"
            ></v-checkbox>
          </div>

          <div class="object-reglament__top__item">
            <label for="action" class="object-reglament__top__item__label"
              >Текст email уведомления:</label
            >
            <input
              id="action"
              class="object-reglament__top__item__input"
              v-model="STATUS_META_ATTRIBUTES.noticeTemplateType.emailText"
            />
          </div>
          <div class="object-reglament__top__item">
            <label for="archived" class="object-reglament__top__item__label"
              >Уведомление по sms:</label
            >
            <v-checkbox
              id="archived"
              v-model="STATUS_META_ATTRIBUTES.noticeTemplateType.sms"
            ></v-checkbox>
          </div>
          <div class="object-reglament__top__item">
            <label for="action" class="object-reglament__top__item__label"
              >Текст sms уведомления:</label
            >
            <input
              id="action"
              class="object-reglament__top__item__input"
              v-model="STATUS_META_ATTRIBUTES.noticeTemplateType.smsText"
            />
          </div>
          <div class="object-reglament__top__item">
            <label for="archived" class="object-reglament__top__item__label"
              >Push уведомления:</label
            >
            <v-checkbox
              id="archived"
              v-model="STATUS_META_ATTRIBUTES.noticeTemplateType.push"
            ></v-checkbox>
          </div>
          <div class="object-reglament__top__item">
            <label for="action" class="object-reglament__top__item__label"
              >Текст Push уведомления:</label
            >
            <input
              id="action"
              class="object-reglament__top__item__input"
              v-model="STATUS_META_ATTRIBUTES.noticeTemplateType.pushText"
            />
          </div>
        </div>
        <div class="object-reglament__top__item">
          <label for="group" class="object-reglament__top__item__label"
            >Признак разрешения груповой обработки:</label
          >
          <v-checkbox
            id="group"
            v-model="STATUS_META_ATTRIBUTES.groupChange"
          ></v-checkbox>
        </div>
        <div class="object-reglament__top__item">
          <label for="unity" class="object-reglament__top__item__label"
            >Признак объединения смены статуса с операцией сохранения:</label
          >
          <v-checkbox
            id="unity"
            v-model="STATUS_META_ATTRIBUTES.saveOnChange"
          ></v-checkbox>
        </div>
        <div class="object-reglament__top__item">
          <label for="calculation" class="object-reglament__top__item__label"
            >Признак расчетов перед входом в статус:</label
          >
          <v-checkbox
            id="calculation"
            v-model="beforeStatus"
            @change="changeBeforeStatus"
            :disabled="STATUS_META_ATTRIBUTES.statusType === 'FINAL_STATUS'"
          ></v-checkbox>
          <input
            v-if="beforeStatus"
            placeholder="Введите наименование серверного метода"
            class="object-reglament__top__item__input"
            v-model="STATUS_META_ATTRIBUTES.statusActionOnEnter.actionCode"
          />
          <input
            v-if="beforeStatus"
            placeholder="Введите сообщение пользователю"
            class="object-reglament__top__item__input"
            v-model="STATUS_META_ATTRIBUTES.statusActionOnEnter.blockingMessage"
          />
          <input
            v-if="beforeStatus"
            type="number"
            placeholder="Частота опроса (сек.)"
            class="object-reglament__top__item__input"
            v-model="STATUS_META_ATTRIBUTES.statusActionOnEnter.pollingInterval"
          />
        </div>
        <div class="object-reglament__top__item">
          <label for="calculation" class="object-reglament__top__item__label"
            >Признак расчетов при сохранении:</label
          >
          <v-checkbox
            id="onSave"
            v-model="onSave"
            @change="changeOnSave"
            :disabled="STATUS_META_ATTRIBUTES.statusType === 'FINAL_STATUS'"
          ></v-checkbox>
          <input
            v-if="onSave"
            placeholder="Введите наименование серверного метода"
            class="object-reglament__top__item__input"
            v-model="STATUS_META_ATTRIBUTES.statusActionOnSave.actionCode"
          />
        </div>
        <div class="object-reglament__top__item">
          <p>Сделать все атрибуты:</p>
          <v-radio-group v-model="atributesAll" row>
            <v-radio
              label="Невидимыми"
              value="invisible"
              @change="changeAccessAll('invisible')"
            ></v-radio>
            <v-radio
              label="Видимыми"
              value="accessReadOnly"
              @change="changeAccessAll('accessReadOnly')"
            ></v-radio>
            <v-radio
              label="Редактируемыми"
              value="accessFull"
              @change="changeAccessAll('accessFull')"
            ></v-radio>
          </v-radio-group>
        </div>
        <v-data-table
          class="object-reglament__middle__table__add"
          :headers="headersAdd"
          :items="STATUS_META_ATTRIBUTES.accessedMetaAttributes"
          :items-per-page="10000"
          hide-default-footer
          dense
        >
          <template v-slot:item.invisible="{ item }">
            <v-checkbox
              v-model="item.invisible"
              @change="changeAccess($event, item, 'invisible')"
              :disabled="item.invisible"
            ></v-checkbox>
          </template>
          <template v-slot:item.accessReadOnly="{ item }">
            <v-checkbox
              v-model="item.accessReadOnly"
              @change="changeAccess($event, item, 'accessReadOnly')"
              :disabled="item.accessReadOnly"
            ></v-checkbox>
          </template>
          <template v-slot:item.accessFull="{ item }">
            <v-checkbox
              v-model="item.accessFull"
              @change="changeAccess($event, item, 'accessFull')"
              :disabled="item.accessFull"
            ></v-checkbox>
          </template>
        </v-data-table>
      </div>
      <div class="object-reglament__bottom">
        <button class="buttons_conteiner__button" @click="onAddStatus">
          Сохранить
        </button>
        <button class="buttons_conteiner__button" @click="state = 'reglament'">
          Отмена
        </button>
      </div>
    </div>
    <div v-if="state === 'transitTable'">
      <p>Таблица переходов между статусами:</p>
      <v-data-table
        class="object-reglament__middle__table"
        :headers="hedersTransitTable"
        :items="STATUS_MATRIX_ROWS"
        :items-per-page="10000"
        hide-default-footer
        dense
      >
        <template v-slot:item="{ item, index }">
          <tr>
            <td
              v-for="(column, columnIndex) of hedersTransitTable"
              :key="`${index}-${column.value}`"
            >
              <span v-if="column.value === 'name'">{{ item.name }}</span>
              <v-checkbox
                v-else
                v-model="item.statusMatrixCells[columnIndex - 1].transition"
                :disabled="item.id === column.value"
              ></v-checkbox>
            </td>
          </tr>
        </template>
      </v-data-table>
      <div class="object-reglament__bottom">
        <button class="buttons_conteiner__button" @click="onSaveTransitTable">
          Сохранить
        </button>
        <button class="buttons_conteiner__button" @click="state = 'reglament'">
          Отмена
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  data() {
    return {
      headers: [
        { text: 'ID', value: 'id' },
        { text: 'Код', value: 'code' },
        { text: 'Наим.', value: 'name' },
        { text: 'Переход', value: 'transit' },
        { text: 'Срок', value: 'term' },
        { text: 'Нач ст.', value: 'initialState' },
        { text: 'Кон ст.', value: 'finalState' },
      ],
      state: 'reglament',
      onSave: false,
      beforeStatus: false,
      atributesAll: null,
      headersAdd: [
        { text: 'ID', value: 'id' },
        { text: 'Код', value: 'code' },
        { text: 'Наим.', value: 'name' },
        { text: 'Не видно', value: 'invisible' },
        { text: 'Видно', value: 'accessReadOnly' },
        { text: 'Ред.', value: 'accessFull' },
      ],
      hedersTransitTable: [{ text: 'Исходный статус', value: 'name' }],
    };
  },
  computed: {
    ...mapGetters([
      'OBJECTS_TEMPLATE_REGULATIONS',
      'STATUS_META_ATTRIBUTES',
      'STATUS_MATRIX_ROWS',
    ]),
  },
  methods: {
    ...mapActions([
      'getStatusMetaAttributes',
      'getStatusMatrix',
      'setStatusMatrix',
    ]),
    openAddStatus() {
      this.onGetStatusMetaAttributes();
      this.state = 'addStatus';
    },
    onAddStatus() {
      for (let item of this.STATUS_META_ATTRIBUTES.accessedMetaAttributes) {
        delete item.invisible;
      }
      this.$emit('create_Status_Meta_Attributes');
      this.onSave = false;
      this.beforeStatus = false;
      this.state = 'reglament';
    },
    changeAccess(e, item, type) {
      item.accessFull = false;
      item.accessReadOnly = false;
      item.invisible = false;
      item[type] = e;
    },
    changeAccessAll(type) {
      for (let item of this.STATUS_META_ATTRIBUTES.accessedMetaAttributes) {
        item.accessFull = false;
        item.accessReadOnly = false;
        item.invisible = false;
        item[type] = true;
      }
    },
    changeBeforeStatus(e) {
      if (e) {
        this.STATUS_META_ATTRIBUTES.statusActionOnEnter = {
          actionCode: null,
          blockingMessage: null,
          pollingInterval: 0,
        };
      } else {
        this.STATUS_META_ATTRIBUTES.statusActionOnEnter = null;
      }
    },
    changeOnSave(e) {
      if (e) {
        this.STATUS_META_ATTRIBUTES.statusActionOnSave = {
          actionCode: null,
        };
      } else {
        this.STATUS_META_ATTRIBUTES.statusActionOnSave = null;
      }
    },
    rowClick(e, item) {
      this.onSave = false;
      this.beforeStatus = false;
      this.onGetStatusMetaAttributes(item.item);
      this.state = 'addStatus';
    },
    onGetStatusMetaAttributes(item) {
      const statusId = item ? item.id : '0';
      const regulationsId = item
        ? item.parentId
        : this.OBJECTS_TEMPLATE_REGULATIONS.id;
      this.getStatusMetaAttributes({ statusId, regulationsId })
        .then(() => {
          if (
            this.STATUS_META_ATTRIBUTES.statusActionOnSave &&
            this.STATUS_META_ATTRIBUTES.statusActionOnSave !== null
          ) {
            this.onSave = true;
          }
          if (
            this.STATUS_META_ATTRIBUTES.statusActionOnEnter &&
            this.STATUS_META_ATTRIBUTES.statusActionOnEnter !== null
          ) {
            this.beforeStatus = true;
          }
          // c бэкенда приходят эти поля в виде string значения true или false, поэтому меняем таким образом
          if (this.STATUS_META_ATTRIBUTES.noticeTemplateType) {
            this.STATUS_META_ATTRIBUTES.noticeTemplateType.email =
              this.STATUS_META_ATTRIBUTES.noticeTemplateType.email.length === 4
                ? true
                : false;
            this.STATUS_META_ATTRIBUTES.noticeTemplateType.sms =
              this.STATUS_META_ATTRIBUTES.noticeTemplateType.sms.length === 4
                ? true
                : false;
            this.STATUS_META_ATTRIBUTES.noticeTemplateType.push =
              this.STATUS_META_ATTRIBUTES.noticeTemplateType.push.length === 4
                ? true
                : false;
          }
        })
        .catch((e) => {
          this.$emit('error', e);
        });
    },
    openTransitTable() {
      this.getStatusMatrix(this.OBJECTS_TEMPLATE_REGULATIONS.id)
        .then(() => {
          this.hedersTransitTable = [
            { text: 'Исходный статус', value: 'name' },
          ];
          this.hedersTransitTable.push(
            ...this.STATUS_MATRIX_ROWS[0].statusMatrixCells.map((el) => {
              return { text: el.name, value: el.id, width: 30 };
            })
          );
          this.state = 'transitTable';
        })
        .catch((e) => {
          this.$emit('error', e);
        });
    },
    onSaveTransitTable() {
      const statusMatrixType = {
        regulationsId: this.OBJECTS_TEMPLATE_REGULATIONS.id,
        statusMatrixRows: this.STATUS_MATRIX_ROWS,
      };
      this.setStatusMatrix(statusMatrixType)
        .then(() => {
          this.state = 'reglament';
          this.hedersTransitTable = [
            { text: 'Исходный статус', value: 'name' },
          ];
        })
        .catch((e) => {
          this.$emit('error', e);
        });
    },
  },
  mounted() {
    console.log(342, this.STATUS_META_ATTRIBUTES);
    setTimeout(() => {
      console.log(3433, this.STATUS_META_ATTRIBUTES);
    }, 5000);
    this.$emit('get-Template-Regulations');
  },
};
</script>

<style lang="scss" scoped>
.object-reglament__top {
  display: flex;
  justify-content: flex-start;
}

.object-reglament__bottom {
  margin-top: 10px;
}
.object-reglament__top__item {
  margin-left: 10px;
}

.object-reglament__top__item__input {
  border: 1px solid black;
  width: 76%;
  margin-left: 24px;
}

.object-reglament__middle__table {
  max-height: 70vh;
  overflow-y: scroll;
  max-width: 100%;
}

.object-reglament__top__item__label {
  width: 300px;
  display: block;
}

.object-reglament__top__item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 5px;
  height: 37px !important;
}
.object-reglament__top__item p {
  margin-right: 7px;
}
.object-reglament__middle__table__add {
  height: 300px;
  overflow-y: scroll;
}

.object-reglament {
  overflow-y: scroll;
  overflow-x: hidden;
  height: calc(100vh - 210px);
}
</style>
