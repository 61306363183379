import axios from 'axios';

import config from '@/config';

const round = (tree, acc, element, arrProps, emptyNull = false) => {
  const tableData = [];
  const inner = (tree, acc, element) => {
    if (tree.childNodes.length <= 1 && acc !== 0) {
      if (emptyNull && tree.textContent === '') {
        element[tree.tagName] = null;
        return;
      } else {
        element[tree.tagName] = tree.textContent;
        return;
      }
    }
    if (acc === 1) {
      const newEl = {};
      tableData.push(newEl);
      const children = tree.childNodes;
      children.forEach((el) => inner(el, acc + 1, newEl));
    } else {
      let newEl = {};
      if (arrProps.includes(tree.tagName)) {
        if (element[tree.tagName]) {
          element[tree.tagName].push(newEl);
        } else {
          element[tree.tagName] = [newEl];
        }
      } else {
        element[tree.tagName] = newEl;
      }
      const children = tree.childNodes;
      children.forEach((el) => inner(el, acc + 1, newEl));
    }
  };
  inner(tree, acc, element);
  return tableData;
};

const jsToXmlParser = (item, xmlAttributes = [], xmlType = '') => {
  const result = [];
  const inner = (tree, arr) => {
    for (let key in tree) {
      if (typeof tree[key] === 'string') {
        arr.push(`<${key}>${tree[key]}</${key}>`);
      } else if (tree[key] === null) {
        if (xmlAttributes.includes(key)) {
          arr.push(`<${key} xsi:type="reg:${xmlType}"/>`);
        } else {
          arr.push(`<${key} xsi:nil="true"/>`);
        }
      } else if (Array.isArray(tree[key])) {
        const newArr = [];
        for (let obj of tree[key]) {
          newArr.push(
            `<${key}${
              xmlAttributes.includes(key) ? ` xsi:type="reg:${xmlType}"` : ''
            }>${inner(obj, []).join('')}</${key}>`
          );
        }
        arr.push(`${newArr.join('')}`);
      } else if (typeof tree[key] === 'boolean') {
        arr.push(`<${key}>${tree[key]}</${key}>`);
      } else if (typeof tree[key] === 'number') {
        arr.push(`<${key}>${tree[key]}</${key}>`);
      } else {
        arr.push(`<${key}>${inner(tree[key], []).join('')}</${key}>`);
      }
    }
    return arr;
  };
  inner(item, result);
  return result;
};

const fetchDictionaries = (context) => {
  return new Promise((resolve, reject) => {
    axios({
      method: 'post',
      headers: {
        Authorization: context.state.token,
        'Content-Type': 'text/xml; charset=utf-8',
      },
      url: `${config.baseUrl}/register-ws/RegisterTechnologistService/RegisterTechnologist?wsdl`,
      data: `<SOAP-ENV:Envelope xmlns:SOAP-ENV="http://schemas.xmlsoap.org/soap/envelope/" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance">
                <SOAP-ENV:Body>
                    <tns:getAllDictionaries xmlns:tns="http://ru.petrosoft/register"/>
                </SOAP-ENV:Body>
            </SOAP-ENV:Envelope>`,
    })
      .then((res) => {
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(res.data, 'text/xml');
        const tableData = [];
        const list = xmlDoc.getElementsByTagName(
          'reg:getAllDictionariesResponse'
        )[0].childNodes;
        for (let i = 0; i < list.length; ++i) {
          const fields = list[i].childNodes;
          const item = { data: {}, label: '' };
          for (let j = 0; j < fields.length; ++j) {
            item.data[fields[j].tagName] = fields[j].textContent;
          }
          item.label = item.data.name;
          item.nodes = [];

          tableData.push(item);
        }
        context.commit('SET_DICTIONARY_LIST', tableData);
        resolve(tableData);
      })
      .catch((e) => {
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(e.response.data, 'text/xml');
        const message = xmlDoc.getElementsByTagName('faultstring')[0].innerHTML;
        reject(message);
      });
  });
};

const fetchInnerDictionaries = (context, node) => {
  return new Promise((resolve, reject) => {
    const data = `<SOAP-ENV:Envelope xmlns:SOAP-ENV="http://schemas.xmlsoap.org/soap/envelope/" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance">
            <SOAP-ENV:Body>
                <tns:getChildDictionaries xmlns:tns="http://ru.petrosoft/register">
                    <dictionaryId>${node.id}</dictionaryId>
                </tns:getChildDictionaries>
            </SOAP-ENV:Body>
        </SOAP-ENV:Envelope>`;
    axios({
      method: 'post',
      headers: {
        Authorization: context.state.token,
        'Content-Type': 'text/xml; charset=utf-8',
      },
      url: `${config.baseUrl}/register-ws/RegisterTechnologistService/RegisterTechnologist?wsdl`,
      data,
    })
      .then((res) => {
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(res.data, 'text/xml');
        const nodes = [];
        const list = xmlDoc.getElementsByTagName(
          'reg:getChildDictionariesResponse'
        )[0].childNodes;
        for (let i = 0; i < list.length; ++i) {
          const fields = list[i].childNodes;
          const item = { data: {}, label: '' };
          for (let j = 0; j < fields.length; ++j) {
            item.data[fields[j].tagName] = fields[j].textContent;
          }
          item.label = item.data.name;
          item.nodes = [];
          nodes.push(item);
        }
        const tableData = [...context.state.dictinaryList];
        const nodeIndex = tableData.findIndex(
          (item) => item.data.id === node.id
        );
        tableData[nodeIndex].nodes = nodes;
        context.commit('SET_DICTIONARY_LIST', tableData);
        resolve(tableData);
      })
      .catch((e) => {
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(e.response.data, 'text/xml');
        const message = xmlDoc.getElementsByTagName('faultstring')[0].innerHTML;
        reject(message);
      });
  });
};

const editDictionary = (context, editedData) => {
  return new Promise((resolve, reject) => {
    const editedDataList = [];
    const props = Object.keys(editedData);
    const tag =
      editedData.dictionaryType === 'DICTIONARY_GROUP'
        ? 'groupDictionaryType'
        : 'dictionaryType';
    const type =
      editedData.dictionaryType === 'DICTIONARY_GROUP'
        ? 'updateGroupDictionary'
        : 'updateDictionary';
    for (let i = 0; i < props.length; i += 1) {
      editedDataList.push(`<${props[i]}>${editedData[props[i]]}</${props[i]}>`);
    }
    const data = `<SOAP-ENV:Envelope xmlns:SOAP-ENV="http://schemas.xmlsoap.org/soap/envelope/" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance">
            <SOAP-ENV:Body>
            <tns:${type} xmlns:tns="http://ru.petrosoft/register">
                <${tag}>
                    ${editedDataList.join('')}
                </${tag}>
            </tns:${type}>
            </SOAP-ENV:Body>
        </SOAP-ENV:Envelope>`;
    axios({
      method: 'post',
      headers: {
        Authorization: context.state.token,
        'Content-Type': 'text/xml; charset=utf-8',
      },
      url: `${config.baseUrl}/register-ws/RegisterTechnologistService/RegisterTechnologist?wsdl`,
      data,
    })
      .then(() => {
        const tableData = [...context.state.dictinaryList];
        const nodeIndex = tableData.findIndex(
          (item) => item.data.id === editedData.id
        );
        tableData[nodeIndex].data = { ...editedData };
        context.commit('SET_DICTIONARY_LIST', tableData);
        resolve(tableData);
      })
      .catch((e) => {
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(e.response.data, 'text/xml');
        const message = xmlDoc.getElementsByTagName('faultstring')[0].innerHTML;
        reject(message);
      });
  });
};

const fetchDictionary = (context, node) => {
  return new Promise((resolve, reject) => {
    const tag =
      node.dictionaryType === 'DICTIONARY_GROUP'
        ? 'dictionaryGroupId'
        : 'dictionaryId';
    const type =
      node.dictionaryType === 'DICTIONARY_GROUP'
        ? 'getDictionaryGroup'
        : 'getDictionary';
    const data = `<SOAP-ENV:Envelope xmlns:SOAP-ENV="http://schemas.xmlsoap.org/soap/envelope/" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance">
            <SOAP-ENV:Body>
                <tns:${type} xmlns:tns="http://ru.petrosoft/register">
                    <${tag}>${node.id}</${tag}>
                </tns:${type}>
            </SOAP-ENV:Body>
        </SOAP-ENV:Envelope>`;
    axios({
      method: 'post',
      headers: {
        Authorization: context.state.token,
        'Content-Type': 'text/xml; charset=utf-8',
      },
      url: `${config.baseUrl}/register-ws/RegisterTechnologistService/RegisterTechnologist?wsdl`,
      data,
    })
      .then((res) => {
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(res.data, 'text/xml');
        const list = xmlDoc.getElementsByTagName(
          node.dictionaryType === 'DICTIONARY_GROUP'
            ? 'groupDictionaryType'
            : 'dictionaryType'
        )[0].childNodes;
        const item = { data: {}, label: '' };
        for (let j = 0; j < list.length; ++j) {
          item.data[list[j].tagName] = list[j].textContent;
        }
        item.label = item.data.name;
        const tableData = [...context.state.dictinaryList];
        const nodeIndex = tableData.findIndex((el) => el.data.id === node.id);
        tableData[nodeIndex] = { ...item, nodes: tableData[nodeIndex].nodes };
        context.commit('SET_DICTIONARY_LIST', tableData);
        resolve(item);
      })
      .catch((e) => {
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(e.response.data, 'text/xml');
        const message = xmlDoc.getElementsByTagName('faultstring')[0].innerHTML;
        reject(message);
      });
  });
};

const fetchDictinaryManagedGroups = (context) => {
  return new Promise((resolve, reject) => {
    const data = `<SOAP-ENV:Envelope xmlns:SOAP-ENV="http://schemas.xmlsoap.org/soap/envelope/" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance">
            <SOAP-ENV:Body>
                <tns:getGroupsDictionaries xmlns:tns="http://ru.petrosoft/register"/>
            </SOAP-ENV:Body>
        </SOAP-ENV:Envelope>`;
    axios({
      method: 'post',
      headers: {
        Authorization: context.state.token,
        'Content-Type': 'text/xml; charset=utf-8',
      },
      url: `${config.baseUrl}/register-ws/RegisterTechnologistService/RegisterTechnologist?wsdl`,
      data,
    })
      .then((res) => {
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(res.data, 'text/xml');
        const list = xmlDoc.getElementsByTagName(
          'reg:getGroupsDictionariesResponse'
        )[0].childNodes;
        const dictinaryManagedGroups = [{ name: 'Не задана', id: '0' }];
        for (let i = 0; i < list.length; ++i) {
          const fields = list[i].childNodes;
          const item = {};
          for (let j = 0; j < fields.length; ++j) {
            if (fields[j].tagName === 'id' || fields[j].tagName === 'name') {
              item[fields[j].tagName] = fields[j].textContent;
            }
          }
          dictinaryManagedGroups.push(item);
        }
        context.commit('SET_DICTIONARY_MANAGED_GROUPS', dictinaryManagedGroups);
        resolve(dictinaryManagedGroups);
      })
      .catch((e) => {
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(e.response.data, 'text/xml');
        const message = xmlDoc.getElementsByTagName('faultstring')[0].innerHTML;
        reject(message);
      });
  });
};

const getGroupedDictionaries = (context, node) => {
  return new Promise((resolve, reject) => {
    const data = `<SOAP-ENV:Envelope xmlns:SOAP-ENV="http://schemas.xmlsoap.org/soap/envelope/" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance">
            <SOAP-ENV:Body>
                <tns:getGroupedDictionaries xmlns:tns="http://ru.petrosoft/register">
                    <groupDictionaryId>${node.id}</groupDictionaryId>
                </tns:getGroupedDictionaries>
            </SOAP-ENV:Body>
        </SOAP-ENV:Envelope>`;
    axios({
      method: 'post',
      headers: {
        Authorization: context.state.token,
        'Content-Type': 'text/xml; charset=utf-8',
      },
      url: `${config.baseUrl}/register-ws/RegisterTechnologistService/RegisterTechnologist?wsdl`,
      data,
    })
      .then((res) => {
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(res.data, 'text/xml');
        const list = xmlDoc.getElementsByTagName(
          'reg:getGroupedDictionariesResponse'
        )[0].childNodes;
        const groupedDictionaries = [];
        for (let i = 0; i < list.length; ++i) {
          const fields = list[i].childNodes;
          const item = {};
          for (let j = 0; j < fields.length; ++j) {
            item[fields[j].tagName] = fields[j].textContent;
          }
          groupedDictionaries.push(item);
        }
        context.commit('SET_GROUPED_DICTIONARIES', groupedDictionaries);
        context.commit('SET_GROUPED_DICTIONARIES_NEW', groupedDictionaries);
        resolve(groupedDictionaries);
      })
      .catch((e) => {
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(e.response.data, 'text/xml');
        const message = xmlDoc.getElementsByTagName('faultstring')[0].innerHTML;
        reject(message);
      });
  });
};

const addRemoveGroupedDictionaries = (context, node) => {
  return new Promise((resolve, reject) => {
    const old = context.getters.GROUPED_DICTIONARIES.map((el) => el.id);
    const updated = context.getters.GROUPED_DICTIONARIES_NEW.map((el) => el.id);
    const united = new Set(old.concat(updated));
    const added = [];
    const removed = [];
    for (let id of united) {
      if (old.includes(id) && !updated.includes(id)) {
        removed.push(id);
      } else if (!old.includes(id) && updated.includes(id)) {
        added.push(id);
      }
    }
    if (added.length > 0) {
      const data = `<SOAP-ENV:Envelope xmlns:SOAP-ENV="http://schemas.xmlsoap.org/soap/envelope/" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance">
                <SOAP-ENV:Body>
                    <tns:addDictionariesInGroup xmlns:tns="http://ru.petrosoft/register">
                        <groupDictionaryId>${node.id}</groupDictionaryId>
                        ${added
                          .map(
                            (el) => `<dictionariesIds>${el}</dictionariesIds>`
                          )
                          .join('')}
                    </tns:addDictionariesInGroup>
                </SOAP-ENV:Body>
            </SOAP-ENV:Envelope>`;
      axios({
        method: 'post',
        headers: {
          Authorization: context.state.token,
          'Content-Type': 'text/xml; charset=utf-8',
        },
        url: `${config.baseUrl}/register-ws/RegisterTechnologistService/RegisterTechnologist?wsdl`,
        data,
      })
        .then(() => {
          context.commit('SET_GROUPED_DICTIONARIES', []);
          context.commit('SET_GROUPED_DICTIONARIES_NEW', []);
          resolve();
        })
        .catch((e) => {
          const parser = new DOMParser();
          const xmlDoc = parser.parseFromString(e.response.data, 'text/xml');
          const message =
            xmlDoc.getElementsByTagName('faultstring')[0].innerHTML;
          reject(message);
        });
    }
    if (removed.length > 0) {
      const data = `<SOAP-ENV:Envelope xmlns:SOAP-ENV="http://schemas.xmlsoap.org/soap/envelope/" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance">
                <SOAP-ENV:Body>
                    <tns:removeDictionariesFromGroup xmlns:tns="http://ru.petrosoft/register">
                        <groupDictionaryId>${node.id}</groupDictionaryId>
                        ${removed
                          .map(
                            (el) => `<dictionariesIds>${el}</dictionariesIds>`
                          )
                          .join('')}
                    </tns:removeDictionariesFromGroup>
                </SOAP-ENV:Body>
            </SOAP-ENV:Envelope>`;
      axios({
        method: 'post',
        headers: {
          Authorization: context.state.token,
          'Content-Type': 'text/xml; charset=utf-8',
        },
        url: `${config.baseUrl}/register-ws/RegisterTechnologistService/RegisterTechnologist?wsdl`,
        data,
      })
        .then(() => {
          context.commit('SET_GROUPED_DICTIONARIES', []);
          context.commit('SET_GROUPED_DICTIONARIES_NEW', []);
          resolve();
        })
        .catch((e) => {
          const parser = new DOMParser();
          const xmlDoc = parser.parseFromString(e.response.data, 'text/xml');
          const message =
            xmlDoc.getElementsByTagName('faultstring')[0].innerHTML;
          reject(message);
        });
    }
  });
};

const createNewDictionary = (context, node) => {
  return new Promise((resolve, reject) => {
    const editedDataList = [];
    const props = Object.keys(node);
    const type =
      node.dictionaryType === 'DICTIONARY_GROUP'
        ? 'groupDictionaryType'
        : 'dictionaryType';
    const tag =
      node.dictionaryType === 'DICTIONARY_GROUP'
        ? 'createGroupDictionary'
        : 'createDictionary';
    for (let i = 0; i < props.length; i += 1) {
      editedDataList.push(`<${props[i]}>${node[props[i]]}</${props[i]}>`);
    }
    const data = `<SOAP-ENV:Envelope xmlns:SOAP-ENV="http://schemas.xmlsoap.org/soap/envelope/" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance">
            <SOAP-ENV:Body>
                <tns:${tag} xmlns:tns="http://ru.petrosoft/register">
                    <${type}>
                        ${editedDataList.join('')}
                        <id>0</id>
                        <version>0</version>
                        <versionDate xsi:nil="true"/>
                        <creationDate xsi:nil="true"/>
                    </${type}>
                </tns:${tag}>
            </SOAP-ENV:Body>
        </SOAP-ENV:Envelope>`;
    axios({
      method: 'post',
      headers: {
        Authorization: context.state.token,
        'Content-Type': 'text/xml; charset=utf-8',
      },
      url: `${config.baseUrl}/register-ws/RegisterTechnologistService/RegisterTechnologist?wsdl`,
      data,
    })
      .then((res) => {
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(res.data, 'text/xml');
        const list = xmlDoc.getElementsByTagName(
          node.dictionaryType === 'DICTIONARY_GROUP'
            ? 'groupDictionaryType'
            : 'dictionaryType'
        )[0].childNodes;
        const item = { data: {}, label: '' };
        for (let j = 0; j < list.length; ++j) {
          item.data[list[j].tagName] = list[j].textContent;
        }
        item.label = item.data.name;
        item.nodes = [];
        const tableData = [...context.state.dictinaryList];
        tableData.push(item);
        context.commit('SET_DICTIONARY_LIST', tableData);
        if (node.dictionaryType === 'DICTIONARY_GROUP') {
          context.commit('ADD_DICTIONARY_MANAGED_GROUPS', item.data);
        }
        resolve(tableData);
      })
      .catch((e) => {
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(e.response.data, 'text/xml');
        const message = xmlDoc.getElementsByTagName('faultstring')[0].innerHTML;
        reject(message);
        //alert('No connection to server')
      });
  });
};

const deleteDictionaryItem = (context, node) => {
  return new Promise((resolve, reject) => {
    const data = `<SOAP-ENV:Envelope xmlns:SOAP-ENV="http://schemas.xmlsoap.org/soap/envelope/" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance">
            <SOAP-ENV:Body>
                <tns:deleteDictionary xmlns:tns="http://ru.petrosoft/register">
                    <dictionaryId>${node.id}</dictionaryId>
                </tns:deleteDictionary>
            </SOAP-ENV:Body>
        </SOAP-ENV:Envelope>`;
    axios({
      method: 'post',
      headers: {
        Authorization: context.state.token,
        'Content-Type': 'text/xml; charset=utf-8',
      },
      url: `${config.baseUrl}/register-ws/RegisterTechnologistService/RegisterTechnologist?wsdl`,
      data,
    })
      .then(() => {
        const tableData = [...context.state.dictinaryList].filter(
          (el) => el.id != node.id
        );
        context.commit('SET_DICTIONARY_LIST', tableData);
        resolve(tableData);
      })
      .catch((e) => {
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(e.response.data, 'text/xml');
        const message = xmlDoc.getElementsByTagName('faultstring')[0].innerHTML;
        reject(message);
        //alert('No connection to server')
      });
  });
};

const fetchReports = (context) => {
  return new Promise((resolve, reject) => {
    const data = `<SOAP-ENV:Envelope xmlns:SOAP-ENV="http://schemas.xmlsoap.org/soap/envelope/" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance">
            <SOAP-ENV:Body>
                <tns:getAllReportsProxies xmlns:tns="http://ru.petrosoft/register"/>
            </SOAP-ENV:Body>
        </SOAP-ENV:Envelope>`;
    axios({
      method: 'post',
      headers: {
        Authorization: context.state.token,
        'Content-Type': 'text/xml; charset=utf-8',
      },
      url: `${config.baseUrl}/register-ws/RegisterTechnologistService/RegisterTechnologist?wsdl`,
      data,
    })
      .then((res) => {
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(res.data, 'text/xml');
        const tableData = [];
        const list = xmlDoc.getElementsByTagName(
          'reg:getAllReportsProxiesResponse'
        )[0].childNodes;
        for (let i = 0; i < list.length; ++i) {
          const fields = list[i].childNodes;
          const item = {};
          for (let j = 0; j < fields.length; ++j) {
            if (fields[j].tagName === 'reportSource') {
              item.reportSource = {
                type: fields[j].childNodes[0].textContent,
                name: fields[j].childNodes[1].textContent,
              };
            } else {
              item[fields[j].tagName] = fields[j].textContent;
            }
          }
          tableData.push(item);
        }
        context.commit('SET_REPORTS_LIST', tableData);
        resolve(tableData);
      })
      .catch((e) => {
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(e.response.data, 'text/xml');
        const message = xmlDoc.getElementsByTagName('faultstring')[0].innerHTML;
        reject(message);
        //alert('No connection to server')
      });
  });
};

const fetchReportsSources = (context) => {
  return new Promise((resolve, reject) => {
    const data = `<SOAP-ENV:Envelope xmlns:SOAP-ENV="http://schemas.xmlsoap.org/soap/envelope/" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance">
            <SOAP-ENV:Body>
                <tns:getReportSources xmlns:tns="http://ru.petrosoft/register"/>
            </SOAP-ENV:Body>
        </SOAP-ENV:Envelope>`;
    axios({
      method: 'post',
      headers: {
        Authorization: context.state.token,
        'Content-Type': 'text/xml; charset=utf-8',
      },
      url: `${config.baseUrl}/register-ws/RegisterTechnologistService/RegisterTechnologist?wsdl`,
      data,
    })
      .then((res) => {
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(res.data, 'text/xml');
        const reportSources = [];
        const list = xmlDoc.getElementsByTagName(
          'reg:getReportSourcesResponse'
        )[0].childNodes;
        for (let i = 0; i < list.length; ++i) {
          const fields = list[i].childNodes;
          const data = {};
          for (let j = 0; j < fields.length; ++j) {
            data[fields[j].tagName] = fields[j].textContent;
          }
          const item = { data, label: data.name };
          reportSources.push(item);
        }

        context.commit('SET_REPORTS_SOURCES', reportSources);
        resolve(reportSources);
      })
      .catch((e) => {
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(e.response.data, 'text/xml');
        const message = xmlDoc.getElementsByTagName('faultstring')[0].innerHTML;
        reject(message);
      });
  });
};

const fetchReportsTemplates = (context) => {
  return new Promise((resolve, reject) => {
    const data = `<SOAP-ENV:Envelope xmlns:SOAP-ENV="http://schemas.xmlsoap.org/soap/envelope/" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance">
            <SOAP-ENV:Body>
                <tns:getRegisterTemplates xmlns:tns="http://ru.petrosoft/register"/>
            </SOAP-ENV:Body>
        </SOAP-ENV:Envelope>`;
    axios({
      method: 'post',
      headers: {
        Authorization: context.state.token,
        'Content-Type': 'text/xml; charset=utf-8',
      },
      url: `${config.baseUrl}/register-ws/RegisterTechnologistService/RegisterTechnologist?wsdl`,
      data,
    })
      .then((res) => {
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(res.data, 'text/xml');
        const reportTemplates = [];
        const list = xmlDoc.getElementsByTagName(
          'reg:getRegisterTemplatesResponse'
        )[0].childNodes;
        for (let i = 0; i < list.length; ++i) {
          const fields = list[i].childNodes;
          const item = { data: {} };
          for (let j = 0; j < fields.length; ++j) {
            if (fields[j].tagName === 'id' || fields[j].tagName === 'name')
              item.data[fields[j].tagName] = fields[j].textContent;
          }
          item.label = item.data.name;
          reportTemplates.push(item);
        }
        context.commit('SET_REPORTS_TEMPLATES', reportTemplates);
        resolve(reportTemplates);
      })
      .catch((e) => {
        console.log(e);
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(e.response.data, 'text/xml');
        const message = xmlDoc.getElementsByTagName('faultstring')[0].innerHTML;
        reject(message);
      });
  });
};

const createReport = (context, newReportData) => {
  return new Promise((resolve, reject) => {
    const fields = [];
    for (let prop in newReportData) {
      if (prop === 'reportSource') {
        fields.push(
          `<${prop}><type>${newReportData[prop].type}</type><name>${newReportData[prop].name}</name></${prop}>`
        );
      } else if (prop === 'reportTemplate') {
        if (newReportData.reportTemplate === null) {
          fields.push('<reportTemplate xsi:nil="true"/>');
        } else {
          const reportsData = [];
          reportsData.push(`<id>${newReportData.reportTemplate.id}</id>`);
          reportsData.push(`<name>${newReportData.reportTemplate.name}</name>`);
          const emptyStatuses =
            newReportData.reportTemplate.reportStatuses.filter(
              (el) => el.linked
            );
          if (emptyStatuses.length > 0) {
            for (
              let i = 0;
              i < newReportData.reportTemplate.reportStatuses.length;
              i += 1
            ) {
              const reportStatuses = [];
              reportStatuses.push(
                `<id>${newReportData.reportTemplate.reportStatuses[i].id}</id>`
              );
              reportStatuses.push(`<code xsi:nil="true"/>`);
              reportStatuses.push(`<name xsi:nil="true"/>`);
              reportStatuses.push(
                `<linked>${newReportData.reportTemplate.reportStatuses[i].linked}</linked>`
              );
              reportsData.push(
                `<reportStatuses>${reportStatuses.join('')}</reportStatuses>`
              );
            }
          } else {
            reportsData.push(`<reportStatuses xsi:nil="true"/>`);
          }
          fields.push(
            `<reportTemplate>${reportsData.join('')}</reportTemplate>`
          );
        }
      } else {
        fields.push(`<${prop}>${newReportData[prop]}</${prop}>`);
      }
    }
    const data = `<SOAP-ENV:Envelope xmlns:SOAP-ENV="http://schemas.xmlsoap.org/soap/envelope/" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance">
            <SOAP-ENV:Body>
                <tns:createReport xmlns:tns="http://ru.petrosoft/register">
                    <reportProxyType>
                        <id>0</id>
                        ${fields.join('')}
                        <parameters xsi:nil="true"/>
                    </reportProxyType>
                </tns:createReport>
            </SOAP-ENV:Body>
        </SOAP-ENV:Envelope>`;
    axios({
      method: 'post',
      headers: {
        Authorization: context.state.token,
        'Content-Type': 'text/xml; charset=utf-8',
      },
      url: `${config.baseUrl}/register-ws/RegisterTechnologistService/RegisterTechnologist?wsdl`,
      data,
    })
      .then((res) => {
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(res.data, 'text/xml');
        const list =
          xmlDoc.getElementsByTagName('reportProxyType')[0].childNodes;
        const item = {};
        for (let i = 0; i < list.length; ++i) {
          if (list[i].tagName === 'reportSource') {
            item.reportSource = {
              type: list[i].childNodes[0].textContent,
              name: list[i].childNodes[1].textContent,
            };
          } else {
            item[list[i].tagName] = list[i].textContent;
          }
        }
        context.commit('ADD_REPORT', item);
        resolve(item);
      })
      .catch((e) => {
        console.log(e);
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(e.response.data, 'text/xml');
        const message =
          xmlDoc.getElementsByTagName('faultstring')[0].textContent;
        reject(message);
      });
  });
};

const editItem = (context, newReportData) => {
  return new Promise((resolve, reject) => {
    const fields = [];
    for (let prop in newReportData) {
      if (prop === 'reportSource') {
        fields.push(
          `<${prop}><type>${newReportData[prop].type}</type><name>${newReportData[prop].name}</name></${prop}>`
        );
      } else if (prop === 'reportTemplate') {
        if (newReportData.reportTemplate === null) {
          fields.push('<reportTemplate xsi:nil="true"/>');
        } else {
          const reportsData = [];
          reportsData.push(`<id>${newReportData.reportTemplate.id}</id>`);
          reportsData.push(`<name>${newReportData.reportTemplate.name}</name>`);
          for (
            let i = 0;
            i < newReportData.reportTemplate.reportStatuses.length;
            i += 1
          ) {
            const reportStatuses = [];
            reportStatuses.push(
              `<id>${newReportData.reportTemplate.reportStatuses[i].id}</id>`
            );
            reportStatuses.push(`<code xsi:nil="true"/>`);
            reportStatuses.push(`<name xsi:nil="true"/>`);
            reportStatuses.push(
              `<linked>${newReportData.reportTemplate.reportStatuses[i].linked}</linked>`
            );
            reportsData.push(
              `<reportStatuses>${reportStatuses.join('')}</reportStatuses>`
            );
          }
          fields.push(
            `<reportTemplate>${reportsData.join('')}</reportTemplate>`
          );
        }
      } else {
        fields.push(`<${prop}>${newReportData[prop]}</${prop}>`);
      }
    }
    const data = `<SOAP-ENV:Envelope xmlns:SOAP-ENV="http://schemas.xmlsoap.org/soap/envelope/" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance">
            <SOAP-ENV:Body>
                <tns:updateReport xmlns:tns="http://ru.petrosoft/register">
                    <reportProxyType>
                        ${fields.join('')}
                    </reportProxyType>
                </tns:updateReport>
            </SOAP-ENV:Body>
        </SOAP-ENV:Envelope>`;
    axios({
      method: 'post',
      headers: {
        Authorization: context.state.token,
        'Content-Type': 'text/xml; charset=utf-8',
      },
      url: `${config.baseUrl}/register-ws/RegisterTechnologistService/RegisterTechnologist?wsdl`,
      data,
    })
      .then(() => {
        context.commit('EDIT_REPORT', newReportData);
        resolve(newReportData);
      })
      .catch((e) => {
        console.log(e);
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(e.response.data, 'text/xml');
        const message =
          xmlDoc.getElementsByTagName('faultstring')[0].textContent;
        reject(message);
      });
  });
};

const deleteReport = (context, reportData) => {
  return new Promise((resolve, reject) => {
    const data = `<SOAP-ENV:Envelope xmlns:SOAP-ENV="http://schemas.xmlsoap.org/soap/envelope/" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance">
            <SOAP-ENV:Body>
                <tns:deleteReport xmlns:tns="http://ru.petrosoft/register">
                    <reportId>${reportData.id}</reportId>
                </tns:deleteReport>
            </SOAP-ENV:Body>
        </SOAP-ENV:Envelope>`;
    axios({
      method: 'post',
      headers: {
        Authorization: context.state.token,
        'Content-Type': 'text/xml; charset=utf-8',
      },
      url: `${config.baseUrl}/register-ws/RegisterTechnologistService/RegisterTechnologist?wsdl`,
      data,
    })
      .then(() => {
        context.commit('DELETE_REPORT', reportData.id);
        resolve(reportData.id);
      })
      .catch((e) => {
        console.log(e);
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(e.response.data, 'text/xml');
        const message = xmlDoc.getElementsByTagName('faultstring')[0].innerHTML;
        reject(message);
      });
  });
};

const getTemplateRegulations = (context, reportTemplate) => {
  return new Promise((resolve, reject) => {
    if (!reportTemplate.id) {
      resolve();
    }
    const data = `<SOAP-ENV:Envelope xmlns:SOAP-ENV="http://schemas.xmlsoap.org/soap/envelope/" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance">
            <SOAP-ENV:Body>
                <tns:getTemplateRegulations xmlns:tns="http://ru.petrosoft/register">
                    <templateId>${reportTemplate.id}</templateId>
                </tns:getTemplateRegulations>
            </SOAP-ENV:Body>
        </SOAP-ENV:Envelope>`;
    axios({
      method: 'post',
      headers: {
        Authorization: context.state.token,
        'Content-Type': 'text/xml; charset=utf-8',
      },
      url: `${config.baseUrl}/register-ws/RegisterTechnologistService/RegisterTechnologist?wsdl`,
      data,
    })
      .then((res) => {
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(res.data, 'text/xml');
        const reportTemplatesRegulations = [];
        const list =
          xmlDoc.getElementsByTagName('regulationsType')[0].childNodes;
        for (let i = 0; i < list.length; ++i) {
          if (list[i].tagName === 'linkedStatuses') {
            const fields = list[i].childNodes;
            const item = {};
            for (let j = 0; j < fields.length; ++j) {
              item[fields[j].tagName] = fields[j].textContent;
            }
            item.linked = context.getters.REPORTS_STATUSES.includes(item.id);
            reportTemplatesRegulations.push(item);
          }
        }
        context.commit(
          'SET_REPORTS_TEMPLATES_REGULATIONS',
          reportTemplatesRegulations
        );
        resolve(reportTemplatesRegulations);
      })
      .catch((e) => {
        console.log(e);
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(e.response.data, 'text/xml');
        const message = xmlDoc.getElementsByTagName('faultstring')[0].innerHTML;
        reject(message);
      });
  });
};

const getReport = (context, item) => {
  return new Promise((resolve, reject) => {
    const data = `<SOAP-ENV:Envelope xmlns:SOAP-ENV="http://schemas.xmlsoap.org/soap/envelope/" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance">
            <SOAP-ENV:Body>
                <tns:getReportProxyById xmlns:tns="http://ru.petrosoft/register">
                    <reportId>${item.id}</reportId>
                </tns:getReportProxyById>
            </SOAP-ENV:Body>
        </SOAP-ENV:Envelope>`;
    axios({
      method: 'post',
      headers: {
        Authorization: context.state.token,
        'Content-Type': 'text/xml; charset=utf-8',
      },
      url: `${config.baseUrl}/register-ws/RegisterTechnologistService/RegisterTechnologist?wsdl`,
      data,
    })
      .then((res) => {
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(res.data, 'text/xml');
        const list =
          xmlDoc.getElementsByTagName('reportProxyType')[0].childNodes;
        const editedItem = { reportStatuses: [] };
        for (let i = 0; i < list.length; ++i) {
          if (list[i].tagName === 'reportSource') {
            editedItem.reportSource = {
              type: list[i].childNodes[0].textContent,
              name: list[i].childNodes[1].textContent,
            };
          } else if (list[i].tagName === 'reportTemplate') {
            const reportTemplateList = list[i].childNodes;
            editedItem.reportTemplate = {};
            for (let j = 0; j < reportTemplateList.length; j += 1) {
              if (reportTemplateList[j].tagName === 'reportStatuses') {
                const reportStatus = {};
                for (
                  let x = 0;
                  x < reportTemplateList[j].childNodes.length;
                  x += 1
                ) {
                  reportStatus[reportTemplateList[j].childNodes[x].tagName] =
                    reportTemplateList[j].childNodes[x].textContent;
                }
                editedItem.reportStatuses.push(reportStatus);
              } else if (reportTemplateList[j].tagName === 'id') {
                editedItem.reportTemplate =
                  context.getters.REPORTS_TEMPLATES.find(
                    (el) => el.data.id === reportTemplateList[j].textContent
                  ).data;
                //editedItem.reportTemplate[reportTemplateList[j].tagName] = reportTemplateList[j].textContent;
              }
            }
          } else {
            editedItem[list[i].tagName] = list[i].textContent;
          }
        }
        editedItem.templateRegulations = '';
        const statuses = editedItem.reportStatuses
          .filter((report) => report.linked === 'true')
          .map((rep) => rep.id);
        context.commit('SET_REPORTS_STATUSES', statuses);
        resolve(editedItem);
      })
      .catch((e) => {
        console.log(e);
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(e.response.data, 'text/xml');
        const message = xmlDoc.getElementsByTagName('faultstring')[0].innerHTML;
        reject(message);
      });
  });
};

const getParametersProxiesByReportId = (context, report) => {
  return new Promise((resolve, reject) => {
    const data = `<SOAP-ENV:Envelope xmlns:SOAP-ENV="http://schemas.xmlsoap.org/soap/envelope/" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance">
            <SOAP-ENV:Body>
                <tns:getParametersProxiesByReportId xmlns:tns="http://ru.petrosoft/register">
                    <reportId>${report.id}</reportId>
                </tns:getParametersProxiesByReportId>
            </SOAP-ENV:Body>
        </SOAP-ENV:Envelope>`;
    axios({
      method: 'post',
      headers: {
        Authorization: context.state.token,
        'Content-Type': 'text/xml; charset=utf-8',
      },
      url: `${config.baseUrl}/register-ws/RegisterTechnologistService/RegisterTechnologist?wsdl`,
      data,
    })
      .then((res) => {
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(res.data, 'text/xml');
        const parametersProxies = [];
        const list = xmlDoc.getElementsByTagName(
          'reg:getParametersProxiesByReportIdResponse'
        )[0].childNodes;
        for (let i = 0; i < list.length; ++i) {
          const fields = list[i].childNodes;
          const item = {};
          for (let j = 0; j < fields.length; j += 1) {
            item[fields[j].tagName] = fields[j].textContent;
          }
          parametersProxies.push(item);
        }
        context.commit('SET_REPORTS_PARAMETERS_PROXIES', parametersProxies);
        resolve(parametersProxies);
      })
      .catch((e) => {
        console.log(e);
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(e.response.data, 'text/xml');
        const message = xmlDoc.getElementsByTagName('faultstring')[0].innerHTML;
        reject(message);
      });
  });
};

const createReportParameter = (
  context,
  { newParameter, reportId, templateName, instanceName }
) => {
  return new Promise((resolve, reject) => {
    const fields = [];
    for (let prop in newParameter) {
      if (
        !newParameter[prop] ||
        prop === 'templateName' ||
        prop === 'instanceName'
      ) {
        fields.push(`<${prop} xsi:nil="true"/>`);
      } else {
        fields.push(`<${prop}>${newParameter[prop]}</${prop}>`);
      }
    }
    const data = `<SOAP-ENV:Envelope xmlns:SOAP-ENV="http://schemas.xmlsoap.org/soap/envelope/" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance">
            <SOAP-ENV:Body>
                <tns:createReportParameter xmlns:tns="http://ru.petrosoft/register">
                    <reportParameterProxyType>
                        ${fields.join('')}
                    </reportParameterProxyType>
                    <reportId>${reportId}</reportId>
                </tns:createReportParameter>
            </SOAP-ENV:Body>
        </SOAP-ENV:Envelope>`;
    axios({
      method: 'post',
      headers: {
        Authorization: context.state.token,
        'Content-Type': 'text/xml; charset=utf-8',
      },
      url: `${config.baseUrl}/register-ws/RegisterTechnologistService/RegisterTechnologist?wsdl`,
      data,
    })
      .then((res) => {
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(res.data, 'text/xml');
        const list = xmlDoc.getElementsByTagName('reportParameterProxyType')[0]
          .childNodes;
        const item = {};
        for (let i = 0; i < list.length; i += 1) {
          if (!list[i].textContent) {
            item[list[i].tagName] = '';
          } else {
            item[list[i].tagName] = list[i].textContent;
          }
        }
        if (item.paramType === 'DICTIONARY') {
          item.templateName = templateName;
          item.instanceName = instanceName;
        }
        context.commit('ADD_REPORT_PARAMETER', item);
        resolve(item);
      })
      .catch((e) => {
        console.log(e);
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(e.response.data, 'text/xml');
        const message =
          xmlDoc.getElementsByTagName('faultstring')[0].textContent;
        reject(message);
      });
  });
};

const deleteReportParameter = (context, { reportId, reportParameterId }) => {
  return new Promise((resolve, reject) => {
    const data = `<SOAP-ENV:Envelope xmlns:SOAP-ENV="http://schemas.xmlsoap.org/soap/envelope/" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance">
            <SOAP-ENV:Body>
                <tns:deleteReportParameter xmlns:tns="http://ru.petrosoft/register">
                    <reportParameterId>${reportParameterId}</reportParameterId>
                    <reportId>${reportId}</reportId>
                </tns:deleteReportParameter>
            </SOAP-ENV:Body>
        </SOAP-ENV:Envelope>`;
    axios({
      method: 'post',
      headers: {
        Authorization: context.state.token,
        'Content-Type': 'text/xml; charset=utf-8',
      },
      url: `${config.baseUrl}/register-ws/RegisterTechnologistService/RegisterTechnologist?wsdl`,
      data,
    })
      .then(() => {
        context.commit('DELETE_REPORT_PARAMETER', reportParameterId);
        resolve(reportParameterId);
      })
      .catch((e) => {
        console.log(e);
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(e.response.data, 'text/xml');
        const message = xmlDoc.getElementsByTagName('faultstring')[0].innerHTML;
        reject(message);
      });
  });
};

const editReportParameter = (
  context,
  { newParameter, reportId, templateName, instanceName }
) => {
  return new Promise((resolve, reject) => {
    const fields = [];
    for (let prop in newParameter) {
      if (!newParameter[prop]) {
        fields.push(`<${prop} xsi:nil="true"/>`);
      } else {
        fields.push(`<${prop}>${newParameter[prop]}</${prop}>`);
      }
    }
    const data = `<SOAP-ENV:Envelope xmlns:SOAP-ENV="http://schemas.xmlsoap.org/soap/envelope/" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance">
            <SOAP-ENV:Body>
                <tns:updateReportParameter xmlns:tns="http://ru.petrosoft/register">
                    <reportParameterProxyType>
                    ${fields.join('')}
                    </reportParameterProxyType>
                    <reportId>${reportId}</reportId>
                </tns:updateReportParameter>
            </SOAP-ENV:Body>
        </SOAP-ENV:Envelope>`;

    axios({
      method: 'post',
      headers: {
        Authorization: context.state.token,
        'Content-Type': 'text/xml; charset=utf-8',
      },
      url: `${config.baseUrl}/register-ws/RegisterTechnologistService/RegisterTechnologist?wsdl`,
      data,
    })
      .then(() => {
        if (newParameter.paramType === 'DICTIONARY') {
          newParameter.templateName = templateName;
          newParameter.instanceName = instanceName;
        }
        context.commit('UPDATE_REPORT_PARAMETER', newParameter);
        resolve();
      })
      .catch((e) => {
        console.log(e);
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(e.response.data, 'text/xml');
        const message =
          xmlDoc.getElementsByTagName('faultstring')[0].textContent;
        reject(message);
      });
  });
};

const fetchDictionaryItemsHeaders = (context, dictionaryId) => {
  return new Promise((resolve, reject) => {
    const data = `<SOAP-ENV:Envelope xmlns:SOAP-ENV="http://schemas.xmlsoap.org/soap/envelope/" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance">
            <SOAP-ENV:Body>
                <tns:getTableViewTemplate xmlns:tns="http://ru.petrosoft/register">
                    <menuItemType>
                    <id>${dictionaryId}</id>
                    <parentId>0</parentId>
                    <name xsi:nil="true"/>
                    <hasChilds>false</hasChilds>
                    <menuType xsi:nil="true"/>
                    <allowCreate>false</allowCreate>
                    <accessRoles xsi:nil="true"/>
                    <childMenuItems xsi:nil="true"/>
                    <owner xsi:nil="true"/>
                    <transitiveRelation xsi:nil="true"/>
                    </menuItemType>
                </tns:getTableViewTemplate>
            </SOAP-ENV:Body>
        </SOAP-ENV:Envelope>`;

    axios({
      method: 'post',
      headers: {
        Authorization: context.state.token,
        'Content-Type': 'text/xml; charset=utf-8',
      },
      url: `${config.baseUrl}/register-ws/RegisterTechnologistService/RegisterTechnologist?wsdl`,
      data,
    })
      .then((res) => {
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(res.data, 'text/xml');
        const dictionariesItemsHeaders = [];
        const list = xmlDoc.getElementsByTagName('tableRepresentationType')[0]
          .childNodes;
        for (let i = 0; i < list.length; i += 1) {
          if (list[i].tagName === 'metaAttributes') {
            const fields = list[i].childNodes;
            const item = {};
            for (let j = 0; j < fields.length; j += 1) {
              item[fields[j].tagName] = fields[j].textContent;
            }
            dictionariesItemsHeaders.push(item);
          }
        }
        context.commit(
          'SET_DICTIONARY_ITEMS_HEADERS',
          dictionariesItemsHeaders
        );
        resolve(dictionariesItemsHeaders);
      })
      .catch((e) => {
        console.log(e);
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(e.response.data, 'text/xml');
        const message =
          xmlDoc.getElementsByTagName('faultstring')[0].textContent;
        reject(message);
      });
  });
};

const fetchDictionaryItems = (context, dictionaryId) => {
  return new Promise((resolve, reject) => {
    const data = `<SOAP-ENV:Envelope xmlns:SOAP-ENV="http://schemas.xmlsoap.org/soap/envelope/" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:reg="http://ru.petrosoft/register">
            <SOAP-ENV:Body>
                <reg:getTableAttributes xmlns:tns="http://ru.petrosoft/register">
                    <tablePageType>
                    <menuItemType>
                        <id>${dictionaryId}</id>
                        <parentId>0</parentId>
                        <name xsi:nil="true"/>
                        <hasChilds>false</hasChilds>
                        <menuType xsi:nil="true"/>
                        <allowCreate>false</allowCreate>
                        <accessRoles xsi:nil="true"/>
                        <childMenuItems xsi:nil="true"/>
                        <owner xsi:nil="true"/>
                        <transitiveRelation xsi:nil="true"/>
                    </menuItemType>
                    <tableViewType xsi:nil="true"/>
                    <outbound>false</outbound>
                    <offSet>0</offSet>
                    <pageSize>15</pageSize>
                    <sort xsi:nil="true"/>
                    <sorts xsi:nil="true"/>
                    <linkedTemplateType xsi:nil="true"/>
                    <getLinkedWithOwner>false</getLinkedWithOwner>
                    <includeShortForm>false</includeShortForm>
                    <excludeAttributes>false</excludeAttributes>
                    <excludeStatus>false</excludeStatus>
                    <attributesIds xsi:nil="true"/>
                    <useJoinDataQuery>false</useJoinDataQuery>
                    </tablePageType>
                    <filterType>
                        <owner xsi:nil="true"/>
                        <inboundSearch>false</inboundSearch>
                        <inboundCondition xsi:nil="true"/>
                    </filterType>
                </reg:getTableAttributes>
            </SOAP-ENV:Body>
        </SOAP-ENV:Envelope>`;

    axios({
      method: 'post',
      headers: {
        Authorization: context.state.token,
        'Content-Type': 'text/xml; charset=utf-8',
      },
      url: `${config.baseUrl}/register-ws/RegisterTechnologistService/RegisterTechnologist?wsdl`,
      data,
    })
      .then((res) => {
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(res.data, 'text/xml');
        const dictionariesItems = [];
        const list = xmlDoc.getElementsByTagName(
          'reg:getTableAttributesResponse'
        )[0].childNodes;
        for (let i = 0; i < list.length; i += 1) {
          const fields = list[i].childNodes;
          const item = {};
          for (let j = 0; j < fields.length; j += 1) {
            if (fields[j].tagName === 'id') {
              item.id = fields[j].textContent;
            }
            if (fields[j].tagName === 'attributeValueTypes') {
              item[fields[j].childNodes[0].textContent] = fields[
                j
              ].getElementsByTagName('data')[0]
                ? fields[j].getElementsByTagName('data')[0].textContent
                : '';
            }
          }
          dictionariesItems.push(item);
        }
        context.commit('SET_DICTIONARY_ITEMS', dictionariesItems);
        resolve(dictionariesItems);
      })
      .catch((e) => {
        console.log(e);
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(e.response.data, 'text/xml');
        const message =
          xmlDoc.getElementsByTagName('faultstring')[0].textContent;
        reject(message);
      });
  });
};

const fetchAllReportViews = (context) => {
  return new Promise((resolve, reject) => {
    const data = `<SOAP-ENV:Envelope xmlns:SOAP-ENV="http://schemas.xmlsoap.org/soap/envelope/" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance">
            <SOAP-ENV:Body>
                <tns:getAllReportViews xmlns:tns="http://ru.petrosoft/register"/>
            </SOAP-ENV:Body>
        </SOAP-ENV:Envelope>`;
    axios({
      method: 'post',
      headers: {
        Authorization: context.state.token,
        'Content-Type': 'text/xml; charset=utf-8',
      },
      url: `${config.baseUrl}/register-ws/RegisterTechnologistService/RegisterTechnologist?wsdl`,
      data,
    })
      .then((res) => {
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(res.data, 'text/xml');
        const list = xmlDoc.getElementsByTagName(
          'reg:getAllReportViewsResponse'
        )[0];
        const arrProps = ['columns', 'joinedGroupsColumns'];

        const tableData = round(list, 0, {}, arrProps);

        context.commit('SET_REPORT_VIEWS', tableData);
        resolve(tableData);
      })
      .catch((e) => {
        console.log(e);
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(e.response.data, 'text/xml');
        const message = xmlDoc.getElementsByTagName('faultstring')[0].innerHTML;
        reject(message);
      });
  });
};

const fetchTemplatesForReport = (context) => {
  return new Promise((resolve, reject) => {
    const data = `<SOAP-ENV:Envelope xmlns:SOAP-ENV="http://schemas.xmlsoap.org/soap/envelope/" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance">
            <SOAP-ENV:Body>
            <tns:getTemplatesForReport xmlns:tns="http://ru.petrosoft/register"/>
            </SOAP-ENV:Body>
        </SOAP-ENV:Envelope>`;
    axios({
      method: 'post',
      headers: {
        Authorization: context.state.token,
        'Content-Type': 'text/xml; charset=utf-8',
      },
      url: `${config.baseUrl}/register-ws/RegisterTechnologistService/RegisterTechnologist?wsdl`,
      data,
    })
      .then((res) => {
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(res.data, 'text/xml');
        const list = xmlDoc.getElementsByTagName(
          'reg:getTemplatesForReportResponse'
        )[0];
        const arrProps = [];

        const tableData = round(list, 0, {}, arrProps).map((el) => {
          el.nodes = [];
          el.selected = false;
          el.expanded = [];
          return el;
        });
        context.commit('SET_TEMPLATES_FOR_REPORT_VIEWS', tableData);
        resolve(tableData);
      })
      .catch((e) => {
        console.log(e);
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(e.response.data, 'text/xml');
        const message = xmlDoc.getElementsByTagName('faultstring')[0].innerHTML;
        reject(message);
      });
  });
};

const getReportAttributesByTemplateId = (context, { node, parentId }) => {
  return new Promise((resolve, reject) => {
    const data = `<SOAP-ENV:Envelope xmlns:SOAP-ENV="http://schemas.xmlsoap.org/soap/envelope/" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance">
            <SOAP-ENV:Body>
                <tns:getReportAttributesByTemplateId xmlns:tns="http://ru.petrosoft/register">
                    <templateId>${node.parentId}</templateId>
                </tns:getReportAttributesByTemplateId>
            </SOAP-ENV:Body>
        </SOAP-ENV:Envelope>`;
    axios({
      method: 'post',
      headers: {
        Authorization: context.state.token,
        'Content-Type': 'text/xml; charset=utf-8',
      },
      url: `${config.baseUrl}/register-ws/RegisterTechnologistService/RegisterTechnologist?wsdl`,
      data,
    })
      .then((res) => {
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(res.data, 'text/xml');
        const list = xmlDoc.getElementsByTagName(
          'reg:getReportAttributesByTemplateIdResponse'
        )[0];
        const tableData = round(list, 0, {}, []).map((el) => {
          if (
            el.attributeType === 'RELATION_REVERSE' ||
            el.attributeType === 'RELATION' ||
            el.attributeType === 'RELATION_DICTIONARY'
          ) {
            el.nodes = [];
          }
          el.selected = false;
          return el;
        });
        context.commit('UPDATE_TEMPLATE_FOR_REPORT_VIEWS', {
          reportAttributes: tableData,
          templateId: parentId ? node.linkAttributeId : node.parentId,
          parentId,
        });
        resolve(tableData);
      })
      .catch((e) => {
        console.log(e);
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(e.response.data, 'text/xml');
        const message = xmlDoc.getElementsByTagName('faultstring')[0].innerHTML;
        reject(message);
      });
  });
};

const updateReportView = (context, item) => {
  return new Promise((resolve, reject) => {
    console.log(item);
    const fields = jsToXmlParser(item);
    const data = `<SOAP-ENV:Envelope xmlns:SOAP-ENV="http://schemas.xmlsoap.org/soap/envelope/" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance">
            <SOAP-ENV:Body>
                <tns:updateReportView xmlns:tns="http://ru.petrosoft/register">
                    <reportViewType>
                        ${fields.join('')}
                    </reportViewType>
                </tns:updateReportView>
            </SOAP-ENV:Body>
        </SOAP-ENV:Envelope>`;
    axios({
      method: 'post',
      headers: {
        Authorization: context.state.token,
        'Content-Type': 'text/xml; charset=utf-8',
      },
      url: `${config.baseUrl}/register-ws/RegisterTechnologistService/RegisterTechnologist?wsdl`,
      data,
    })
      .then((res) => {
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(res.data, 'text/xml');
        const list = xmlDoc.getElementsByTagName('reportViewType')[0];
        const arrProps = ['columns', 'joinedGroupsColumns'];
        const newItem = round(list, 1, {}, arrProps);
        context.commit('UPDATE_REPORT_VIEW', newItem[0]);
        resolve(newItem[0]);
      })
      .catch((e) => {
        console.log(e);
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(e.response.data, 'text/xml');
        const message = xmlDoc.getElementsByTagName('faultstring')[0].innerHTML;
        reject(message);
      });
  });
};

const createReportView = (context, item) => {
  return new Promise((resolve, reject) => {
    console.log(item);
    const fields = jsToXmlParser(item);
    const data = `<SOAP-ENV:Envelope xmlns:SOAP-ENV="http://schemas.xmlsoap.org/soap/envelope/" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance">
            <SOAP-ENV:Body>
                <tns:createReportView xmlns:tns="http://ru.petrosoft/register">
                    <reportViewType>
                        ${fields.join('')}
                    </reportViewType>
                </tns:createReportView>
            </SOAP-ENV:Body>
        </SOAP-ENV:Envelope>`;
    axios({
      method: 'post',
      headers: {
        Authorization: context.state.token,
        'Content-Type': 'text/xml; charset=utf-8',
      },
      url: `${config.baseUrl}/register-ws/RegisterTechnologistService/RegisterTechnologist?wsdl`,
      data,
    })
      .then((res) => {
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(res.data, 'text/xml');
        const list = xmlDoc.getElementsByTagName('reportViewType')[0];
        const arrProps = ['columns', 'joinedGroupsColumns'];
        const newItem = round(list, 1, {}, arrProps);
        context.commit('ADD_REPORT_VIEW', newItem[0]);
        resolve(newItem[0]);
      })
      .catch((e) => {
        console.log(e);
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(e.response.data, 'text/xml');
        const message = xmlDoc.getElementsByTagName('faultstring')[0].innerHTML;
        reject(message);
      });
  });
};

const deleteReportView = (context, item) => {
  return new Promise((resolve, reject) => {
    console.log(item);
    const data = `<SOAP-ENV:Envelope xmlns:SOAP-ENV="http://schemas.xmlsoap.org/soap/envelope/" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance">
            <SOAP-ENV:Body>
                <tns:deleteReportView xmlns:tns="http://ru.petrosoft/register">
                    <reportViewId>${item.id}</reportViewId>
                </tns:deleteReportView>
            </SOAP-ENV:Body>
        </SOAP-ENV:Envelope>`;
    axios({
      method: 'post',
      headers: {
        Authorization: context.state.token,
        'Content-Type': 'text/xml; charset=utf-8',
      },
      url: `${config.baseUrl}/register-ws/RegisterTechnologistService/RegisterTechnologist?wsdl`,
      data,
    })
      .then(() => {
        context.commit('DELETE_REPORT_VIEW', item.id);
        resolve(item);
      })
      .catch((e) => {
        console.log(e);
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(e.response.data, 'text/xml');
        const message = xmlDoc.getElementsByTagName('faultstring')[0].innerHTML;
        reject(message);
      });
  });
};

const fetchObjects = (context) => {
  return new Promise((resolve, reject) => {
    const data = `<SOAP-ENV:Envelope xmlns:SOAP-ENV="http://schemas.xmlsoap.org/soap/envelope/" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance">
            <SOAP-ENV:Body>
            <tns:getFirstLevelObjects xmlns:tns="http://ru.petrosoft/register"/>
            </SOAP-ENV:Body>
        </SOAP-ENV:Envelope>`;
    axios({
      method: 'post',
      headers: {
        Authorization: context.state.token,
        'Content-Type': 'text/xml; charset=utf-8',
      },
      url: `${config.baseUrl}/register-ws/RegisterTechnologistService/RegisterTechnologist?wsdl`,
      data,
    })
      .then((res) => {
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(res.data, 'text/xml');
        const list = xmlDoc.getElementsByTagName(
          'reg:getFirstLevelObjectsResponse'
        )[0];
        const arrProps = ['columns', 'joinedGroupsColumns'];

        const tableData = round(list, 0, {}, arrProps).map((el) => {
          return {
            label: el.name,
            data: el,
            nodes: [],
          };
        });

        context.commit('SET_OBJECTS', tableData);
        resolve(tableData);
      })
      .catch((e) => {
        console.log(e);
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(e.response.data, 'text/xml');
        const message = xmlDoc.getElementsByTagName('faultstring')[0].innerHTML;
        reject(message);
      });
  });
};

const getChildObjects = (context, node) => {
  return new Promise((resolve, reject) => {
    const data = `<SOAP-ENV:Envelope xmlns:SOAP-ENV="http://schemas.xmlsoap.org/soap/envelope/" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance">
            <SOAP-ENV:Body>
                <tns:getChildObjects xmlns:tns="http://ru.petrosoft/register">
                    <objectId>${node.id}</objectId>
                </tns:getChildObjects>
            </SOAP-ENV:Body>
        </SOAP-ENV:Envelope>`;
    axios({
      method: 'post',
      headers: {
        Authorization: context.state.token,
        'Content-Type': 'text/xml; charset=utf-8',
      },
      url: `${config.baseUrl}/register-ws/RegisterTechnologistService/RegisterTechnologist?wsdl`,
      data,
    })
      .then((res) => {
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(res.data, 'text/xml');
        const list = xmlDoc.getElementsByTagName(
          'reg:getChildObjectsResponse'
        )[0];
        const arrProps = [];

        const tableData = round(list, 0, {}, arrProps).map((el) => {
          return {
            label: el.name,
            data: el,
            nodes: [],
          };
        });

        context.commit('SET_CHILD_OBJECTS', { tableData, node });
        resolve(tableData);
      })
      .catch((e) => {
        console.log(e);
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(e.response.data, 'text/xml');
        const message = xmlDoc.getElementsByTagName('faultstring')[0].innerHTML;
        reject(message);
      });
  });
};

const createClassificationNode = (context, node) => {
  const fields = jsToXmlParser(node);
  return new Promise((resolve, reject) => {
    const data = `<SOAP-ENV:Envelope xmlns:SOAP-ENV="http://schemas.xmlsoap.org/soap/envelope/" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance">
            <SOAP-ENV:Body>
                <tns:createClassificationNode xmlns:tns="http://ru.petrosoft/register">
                    <classificationNodeType>
                        ${fields.join('')}
                    </classificationNodeType>
                </tns:createClassificationNode>
            </SOAP-ENV:Body>
        </SOAP-ENV:Envelope>`;
    axios({
      method: 'post',
      headers: {
        Authorization: context.state.token,
        'Content-Type': 'text/xml; charset=utf-8',
      },
      url: `${config.baseUrl}/register-ws/RegisterTechnologistService/RegisterTechnologist?wsdl`,
      data,
    })
      .then(() => {
        resolve(node);
      })
      .catch((e) => {
        console.log(e);
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(e.response.data, 'text/xml');
        const message = xmlDoc.getElementsByTagName('faultstring')[0].innerHTML;
        reject(message);
      });
  });
};

const getClassificationNode = (context, node) => {
  return new Promise((resolve, reject) => {
    const data = `<SOAP-ENV:Envelope xmlns:SOAP-ENV="http://schemas.xmlsoap.org/soap/envelope/" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance">
            <SOAP-ENV:Body>
                <tns:getClassificationNode xmlns:tns="http://ru.petrosoft/register">
                    <nodeId>${node.id}</nodeId>
                </tns:getClassificationNode>
            </SOAP-ENV:Body>
        </SOAP-ENV:Envelope>`;
    axios({
      method: 'post',
      headers: {
        Authorization: context.state.token,
        'Content-Type': 'text/xml; charset=utf-8',
      },
      url: `${config.baseUrl}/register-ws/RegisterTechnologistService/RegisterTechnologist?wsdl`,
      data,
    })
      .then((res) => {
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(res.data, 'text/xml');
        const list = xmlDoc.getElementsByTagName('classificationNodeType')[0];

        const arrProps = [];

        const tableData = round(list, 1, {}, arrProps)[0];

        context.commit('SET_EDITED_OBJECT', tableData);
        resolve(tableData);
      })
      .catch((e) => {
        console.log(e);
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(e.response.data, 'text/xml');
        const message = xmlDoc.getElementsByTagName('faultstring')[0].innerHTML;
        reject(message);
      });
  });
};

const getTypesNodes = (context) => {
  return new Promise((resolve, reject) => {
    const data = `<SOAP-ENV:Envelope xmlns:SOAP-ENV="http://schemas.xmlsoap.org/soap/envelope/" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance">
            <SOAP-ENV:Body>
                <tns:getTypesNodes xmlns:tns="http://ru.petrosoft/register"/>
            </SOAP-ENV:Body>
        </SOAP-ENV:Envelope>`;
    axios({
      method: 'post',
      headers: {
        Authorization: context.state.token,
        'Content-Type': 'text/xml; charset=utf-8',
      },
      url: `${config.baseUrl}/register-ws/RegisterTechnologistService/RegisterTechnologist?wsdl`,
      data,
    })
      .then((res) => {
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(res.data, 'text/xml');
        const list = xmlDoc.getElementsByTagName(
          'reg:getTypesNodesResponse'
        )[0];
        const arrProps = [];

        const tableData = round(list, 0, {}, arrProps);

        context.commit('SET_OBJECTS_TYPES_NODES', tableData);
        resolve(tableData);
      })
      .catch((e) => {
        console.log(e);
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(e.response.data, 'text/xml');
        const message = xmlDoc.getElementsByTagName('faultstring')[0].innerHTML;
        reject(message);
      });
  });
};

const getTypesTemplates = (context) => {
  return new Promise((resolve, reject) => {
    const data = `<SOAP-ENV:Envelope xmlns:SOAP-ENV="http://schemas.xmlsoap.org/soap/envelope/" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance">
            <SOAP-ENV:Body>
                <tns:getTypesTemplates xmlns:tns="http://ru.petrosoft/register"/>
            </SOAP-ENV:Body>
        </SOAP-ENV:Envelope>`;
    axios({
      method: 'post',
      headers: {
        Authorization: context.state.token,
        'Content-Type': 'text/xml; charset=utf-8',
      },
      url: `${config.baseUrl}/register-ws/RegisterTechnologistService/RegisterTechnologist?wsdl`,
      data,
    })
      .then((res) => {
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(res.data, 'text/xml');
        const list = xmlDoc.getElementsByTagName(
          'reg:getTypesTemplatesResponse'
        )[0];
        const arrProps = [];

        const tableData = round(list, 0, {}, arrProps);

        context.commit('SET_OBJECTS_TYPES_TEMPLATES', tableData);
        resolve(tableData);
      })
      .catch((e) => {
        console.log(e);
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(e.response.data, 'text/xml');
        const message = xmlDoc.getElementsByTagName('faultstring')[0].innerHTML;
        reject(message);
      });
  });
};

const getFirstLevelNodes = (context) => {
  return new Promise((resolve, reject) => {
    const data = `<SOAP-ENV:Envelope xmlns:SOAP-ENV="http://schemas.xmlsoap.org/soap/envelope/" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance">
            <SOAP-ENV:Body>
                <tns:getFirstLevelNodes xmlns:tns="http://ru.petrosoft/register"/>
            </SOAP-ENV:Body>
        </SOAP-ENV:Envelope>`;
    axios({
      method: 'post',
      headers: {
        Authorization: context.state.token,
        'Content-Type': 'text/xml; charset=utf-8',
      },
      url: `${config.baseUrl}/register-ws/RegisterTechnologistService/RegisterTechnologist?wsdl`,
      data,
    })
      .then((res) => {
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(res.data, 'text/xml');
        const list = xmlDoc.getElementsByTagName(
          'reg:getFirstLevelNodesResponse'
        )[0];
        const arrProps = [];

        const tableData = round(list, 0, {}, arrProps).map((el) => {
          return {
            label: el.name,
            data: el,
            nodes: [],
          };
        });
        context.commit('SET_OBJECTS_FIRST_LEVEL_NODES', [
          {
            label: 'Типы объектов',
            data: { name: 'Типы объектов' },
            nodes: tableData,
          },
        ]);
        resolve(tableData);
      })
      .catch((e) => {
        console.log(e);
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(e.response.data, 'text/xml');
        const message = xmlDoc.getElementsByTagName('faultstring')[0].innerHTML;
        reject(message);
      });
  });
};

const getChildNodes = (context, node) => {
  return new Promise((resolve, reject) => {
    const data = `<SOAP-ENV:Envelope xmlns:SOAP-ENV="http://schemas.xmlsoap.org/soap/envelope/" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance">
            <SOAP-ENV:Body>
                <tns:getChildNodes xmlns:tns="http://ru.petrosoft/register">
                    <nodeId>${node.id}</nodeId>
                </tns:getChildNodes>
            </SOAP-ENV:Body>
        </SOAP-ENV:Envelope>`;
    axios({
      method: 'post',
      headers: {
        Authorization: context.state.token,
        'Content-Type': 'text/xml; charset=utf-8',
      },
      url: `${config.baseUrl}/register-ws/RegisterTechnologistService/RegisterTechnologist?wsdl`,
      data,
    })
      .then((res) => {
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(res.data, 'text/xml');
        const list = xmlDoc.getElementsByTagName(
          'reg:getChildNodesResponse'
        )[0];
        const arrProps = [];
        const tableData = round(list, 0, {}, arrProps).map((el) => {
          return {
            label: el.name,
            data: el,
            nodes: [],
          };
        });
        context.commit('SET_OBJECTS_FIRST_LEVEL_NODES_CHILDREN', {
          tableData,
          node,
        });
        resolve(tableData);
      })
      .catch((e) => {
        console.log(e);
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(e.response.data, 'text/xml');
        const message = xmlDoc.getElementsByTagName('faultstring')[0].innerHTML;
        reject(message);
      });
  });
};

const deleteClassificationNode = (context, node) => {
  return new Promise((resolve, reject) => {
    const data = `<SOAP-ENV:Envelope xmlns:SOAP-ENV="http://schemas.xmlsoap.org/soap/envelope/" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance">
            <SOAP-ENV:Body>
                <tns:deleteClassificationNode xmlns:tns="http://ru.petrosoft/register">
                    <nodeId>${node.id}</nodeId>
                </tns:deleteClassificationNode>
            </SOAP-ENV:Body>
        </SOAP-ENV:Envelope>`;
    axios({
      method: 'post',
      headers: {
        Authorization: context.state.token,
        'Content-Type': 'text/xml; charset=utf-8',
      },
      url: `${config.baseUrl}/register-ws/RegisterTechnologistService/RegisterTechnologist?wsdl`,
      data,
    })
      .then(() => {
        context.commit('DELETE_OBJECT', node.id);
        resolve(node);
      })
      .catch((e) => {
        console.log(e);
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(e.response.data, 'text/xml');
        const message = xmlDoc.getElementsByTagName('faultstring')[0].innerHTML;
        reject(message);
      });
  });
};

const updateClassificationNode = (context, node) => {
  const fields = jsToXmlParser(node);
  return new Promise((resolve, reject) => {
    const data = `<SOAP-ENV:Envelope xmlns:SOAP-ENV="http://schemas.xmlsoap.org/soap/envelope/" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance">
            <SOAP-ENV:Body>
                <tns:updateClassificationNode xmlns:tns="http://ru.petrosoft/register">
                    <classificationNodeType>
                        ${fields.join('')}
                    </classificationNodeType>
                </tns:updateClassificationNode>
            </SOAP-ENV:Body>
        </SOAP-ENV:Envelope>`;
    axios({
      method: 'post',
      headers: {
        Authorization: context.state.token,
        'Content-Type': 'text/xml; charset=utf-8',
      },
      url: `${config.baseUrl}/register-ws/RegisterTechnologistService/RegisterTechnologist?wsdl`,
      data,
    })
      .then(() => {
        context.commit('UPDATE_OBJECT', node);
        resolve(node);
      })
      .catch((e) => {
        console.log(e);
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(e.response.data, 'text/xml');
        const message = xmlDoc.getElementsByTagName('faultstring')[0].innerHTML;
        reject(message);
      });
  });
};

const getObjectAttributes = (context, node) => {
  return new Promise((resolve, reject) => {
    const data = `<SOAP-ENV:Envelope xmlns:SOAP-ENV="http://schemas.xmlsoap.org/soap/envelope/" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance">
            <SOAP-ENV:Body>
                <tns:getObjectAttributes xmlns:tns="http://ru.petrosoft/register">
                    <objectId>${node.id}</objectId>
                </tns:getObjectAttributes>
            </SOAP-ENV:Body>
        </SOAP-ENV:Envelope>`;
    axios({
      method: 'post',
      headers: {
        Authorization: context.state.token,
        'Content-Type': 'text/xml; charset=utf-8',
      },
      url: `${config.baseUrl}/register-ws/RegisterTechnologistService/RegisterTechnologist?wsdl`,
      data,
    })
      .then((res) => {
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(res.data, 'text/xml');
        const list = xmlDoc.getElementsByTagName('objectAttributesType')[0];
        const arrProps = [];
        const tableData = round(list, 0, {}, arrProps).slice(1);
        context.commit('SET_OBJECTS_ATTRIBUTES', tableData);
        resolve(tableData);
      })
      .catch((e) => {
        console.log(e);
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(e.response.data, 'text/xml');
        const message = xmlDoc.getElementsByTagName('faultstring')[0].innerHTML;
        reject(message);
      });
  });
};

const getTypesAttributes = (context) => {
  return new Promise((resolve, reject) => {
    const data = `<SOAP-ENV:Envelope xmlns:SOAP-ENV="http://schemas.xmlsoap.org/soap/envelope/" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance">
            <SOAP-ENV:Body>
                <tns:getTypesAttributes xmlns:tns="http://ru.petrosoft/register"/>
            </SOAP-ENV:Body>
        </SOAP-ENV:Envelope>`;
    axios({
      method: 'post',
      headers: {
        Authorization: context.state.token,
        'Content-Type': 'text/xml; charset=utf-8',
      },
      url: `${config.baseUrl}/register-ws/RegisterTechnologistService/RegisterTechnologist?wsdl`,
      data,
    })
      .then((res) => {
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(res.data, 'text/xml');
        const list = xmlDoc.getElementsByTagName(
          'reg:getTypesAttributesResponse'
        )[0];
        const arrProps = [];
        const tableData = round(list, 0, {}, arrProps);
        context.commit('SET_OBJECTS_TYPES_ATRIBUTES', tableData);
        resolve(tableData);
      })
      .catch((e) => {
        console.log(e);
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(e.response.data, 'text/xml');
        const message = xmlDoc.getElementsByTagName('faultstring')[0].innerHTML;
        reject(message);
      });
  });
};

const getTypesCopied = (context) => {
  return new Promise((resolve, reject) => {
    const data = `<SOAP-ENV:Envelope xmlns:SOAP-ENV="http://schemas.xmlsoap.org/soap/envelope/" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance">
            <SOAP-ENV:Body>
                <tns:getTypesCopied xmlns:tns="http://ru.petrosoft/register"/>
            </SOAP-ENV:Body>
        </SOAP-ENV:Envelope>`;
    axios({
      method: 'post',
      headers: {
        Authorization: context.state.token,
        'Content-Type': 'text/xml; charset=utf-8',
      },
      url: `${config.baseUrl}/register-ws/RegisterTechnologistService/RegisterTechnologist?wsdl`,
      data,
    })
      .then((res) => {
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(res.data, 'text/xml');
        const list = xmlDoc.getElementsByTagName(
          'reg:getTypesCopiedResponse'
        )[0];
        const arrProps = [];
        const tableData = round(list, 0, {}, arrProps);
        context.commit('SET_OBJECTS_TYPES_COPIED', tableData);
        resolve(tableData);
      })
      .catch((e) => {
        console.log(e);
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(e.response.data, 'text/xml');
        const message = xmlDoc.getElementsByTagName('faultstring')[0].innerHTML;
        reject(message);
      });
  });
};

const getTypesGroupOperation = (context) => {
  return new Promise((resolve, reject) => {
    const data = `<SOAP-ENV:Envelope xmlns:SOAP-ENV="http://schemas.xmlsoap.org/soap/envelope/" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance">
            <SOAP-ENV:Body>
                <tns:getTypesGroupOperation xmlns:tns="http://ru.petrosoft/register"/>
            </SOAP-ENV:Body>
        </SOAP-ENV:Envelope>`;
    axios({
      method: 'post',
      headers: {
        Authorization: context.state.token,
        'Content-Type': 'text/xml; charset=utf-8',
      },
      url: `${config.baseUrl}/register-ws/RegisterTechnologistService/RegisterTechnologist?wsdl`,
      data,
    })
      .then((res) => {
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(res.data, 'text/xml');
        const list = xmlDoc.getElementsByTagName(
          'reg:getTypesGroupOperationResponse'
        )[0];
        const arrProps = [];
        const tableData = round(list, 0, {}, arrProps);
        context.commit('SET_OBJECTS_TYPES_GROUP_OPERATION', tableData);
        resolve(tableData);
      })
      .catch((e) => {
        console.log(e);
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(e.response.data, 'text/xml');
        const message = xmlDoc.getElementsByTagName('faultstring')[0].innerHTML;
        reject(message);
      });
  });
};

const getRegisterRelationParts = (context) => {
  return new Promise((resolve, reject) => {
    const data = `<SOAP-ENV:Envelope xmlns:SOAP-ENV="http://schemas.xmlsoap.org/soap/envelope/" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance">
            <SOAP-ENV:Body>
                <tns:getRegisterRelationParts xmlns:tns="http://ru.petrosoft/register"/>
            </SOAP-ENV:Body>
        </SOAP-ENV:Envelope>`;
    axios({
      method: 'post',
      headers: {
        Authorization: context.state.token,
        'Content-Type': 'text/xml; charset=utf-8',
      },
      url: `${config.baseUrl}/register-ws/RegisterTechnologistService/RegisterTechnologist?wsdl`,
      data,
    })
      .then((res) => {
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(res.data, 'text/xml');
        const list = xmlDoc.getElementsByTagName(
          'reg:getRegisterRelationPartsResponse'
        )[0];
        const arrProps = ['relations', 'directions', 'mandatory'];
        const tableData = round(list, 0, {}, arrProps)[0];
        tableData.relations = tableData.relations.map((el) => {
          return { label: el.name, data: el };
        });
        tableData.directions = tableData.directions.map((el) => {
          return { label: el.name, data: el };
        });
        tableData.mandatory = tableData.mandatory.map((el) => {
          return { label: el.name, data: el };
        });
        context.commit('SET_OBJECTS_REGISTER_RELATION_PARTS', tableData);
        resolve(tableData);
      })
      .catch((e) => {
        console.log(e);
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(e.response.data, 'text/xml');
        const message = xmlDoc.getElementsByTagName('faultstring')[0].innerHTML;
        reject(message);
      });
  });
};

const createMetaAttribute = (context, item) => {
  const xmlType = item.value.xmlType;
  delete item.value.xmlType;
  const fields = jsToXmlParser(item, ['dataList'], xmlType);
  return new Promise((resolve, reject) => {
    const data = `<SOAP-ENV:Envelope xmlns:SOAP-ENV="http://schemas.xmlsoap.org/soap/envelope/" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:reg="http://ru.petrosoft/register">
            <SOAP-ENV:Body>
                <reg:createMetaAttribute xmlns:tns="http://ru.petrosoft/register">
                    <metaAttributesObjectValueType>
                        ${fields.join('')}
                    </metaAttributesObjectValueType>
                </reg:createMetaAttribute>
            </SOAP-ENV:Body>
        </SOAP-ENV:Envelope>`;
    axios({
      method: 'post',
      headers: {
        Authorization: context.state.token,
        'Content-Type': 'text/xml; charset=utf-8',
      },
      url: `${config.baseUrl}/register-ws/RegisterTechnologistService/RegisterTechnologist?wsdl`,
      data,
    })
      .then(() => {
        resolve(item);
      })
      .catch((e) => {
        console.log(e);
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(e.response.data, 'text/xml');
        const message = xmlDoc.getElementsByTagName('faultstring')[0].innerHTML;
        reject(message);
      });
  });
};

const getRelationalObjectAttributes = (context, item) => {
  return new Promise((resolve, reject) => {
    const data = `<SOAP-ENV:Envelope xmlns:SOAP-ENV="http://schemas.xmlsoap.org/soap/envelope/" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance">
            <SOAP-ENV:Body>
                <tns:getRelationalObjectAttributes xmlns:tns="http://ru.petrosoft/register">
                    <objectId>${item.id}</objectId>
                </tns:getRelationalObjectAttributes>
            </SOAP-ENV:Body>
        </SOAP-ENV:Envelope>`;
    axios({
      method: 'post',
      headers: {
        Authorization: context.state.token,
        'Content-Type': 'text/xml; charset=utf-8',
      },
      url: `${config.baseUrl}/register-ws/RegisterTechnologistService/RegisterTechnologist?wsdl`,
      data,
    })
      .then((res) => {
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(res.data, 'text/xml');
        const list = xmlDoc.getElementsByTagName(
          'reg:getRelationalObjectAttributesResponse'
        )[0];
        const arrProps = ['attributeValueTypes', 'dataList'];
        const tableData =
          round(list, 0, {}, arrProps)[0] &&
          round(list, 0, {}, arrProps)[0].attributeValueTypes.map((el) => {
            return { data: el, label: el.attributeObjectType.name };
          });
        context.commit('SET_OBJECT_RELATIONAL_OBJECT_ATTRIBUTES', tableData);
        resolve(tableData);
      })
      .catch((e) => {
        console.log(e);
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(e.response.data, 'text/xml');
        const message = xmlDoc.getElementsByTagName('faultstring')[0].innerHTML;
        reject(message);
      });
  });
};

const deleteMetaAttribute = (context, item) => {
  return new Promise((resolve, reject) => {
    const data = `<SOAP-ENV:Envelope xmlns:SOAP-ENV="http://schemas.xmlsoap.org/soap/envelope/" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance">
            <SOAP-ENV:Body>
                <tns:deleteMetaAttribute xmlns:tns="http://ru.petrosoft/register">
                    <metaAttributeId>${item.attributeObjectType.metaAttributeId}</metaAttributeId>
                    <deleteAttribute>true</deleteAttribute>
                </tns:deleteMetaAttribute>
            </SOAP-ENV:Body>
        </SOAP-ENV:Envelope>`;
    axios({
      method: 'post',
      headers: {
        Authorization: context.state.token,
        'Content-Type': 'text/xml; charset=utf-8',
      },
      url: `${config.baseUrl}/register-ws/RegisterTechnologistService/RegisterTechnologist?wsdl`,
      data,
    })
      .then(() => {
        context.commit('DELETE_META_ATTRIBUTE', item);
        resolve(item);
      })
      .catch((e) => {
        console.log(e);
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(e.response.data, 'text/xml');
        const message = xmlDoc.getElementsByTagName('faultstring')[0].innerHTML;
        reject(message);
      });
  });
};

const getMetaAttributesObjectValue = (context, item) => {
  return new Promise((resolve, reject) => {
    const data = `<SOAP-ENV:Envelope xmlns:SOAP-ENV="http://schemas.xmlsoap.org/soap/envelope/" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance">
            <SOAP-ENV:Body>
                <tns:getMetaAttributesObjectValue xmlns:tns="http://ru.petrosoft/register">
                    <metaAttributeId>${item.attributeObjectType.metaAttributeId}</metaAttributeId>
                    <objectId>${item.objectId}</objectId>
                </tns:getMetaAttributesObjectValue>
            </SOAP-ENV:Body>
        </SOAP-ENV:Envelope>`;
    axios({
      method: 'post',
      headers: {
        Authorization: context.state.token,
        'Content-Type': 'text/xml; charset=utf-8',
      },
      url: `${config.baseUrl}/register-ws/RegisterTechnologistService/RegisterTechnologist?wsdl`,
      data,
    })
      .then((res) => {
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(res.data, 'text/xml');
        const list = xmlDoc.getElementsByTagName(
          'reg:getMetaAttributesObjectValueResponse'
        )[0];
        const arrProps = ['dataList'];
        const tableData = round(list, 0, {}, arrProps, true)[0];
        for (let key in tableData.metaAttributeObjectType) {
          if (tableData.metaAttributeObjectType[key] === 'true') {
            tableData.metaAttributeObjectType[key] = true;
          }
          if (tableData.metaAttributeObjectType[key] === 'false') {
            tableData.metaAttributeObjectType[key] = false;
          }
        }
        const attributeType = context.getters.OBJECTS_TYPES_ATRIBUTES.find(
          (el) =>
            el.attributeType === tableData.metaAttributeObjectType.attributeType
        );
        if (attributeType) {
          tableData.value.xmlType = attributeType.xmlType;
        } else {
          const xmlType = context.getters.OBJECTS_TYPES_ATRIBUTES.find(
            (el) => el.dataType === tableData.value.dataType
          ).xmlType;
          context.commit('ADD_OBJECTS_TYPES_ATRIBUTES', {
            attributeType: tableData.metaAttributeObjectType.attributeType,
            name: tableData.metaAttributeObjectType.attributeType,
            dataType: tableData.value.dataType,
            xmlType: xmlType,
          });
        }

        if (
          tableData.value.dataList &&
          tableData.value.dataList[0] &&
          tableData.value.dataList[0].data.memberId
        ) {
          tableData.value.dataList[0] = {
            data: tableData.value.dataList[0].data.memberId,
          };
        }
        resolve(tableData);
      })
      .catch((e) => {
        console.log(e);
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(e.response.data, 'text/xml');
        const message = xmlDoc.getElementsByTagName('faultstring')[0].innerHTML;
        reject(message);
      });
  });
};

const updateMetaAttribute = (context, item) => {
  const xmlType = item.value.xmlType;
  delete item.value.xmlType;
  const fields = jsToXmlParser(item, ['dataList'], xmlType);
  return new Promise((resolve, reject) => {
    const data = `<SOAP-ENV:Envelope xmlns:SOAP-ENV="http://schemas.xmlsoap.org/soap/envelope/" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:reg="http://ru.petrosoft/register">
            <SOAP-ENV:Body>
            <reg:updateMetaAttribute xmlns:tns="http://ru.petrosoft/register">
                <metaAttributesObjectValueType>
                    ${fields.join('')}
                </metaAttributesObjectValueType>
            </reg:updateMetaAttribute>
            </SOAP-ENV:Body>
        </SOAP-ENV:Envelope>`;
    axios({
      method: 'post',
      headers: {
        Authorization: context.state.token,
        'Content-Type': 'text/xml; charset=utf-8',
      },
      url: `${config.baseUrl}/register-ws/RegisterTechnologistService/RegisterTechnologist?wsdl`,
      data,
    })
      .then(() => {
        resolve(item);
      })
      .catch((e) => {
        console.log(e);
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(e.response.data, 'text/xml');
        const message = xmlDoc.getElementsByTagName('faultstring')[0].innerHTML;
        reject(message);
      });
  });
};

const getTemplateRepresentation = (context, item) => {
  return new Promise((resolve, reject) => {
    const data = `<SOAP-ENV:Envelope xmlns:SOAP-ENV="http://schemas.xmlsoap.org/soap/envelope/" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance">
            <SOAP-ENV:Body>
                <tns:getTemplateRepresentation xmlns:tns="http://ru.petrosoft/register">
                    <templateId>${item.id}</templateId>
                </tns:getTemplateRepresentation>
            </SOAP-ENV:Body>
        </SOAP-ENV:Envelope>`;
    axios({
      method: 'post',
      headers: {
        Authorization: context.state.token,
        'Content-Type': 'text/xml; charset=utf-8',
      },
      url: `${config.baseUrl}/register-ws/RegisterTechnologistService/RegisterTechnologist?wsdl`,
      data,
    })
      .then((res) => {
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(res.data, 'text/xml');
        const list = xmlDoc.getElementsByTagName(
          'templateRepresentationType'
        )[0];
        const arrProps = ['metaAttributeLinkTypes', 'availableTypesUI'];
        const tableData = round(list, 1, {}, arrProps)[0];
        for (let i = 0; i < tableData.metaAttributeLinkTypes.length; i += 1) {
          tableData.metaAttributeLinkTypes[i];
          for (let key in tableData.metaAttributeLinkTypes[i]) {
            if (tableData.metaAttributeLinkTypes[i][key] === 'false') {
              tableData.metaAttributeLinkTypes[i][key] = false;
            }
            if (tableData.metaAttributeLinkTypes[i][key] === 'true') {
              tableData.metaAttributeLinkTypes[i][key] = true;
            }
            if (key === 'order') {
              tableData.metaAttributeLinkTypes[i][key] = Number.parseInt(
                tableData.metaAttributeLinkTypes[i][key],
                10
              );
            }
          }
        }
        context.commit('SET_OBJECTS_TEMPLATE_REPRESENTATION', tableData);
        resolve(tableData);
      })
      .catch((e) => {
        console.log(e);
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(e.response.data, 'text/xml');
        const message = xmlDoc.getElementsByTagName('faultstring')[0].innerHTML;
        reject(message);
      });
  });
};

const setTemplateRepresentation = (context, item) => {
  const fields = jsToXmlParser(item, [
    'metaAttributeLinkTypes',
    'availableTypesUI',
  ]);

  return new Promise((resolve, reject) => {
    const data = `<SOAP-ENV:Envelope xmlns:SOAP-ENV="http://schemas.xmlsoap.org/soap/envelope/" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance">
            <SOAP-ENV:Body>
                <tns:setTemplateRepresentation xmlns:tns="http://ru.petrosoft/register">
                    <templateRepresentationType>
                        ${fields.join('')}
                    </templateRepresentationType>
                </tns:setTemplateRepresentation>
            </SOAP-ENV:Body>
        </SOAP-ENV:Envelope>`;
    axios({
      method: 'post',
      headers: {
        Authorization: context.state.token,
        'Content-Type': 'text/xml; charset=utf-8',
      },
      url: `${config.baseUrl}/register-ws/RegisterTechnologistService/RegisterTechnologist?wsdl`,
      data,
    })
      .then(() => {
        resolve(item);
      })
      .catch((e) => {
        console.log(e);
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(e.response.data, 'text/xml');
        const message = xmlDoc.getElementsByTagName('faultstring')[0].innerHTML;
        reject(message);
      });
  });
};

const getObjectTemplateRegulations = (context, item) => {
  return new Promise((resolve, reject) => {
    const data = `<SOAP-ENV:Envelope xmlns:SOAP-ENV="http://schemas.xmlsoap.org/soap/envelope/" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance">
            <SOAP-ENV:Body>
                <tns:getTemplateRegulations xmlns:tns="http://ru.petrosoft/register">
                    <templateId>${item.id}</templateId>
                </tns:getTemplateRegulations>
            </SOAP-ENV:Body>
        </SOAP-ENV:Envelope>`;
    axios({
      method: 'post',
      headers: {
        Authorization: context.state.token,
        'Content-Type': 'text/xml; charset=utf-8',
      },
      url: `${config.baseUrl}/register-ws/RegisterTechnologistService/RegisterTechnologist?wsdl`,
      data,
    })
      .then((res) => {
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(res.data, 'text/xml');
        const list = xmlDoc.getElementsByTagName('regulationsType')[0];
        const arrProps = ['linkedStatuses', 'availableStatuses'];
        const tableData = round(list, 1, {}, arrProps)[0];
        context.commit('SET_OBJECTS_TEMPLATE_REGULATIONS', tableData);
        resolve(tableData);
      })
      .catch((e) => {
        console.log(e);
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(e.response.data, 'text/xml');
        const message = xmlDoc.getElementsByTagName('faultstring')[0].innerHTML;
        reject(message);
      });
  });
};

const getStatusMetaAttributes = (context, { statusId, regulationsId }) => {
  return new Promise((resolve, reject) => {
    const data = `<SOAP-ENV:Envelope xmlns:SOAP-ENV="http://schemas.xmlsoap.org/soap/envelope/" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance">
            <SOAP-ENV:Body>
                <tns:getStatusMetaAttributes xmlns:tns="http://ru.petrosoft/register">
                    <statusId>${statusId}</statusId>
                    <regulationsId>${regulationsId}</regulationsId>
                </tns:getStatusMetaAttributes>
            </SOAP-ENV:Body>
        </SOAP-ENV:Envelope>`;
    axios({
      method: 'post',
      headers: {
        Authorization: context.state.token,
        'Content-Type': 'text/xml; charset=utf-8',
      },
      url: `${config.baseUrl}/register-ws/RegisterTechnologistService/RegisterTechnologist?wsdl`,
      data,
    })
      .then((res) => {
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(res.data, 'text/xml');
        const list = xmlDoc.getElementsByTagName('statusMetaAttributesType')[0];
        const arrProps = ['accessedMetaAttributes'];
        const tableData = round(list, 1, {}, arrProps, true)[0];
        for (let key in tableData) {
          if (tableData[key] === 'false') {
            tableData[key] = false;
          }
          if (tableData[key] === 'true') {
            tableData[key] = true;
          }
          if (key === 'statusActionOnSave') {
            tableData[key] = { actionCode: tableData.statusActionOnSave };
          }
          if (key === 'accessedMetaAttributes') {
            for (let element of tableData.accessedMetaAttributes) {
              for (let innerKey in element) {
                if (element[innerKey] === 'false') {
                  element[innerKey] = false;
                }
                if (element[innerKey] === 'true') {
                  element[innerKey] = true;
                }
              }
              if (element.accessReadOnly || element.accessFull) {
                element.invisible = false;
              } else {
                element.invisible = true;
              }
            }
          }
        }
        context.commit('SET_STATUS_META_ATTRIBUTES', tableData);
        resolve(tableData);
      })
      .catch((e) => {
        console.log(e);
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(e.response.data, 'text/xml');
        const message = xmlDoc.getElementsByTagName('faultstring')[0].innerHTML;
        reject(message);
      });
  });
};

const createStatusMetaAttributes = (context, item) => {
  const fields = jsToXmlParser(item);

  return new Promise((resolve, reject) => {
    const data = `<SOAP-ENV:Envelope xmlns:SOAP-ENV="http://schemas.xmlsoap.org/soap/envelope/" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance">
        <SOAP-ENV:Body>
            <tns:createStatusMetaAttributes xmlns:tns="http://ru.petrosoft/register">
                    <statusMetaAttributesType>
                        ${fields}
                    </statusMetaAttributesType>
                </tns:createStatusMetaAttributes>
            </SOAP-ENV:Body>
        </SOAP-ENV:Envelope>`;
    axios({
      method: 'post',
      headers: {
        Authorization: context.state.token,
        'Content-Type': 'text/xml; charset=utf-8',
      },
      url: `${config.baseUrl}/register-ws/RegisterTechnologistService/RegisterTechnologist?wsdl`,
      data,
    })
      .then(() => {
        context.commit('CLEAR_STATUS_META_ATTRIBUTES');
        resolve(item);
      })
      .catch((e) => {
        console.log(e);
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(e.response.data, 'text/xml');
        const message = xmlDoc.getElementsByTagName('faultstring')[0].innerHTML;
        reject(message);
      });
  });
};

const updateStatusMetaAttributes = (context, item) => {
  let fields = jsToXmlParser(item);
  console.log(2696, fields);
  fields = fields.map((str) => str.replace(/<shortForm>/g, 'shortForm'));
  fields = fields.map((str) => str.replace(/<statusName>/g, 'statusName'));

  console.log(2698, fields);
  return new Promise((resolve, reject) => {
    const data = `<SOAP-ENV:Envelope xmlns:SOAP-ENV="http://schemas.xmlsoap.org/soap/envelope/" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance">
        <SOAP-ENV:Body>
            <tns:updateStatusMetaAttributes xmlns:tns="http://ru.petrosoft/register">
                    <statusMetaAttributesType>
                        ${fields}
                    </statusMetaAttributesType>
                </tns:updateStatusMetaAttributes>
            </SOAP-ENV:Body>
        </SOAP-ENV:Envelope>`;
    axios({
      method: 'post',
      headers: {
        Authorization: context.state.token,
        'Content-Type': 'text/xml; charset=utf-8',
      },
      url: `${config.baseUrl}/register-ws/RegisterTechnologistService/RegisterTechnologist?wsdl`,
      data,
    })
      .then(() => {
        context.commit('CLEAR_STATUS_META_ATTRIBUTES');
        resolve(item);
      })
      .catch((e) => {
        console.log(e);
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(e.response.data, 'text/xml');
        const message = xmlDoc.getElementsByTagName('faultstring')[0].innerHTML;
        reject(message);
      });
  });
};

const getStatusMatrix = (context, id) => {
  return new Promise((resolve, reject) => {
    const data = `<SOAP-ENV:Envelope xmlns:SOAP-ENV="http://schemas.xmlsoap.org/soap/envelope/" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance">
            <SOAP-ENV:Body>
                <tns:getStatusMatrix xmlns:tns="http://ru.petrosoft/register">
                    <regulationsId>${id}</regulationsId>
                </tns:getStatusMatrix>
            </SOAP-ENV:Body>
        </SOAP-ENV:Envelope>`;
    axios({
      method: 'post',
      headers: {
        Authorization: context.state.token,
        'Content-Type': 'text/xml; charset=utf-8',
      },
      url: `${config.baseUrl}/register-ws/RegisterTechnologistService/RegisterTechnologist?wsdl`,
      data,
    })
      .then((res) => {
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(res.data, 'text/xml');
        const list = xmlDoc.getElementsByTagName('statusMatrixType')[0];
        const arrProps = ['statusMatrixRows', 'statusMatrixCells'];
        const tableData = round(list, 1, {}, arrProps, true)[0]
          .statusMatrixRows;

        for (let row of tableData) {
          for (let cell of row.statusMatrixCells) {
            cell.transition = cell.transition === 'true';
          }
        }
        context.commit('SET_STATUS_MATRIX_ROWS', tableData);
        resolve(tableData);
      })
      .catch((e) => {
        console.log(e);
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(e.response.data, 'text/xml');
        const message = xmlDoc.getElementsByTagName('faultstring')[0].innerHTML;
        reject(message);
      });
  });
};

const setStatusMatrix = (context, item) => {
  const fields = jsToXmlParser(item);

  return new Promise((resolve, reject) => {
    const data = `<SOAP-ENV:Envelope xmlns:SOAP-ENV="http://schemas.xmlsoap.org/soap/envelope/" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance">
            <SOAP-ENV:Body>
                <tns:setStatusMatrix xmlns:tns="http://ru.petrosoft/register">
                    <statusMatrixType>
                        ${fields}
                    </statusMatrixType>
                </tns:setStatusMatrix>
            </SOAP-ENV:Body>
        </SOAP-ENV:Envelope>`;
    axios({
      method: 'post',
      headers: {
        Authorization: context.state.token,
        'Content-Type': 'text/xml; charset=utf-8',
      },
      url: `${config.baseUrl}/register-ws/RegisterTechnologistService/RegisterTechnologist?wsdl`,
      data,
    })
      .then(() => {
        context.commit('CLEAR_STATUS_MATRIX_ROWS');
        resolve(item);
      })
      .catch((e) => {
        console.log(e);
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(e.response.data, 'text/xml');
        const message = xmlDoc.getElementsByTagName('faultstring')[0].innerHTML;
        reject(message);
      });
  });
};

const getRegisterMenu = (context) => {
  return new Promise((resolve, reject) => {
    const data = `<SOAP-ENV:Envelope xmlns:SOAP-ENV="http://schemas.xmlsoap.org/soap/envelope/" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance">
            <SOAP-ENV:Body>
                <tns:getRegisterMenu xmlns:tns="http://ru.petrosoft/register"/>
            </SOAP-ENV:Body>
        </SOAP-ENV:Envelope>`;
    axios({
      method: 'post',
      headers: {
        Authorization: context.state.token,
        'Content-Type': 'text/xml; charset=utf-8',
      },
      url: `${config.baseUrl}/register-ws/RegisterTechnologistService/RegisterTechnologist?wsdl`,
      data,
    })
      .then((res) => {
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(res.data, 'text/xml');
        const list = xmlDoc.getElementsByTagName('registerMenuType')[0];
        const arrProps = ['registers', 'templates'];
        console.log(round(list, 1, {}, arrProps, true)[0].registers);
        const tableData = round(list, 1, {}, arrProps, true)[0].registers.map(
          (el) => {
            console.log(el);
            let nodes = [];
            if (el.templates) {
              nodes = el.templates.map((template) => {
                return {
                  label: template.name,
                  id: template.id,
                  data: template,
                  nodes: [],
                };
              });
            } else {
              el.templates = [];
            }
            return { label: el.name, id: el.id, data: el, nodes };
          }
        );
        console.log(tableData);
        context.commit('SET_REGISTER_MENU', tableData);
        resolve(tableData);
      })
      .catch((e) => {
        console.log(e);
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(e.response.data, 'text/xml');
        const message = xmlDoc.getElementsByTagName('faultstring')[0].innerHTML;
        reject(message);
      });
  });
};

const createRegister = (context, item) => {
  const fields = jsToXmlParser(item);
  return new Promise((resolve, reject) => {
    const data = `<SOAP-ENV:Envelope xmlns:SOAP-ENV="http://schemas.xmlsoap.org/soap/envelope/" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance">
            <SOAP-ENV:Body>
                <tns:createRegister xmlns:tns="http://ru.petrosoft/register">
                    <registerProxyType>
                        ${fields.join('')}
                    </registerProxyType>
                </tns:createRegister>
            </SOAP-ENV:Body>
        </SOAP-ENV:Envelope>`;
    axios({
      method: 'post',
      headers: {
        Authorization: context.state.token,
        'Content-Type': 'text/xml; charset=utf-8',
      },
      url: `${config.baseUrl}/register-ws/RegisterTechnologistService/RegisterTechnologist?wsdl`,
      data,
    })
      .then(() => {
        resolve(item);
      })
      .catch((e) => {
        console.log(e);
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(e.response.data, 'text/xml');
        const message = xmlDoc.getElementsByTagName('faultstring')[0].innerHTML;
        reject(message);
      });
  });
};

const initializeRegister = (context, item) => {
  return new Promise((resolve, reject) => {
    const data = `<SOAP-ENV:Envelope xmlns:SOAP-ENV="http://schemas.xmlsoap.org/soap/envelope/" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance">
            <SOAP-ENV:Body>
                <tns:initializeRegister xmlns:tns="http://ru.petrosoft/register">
                    <registerId>${item.id}</registerId>
                    <idIs>urn:eis:toris</idIs>
                </tns:initializeRegister>
            </SOAP-ENV:Body>
        </SOAP-ENV:Envelope>`;
    axios({
      method: 'post',
      headers: {
        Authorization: context.state.token,
        'Content-Type': 'text/xml; charset=utf-8',
      },
      url: `${config.baseUrl}/register-ws/RegisterTechnologistService/RegisterTechnologist?wsdl`,
      data,
    })
      .then(() => {
        resolve(item);
      })
      .catch((e) => {
        console.log(e);
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(e.response.data, 'text/xml');
        const message = xmlDoc.getElementsByTagName('faultstring')[0].innerHTML;
        reject(message);
      });
  });
};

const getUnregisteredTemplates = (context) => {
  return new Promise((resolve, reject) => {
    const data = `<SOAP-ENV:Envelope xmlns:SOAP-ENV="http://schemas.xmlsoap.org/soap/envelope/" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance">
            <SOAP-ENV:Body>
                <tns:getUnregisteredTemplates xmlns:tns="http://ru.petrosoft/register"/>
            </SOAP-ENV:Body>
        </SOAP-ENV:Envelope>`;
    axios({
      method: 'post',
      headers: {
        Authorization: context.state.token,
        'Content-Type': 'text/xml; charset=utf-8',
      },
      url: `${config.baseUrl}/register-ws/RegisterTechnologistService/RegisterTechnologist?wsdl`,
      data,
    })
      .then((res) => {
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(res.data, 'text/xml');
        const list = xmlDoc.getElementsByTagName(
          'reg:getUnregisteredTemplatesResponse'
        )[0];
        console.log(list);
        const arrProps = [];
        const tableData = round(list, 0, {}, arrProps, true);
        console.log(tableData);
        context.commit('SET_UNREGISTERED_TEMPLATES', tableData);
        resolve(tableData);
      })
      .catch((e) => {
        console.log(e);
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(e.response.data, 'text/xml');
        const message = xmlDoc.getElementsByTagName('faultstring')[0].innerHTML;
        reject(message);
      });
  });
};

const addTemplatesToRegister = (context, { templates, registerId }) => {
  const fields = templates.map((el) => `<templateIds>${el.id}</templateIds>`);
  return new Promise((resolve, reject) => {
    if (templates.length === 0) {
      resolve('empty');
    } else {
      const data = `<SOAP-ENV:Envelope xmlns:SOAP-ENV="http://schemas.xmlsoap.org/soap/envelope/" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance">
                <SOAP-ENV:Body>
                    <tns:addTemplatesToRegister xmlns:tns="http://ru.petrosoft/register">
                        <registerId>${registerId}</registerId>
                        ${fields.join('')}
                    </tns:addTemplatesToRegister>
                </SOAP-ENV:Body>
            </SOAP-ENV:Envelope>`;
      axios({
        method: 'post',
        headers: {
          Authorization: context.state.token,
          'Content-Type': 'text/xml; charset=utf-8',
        },
        url: `${config.baseUrl}/register-ws/RegisterTechnologistService/RegisterTechnologist?wsdl`,
        data,
      })
        .then(() => {
          resolve('added');
        })
        .catch((e) => {
          console.log(e);
          const parser = new DOMParser();
          const xmlDoc = parser.parseFromString(e.response.data, 'text/xml');
          const message =
            xmlDoc.getElementsByTagName('faultstring')[0].innerHTML;
          reject(message);
        });
    }
  });
};

const removeTemplatesFromRegister = (context, { templates, registerId }) => {
  const fields = templates.map((el) => `<templateIds>${el.id}</templateIds>`);
  return new Promise((resolve, reject) => {
    if (templates.length === 0) {
      resolve('empty');
    } else {
      const data = `<SOAP-ENV:Envelope xmlns:SOAP-ENV="http://schemas.xmlsoap.org/soap/envelope/" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance">
                <SOAP-ENV:Body>
                    <tns:removeTemplatesFromRegister xmlns:tns="http://ru.petrosoft/register">
                        <registerId>${registerId}</registerId>
                        ${fields.join('')}
                    </tns:removeTemplatesFromRegister>
                </SOAP-ENV:Body>
            </SOAP-ENV:Envelope>`;
      axios({
        method: 'post',
        headers: {
          Authorization: context.state.token,
          'Content-Type': 'text/xml; charset=utf-8',
        },
        url: `${config.baseUrl}/register-ws/RegisterTechnologistService/RegisterTechnologist?wsdl`,
        data,
      })
        .then(() => {
          resolve('deleted');
        })
        .catch((e) => {
          console.log(e);
          const parser = new DOMParser();
          const xmlDoc = parser.parseFromString(e.response.data, 'text/xml');
          const message =
            xmlDoc.getElementsByTagName('faultstring')[0].innerHTML;
          reject(message);
        });
    }
  });
};

const updateRegister = (context, item) => {
  const fields = jsToXmlParser(item);
  return new Promise((resolve, reject) => {
    if (fields.length === 0) {
      resolve('empty');
    }
    const data = `<SOAP-ENV:Envelope xmlns:SOAP-ENV="http://schemas.xmlsoap.org/soap/envelope/" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance">
            <SOAP-ENV:Body>
            <tns:updateRegister xmlns:tns="http://ru.petrosoft/register">
                <registerProxyType>
                    ${fields.join('')}
                </registerProxyType>
            </tns:updateRegister>
            </SOAP-ENV:Body>
        </SOAP-ENV:Envelope>`;
    axios({
      method: 'post',
      headers: {
        Authorization: context.state.token,
        'Content-Type': 'text/xml; charset=utf-8',
      },
      url: `${config.baseUrl}/register-ws/RegisterTechnologistService/RegisterTechnologist?wsdl`,
      data,
    })
      .then(() => {
        resolve('updated');
      })
      .catch((e) => {
        console.log(e);
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(e.response.data, 'text/xml');
        const message = xmlDoc.getElementsByTagName('faultstring')[0].innerHTML;
        reject(message);
      });
  });
};

const getDetailedFormViewMetaAttrs = (context, item) => {
  return new Promise((resolve, reject) => {
    const data = `<SOAP-ENV:Envelope xmlns:SOAP-ENV="http://schemas.xmlsoap.org/soap/envelope/" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance">
            <SOAP-ENV:Body>
                <tns:getDetailedFormViewMetaAttrs xmlns:tns="http://ru.petrosoft/register">
                <templateId>${item.id}</templateId>
                </tns:getDetailedFormViewMetaAttrs>
            </SOAP-ENV:Body>
        </SOAP-ENV:Envelope>`;
    axios({
      method: 'post',
      headers: {
        Authorization: context.state.token,
        'Content-Type': 'text/xml; charset=utf-8',
      },
      url: `${config.baseUrl}/register-ws/RegisterTechnologistService/RegisterTechnologist?wsdl`,
      data,
    })
      .then((res) => {
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(res.data, 'text/xml');
        if (
          xmlDoc.getElementsByTagName('title').length > 0 &&
          xmlDoc.getElementsByTagName('title')[0].innerHTML ===
            'Доступ запрещен'
        ) {
          reject('Доступ запрещен');
        }
        const list = xmlDoc.getElementsByTagName(
          'reg:getDetailedFormViewMetaAttrsResponse'
        )[0];
        const arrProps = ['metaAttributeForXml', 'availableTypesUI'];
        const tableData = round(list, 0, {}, arrProps);
        context.commit('SET_DETAILED_FORM_VIEW_META_ATTRS', tableData);
        resolve(tableData);
      })
      .catch((e) => {
        console.log(e);
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(e.response.data, 'text/xml');
        const message = xmlDoc.getElementsByTagName('faultstring')[0].innerHTML;
        reject(message);
      });
  });
};

const getDetailedFormViewXmlDescResponse = (context, item) => {
  return new Promise((resolve, reject) => {
    const data = `<SOAP-ENV:Envelope xmlns:SOAP-ENV="http://schemas.xmlsoap.org/soap/envelope/" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance">
            <SOAP-ENV:Body>
                <tns:getDetailedFormViewXmlDesc xmlns:tns="http://ru.petrosoft/register">
                    <templateId>${item.id}</templateId>
                </tns:getDetailedFormViewXmlDesc>
            </SOAP-ENV:Body>
        </SOAP-ENV:Envelope>`;
    axios({
      method: 'post',
      headers: {
        Authorization: context.state.token,
        'Content-Type': 'text/xml; charset=utf-8',
      },
      url: `${config.baseUrl}/register-ws/RegisterTechnologistService/RegisterTechnologist?wsdl`,
      data,
    })
      .then((res) => {
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(res.data, 'text/xml');
        if (
          xmlDoc.getElementsByTagName('title').length > 0 &&
          xmlDoc.getElementsByTagName('title')[0].innerHTML ===
            'Доступ запрещен'
        ) {
          reject('Доступ запрещен');
        }
        const list = xmlDoc.getElementsByTagName(
          'reg:getDetailedFormViewXmlDescResponse'
        )[0];
        const arrProps = ['metaAttributeForXml', 'availableTypesUI'];
        const raw = round(list, 0, {}, arrProps)[0].xmlDescription;
        if (raw.data) {
          const bytes = round(list, 0, {}, arrProps)[0].xmlDescription.data
            .bytes;
          const tableData = JSON.parse(
            decodeURIComponent(escape(window.atob(bytes)))
          );

          let tabs = tableData.data.tabs;
          console.log(tabs);
          if (tabs.length === 0) {
            tabs.push({
              name: `Новая вкладка 1`,
              id: 1,
              systemTab: false,
              components: [],
            });
          }
          const selectedAttributes = [];
          const bigElements = ['TEXT_AREA', 'TABLE', 'LINK'];
          if (tabs[0].components.length !== 0) {
            for (let tab of tabs) {
              let y = 0;
              for (let [i, component] of tab.components.entries()) {
                if (component.x || component.x === 0) {
                  break;
                }
                component.x = 0;
                component.y = y;
                component.w = 32;
                component.h = 4;
                let newY = i * 4;
                if (
                  component.elementUIType &&
                  !bigElements.includes(component.elementUIType)
                ) {
                  component.h = 2;
                  newY = 2;
                }
                y += newY;
                if (component.maxWidth) {
                  const maxWidths = Math.max(
                    ...tab.components
                      .map((el) => el.maxWidth)
                      .filter((el) => typeof el === 'number')
                  );
                  component.w = Math.floor(
                    (component.maxWidth / maxWidths) * 32
                  );
                  if (component.width && component.width !== 100) {
                    component.w = Math.floor((component.width / 100) * 32);
                  }
                } else if (component.width) {
                  component.w = Math.floor((component.width / 100) * 32);
                }

                if (component.id === null) {
                  let newId = tab.components.length + 1;
                  while (tab.components.find((el) => el.id === newId)) {
                    newId += 1;
                  }
                  component.id = newId;
                  component.i = `${component.id}`;
                } else {
                  component.i = `${component.id}`;
                }
                if (component.type === 'image') {
                  if (component.bytes.startsWith('data:')) {
                    component.image = component.bytes;
                  } else {
                    component.image =
                      'data:image/png;base64, ' + component.bytes;
                  }
                }
                if (component.attributeId) {
                  selectedAttributes.push(String(component.attributeId));
                }
                if (component.components) {
                  let innerY = 0;
                  let elementUITypeAllInner = [];
                  let bigElementUpdateMain = false;
                  for (let [
                    iInner,
                    innerComponent,
                  ] of component.components.entries()) {
                    elementUITypeAllInner.push(
                      innerComponent.elementUIType &&
                        !bigElements.includes(innerComponent.elementUIType)
                    );
                    if (innerComponent.id === null) {
                      let newId2 = component.components.length + 1;
                      while (
                        component.components.find((el) => el.id === newId2)
                      ) {
                        newId2 += 1;
                      }
                      innerComponent.id = newId2;
                    }
                    innerComponent.x = (iInner * 6) % 32;
                    innerComponent.y = innerY;
                    innerComponent.i = `${component.id}-${innerComponent.id}`;
                    innerComponent.w = 6;
                    innerComponent.h = 3;
                    if (component.layout === 'hbox') {
                      innerComponent.w = Math.floor(
                        32 / component.components.length
                      );
                      innerComponent.x =
                        iInner * Math.floor(32 / component.components.length);
                      const widths = component.components
                        .map((el) => el.width)
                        .filter((el) => !isNaN(el));
                      const sumWidth = widths.reduce((a, b) => a + b, 0);
                      if (innerComponent.maxWidth) {
                        const sumMaxWidth = component.components
                          .map((el) => el.maxWidth)
                          .filter((el) => typeof el === 'number')
                          .reduce((acc, val) => acc + val, 0);
                        const maxWidths = component.maxWidth
                          ? component.maxWidth
                          : sumMaxWidth;
                        if (sumMaxWidth > maxWidths) {
                          innerComponent.w = Math.floor(
                            32 / component.components.length
                          );
                          innerComponent.x = component.components[iInner - 1]
                            ? component.components[iInner - 1].x +
                              component.components[iInner - 1].w
                            : 0;
                        } else {
                          innerComponent.w = Math.floor(
                            (innerComponent.maxWidth / maxWidths) * 32
                          );
                          innerComponent.x = component.components[iInner - 1]
                            ? component.components[iInner - 1].x +
                              component.components[iInner - 1].w
                            : 0;
                        }
                      } else if (sumWidth <= 105) {
                        if (innerComponent.width) {
                          innerComponent.w = Math.floor(
                            (innerComponent.width / 100) * 32
                          );
                          innerComponent.x = component.components[iInner - 1]
                            ? component.components[iInner - 1].x +
                              component.components[iInner - 1].w
                            : 0;
                        } else if (widths.length > 0) {
                          const width =
                            (100 - sumWidth) /
                            (component.components.length - widths.length);
                          innerComponent.w = Math.floor((width / 100) * 32);
                          innerComponent.x = component.components[iInner - 1]
                            ? component.components[iInner - 1].x +
                              component.components[iInner - 1].w
                            : 0;
                        }
                      }
                      if (
                        innerComponent.elementUIType &&
                        !bigElements.includes(innerComponent.elementUIType)
                      ) {
                        innerComponent.h = 2;
                        if (!elementUITypeAllInner.includes(false)) {
                          component.h = 3;
                        }
                      }
                    } else if (component.layout === 'vbox') {
                      innerComponent.w = 32;
                      innerComponent.x = 0;
                      let newYinner = iInner * 3;
                      if (
                        innerComponent.elementUIType &&
                        !bigElements.includes(innerComponent.elementUIType)
                      ) {
                        innerComponent.h = 2;
                        newYinner = 2;
                      }
                      if (bigElements.includes(innerComponent.elementUIType)) {
                        innerComponent.h = 4;
                      }
                      innerY += newYinner;
                      if (iInner !== 0) {
                        component.h +=
                          innerComponent.elementUIType &&
                          !bigElements.includes(innerComponent.elementUIType)
                            ? 2
                            : 3;
                      }
                      if (innerComponent.width) {
                        innerComponent.w = Math.floor(
                          (innerComponent.width / 100) * 32
                        );
                      }
                    }

                    if (innerComponent.type === 'image') {
                      if (innerComponent.bytes.startsWith('data:')) {
                        innerComponent.image = innerComponent.bytes;
                      } else {
                        innerComponent.image =
                          'data:image/png;base64, ' + innerComponent.bytes;
                      }
                    }
                    if (innerComponent.attributeId) {
                      selectedAttributes.push(
                        String(innerComponent.attributeId)
                      );
                    }
                    if (innerComponent.components) {
                      let inner2Y = 0;
                      let bigElementUpdate = false;
                      for (let [
                        iSecondInner,
                        secondInnerComponent,
                      ] of innerComponent.components.entries()) {
                        if (secondInnerComponent.id === null) {
                          let newId3 = innerComponent.components.length + 1;
                          while (
                            innerComponent.components.find(
                              (el) => el.id === newId3
                            )
                          ) {
                            newId3 += 1;
                          }
                          secondInnerComponent.id = newId3;
                        }
                        secondInnerComponent.x = (iSecondInner * 6) % 32;
                        secondInnerComponent.y = inner2Y;
                        secondInnerComponent.i = `${component.id}-${innerComponent.id}-${secondInnerComponent.id}`;
                        secondInnerComponent.w = 6;
                        secondInnerComponent.h = 2;
                        inner2Y += i * 2;
                        if (secondInnerComponent.width) {
                          secondInnerComponent.w = Math.floor(
                            (secondInnerComponent.width / 100) * 32
                          );
                        }
                        if (innerComponent.layout === 'hbox') {
                          secondInnerComponent.w = Math.floor(
                            32 / innerComponent.components.length
                          );
                          secondInnerComponent.x =
                            iSecondInner *
                            Math.floor(32 / innerComponent.components.length);
                          const widths = innerComponent.components
                            .map((el) => el.width)
                            .filter((el) => !isNaN(el));
                          if (secondInnerComponent.maxWidth) {
                            const sumMaxWidth = innerComponent.components
                              .map((el) => el.maxWidth)
                              .filter((el) => typeof el === 'number')
                              .reduce((acc, val) => acc + val, 0);
                            const maxWidths = innerComponent.maxWidth
                              ? innerComponent.maxWidth
                              : sumMaxWidth;
                            if (sumMaxWidth > maxWidths) {
                              secondInnerComponent.w = Math.floor(
                                32 / innerComponent.components.length
                              );
                              secondInnerComponent.x = innerComponent
                                .components[iSecondInner - 1]
                                ? innerComponent.components[iSecondInner - 1]
                                    .x +
                                  innerComponent.components[iSecondInner - 1].w
                                : 0;
                            } else {
                              secondInnerComponent.w = Math.floor(
                                (secondInnerComponent.maxWidth / maxWidths) * 32
                              );
                              secondInnerComponent.x = innerComponent
                                .components[iSecondInner - 1]
                                ? innerComponent.components[iSecondInner - 1]
                                    .x +
                                  innerComponent.components[iSecondInner - 1].w
                                : 0;
                              if (
                                secondInnerComponent.x +
                                  secondInnerComponent.w >
                                32
                              ) {
                                secondInnerComponent.w -=
                                  secondInnerComponent.x +
                                  secondInnerComponent.w -
                                  32;
                              }
                            }
                          } else if (secondInnerComponent.width) {
                            secondInnerComponent.w = Math.floor(
                              (secondInnerComponent.width / 100) * 32
                            );
                            secondInnerComponent.x = innerComponent.components[
                              iSecondInner - 1
                            ]
                              ? innerComponent.components[iSecondInner - 1].x +
                                innerComponent.components[iSecondInner - 1].w
                              : 0;
                          } else if (widths.length > 0) {
                            const sumWidth = widths.reduce((a, b) => a + b, 0);
                            const width =
                              (100 - sumWidth) /
                              (secondInnerComponent.components.length -
                                widths.length);
                            secondInnerComponent.w = Math.floor(
                              (width / 100) * 32
                            );
                            secondInnerComponent.x = innerComponent.components[
                              iSecondInner - 1
                            ]
                              ? innerComponent.components[iSecondInner - 1].x +
                                innerComponent.components[iSecondInner - 1].w
                              : 0;
                          }
                          if (
                            bigElements.includes(
                              secondInnerComponent.elementUIType
                            )
                          ) {
                            secondInnerComponent.h = 4;
                            if (!bigElementUpdate) {
                              innerComponent.h += 2;
                              bigElementUpdate = true;
                              if (!bigElementUpdateMain) {
                                component.h += 2;
                                bigElementUpdateMain = true;
                              }
                            }
                          }
                        } else if (innerComponent.layout === 'vbox') {
                          secondInnerComponent.w = 32;
                          secondInnerComponent.x = 0;
                          inner2Y += iSecondInner * 4;
                          if (
                            bigElements.includes(
                              secondInnerComponent.elementUIType
                            )
                          ) {
                            secondInnerComponent.h = 4;
                            if (!bigElementUpdate) {
                              innerComponent.h += 2;
                              bigElementUpdate = true;
                              if (!bigElementUpdateMain) {
                                if (component.layout === 'hbox') {
                                  if (component.h <= innerComponent.h) {
                                    component.h += 2;
                                    bigElementUpdateMain = true;
                                  }
                                } else {
                                  component.h += 2;
                                  bigElementUpdateMain = true;
                                }
                              }
                            }
                          }
                          if (iSecondInner !== 0) {
                            innerComponent.h += 2;
                            if (component.layout === 'hbox') {
                              if (component.h <= innerComponent.h) {
                                component.h += 2;
                              }
                            } else {
                              component.h += 2;
                            }
                          }
                        }

                        if (secondInnerComponent.type === 'image') {
                          if (secondInnerComponent.bytes.startsWith('data:')) {
                            secondInnerComponent.image =
                              secondInnerComponent.bytes;
                          } else {
                            secondInnerComponent.image =
                              'data:image/png;base64, ' +
                              secondInnerComponent.bytes;
                          }
                        }
                        if (secondInnerComponent.attributeId) {
                          selectedAttributes.push(
                            String(secondInnerComponent.attributeId)
                          );
                        }

                        if (secondInnerComponent.components) {
                          let inner3Y = 0;
                          let bigElementUpdate = false;
                          for (let [
                            iThirdInner,
                            thirdInnerComponent,
                          ] of secondInnerComponent.components.entries()) {
                            if (thirdInnerComponent.id === null) {
                              let newId3 =
                                secondInnerComponent.components.length + 1;
                              while (
                                secondInnerComponent.components.find(
                                  (el) => el.id === newId3
                                )
                              ) {
                                newId3 += 1;
                              }
                              thirdInnerComponent.id = newId3;
                            }
                            thirdInnerComponent.x = (iThirdInner * 6) % 32;
                            thirdInnerComponent.y = inner3Y;
                            thirdInnerComponent.i = `${component.id}-${innerComponent.id}-${secondInnerComponent.id}-${thirdInnerComponent.id}`;
                            thirdInnerComponent.w = 6;
                            thirdInnerComponent.h = 2;
                            inner3Y += iThirdInner * 2;
                            if (thirdInnerComponent.width) {
                              thirdInnerComponent.w = Math.floor(
                                (thirdInnerComponent.width / 100) * 32
                              );
                            }
                            if (secondInnerComponent.layout === 'hbox') {
                              thirdInnerComponent.w = Math.floor(
                                32 / secondInnerComponent.components.length
                              );
                              thirdInnerComponent.x =
                                iThirdInner *
                                Math.floor(
                                  32 / secondInnerComponent.components.length
                                );
                              const widths = secondInnerComponent.components
                                .map((el) => el.width)
                                .filter((el) => !isNaN(el));
                              if (thirdInnerComponent.maxWidth) {
                                const sumMaxWidth =
                                  secondInnerComponent.components
                                    .map((el) => el.maxWidth)
                                    .filter((el) => typeof el === 'number')
                                    .reduce((acc, val) => acc + val, 0);
                                const maxWidths = secondInnerComponent.maxWidth
                                  ? secondInnerComponent.maxWidth
                                  : sumMaxWidth;
                                if (sumMaxWidth > maxWidths) {
                                  thirdInnerComponent.w = Math.floor(
                                    32 / secondInnerComponent.components.length
                                  );
                                  thirdInnerComponent.x = secondInnerComponent
                                    .components[iThirdInner - 1]
                                    ? secondInnerComponent.components[
                                        iThirdInner - 1
                                      ].x +
                                      secondInnerComponent.components[
                                        iThirdInner - 1
                                      ].w
                                    : 0;
                                } else {
                                  thirdInnerComponent.w = Math.floor(
                                    (thirdInnerComponent.maxWidth / maxWidths) *
                                      32
                                  );
                                  if (thirdInnerComponent.w > 32) {
                                    thirdInnerComponent.w = Math.floor(
                                      32 /
                                        secondInnerComponent.components.length
                                    );
                                  }
                                  thirdInnerComponent.x = secondInnerComponent
                                    .components[iThirdInner - 1]
                                    ? secondInnerComponent.components[
                                        iThirdInner - 1
                                      ].x +
                                      secondInnerComponent.components[
                                        iThirdInner - 1
                                      ].w
                                    : 0;
                                  if (
                                    thirdInnerComponent.x +
                                      thirdInnerComponent.w >
                                    32
                                  ) {
                                    thirdInnerComponent.w -=
                                      thirdInnerComponent.x +
                                      thirdInnerComponent.w -
                                      32;
                                  }
                                }
                              } else if (thirdInnerComponent.width) {
                                thirdInnerComponent.w = Math.floor(
                                  (thirdInnerComponent.width / 100) * 32
                                );
                                thirdInnerComponent.x = secondInnerComponent
                                  .components[iThirdInner - 1]
                                  ? secondInnerComponent.components[
                                      iThirdInner - 1
                                    ].x +
                                    secondInnerComponent.components[
                                      iThirdInner - 1
                                    ].w
                                  : 0;
                              } else if (widths.length > 0) {
                                const sumWidth = widths.reduce(
                                  (a, b) => a + b,
                                  0
                                );
                                const width =
                                  (100 - sumWidth) /
                                  (thirdInnerComponent.components.length -
                                    widths.length);
                                thirdInnerComponent.w = Math.floor(
                                  (width / 100) * 32
                                );
                                thirdInnerComponent.x = secondInnerComponent
                                  .components[iThirdInner - 1]
                                  ? secondInnerComponent.components[
                                      iThirdInner - 1
                                    ].x +
                                    secondInnerComponent.components[
                                      iThirdInner - 1
                                    ].w
                                  : 0;
                              }
                              if (
                                bigElements.includes(
                                  thirdInnerComponent.elementUIType
                                )
                              ) {
                                thirdInnerComponent.h = 4;
                                secondInnerComponent.h += 2;
                                component.h += 2;
                              }
                            } else if (secondInnerComponent.layout === 'vbox') {
                              thirdInnerComponent.w = 32;
                              thirdInnerComponent.x = 0;
                              inner3Y += iThirdInner * 4;

                              if (iThirdInner !== 0) {
                                secondInnerComponent.h += 2;
                              } else {
                                secondInnerComponent.h += 1;
                              }
                              if (
                                bigElements.includes(
                                  thirdInnerComponent.elementUIType
                                )
                              ) {
                                thirdInnerComponent.h = 4;
                                if (!bigElementUpdate) {
                                  secondInnerComponent.h += 2;
                                  bigElementUpdate = true;
                                }
                              }

                              if (innerComponent.layout === 'hbox') {
                                if (
                                  innerComponent.h <= secondInnerComponent.h
                                ) {
                                  innerComponent.h += 1;
                                }
                              } else {
                                innerComponent.h += 1;
                              }

                              if (component.layout === 'hbox') {
                                if (component.h <= innerComponent.h) {
                                  component.h += 1;
                                }
                              } else {
                                component.h += 1;
                              }
                            }
                            if (thirdInnerComponent.type === 'image') {
                              if (
                                thirdInnerComponent.bytes.startsWith('data:')
                              ) {
                                thirdInnerComponent.image =
                                  thirdInnerComponent.bytes;
                              } else {
                                thirdInnerComponent.image =
                                  'data:image/png;base64, ' +
                                  thirdInnerComponent.bytes;
                              }
                            }
                            if (thirdInnerComponent.attributeId) {
                              selectedAttributes.push(
                                String(thirdInnerComponent.attributeId)
                              );
                            }

                            if (thirdInnerComponent.components) {
                              let inner4Y = 0;
                              let bigElementUpdate = false;
                              for (let [
                                iFourthInner,
                                fourthInnerComponent,
                              ] of thirdInnerComponent.components.entries()) {
                                if (fourthInnerComponent.id === null) {
                                  let newId3 =
                                    thirdInnerComponent.components.length + 1;
                                  while (
                                    thirdInnerComponent.components.find(
                                      (el) => el.id === newId3
                                    )
                                  ) {
                                    newId3 += 1;
                                  }
                                  fourthInnerComponent.id = newId3;
                                }

                                fourthInnerComponent.x =
                                  (iFourthInner * 6) % 32;
                                fourthInnerComponent.y = inner4Y;
                                fourthInnerComponent.i = `${component.id}-${innerComponent.id}-${secondInnerComponent.id}-${thirdInnerComponent.id}-${fourthInnerComponent.id}`;
                                fourthInnerComponent.w = 6;
                                fourthInnerComponent.h = 2;
                                inner4Y += iFourthInner * 2;
                                if (fourthInnerComponent.width) {
                                  fourthInnerComponent.w = Math.floor(
                                    (fourthInnerComponent.width / 100) * 32
                                  );
                                }
                                if (thirdInnerComponent.layout === 'hbox') {
                                  fourthInnerComponent.w = Math.floor(
                                    32 / thirdInnerComponent.components.length
                                  );
                                  fourthInnerComponent.x =
                                    iFourthInner *
                                    Math.floor(
                                      32 / thirdInnerComponent.components.length
                                    );
                                  const widths = thirdInnerComponent.components
                                    .map((el) => el.width)
                                    .filter((el) => !isNaN(el));
                                  if (fourthInnerComponent.maxWidth) {
                                    const sumMaxWidth =
                                      thirdInnerComponent.components
                                        .map((el) => el.maxWidth)
                                        .filter((el) => typeof el === 'number')
                                        .reduce((acc, val) => acc + val, 0);
                                    const maxWidths =
                                      thirdInnerComponent.maxWidth
                                        ? thirdInnerComponent.maxWidth
                                        : sumMaxWidth;
                                    if (sumMaxWidth > maxWidths) {
                                      fourthInnerComponent.w = Math.floor(
                                        32 /
                                          thirdInnerComponent.components.length
                                      );
                                      fourthInnerComponent.x =
                                        thirdInnerComponent.components[
                                          iFourthInner - 1
                                        ]
                                          ? thirdInnerComponent.components[
                                              iFourthInner - 1
                                            ].x +
                                            thirdInnerComponent.components[
                                              iFourthInner - 1
                                            ].w
                                          : 0;
                                    } else {
                                      fourthInnerComponent.w = Math.floor(
                                        (fourthInnerComponent.maxWidth /
                                          maxWidths) *
                                          32
                                      );
                                      if (fourthInnerComponent.w > 32) {
                                        fourthInnerComponent.w = Math.floor(
                                          32 /
                                            thirdInnerComponent.components
                                              .length
                                        );
                                      }
                                      fourthInnerComponent.x =
                                        thirdInnerComponent.components[
                                          iFourthInner - 1
                                        ]
                                          ? thirdInnerComponent.components[
                                              iFourthInner - 1
                                            ].x +
                                            thirdInnerComponent.components[
                                              iFourthInner - 1
                                            ].w
                                          : 0;
                                      if (
                                        fourthInnerComponent.x +
                                          fourthInnerComponent.w >
                                        32
                                      ) {
                                        fourthInnerComponent.w -=
                                          fourthInnerComponent.x +
                                          fourthInnerComponent.w -
                                          32;
                                      }
                                    }
                                  } else if (fourthInnerComponent.width) {
                                    fourthInnerComponent.w = Math.floor(
                                      (fourthInnerComponent.width / 100) * 32
                                    );
                                    fourthInnerComponent.x = thirdInnerComponent
                                      .components[iFourthInner - 1]
                                      ? thirdInnerComponent.components[
                                          iFourthInner - 1
                                        ].x +
                                        thirdInnerComponent.components[
                                          iFourthInner - 1
                                        ].w
                                      : 0;
                                  } else if (widths.length > 0) {
                                    const sumWidth = widths.reduce(
                                      (a, b) => a + b,
                                      0
                                    );
                                    const width =
                                      (100 - sumWidth) /
                                      (fourthInnerComponent.components.length -
                                        widths.length);
                                    fourthInnerComponent.w = Math.floor(
                                      (width / 100) * 32
                                    );
                                    fourthInnerComponent.x = thirdInnerComponent
                                      .components[iFourthInner - 1]
                                      ? thirdInnerComponent.components[
                                          iFourthInner - 1
                                        ].x +
                                        thirdInnerComponent.components[
                                          iFourthInner - 1
                                        ].w
                                      : 0;
                                  }
                                  if (
                                    bigElements.includes(
                                      fourthInnerComponent.elementUIType
                                    )
                                  ) {
                                    fourthInnerComponent.h = 4;
                                    thirdInnerComponent.h += 2;
                                    if (
                                      secondInnerComponent.layout === 'hbox'
                                    ) {
                                      if (
                                        secondInnerComponent.h <=
                                        thirdInnerComponent.h
                                      ) {
                                        secondInnerComponent.h =
                                          thirdInnerComponent.h + 1;
                                      }
                                    } else {
                                      secondInnerComponent.h += 2;
                                    }
                                    if (innerComponent.layout === 'hbox') {
                                      if (
                                        innerComponent.h <=
                                        secondInnerComponent.h
                                      ) {
                                        innerComponent.h =
                                          secondInnerComponent.h + 1;
                                      }
                                    } else {
                                      innerComponent.h += 2;
                                    }
                                  }
                                } else if (
                                  thirdInnerComponent.layout === 'vbox'
                                ) {
                                  fourthInnerComponent.w = 32;
                                  fourthInnerComponent.x = 0;
                                  inner4Y += iFourthInner * 4;

                                  if (iFourthInner !== 0) {
                                    thirdInnerComponent.h += 2;
                                  } else {
                                    thirdInnerComponent.h += 1;
                                  }
                                  if (
                                    bigElements.includes(
                                      fourthInnerComponent.elementUIType
                                    )
                                  ) {
                                    fourthInnerComponent.h = 4;
                                    if (!bigElementUpdate) {
                                      thirdInnerComponent.h += 2;
                                      secondInnerComponent.h += 2;
                                      innerComponent.h += 2;
                                      bigElementUpdate = true;
                                    }
                                  }

                                  if (secondInnerComponent.layout === 'hbox') {
                                    if (
                                      secondInnerComponent.h <=
                                      thirdInnerComponent.h
                                    ) {
                                      secondInnerComponent.h =
                                        thirdInnerComponent.h + 1;
                                    }
                                  } else {
                                    secondInnerComponent.h += 1;
                                  }

                                  if (innerComponent.layout === 'hbox') {
                                    if (
                                      innerComponent.h <= secondInnerComponent.h
                                    ) {
                                      innerComponent.h =
                                        secondInnerComponent.h + 1;
                                    }
                                  } else {
                                    innerComponent.h += 1;
                                  }

                                  if (component.layout === 'hbox') {
                                    if (component.h <= innerComponent.h) {
                                      component.h = innerComponent.h + 1;
                                    }
                                  } else {
                                    component.h += 1;
                                  }
                                }
                                if (fourthInnerComponent.type === 'image') {
                                  if (
                                    fourthInnerComponent.bytes.startsWith(
                                      'data:'
                                    )
                                  ) {
                                    fourthInnerComponent.image =
                                      fourthInnerComponent.bytes;
                                  } else {
                                    fourthInnerComponent.image =
                                      'data:image/png;base64, ' +
                                      fourthInnerComponent.bytes;
                                  }
                                }
                                if (fourthInnerComponent.attributeId) {
                                  selectedAttributes.push(
                                    String(fourthInnerComponent.attributeId)
                                  );
                                }

                                if (fourthInnerComponent.components) {
                                  let inner5Y = 0;
                                  let bigElementUpdate = false;
                                  for (let [
                                    iFifthInner,
                                    fifthInnerComponent,
                                  ] of fourthInnerComponent.components.entries()) {
                                    if (fifthInnerComponent.id === null) {
                                      let newId3 =
                                        fourthInnerComponent.components.length +
                                        1;
                                      while (
                                        fourthInnerComponent.components.find(
                                          (el) => el.id === newId3
                                        )
                                      ) {
                                        newId3 += 1;
                                      }
                                      fifthInnerComponent.id = newId3;
                                    }

                                    fifthInnerComponent.x =
                                      (iFifthInner * 6) % 32;
                                    fifthInnerComponent.y = inner5Y;
                                    fifthInnerComponent.i = `${component.id}-${innerComponent.id}-${secondInnerComponent.id}-${thirdInnerComponent.id}-${fourthInnerComponent.id}-${fifthInnerComponent.id}`;
                                    fifthInnerComponent.w = 6;
                                    fifthInnerComponent.h = 2;
                                    inner5Y += iFifthInner * 2;
                                    if (fifthInnerComponent.width) {
                                      fifthInnerComponent.w = Math.floor(
                                        (fifthInnerComponent.width / 100) * 32
                                      );
                                    }
                                    if (
                                      fourthInnerComponent.layout === 'hbox'
                                    ) {
                                      fifthInnerComponent.w = Math.floor(
                                        32 /
                                          fourthInnerComponent.components.length
                                      );
                                      fifthInnerComponent.x =
                                        iFifthInner *
                                        Math.floor(
                                          32 /
                                            fourthInnerComponent.components
                                              .length
                                        );
                                      const widths =
                                        fourthInnerComponent.components
                                          .map((el) => el.width)
                                          .filter((el) => !isNaN(el));
                                      if (fifthInnerComponent.maxWidth) {
                                        const sumMaxWidth =
                                          fourthInnerComponent.components
                                            .map((el) => el.maxWidth)
                                            .filter(
                                              (el) => typeof el === 'number'
                                            )
                                            .reduce((acc, val) => acc + val, 0);
                                        const maxWidths =
                                          fourthInnerComponent.maxWidth
                                            ? fourthInnerComponent.maxWidth
                                            : sumMaxWidth;
                                        if (sumMaxWidth > maxWidths) {
                                          fifthInnerComponent.w = Math.floor(
                                            32 /
                                              fourthInnerComponent.components
                                                .length
                                          );
                                          fifthInnerComponent.x =
                                            fourthInnerComponent.components[
                                              iFifthInner - 1
                                            ]
                                              ? fourthInnerComponent.components[
                                                  iFifthInner - 1
                                                ].x +
                                                fourthInnerComponent.components[
                                                  iFifthInner - 1
                                                ].w
                                              : 0;
                                        } else {
                                          fifthInnerComponent.w = Math.floor(
                                            (fifthInnerComponent.maxWidth /
                                              maxWidths) *
                                              32
                                          );
                                          if (fifthInnerComponent.w > 32) {
                                            fifthInnerComponent.w = Math.floor(
                                              32 /
                                                fourthInnerComponent.components
                                                  .length
                                            );
                                          }
                                          fifthInnerComponent.x =
                                            fourthInnerComponent.components[
                                              iFifthInner - 1
                                            ]
                                              ? fourthInnerComponent.components[
                                                  iFifthInner - 1
                                                ].x +
                                                fourthInnerComponent.components[
                                                  iFifthInner - 1
                                                ].w
                                              : 0;
                                          if (
                                            fifthInnerComponent.x +
                                              fifthInnerComponent.w >
                                            32
                                          ) {
                                            fifthInnerComponent.w -=
                                              fifthInnerComponent.x +
                                              fifthInnerComponent.w -
                                              32;
                                          }
                                        }
                                      } else if (fifthInnerComponent.width) {
                                        fifthInnerComponent.w = Math.floor(
                                          (fifthInnerComponent.width / 100) * 32
                                        );
                                        fifthInnerComponent.x =
                                          fourthInnerComponent.components[
                                            iFifthInner - 1
                                          ]
                                            ? fourthInnerComponent.components[
                                                iFifthInner - 1
                                              ].x +
                                              fourthInnerComponent.components[
                                                iFifthInner - 1
                                              ].w
                                            : 0;
                                      } else if (widths.length > 0) {
                                        const sumWidth = widths.reduce(
                                          (a, b) => a + b,
                                          0
                                        );
                                        const width =
                                          (100 - sumWidth) /
                                          (fifthInnerComponent.components
                                            .length -
                                            widths.length);
                                        fifthInnerComponent.w = Math.floor(
                                          (width / 100) * 32
                                        );
                                        fifthInnerComponent.x =
                                          fourthInnerComponent.components[
                                            iFifthInner - 1
                                          ]
                                            ? fourthInnerComponent.components[
                                                iFifthInner - 1
                                              ].x +
                                              fourthInnerComponent.components[
                                                iFifthInner - 1
                                              ].w
                                            : 0;
                                      }
                                      if (
                                        bigElements.includes(
                                          fifthInnerComponent.elementUIType
                                        )
                                      ) {
                                        fifthInnerComponent.h = 4;
                                        fourthInnerComponent.h += 2;
                                        if (
                                          thirdInnerComponent.layout === 'hbox'
                                        ) {
                                          if (
                                            thirdInnerComponent.h <=
                                            fourthInnerComponent.h
                                          ) {
                                            thirdInnerComponent.h =
                                              fourthInnerComponent.h + 1;
                                          }
                                        } else {
                                          thirdInnerComponent.h += 2;
                                        }
                                        if (
                                          secondInnerComponent.layout === 'hbox'
                                        ) {
                                          if (
                                            secondInnerComponent.h <=
                                            fourthInnerComponent.h
                                          ) {
                                            secondInnerComponent.h =
                                              fourthInnerComponent.h + 1;
                                          }
                                        } else {
                                          secondInnerComponent.h += 2;
                                        }
                                        if (innerComponent.layout === 'hbox') {
                                          if (
                                            innerComponent.h <=
                                            secondInnerComponent.h
                                          ) {
                                            innerComponent.h =
                                              secondInnerComponent.h + 1;
                                          }
                                        } else {
                                          innerComponent.h += 2;
                                        }
                                      }
                                    } else if (
                                      fourthInnerComponent.layout === 'vbox'
                                    ) {
                                      fifthInnerComponent.w = 32;
                                      fifthInnerComponent.x = 0;
                                      inner5Y += iFifthInner * 4;

                                      if (iFifthInner !== 0) {
                                        fourthInnerComponent.h += 2;
                                      } else {
                                        fourthInnerComponent.h += 1;
                                      }
                                      if (
                                        bigElements.includes(
                                          fifthInnerComponent.elementUIType
                                        )
                                      ) {
                                        fifthInnerComponent.h = 4;
                                        if (!bigElementUpdate) {
                                          fourthInnerComponent.h += 2;
                                          thirdInnerComponent.h += 2;
                                          secondInnerComponent.h += 2;
                                          innerComponent.h += 2;
                                          bigElementUpdate = true;
                                        }
                                      }

                                      if (
                                        thirdInnerComponent.layout === 'hbox'
                                      ) {
                                        if (
                                          thirdInnerComponent.h <=
                                          fourthInnerComponent.h
                                        ) {
                                          thirdInnerComponent.h =
                                            fourthInnerComponent.h + 1;
                                        }
                                      } else {
                                        thirdInnerComponent.h += 1;
                                      }

                                      if (
                                        secondInnerComponent.layout === 'hbox'
                                      ) {
                                        if (
                                          secondInnerComponent.h <=
                                          thirdInnerComponent.h
                                        ) {
                                          secondInnerComponent.h =
                                            thirdInnerComponent.h + 1;
                                        }
                                      } else {
                                        secondInnerComponent.h += 1;
                                      }

                                      if (innerComponent.layout === 'hbox') {
                                        if (
                                          innerComponent.h <=
                                          secondInnerComponent.h
                                        ) {
                                          innerComponent.h =
                                            secondInnerComponent.h + 1;
                                        }
                                      } else {
                                        innerComponent.h += 1;
                                      }

                                      if (component.layout === 'hbox') {
                                        if (component.h <= innerComponent.h) {
                                          component.h = innerComponent.h + 1;
                                        }
                                      } else {
                                        component.h += 1;
                                      }
                                    }
                                    if (fifthInnerComponent.type === 'image') {
                                      if (
                                        fifthInnerComponent.bytes.startsWith(
                                          'data:'
                                        )
                                      ) {
                                        fifthInnerComponent.image =
                                          fifthInnerComponent.bytes;
                                      } else {
                                        fifthInnerComponent.image =
                                          'data:image/png;base64, ' +
                                          fifthInnerComponent.bytes;
                                      }
                                    }
                                    if (fifthInnerComponent.attributeId) {
                                      selectedAttributes.push(
                                        String(fifthInnerComponent.attributeId)
                                      );
                                    }

                                    if (fifthInnerComponent.components) {
                                      let inner6Y = 0;
                                      let bigElementUpdate = false;
                                      for (let [
                                        iSixInner,
                                        sixInnerComponent,
                                      ] of fifthInnerComponent.components.entries()) {
                                        if (sixInnerComponent.id === null) {
                                          let newId6 =
                                            fifthInnerComponent.components
                                              .length + 1;
                                          while (
                                            fifthInnerComponent.components.find(
                                              (el) => el.id === newId6
                                            )
                                          ) {
                                            newId6 += 1;
                                          }
                                          sixInnerComponent.id = newId6;
                                        }

                                        sixInnerComponent.x =
                                          (iSixInner * 6) % 32;
                                        sixInnerComponent.y = inner6Y;
                                        sixInnerComponent.i = `${component.id}-${innerComponent.id}-${secondInnerComponent.id}-${thirdInnerComponent.id}-${fourthInnerComponent.id}-${fifthInnerComponent.id}-${sixInnerComponent.id}`;
                                        sixInnerComponent.w = 6;
                                        sixInnerComponent.h = 2;
                                        inner6Y += iSixInner * 2;
                                        if (sixInnerComponent.width) {
                                          sixInnerComponent.w = Math.floor(
                                            (sixInnerComponent.width / 100) * 32
                                          );
                                        }
                                        if (
                                          fifthInnerComponent.layout === 'hbox'
                                        ) {
                                          sixInnerComponent.w = Math.floor(
                                            32 /
                                              fifthInnerComponent.components
                                                .length
                                          );
                                          sixInnerComponent.x =
                                            iSixInner *
                                            Math.floor(
                                              32 /
                                                fifthInnerComponent.components
                                                  .length
                                            );
                                          const widths =
                                            fifthInnerComponent.components
                                              .map((el) => el.width)
                                              .filter((el) => !isNaN(el));
                                          if (sixInnerComponent.maxWidth) {
                                            const sumMaxWidth =
                                              fifthInnerComponent.components
                                                .map((el) => el.maxWidth)
                                                .filter(
                                                  (el) => typeof el === 'number'
                                                )
                                                .reduce(
                                                  (acc, val) => acc + val,
                                                  0
                                                );
                                            const maxWidths =
                                              fifthInnerComponent.maxWidth
                                                ? fifthInnerComponent.maxWidth
                                                : sumMaxWidth;
                                            if (sumMaxWidth > maxWidths) {
                                              sixInnerComponent.w = Math.floor(
                                                32 /
                                                  fifthInnerComponent.components
                                                    .length
                                              );
                                              sixInnerComponent.x =
                                                fifthInnerComponent.components[
                                                  iSixInner - 1
                                                ]
                                                  ? fifthInnerComponent
                                                      .components[iSixInner - 1]
                                                      .x +
                                                    fifthInnerComponent
                                                      .components[iSixInner - 1]
                                                      .w
                                                  : 0;
                                            } else {
                                              sixInnerComponent.w = Math.floor(
                                                (sixInnerComponent.maxWidth /
                                                  maxWidths) *
                                                  32
                                              );
                                              if (sixInnerComponent.w > 32) {
                                                sixInnerComponent.w =
                                                  Math.floor(
                                                    32 /
                                                      fifthInnerComponent
                                                        .components.length
                                                  );
                                              }
                                              sixInnerComponent.x =
                                                fifthInnerComponent.components[
                                                  iSixInner - 1
                                                ]
                                                  ? fifthInnerComponent
                                                      .components[iSixInner - 1]
                                                      .x +
                                                    fifthInnerComponent
                                                      .components[iSixInner - 1]
                                                      .w
                                                  : 0;
                                              if (
                                                sixInnerComponent.x +
                                                  sixInnerComponent.w >
                                                32
                                              ) {
                                                sixInnerComponent.w -=
                                                  sixInnerComponent.x +
                                                  sixInnerComponent.w -
                                                  32;
                                              }
                                            }
                                          } else if (sixInnerComponent.width) {
                                            sixInnerComponent.w = Math.floor(
                                              (sixInnerComponent.width / 100) *
                                                32
                                            );
                                            sixInnerComponent.x =
                                              fifthInnerComponent.components[
                                                iSixInner - 1
                                              ]
                                                ? fifthInnerComponent
                                                    .components[iSixInner - 1]
                                                    .x +
                                                  fifthInnerComponent
                                                    .components[iSixInner - 1].w
                                                : 0;
                                          } else if (widths.length > 0) {
                                            const sumWidth = widths.reduce(
                                              (a, b) => a + b,
                                              0
                                            );
                                            const width =
                                              (100 - sumWidth) /
                                              (sixInnerComponent.components
                                                .length -
                                                widths.length);
                                            sixInnerComponent.w = Math.floor(
                                              (width / 100) * 32
                                            );
                                            sixInnerComponent.x =
                                              fifthInnerComponent.components[
                                                iSixInner - 1
                                              ]
                                                ? fifthInnerComponent
                                                    .components[iSixInner - 1]
                                                    .x +
                                                  fifthInnerComponent
                                                    .components[iSixInner - 1].w
                                                : 0;
                                          }
                                          if (
                                            bigElements.includes(
                                              sixInnerComponent.elementUIType
                                            )
                                          ) {
                                            sixInnerComponent.h = 4;
                                            fifthInnerComponent.h += 2;
                                            if (
                                              fourthInnerComponent.layout ===
                                              'hbox'
                                            ) {
                                              if (
                                                fourthInnerComponent.h <=
                                                fifthInnerComponent.h
                                              ) {
                                                fourthInnerComponent.h =
                                                  fifthInnerComponent.h + 1;
                                              }
                                            } else {
                                              fourthInnerComponent.h += 2;
                                            }
                                            if (
                                              thirdInnerComponent.layout ===
                                              'hbox'
                                            ) {
                                              if (
                                                thirdInnerComponent.h <=
                                                fifthInnerComponent.h
                                              ) {
                                                thirdInnerComponent.h =
                                                  fifthInnerComponent.h + 1;
                                              }
                                            } else {
                                              thirdInnerComponent.h += 2;
                                            }
                                            if (
                                              secondInnerComponent.layout ===
                                              'hbox'
                                            ) {
                                              if (
                                                secondInnerComponent.h <=
                                                fifthInnerComponent.h
                                              ) {
                                                secondInnerComponent.h =
                                                  fifthInnerComponent.h + 1;
                                              }
                                            } else {
                                              secondInnerComponent.h += 2;
                                            }
                                            if (
                                              innerComponent.layout === 'hbox'
                                            ) {
                                              if (
                                                innerComponent.h <=
                                                secondInnerComponent.h
                                              ) {
                                                innerComponent.h =
                                                  secondInnerComponent.h + 1;
                                              }
                                            } else {
                                              innerComponent.h += 2;
                                            }
                                          }
                                        } else if (
                                          fifthInnerComponent.layout === 'vbox'
                                        ) {
                                          sixInnerComponent.w = 32;
                                          sixInnerComponent.x = 0;
                                          inner6Y += iSixInner * 4;

                                          if (iSixInner !== 0) {
                                            fifthInnerComponent.h += 2;
                                          } else {
                                            fifthInnerComponent.h += 1;
                                          }
                                          if (
                                            bigElements.includes(
                                              sixInnerComponent.elementUIType
                                            )
                                          ) {
                                            sixInnerComponent.h = 4;
                                            if (!bigElementUpdate) {
                                              fifthInnerComponent.h += 2;
                                              fourthInnerComponent.h += 2;
                                              thirdInnerComponent.h += 2;
                                              secondInnerComponent.h += 2;
                                              innerComponent.h += 2;
                                              bigElementUpdate = true;
                                            }
                                          }

                                          if (
                                            fourthInnerComponent.layout ===
                                            'hbox'
                                          ) {
                                            if (
                                              fourthInnerComponent.h <=
                                              fifthInnerComponent.h
                                            ) {
                                              fourthInnerComponent.h =
                                                fifthInnerComponent.h + 1;
                                            }
                                          } else {
                                            fourthInnerComponent.h += 1;
                                          }

                                          if (
                                            thirdInnerComponent.layout ===
                                            'hbox'
                                          ) {
                                            if (
                                              thirdInnerComponent.h <=
                                              fifthInnerComponent.h
                                            ) {
                                              thirdInnerComponent.h =
                                                fifthInnerComponent.h + 1;
                                            }
                                          } else {
                                            thirdInnerComponent.h += 1;
                                          }

                                          if (
                                            secondInnerComponent.layout ===
                                            'hbox'
                                          ) {
                                            if (
                                              secondInnerComponent.h <=
                                              thirdInnerComponent.h
                                            ) {
                                              secondInnerComponent.h =
                                                thirdInnerComponent.h + 1;
                                            }
                                          } else {
                                            secondInnerComponent.h += 1;
                                          }

                                          if (
                                            innerComponent.layout === 'hbox'
                                          ) {
                                            if (
                                              innerComponent.h <=
                                              secondInnerComponent.h
                                            ) {
                                              innerComponent.h =
                                                secondInnerComponent.h + 1;
                                            }
                                          } else {
                                            innerComponent.h += 1;
                                          }

                                          if (component.layout === 'hbox') {
                                            if (
                                              component.h <= innerComponent.h
                                            ) {
                                              component.h =
                                                innerComponent.h + 1;
                                            }
                                          } else {
                                            component.h += 1;
                                          }
                                        }
                                        if (
                                          sixInnerComponent.type === 'image'
                                        ) {
                                          if (
                                            sixInnerComponent.bytes.startsWith(
                                              'data:'
                                            )
                                          ) {
                                            sixInnerComponent.image =
                                              sixInnerComponent.bytes;
                                          } else {
                                            sixInnerComponent.image =
                                              'data:image/png;base64, ' +
                                              sixInnerComponent.bytes;
                                          }
                                        }
                                        if (sixInnerComponent.attributeId) {
                                          selectedAttributes.push(
                                            String(
                                              sixInnerComponent.attributeId
                                            )
                                          );
                                        }

                                        if (sixInnerComponent.components) {
                                          let inner7Y = 0;
                                          let bigElementUpdate = false;
                                          for (let [
                                            iSevenInner,
                                            sevenInnerComponent,
                                          ] of sixInnerComponent.components.entries()) {
                                            if (
                                              sevenInnerComponent.id === null
                                            ) {
                                              let newId7 =
                                                sixInnerComponent.components
                                                  .length + 1;
                                              while (
                                                sixInnerComponent.components.find(
                                                  (el) => el.id === newId7
                                                )
                                              ) {
                                                newId7 += 1;
                                              }
                                              sevenInnerComponent.id = newId7;
                                            }

                                            sevenInnerComponent.x =
                                              (iSevenInner * 6) % 32;
                                            sevenInnerComponent.y = inner7Y;
                                            sevenInnerComponent.i = `${component.id}-${innerComponent.id}-${secondInnerComponent.id}-${thirdInnerComponent.id}-${fourthInnerComponent.id}-${fifthInnerComponent.id}-${sixInnerComponent.id}-${sevenInnerComponent.id}`;
                                            sevenInnerComponent.w = 6;
                                            sevenInnerComponent.h = 2;
                                            inner7Y += iSevenInner * 2;
                                            if (sevenInnerComponent.width) {
                                              sevenInnerComponent.w =
                                                Math.floor(
                                                  (sevenInnerComponent.width /
                                                    100) *
                                                    32
                                                );
                                            }
                                            if (
                                              sixInnerComponent.layout ===
                                              'hbox'
                                            ) {
                                              sevenInnerComponent.w =
                                                Math.floor(
                                                  32 /
                                                    sixInnerComponent.components
                                                      .length
                                                );
                                              sevenInnerComponent.x =
                                                iSevenInner *
                                                Math.floor(
                                                  32 /
                                                    sixInnerComponent.components
                                                      .length
                                                );
                                              const widths =
                                                sixInnerComponent.components
                                                  .map((el) => el.width)
                                                  .filter((el) => !isNaN(el));
                                              if (
                                                sevenInnerComponent.maxWidth
                                              ) {
                                                const sumMaxWidth =
                                                  sixInnerComponent.components
                                                    .map((el) => el.maxWidth)
                                                    .filter(
                                                      (el) =>
                                                        typeof el === 'number'
                                                    )
                                                    .reduce(
                                                      (acc, val) => acc + val,
                                                      0
                                                    );
                                                const maxWidths =
                                                  sixInnerComponent.maxWidth
                                                    ? sixInnerComponent.maxWidth
                                                    : sumMaxWidth;
                                                if (sumMaxWidth > maxWidths) {
                                                  sevenInnerComponent.w =
                                                    Math.floor(
                                                      32 /
                                                        sixInnerComponent
                                                          .components.length
                                                    );
                                                  sevenInnerComponent.x =
                                                    sixInnerComponent
                                                      .components[
                                                      iSevenInner - 1
                                                    ]
                                                      ? sixInnerComponent
                                                          .components[
                                                          iSevenInner - 1
                                                        ].x +
                                                        sixInnerComponent
                                                          .components[
                                                          iSevenInner - 1
                                                        ].w
                                                      : 0;
                                                } else {
                                                  sevenInnerComponent.w =
                                                    Math.floor(
                                                      (sevenInnerComponent.maxWidth /
                                                        maxWidths) *
                                                        32
                                                    );
                                                  if (
                                                    sevenInnerComponent.w > 32
                                                  ) {
                                                    sevenInnerComponent.w =
                                                      Math.floor(
                                                        32 /
                                                          sixInnerComponent
                                                            .components.length
                                                      );
                                                  }
                                                  sevenInnerComponent.x =
                                                    sixInnerComponent
                                                      .components[
                                                      iSevenInner - 1
                                                    ]
                                                      ? sixInnerComponent
                                                          .components[
                                                          iSevenInner - 1
                                                        ].x +
                                                        sixInnerComponent
                                                          .components[
                                                          iSevenInner - 1
                                                        ].w
                                                      : 0;
                                                  if (
                                                    sevenInnerComponent.x +
                                                      sevenInnerComponent.w >
                                                    32
                                                  ) {
                                                    sevenInnerComponent.w -=
                                                      sevenInnerComponent.x +
                                                      sevenInnerComponent.w -
                                                      32;
                                                  }
                                                }
                                              } else if (
                                                sevenInnerComponent.width
                                              ) {
                                                sevenInnerComponent.w =
                                                  Math.floor(
                                                    (sevenInnerComponent.width /
                                                      100) *
                                                      32
                                                  );
                                                sevenInnerComponent.x =
                                                  sixInnerComponent.components[
                                                    iSevenInner - 1
                                                  ]
                                                    ? sixInnerComponent
                                                        .components[
                                                        iSevenInner - 1
                                                      ].x +
                                                      sixInnerComponent
                                                        .components[
                                                        iSevenInner - 1
                                                      ].w
                                                    : 0;
                                              } else if (widths.length > 0) {
                                                const sumWidth = widths.reduce(
                                                  (a, b) => a + b,
                                                  0
                                                );
                                                const width =
                                                  (100 - sumWidth) /
                                                  (sevenInnerComponent
                                                    .components.length -
                                                    widths.length);
                                                sevenInnerComponent.w =
                                                  Math.floor(
                                                    (width / 100) * 32
                                                  );
                                                sevenInnerComponent.x =
                                                  sixInnerComponent.components[
                                                    iSevenInner - 1
                                                  ]
                                                    ? sixInnerComponent
                                                        .components[
                                                        iSevenInner - 1
                                                      ].x +
                                                      sixInnerComponent
                                                        .components[
                                                        iSevenInner - 1
                                                      ].w
                                                    : 0;
                                              }
                                              if (
                                                bigElements.includes(
                                                  sevenInnerComponent.elementUIType
                                                )
                                              ) {
                                                sevenInnerComponent.h = 4;
                                                sixInnerComponent.h += 2;
                                                if (
                                                  sixInnerComponent.layout ===
                                                  'hbox'
                                                ) {
                                                  if (
                                                    sixInnerComponent.h <=
                                                    sevenInnerComponent.h
                                                  ) {
                                                    sixInnerComponent.h =
                                                      sevenInnerComponent.h + 1;
                                                  }
                                                } else {
                                                  sixInnerComponent.h += 2;
                                                }
                                                if (
                                                  fifthInnerComponent.layout ===
                                                  'hbox'
                                                ) {
                                                  if (
                                                    fifthInnerComponent.h <=
                                                    sixInnerComponent.h
                                                  ) {
                                                    fifthInnerComponent.h =
                                                      sixInnerComponent.h + 1;
                                                  }
                                                } else {
                                                  fifthInnerComponent.h += 2;
                                                }
                                                if (
                                                  fourthInnerComponent.layout ===
                                                  'hbox'
                                                ) {
                                                  if (
                                                    fourthInnerComponent.h <=
                                                    sixInnerComponent.h
                                                  ) {
                                                    fourthInnerComponent.h =
                                                      sixInnerComponent.h + 1;
                                                  }
                                                } else {
                                                  fourthInnerComponent.h += 2;
                                                }
                                                if (
                                                  thirdInnerComponent.layout ===
                                                  'hbox'
                                                ) {
                                                  if (
                                                    thirdInnerComponent.h <=
                                                    sixInnerComponent.h
                                                  ) {
                                                    thirdInnerComponent.h =
                                                      sixInnerComponent.h + 1;
                                                  }
                                                } else {
                                                  thirdInnerComponent.h += 2;
                                                }
                                                if (
                                                  secondInnerComponent.layout ===
                                                  'hbox'
                                                ) {
                                                  if (
                                                    secondInnerComponent.h <=
                                                    sixInnerComponent.h
                                                  ) {
                                                    secondInnerComponent.h =
                                                      sixInnerComponent.h + 1;
                                                  }
                                                } else {
                                                  secondInnerComponent.h += 2;
                                                }
                                                if (
                                                  innerComponent.layout ===
                                                  'hbox'
                                                ) {
                                                  if (
                                                    innerComponent.h <=
                                                    secondInnerComponent.h
                                                  ) {
                                                    innerComponent.h =
                                                      secondInnerComponent.h +
                                                      1;
                                                  }
                                                } else {
                                                  innerComponent.h += 2;
                                                }
                                              }
                                            } else if (
                                              sixInnerComponent.layout ===
                                              'vbox'
                                            ) {
                                              sevenInnerComponent.w = 32;
                                              sevenInnerComponent.x = 0;
                                              inner7Y += iSevenInner * 4;

                                              if (iSevenInner !== 0) {
                                                sixInnerComponent.h += 2;
                                              } else {
                                                sixInnerComponent.h += 1;
                                              }
                                              if (
                                                bigElements.includes(
                                                  sevenInnerComponent.elementUIType
                                                )
                                              ) {
                                                sevenInnerComponent.h = 4;
                                                if (!bigElementUpdate) {
                                                  sixInnerComponent.h += 2;
                                                  fifthInnerComponent.h += 2;
                                                  fourthInnerComponent.h += 2;
                                                  thirdInnerComponent.h += 2;
                                                  secondInnerComponent.h += 2;
                                                  innerComponent.h += 2;
                                                  bigElementUpdate = true;
                                                }
                                              }

                                              if (
                                                fifthInnerComponent.layout ===
                                                'hbox'
                                              ) {
                                                if (
                                                  fifthInnerComponent.h <=
                                                  sixInnerComponent.h
                                                ) {
                                                  fifthInnerComponent.h =
                                                    sixInnerComponent.h + 1;
                                                }
                                              } else {
                                                fifthInnerComponent.h += 1;
                                              }

                                              if (
                                                fourthInnerComponent.layout ===
                                                'hbox'
                                              ) {
                                                if (
                                                  fourthInnerComponent.h <=
                                                  fifthInnerComponent.h
                                                ) {
                                                  fourthInnerComponent.h =
                                                    fifthInnerComponent.h + 1;
                                                }
                                              } else {
                                                fourthInnerComponent.h += 1;
                                              }

                                              if (
                                                thirdInnerComponent.layout ===
                                                'hbox'
                                              ) {
                                                if (
                                                  thirdInnerComponent.h <=
                                                  fifthInnerComponent.h
                                                ) {
                                                  thirdInnerComponent.h =
                                                    fifthInnerComponent.h + 1;
                                                }
                                              } else {
                                                thirdInnerComponent.h += 1;
                                              }

                                              if (
                                                secondInnerComponent.layout ===
                                                'hbox'
                                              ) {
                                                if (
                                                  secondInnerComponent.h <=
                                                  thirdInnerComponent.h
                                                ) {
                                                  secondInnerComponent.h =
                                                    thirdInnerComponent.h + 1;
                                                }
                                              } else {
                                                secondInnerComponent.h += 1;
                                              }

                                              if (
                                                innerComponent.layout === 'hbox'
                                              ) {
                                                if (
                                                  innerComponent.h <=
                                                  secondInnerComponent.h
                                                ) {
                                                  innerComponent.h =
                                                    secondInnerComponent.h + 1;
                                                }
                                              } else {
                                                innerComponent.h += 1;
                                              }

                                              if (component.layout === 'hbox') {
                                                if (
                                                  component.h <=
                                                  innerComponent.h
                                                ) {
                                                  component.h =
                                                    innerComponent.h + 1;
                                                }
                                              } else {
                                                component.h += 1;
                                              }
                                            }
                                            if (
                                              sevenInnerComponent.type ===
                                              'image'
                                            ) {
                                              if (
                                                sevenInnerComponent.bytes.startsWith(
                                                  'data:'
                                                )
                                              ) {
                                                sevenInnerComponent.image =
                                                  sevenInnerComponent.bytes;
                                              } else {
                                                sevenInnerComponent.image =
                                                  'data:image/png;base64, ' +
                                                  sevenInnerComponent.bytes;
                                              }
                                            }
                                            if (
                                              sevenInnerComponent.attributeId
                                            ) {
                                              selectedAttributes.push(
                                                String(
                                                  sevenInnerComponent.attributeId
                                                )
                                              );
                                            }

                                            if (
                                              sevenInnerComponent.components &&
                                              sevenInnerComponent.layout ===
                                                'vbox'
                                            ) {
                                              sevenInnerComponent.h =
                                                sevenInnerComponent.components.reduce(
                                                  (acc, val) => acc + val.h,
                                                  0
                                                ) + 2;
                                            }
                                            if (
                                              sevenInnerComponent.components &&
                                              sevenInnerComponent.layout ===
                                                'hbox'
                                            ) {
                                              sevenInnerComponent.h =
                                                Math.max(
                                                  ...sevenInnerComponent.components.map(
                                                    (el) => el.h
                                                  )
                                                ) + 2;
                                            }

                                            if (
                                              sevenInnerComponent.h >=
                                              sixInnerComponent.h
                                            ) {
                                              sixInnerComponent.h =
                                                sevenInnerComponent.h + 1;
                                            }
                                          }

                                          const sixInnerComponentsHeight =
                                            sixInnerComponent.components.reduce(
                                              (acc, val) => acc + val.h,
                                              0
                                            );
                                          if (
                                            sixInnerComponent.layout ===
                                              'vbox' &&
                                            sixInnerComponentsHeight >=
                                              sixInnerComponent.h
                                          ) {
                                            sixInnerComponent.h =
                                              sixInnerComponentsHeight + 1;
                                          }
                                        }
                                        if (
                                          sixInnerComponent.components &&
                                          sixInnerComponent.layout === 'vbox'
                                        ) {
                                          sixInnerComponent.h =
                                            sixInnerComponent.components.reduce(
                                              (acc, val) => acc + val.h,
                                              0
                                            ) + 2;
                                        }
                                        if (
                                          sixInnerComponent.components &&
                                          sixInnerComponent.layout === 'hbox'
                                        ) {
                                          sixInnerComponent.h =
                                            Math.max(
                                              ...sixInnerComponent.components.map(
                                                (el) => el.h
                                              )
                                            ) + 2;
                                        }

                                        if (
                                          sixInnerComponent.h >=
                                          fifthInnerComponent.h
                                        ) {
                                          fifthInnerComponent.h =
                                            sixInnerComponent.h + 1;
                                        }
                                      }

                                      const fifthInnerComponentsHeight =
                                        fifthInnerComponent.components.reduce(
                                          (acc, val) => acc + val.h,
                                          0
                                        );
                                      if (
                                        fifthInnerComponent.layout === 'vbox' &&
                                        fifthInnerComponentsHeight >=
                                          fifthInnerComponent.h
                                      ) {
                                        fifthInnerComponent.h =
                                          fourthInnerComponentsHeight + 1;
                                      }
                                    }
                                    if (
                                      fifthInnerComponent.components &&
                                      fifthInnerComponent.layout === 'vbox'
                                    ) {
                                      fifthInnerComponent.h =
                                        fifthInnerComponent.components.reduce(
                                          (acc, val) => acc + val.h,
                                          0
                                        ) + 2;
                                    }
                                    if (
                                      fifthInnerComponent.components &&
                                      fifthInnerComponent.layout === 'hbox'
                                    ) {
                                      fifthInnerComponent.h =
                                        Math.max(
                                          ...fifthInnerComponent.components.map(
                                            (el) => el.h
                                          )
                                        ) + 2;
                                    }

                                    if (
                                      fifthInnerComponent.h >=
                                      fourthInnerComponent.h
                                    ) {
                                      fourthInnerComponent.h =
                                        fifthInnerComponent.h + 1;
                                    }
                                  }

                                  const fourthInnerComponentsHeight =
                                    fourthInnerComponent.components.reduce(
                                      (acc, val) => acc + val.h,
                                      0
                                    );
                                  if (
                                    fourthInnerComponent.layout === 'vbox' &&
                                    fourthInnerComponentsHeight >=
                                      fourthInnerComponent.h
                                  ) {
                                    fourthInnerComponent.h =
                                      fourthInnerComponentsHeight + 1;
                                  }
                                }
                                if (
                                  fourthInnerComponent.components &&
                                  fourthInnerComponent.layout === 'vbox'
                                ) {
                                  fourthInnerComponent.h =
                                    fourthInnerComponent.components.reduce(
                                      (acc, val) => acc + val.h,
                                      0
                                    ) + 2;
                                }
                                if (
                                  fourthInnerComponent.components &&
                                  fourthInnerComponent.layout === 'hbox'
                                ) {
                                  fourthInnerComponent.h =
                                    Math.max(
                                      ...fourthInnerComponent.components.map(
                                        (el) => el.h
                                      )
                                    ) + 2;
                                }

                                if (
                                  fourthInnerComponent.h >=
                                  thirdInnerComponent.h
                                ) {
                                  thirdInnerComponent.h =
                                    fourthInnerComponent.h + 1;
                                }
                              }

                              const thirdInnerComponentsHeight =
                                thirdInnerComponent.components.reduce(
                                  (acc, val) => acc + val.h,
                                  0
                                );
                              if (
                                thirdInnerComponent.layout === 'vbox' &&
                                thirdInnerComponentsHeight >=
                                  thirdInnerComponent.h
                              ) {
                                thirdInnerComponent.h =
                                  thirdInnerComponentsHeight + 1;
                              }
                            }
                            if (
                              thirdInnerComponent.components &&
                              thirdInnerComponent.layout === 'vbox'
                            ) {
                              thirdInnerComponent.h =
                                thirdInnerComponent.components.reduce(
                                  (acc, val) => acc + val.h,
                                  0
                                ) + 2;
                            }
                            if (
                              thirdInnerComponent.components &&
                              thirdInnerComponent.layout === 'hbox'
                            ) {
                              thirdInnerComponent.h =
                                Math.max(
                                  ...thirdInnerComponent.components.map(
                                    (el) => el.h
                                  )
                                ) + 2;
                            }

                            if (
                              thirdInnerComponent.h >= secondInnerComponent.h
                            ) {
                              secondInnerComponent.h =
                                thirdInnerComponent.h + 1;
                            }
                          }
                          const secondInnerComponentsHeight =
                            secondInnerComponent.components.reduce(
                              (acc, val) => acc + val.h,
                              0
                            );
                          if (
                            innerComponent.layout === 'vbox' &&
                            secondInnerComponentsHeight >= innerComponent.h
                          ) {
                            innerComponent.h = secondInnerComponentsHeight + 1;
                          }
                        }
                        if (
                          secondInnerComponent.components &&
                          secondInnerComponent.layout === 'vbox'
                        ) {
                          secondInnerComponent.h =
                            secondInnerComponent.components.reduce(
                              (acc, val) => acc + val.h,
                              0
                            ) + 2;
                        }
                        if (
                          secondInnerComponent.components &&
                          secondInnerComponent.layout === 'hbox'
                        ) {
                          secondInnerComponent.h =
                            Math.max(
                              ...secondInnerComponent.components.map(
                                (el) => el.h
                              )
                            ) + 2;
                        }

                        if (secondInnerComponent.h >= innerComponent.h) {
                          innerComponent.h = secondInnerComponent.h + 1;
                        }
                      }
                    }
                    if (
                      innerComponent.components &&
                      innerComponent.layout === 'vbox'
                    ) {
                      innerComponent.h =
                        innerComponent.components.reduce(
                          (acc, val) => acc + val.h,
                          0
                        ) + 2;
                    }
                    if (
                      innerComponent.components &&
                      innerComponent.layout === 'hbox'
                    ) {
                      console.log();
                      innerComponent.h =
                        Math.max(
                          ...innerComponent.components.map((el) => el.h)
                        ) + 2;
                    }
                    if (innerComponent.h >= component.h) {
                      component.h = innerComponent.h + 1;
                    }
                  }
                  if (component.components && component.layout === 'vbox') {
                    component.h =
                      component.components.reduce(
                        (acc, val) => acc + val.h,
                        0
                      ) + 2;
                  }
                  if (component.components && component.layout === 'hbox') {
                    component.h =
                      Math.max(...component.components.map((el) => el.h)) + 2;
                  }
                }
              }
            }
          }

          context.commit('SET_DETAILED_FORM_VIEW_XML_DESC', tabs);
          const main = tableData;
          main.data.tabs = [];
          context.commit('SET_DETAILED_FORM_VIEW_XML_DESC_MAIN', main);
          context.commit(
            'SET_SELECTED_FORM_VIEW_XML_DESC_MAIN',
            selectedAttributes
          );
        } else {
          context.commit('SET_DETAILED_FORM_VIEW_XML_DESC', [
            {
              name: `Новая вкладка 1`,
              id: 1,
              systemTab: false,
              components: [],
            },
          ]);
          context.commit('SET_DETAILED_FORM_VIEW_XML_DESC_MAIN', {
            formDesignVersion: 15,
            data: {
              tabs: [],
            },
            version: 2,
          });
        }
      })
      .catch((e) => {
        console.log(e);
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(e.response.data, 'text/xml');
        const message = xmlDoc.getElementsByTagName('faultstring')[0].innerHTML;
        reject(message);
      });
  });
};
const getUnAccessedTemplateRoles = (context, item) => {
  return new Promise((resolve, reject) => {
    const data = `<SOAP-ENV:Envelope xmlns:SOAP-ENV="http://schemas.xmlsoap.org/soap/envelope/" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance">
          <SOAP-ENV:Body>
              <tns:getUnAccessedTemplateRoles xmlns:tns="http://ru.petrosoft/register">
                  <templateId>${item.id}</templateId>
              </tns:getUnAccessedTemplateRoles>
          </SOAP-ENV:Body>
      </SOAP-ENV:Envelope>`;
    axios({
      method: 'post',
      headers: {
        Authorization: `Basic YWRtaW46MTIz`,
        'Content-Type': 'text/xml; charset=utf-8',
      },
      url: `${config.baseUrl}/register-ws/RegisterAdminService/RegisterAdmin`,
      data,
    })
      .then((res) => {
        console.log(res);
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(res.data, 'text/xml');

        const list = xmlDoc.getElementsByTagName(
          'reg:getUnAccessedTemplateRolesResponse'
        )[0];
        const arrProps = ['roleObjectProxyType'];
        const tableData = round(list, 0, {}, arrProps);
        context.commit('SET_ADMIN_UN_ACCESSED_TEMPLATE_ROLES', tableData);
        resolve(tableData);
      })
      .catch((e) => {
        console.log(e);
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(e.response.data, 'text/xml');
        const message = xmlDoc.getElementsByTagName('faultstring')[0].innerHTML;
        reject(message);
      });
  });
};
const setDetailedFormViewXmlDesc = (context, item) => {
  const data = context.getters.DETAILED_FORM_VIEW_XML_DESC_MAIN;
  data.data.tabs = context.getters.DETAILED_FORM_VIEW_XML_DESC;
  const updateComponents = (components, parentH) => {
    console.log(parentH);
    var min = 1;
    var max = 100;

    components.forEach((component, index) => {
      component.h = parentH;
      component.w = 32;
      component.x = 0;
      component.y = index > 0 ? components[index - 1].h : 0;
      component.i = Math.floor(Math.random() * (max - min + 1)) + min;

      if (component.components) {
        updateComponents(component.components, component.h - 1);
      }
    });
  };

  data.data.tabs.forEach((el) => {
    if (el.components[0]) {
      if (el.components[0].y === undefined) {
        updateComponents(el.components, el.components.length + 3);
      } else {
        return;
      }
    } else {
      return;
    }
  });
  const bytes = window.btoa(unescape(encodeURIComponent(JSON.stringify(data))));
  return new Promise((resolve, reject) => {
    const data = `<SOAP-ENV:Envelope xmlns:SOAP-ENV="http://schemas.xmlsoap.org/soap/envelope/" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance">
            <SOAP-ENV:Body>
            <tns:setDetailedFormViewXmlDesc xmlns:tns="http://ru.petrosoft/register">
                <formDescriptionXmlType>
                <templateId>${item.id}</templateId>
                <xmlDescription>
                    <action xsi:nil="true"/>
                    <id>0</id>
                    <data>
                        <name xsi:nil="true"/>
                        <bytes>${bytes}</bytes>
                    </data>
                </xmlDescription>
                </formDescriptionXmlType>
            </tns:setDetailedFormViewXmlDesc>
            </SOAP-ENV:Body>
        </SOAP-ENV:Envelope>`;
    axios({
      method: 'post',
      headers: {
        Authorization: context.state.token,
        'Content-Type': 'text/xml; charset=utf-8',
      },
      url: `${config.baseUrl}/register-ws/RegisterTechnologistService/RegisterTechnologist?wsdl`,
      data,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log(e);
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(e.response.data, 'text/xml');
        const message = xmlDoc.getElementsByTagName('faultstring')[0].innerHTML;
        reject(message);
      });
  });
};
const getRoleAccountMenu = (context) => {
  return new Promise((resolve, reject) => {
    const data = `<SOAP-ENV:Envelope xmlns:SOAP-ENV="http://schemas.xmlsoap.org/soap/envelope/" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance">
            <SOAP-ENV:Body>
                <tns:getRoleAccountMenu xmlns:tns="http://ru.petrosoft/register"/>
            </SOAP-ENV:Body>
        </SOAP-ENV:Envelope>`;
    axios({
      method: 'post',
      headers: {
        Authorization: 'Basic YWRtaW46MTIz',
        'Content-Type': 'text/xml; charset=utf-8',
      },
      url: `${config.baseUrl}/register-ws/RegisterAdminService/RegisterAdmin`,
      data,
    })
      .then((res) => {
        console.log(res);
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(res.data, 'text/xml');
        const list = xmlDoc.getElementsByTagName(
          'reg:getRoleAccountMenuResponse'
        )[0];
        const arrProps = ['roleAccounts'];
        const tableData = round(list, 0, {}, arrProps)[0].roleAccounts;
        console.log(tableData);
        context.commit('SET_ADMIN_ROLE_ACCOUNT_MENU', tableData);
        resolve(tableData);
      })
      .catch((e) => {
        console.log(e);
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(e.response.data, 'text/xml');
        const message = xmlDoc.getElementsByTagName('faultstring')[0].innerHTML;
        console.log(message);
        reject(message);
      });
  });
};
const getAdminMenu = (context) => {
  return new Promise((resolve, reject) => {
    const data = `<SOAP-ENV:Envelope xmlns:SOAP-ENV="http://schemas.xmlsoap.org/soap/envelope/" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance">
            <SOAP-ENV:Body>
                <tns:getAdminMenu xmlns:tns="http://ru.petrosoft/register"/>
            </SOAP-ENV:Body>
        </SOAP-ENV:Envelope>`;
    axios({
      method: 'post',
      headers: {
        Authorization: 'Basic YWRtaW46MTIz',
        'Content-Type': 'text/xml; charset=utf-8',
      },
      url: `${config.baseUrl}/register-ws/RegisterAdminService/RegisterAdmin`,
      data,
    })
      .then((res) => {
        console.log(res);
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(res.data, 'text/xml');

        const list = xmlDoc.getElementsByTagName('reg:getAdminMenuResponse')[0];
        const arrProps = ['menuItems', 'childMenuItems', 'accessRoles'];
        const tableData = round(list, 0, {}, arrProps)[0].menuItems;
        const treeCreator = (tree) => {
          const result = [];
          for (let item of tree) {
            if (!item.childMenuItems && item.hasChilds) {
              item.hasChilds = false;
            }
            result.push({
              label: item.name,
              data: item,
              nodes: item.childMenuItems
                ? treeCreator(item.childMenuItems)
                : [],
            });
          }
          return result;
        };

        context.commit('SET_ADMIN_MENU', treeCreator(tableData));
        resolve(tableData);
      })
      .catch((e) => {
        console.log(e);
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(e.response.data, 'text/xml');
        const message = xmlDoc.getElementsByTagName('faultstring')[0].innerHTML;
        reject(message);
      });
  });
};

const getObjectStatusesRoles = (context, item) => {
  console.log(item);
  const fields = jsToXmlParser(item);
  console.log(fields);
  return new Promise((resolve, reject) => {
    const data = `<SOAP-ENV:Envelope xmlns:SOAP-ENV="http://schemas.xmlsoap.org/soap/envelope/" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance">
            <SOAP-ENV:Body>
                <tns:getObjectStatusesRoles xmlns:tns="http://ru.petrosoft/register">
                    <menuItemType>
                        ${fields.join('')}
                    </menuItemType>
                </tns:getObjectStatusesRoles>
            </SOAP-ENV:Body>
        </SOAP-ENV:Envelope>`;
    axios({
      method: 'post',
      headers: {
        Authorization: 'Basic YWRtaW46MTIz',
        'Content-Type': 'text/xml; charset=utf-8',
      },
      url: `${config.baseUrl}/register-ws/RegisterAdminService/RegisterAdmin`,
      data,
    })
      .then((res) => {
        console.log(res);
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(res.data, 'text/xml');

        const list = xmlDoc.getElementsByTagName(
          'reg:getObjectStatusesRolesResponse'
        )[0];
        const arrProps = ['objectStatusesRoleType', 'availableStatuses'];
        const tableData = round(list, 0, {}, arrProps).map((el) => {
          if (el.availableStatuses) {
            el.availableStatuses = el.availableStatuses.map((status) => {
              status.access = status.access === 'true';
              return status;
            });
          }
          return el;
        });
        console.log(tableData);

        context.commit('SET_ADMIN_OBJECT_STATUSES_ROLES', tableData);
        resolve(tableData);
      })
      .catch((e) => {
        console.log(e);
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(e.response.data, 'text/xml');
        const message = xmlDoc.getElementsByTagName('faultstring')[0].innerHTML;
        reject(message);
      });
  });
};

const getAvailableStatuses = (context, item) => {
  const fields = jsToXmlParser(item);
  return new Promise((resolve, reject) => {
    const data = `<SOAP-ENV:Envelope xmlns:SOAP-ENV="http://schemas.xmlsoap.org/soap/envelope/" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance">
            <SOAP-ENV:Body>
                <tns:getAvailableStatuses xmlns:tns="http://ru.petrosoft/register">
                    <menuItemType>
                        ${fields.join('')}
                    </menuItemType>
                </tns:getAvailableStatuses>
            </SOAP-ENV:Body>
        </SOAP-ENV:Envelope>`;
    axios({
      method: 'post',
      headers: {
        Authorization: 'Basic YWRtaW46MTIz',
        'Content-Type': 'text/xml; charset=utf-8',
      },
      url: `${config.baseUrl}/register-ws/RegisterAdminService/RegisterAdmin`,
      data,
    })
      .then((res) => {
        console.log(res);
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(res.data, 'text/xml');

        const list = xmlDoc.getElementsByTagName(
          'reg:getAvailableStatusesResponse'
        )[0];
        const arrProps = ['statusAccessLinkType'];
        const tableData = round(list, 0, {}, arrProps);
        console.log(tableData);

        context.commit('SET_ADMIN_AVAILABLE_STATUSES', tableData);
        resolve(tableData);
      })
      .catch((e) => {
        console.log(e);
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(e.response.data, 'text/xml');
        const message = xmlDoc.getElementsByTagName('faultstring')[0].innerHTML;
        console.log(message);
        reject(message);
      });
  });
};
const getPermissionsRolesProxies = (context) => {
  return new Promise((resolve, reject) => {
    const data = `<SOAP-ENV:Envelope xmlns:SOAP-ENV="http://schemas.xmlsoap.org/soap/envelope/" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance">
            <SOAP-ENV:Body>
                <tns:getPermissionsRolesProxies xmlns:tns="http://ru.petrosoft/register"/>
            </SOAP-ENV:Body>
        </SOAP-ENV:Envelope>`;
    axios({
      method: 'post',
      headers: {
        Authorization: 'Basic YWRtaW46MTIz',
        'Content-Type': 'text/xml; charset=utf-8',
      },
      url: `${config.baseUrl}/register-ws/RegisterAdminService/RegisterAdmin`,
      data,
    })
      .then((res) => {
        console.log(res);
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(res.data, 'text/xml');

        const list = xmlDoc.getElementsByTagName(
          'reg:getPermissionsRolesProxiesResponse'
        )[0];
        const arrProps = ['roleObjectProxyType'];
        const tableData = round(list, 0, {}, arrProps);
        console.log(tableData);

        context.commit('SET_ADMIN_PERMISSIONS_ROLES_PROXIES', tableData);
        resolve(tableData);
      })
      .catch((e) => {
        console.log(e);
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(e.response.data, 'text/xml');
        const message = xmlDoc.getElementsByTagName('faultstring')[0].innerHTML;
        console.log(message);
        reject(message);
      });
  });
};
const createObjectStatusRoleAccess = (context, item) => {
  const fields = jsToXmlParser(item);
  return new Promise((resolve, reject) => {
    const data = `<SOAP-ENV:Envelope xmlns:SOAP-ENV="http://schemas.xmlsoap.org/soap/envelope/" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance">
            <SOAP-ENV:Body>
                <tns:createObjectStatusRoleAccess xmlns:tns="http://ru.petrosoft/register">
                    <objectStatusesRoleType>
                        ${fields.join('')}
                    </objectStatusesRoleType>
                </tns:createObjectStatusRoleAccess>
            </SOAP-ENV:Body>
        </SOAP-ENV:Envelope>`;
    axios({
      method: 'post',
      headers: {
        Authorization: 'Basic YWRtaW46MTIz',
        'Content-Type': 'text/xml; charset=utf-8',
      },
      url: `${config.baseUrl}/register-ws/RegisterAdminService/RegisterAdmin`,
      data,
    })
      .then((res) => {
        console.log(res);
        resolve(res);
      })
      .catch((e) => {
        console.log(e);
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(e.response.data, 'text/xml');
        const message = xmlDoc.getElementsByTagName('faultstring')[0].innerHTML;
        console.log(message);
        reject(message);
      });
  });
};
const updateObjectStatusRoleAccess = (context, item) => {
  const fields = jsToXmlParser(item);
  return new Promise((resolve, reject) => {
    const data = `<SOAP-ENV:Envelope xmlns:SOAP-ENV="http://schemas.xmlsoap.org/soap/envelope/" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance">
            <SOAP-ENV:Body>
                <tns:updateObjectStatusRoleAccess xmlns:tns="http://ru.petrosoft/register">
                    <objectStatusesRoleType>
                    ${fields.join('')}
                    </objectStatusesRoleType>
                </tns:updateObjectStatusRoleAccess>
            </SOAP-ENV:Body>
        </SOAP-ENV:Envelope>`;
    axios({
      method: 'post',
      headers: {
        Authorization: 'Basic YWRtaW46MTIz',
        'Content-Type': 'text/xml; charset=utf-8',
      },
      url: `${config.baseUrl}/register-ws/RegisterAdminService/RegisterAdmin`,
      data,
    })
      .then((res) => {
        console.log(res);
        resolve(res);
      })
      .catch((e) => {
        console.log(e);
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(e.response.data, 'text/xml');
        const message = xmlDoc.getElementsByTagName('faultstring')[0].innerHTML;
        console.log(message);
        reject(message);
      });
  });
};
const getTypesRoles = (context) => {
  return new Promise((resolve, reject) => {
    const data = `<SOAP-ENV:Envelope xmlns:SOAP-ENV="http://schemas.xmlsoap.org/soap/envelope/" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance">
              <SOAP-ENV:Body>
                  <tns:getTypesRoles xmlns:tns="http://ru.petrosoft/register"/>
              </SOAP-ENV:Body>
          </SOAP-ENV:Envelope>`;
    axios({
      method: 'post',
      headers: {
        Authorization: `Basic YWRtaW46MTIz`,
        'Content-Type': 'text/xml; charset=utf-8',
      },
      url: `${config.baseUrl}/register-ws/RegisterAdminService/RegisterAdmin`,
      data,
    })
      .then((res) => {
        console.log(res);
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(res.data, 'text/xml');
        const list = xmlDoc.getElementsByTagName(
          'reg:getTypesRolesResponse'
        )[0];
        const arrProps = ['typeRole'];
        const tableData = round(list, 0, {}, arrProps);
        console.log(tableData);

        context.commit('SET_ADMIN_TYPES_ROLE', tableData);
        resolve(tableData);
      })
      .catch((e) => {
        console.log(e.response);
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(e.response.data, 'text/xml');
        const message = xmlDoc.getElementsByTagName('faultstring')[0].innerHTML;
        console.log(message);
        reject(message);
      });
  });
};

const createRole = (context, item) => {
  const fields = jsToXmlParser(item);
  return new Promise((resolve, reject) => {
    const data = `<SOAP-ENV:Envelope xmlns:SOAP-ENV="http://schemas.xmlsoap.org/soap/envelope/" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance">
              <SOAP-ENV:Body>
                  <tns:createRole xmlns:tns="http://ru.petrosoft/register">
                      <roleObjectProxyType>
                          ${fields.join('')}
                      </roleObjectProxyType>
                  </tns:createRole>
              </SOAP-ENV:Body>
          </SOAP-ENV:Envelope>`;
    axios({
      method: 'post',
      headers: {
        Authorization: `Basic YWRtaW46MTIz`,
        'Content-Type': 'text/xml; charset=utf-8',
      },
      url: `${config.baseUrl}/register-ws/RegisterAdminService/RegisterAdmin`,
      data,
    })
      .then((res) => {
        console.log(res);

        resolve(res);
      })
      .catch((e) => {
        console.log(e);
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(e.response.data, 'text/xml');
        const message = xmlDoc.getElementsByTagName('faultstring')[0].innerHTML;
        console.log(message);
        reject(message);
      });
  });
};

const updateRole = (context, item) => {
  const fields = jsToXmlParser(item);
  return new Promise((resolve, reject) => {
    const data = `<SOAP-ENV:Envelope xmlns:SOAP-ENV="http://schemas.xmlsoap.org/soap/envelope/" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance">
              <SOAP-ENV:Body>
                  <tns:updateRole xmlns:tns="http://ru.petrosoft/register">
                      <roleObjectProxyType>
                          ${fields.join('')}
                   </roleObjectProxyType>
                  </tns:updateRole>
              </SOAP-ENV:Body>
          </SOAP-ENV:Envelope>`;
    axios({
      method: 'post',
      headers: {
        Authorization: `Basic YWRtaW46MTIz`,
        'Content-Type': 'text/xml; charset=utf-8',
      },
      url: `${config.baseUrl}/register-ws/RegisterAdminService/RegisterAdmin`,
      data,
    })
      .then((res) => {
        console.log(res);

        resolve(res);
      })
      .catch((e) => {
        console.log(e);
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(e.response.data, 'text/xml');
        const message = xmlDoc.getElementsByTagName('faultstring')[0].innerHTML;
        console.log(message);
        reject(message);
      });
  });
};

const deleteRole = (context, item) => {
  return new Promise((resolve, reject) => {
    const data = `<SOAP-ENV:Envelope xmlns:SOAP-ENV="http://schemas.xmlsoap.org/soap/envelope/" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance">
              <SOAP-ENV:Body>
                  <tns:deleteRole xmlns:tns="http://ru.petrosoft/register">
                      <roleId>${item.id}</roleId>
                  </tns:deleteRole>
              </SOAP-ENV:Body>
          </SOAP-ENV:Envelope>`;
    axios({
      method: 'post',
      headers: {
        Authorization: `Basic YWRtaW46MTIz`,
        'Content-Type': 'text/xml; charset=utf-8',
      },
      url: `${config.baseUrl}/register-ws/RegisterAdminService/RegisterAdmin`,
      data,
    })
      .then((res) => {
        console.log(res);

        resolve(res);
      })
      .catch((e) => {
        console.log(e);
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(e.response.data, 'text/xml');
        const message = xmlDoc.getElementsByTagName('faultstring')[0].innerHTML;
        console.log(message);
        reject(message);
      });
  });
};
export default {
  fetchDictionaries,
  fetchInnerDictionaries,
  editDictionary,
  fetchDictionary,
  fetchDictinaryManagedGroups,
  getGroupedDictionaries,
  addRemoveGroupedDictionaries,
  createNewDictionary,
  deleteDictionaryItem,
  fetchReports,
  fetchReportsSources,
  createReport,
  fetchReportsTemplates,
  deleteReport,
  getTemplateRegulations,
  getReport,
  editItem,
  getParametersProxiesByReportId,
  createReportParameter,
  deleteReportParameter,
  editReportParameter,
  fetchDictionaryItemsHeaders,
  fetchDictionaryItems,
  fetchAllReportViews,
  fetchTemplatesForReport,
  getReportAttributesByTemplateId,
  updateReportView,
  createReportView,
  deleteReportView,
  fetchObjects,
  getChildObjects,
  createClassificationNode,
  getClassificationNode,
  getTypesNodes,
  getTypesTemplates,
  getFirstLevelNodes,
  getChildNodes,
  deleteClassificationNode,
  updateClassificationNode,
  getObjectAttributes,
  getTypesAttributes,
  getTypesCopied,
  getTypesGroupOperation,
  getRegisterRelationParts,
  createMetaAttribute,
  getRelationalObjectAttributes,
  deleteMetaAttribute,
  getMetaAttributesObjectValue,
  updateMetaAttribute,
  getTemplateRepresentation,
  setTemplateRepresentation,
  getObjectTemplateRegulations,
  getStatusMetaAttributes,
  createStatusMetaAttributes,
  updateStatusMetaAttributes,
  getStatusMatrix,
  setStatusMatrix,
  getRegisterMenu,
  createRegister,
  initializeRegister,
  getUnregisteredTemplates,
  addTemplatesToRegister,
  removeTemplatesFromRegister,
  updateRegister,
  getDetailedFormViewMetaAttrs,
  getDetailedFormViewXmlDescResponse,
  setDetailedFormViewXmlDesc,
  getRoleAccountMenu,
  getAdminMenu,
  getObjectStatusesRoles,
  getAvailableStatuses,
  createObjectStatusRoleAccess,
  getPermissionsRolesProxies,
  getUnAccessedTemplateRoles,
  updateObjectStatusRoleAccess,
  getTypesRoles,
  createRole,
  updateRole,
  deleteRole,
};
