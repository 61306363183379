<template>
  <div class="table-conteiner">
    <p>Перечень объектов</p>
    <v-data-table
      class="atributes_table"
      :headers="headers"
      :items="ADMIN_OBJECT_STATUSES_ROLES"
      :items-per-page="10000"
      hide-default-footer
      dense
      height="100%"
      fixed-header
      @click:row="selectedItem = $event"
    >
      <template v-slot:item.delete="{ item }">
        <div>
          <!-- <img
                style="cursor: pointer"
                :src="require('../assets/trash.png')"
                alt="delete"
                @click="showDeleteForm(item)"
                /> -->
          <i class="ri ri-delete-bin-fill" @click="showDeleteForm(item)"></i>
        </div>
      </template>
      <template v-slot:item.creatorAccess="{ item }">
        <div>
          <v-checkbox
            :disabled="true"
            :input-value="item.creatorAccess === 'true'"
          ></v-checkbox>
        </div>
      </template>
      <template v-slot:item.action="{ item }">
        <div>
          <v-checkbox
            :disabled="true"
            :input-value="item.accessType === 'ACCESS_FULL'"
            label="Редактировать"
          ></v-checkbox>
        </div>
      </template>
      <template v-slot:item.availableStatuses="{ item }">
        <div>
          <v-checkbox
            :disabled="true"
            v-model="availableStatus.access"
            :label="availableStatus.name"
            v-for="availableStatus in item.availableStatuses"
            :key="availableStatus.id"
          ></v-checkbox>
        </div>
      </template>
      <template v-slot:item.notice="{ item }">
        <div>
          <v-checkbox :disabled="true" :input-value="item.notice === 'true'"></v-checkbox>
        </div>
      </template>
    </v-data-table>
    <div class="buttons_conteiner">
      <button class="buttons_conteiner__button" @click="onAddDialog">
        Добавить
      </button>
      <button
        class="buttons_conteiner__button"
        @click="onEditDialog"
        v-if="selectedItem !== null"
      >
        Редактировать
      </button>
    </div>

    <v-dialog v-model="addDialog" persistent max-width="1000">
      <v-card>
        <v-card-text>{{
          editOrCreate === 'create'
            ? 'Добавление новой записи'
            : 'Редактирование записи'
        }}</v-card-text>
        <div style="padding: 10px">
          <v-select
            :disabled="editOrCreate === 'edit'"
            label="роли"
            :items="ADMIN_PERMISSIONS_ROLES_PROXIES"
            item-text="name"
            item-value="id"
            v-model="editedItem.roleObjectType.id"
          ></v-select>
          <p>Функции</p>
          <v-checkbox
            v-for="status in editedItem.availableStatuses"
            :key="status.id"
            :label="status.name"
            v-model="status.access"
          ></v-checkbox>
          <p>Действие</p>
          <v-checkbox
            label="Редактировать"
            :input-value="editedItem.accessType === 'ACCESS_FULL'"
            @change="
              editedItem.accessType === 'ACCESS_FULL'
                ? (editedItem.accessType = 'ACCESS_READ_ONLY')
                : (editedItem.accessType = 'ACCESS_FULL')
            "
          ></v-checkbox>
          <v-checkbox
            label="Учет создателя"
            v-model="editedItem.creatorAccess"
          ></v-checkbox>
          <v-checkbox
            label="Уведомления"
            v-model="editedItem.notice"
          ></v-checkbox>
        </div>
        <v-card-actions>
          <button
            v-if="editOrCreate === 'create'"
            class="btn-save"
            @click="onAddNewItem"
          >
            Сохранить
          </button>
          <!-- <v-btn
                    v-if="editOrCreate === 'create'"
                    color="red darken-1"
                    text
                    @click="onAddNewItem"
                >Сохранить
                </v-btn> -->
          <!-- <v-btn
                    v-if="editOrCreate === 'edit'"
                    color="red darken-1"
                    text
                    @click="onEditItem"
                >Сохранить
                </v-btn> -->

          <button
            v-if="editOrCreate === 'edit'"
            class="btn-save"
            @click="onEditItem"
          >
            Сохранить
          </button>

          <button class="btn-close" @click="addDialog = false">Отмена</button>
          <!-- <v-btn
                    color="green darken-1"
                    text
                    @click="addDialog = false"
                >Отмена
                </v-btn> -->
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="deleteDialog" persistent max-width="290">
      <v-card>
        <v-card-text>Вы действительно хотите удалить запись?</v-card-text>
        <v-card-actions>
          <!-- <v-btn
                    color="red darken-1"
                    text
                    @click="deleteAttribute"
                >Да
                </v-btn>
                <v-btn
                    color="green darken-1"
                    text
                    @click="deleteDialog = false"
                >Нет
                </v-btn> -->
          <button class="btn-save" @click="deleteAttribute">Да</button>

          <button class="btn-close" @click="deleteDialog = false">Нет</button>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    activeItem: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      headers: [
        { text: 'Дост./Недост.', value: '' },
        { text: 'Функции', value: 'availableStatuses' },
        { text: 'Уведомления', value: 'notice' },
        { text: 'Действие', value: 'action' },
        { text: 'Учет создателя', value: 'creatorAccess' },
        { text: 'Роль', value: 'roleObjectType.name' },
        { text: '', value: 'delete' },
      ],
      deleteDialog: false,
      deletedItem: null,
      addDialog: false,
      editedItem: {
        id: this.activeItem !== null ? this.activeItem.id : null,
        name: null,
        templateId: this.activeItem !== null ? this.activeItem.parentId : null,
        roleObjectType: {
          id: null,
          name: null,
          code: null,
          description: null,
          typeRole: null,
          creationDate: null,
        },
        availableStatuses: this.ADMIN_AVAILABLE_STATUSES
          ? this.ADMIN_AVAILABLE_STATUSES.map((el) => {
              return { id: el.id, name: el.name, access: false };
            })
          : [],
        accessType: 'ACCESS_READ_ONLY',
        creatorAccess: false,
        access: true,
        notice: true,
      },
      selectedItem: null,
      editOrCreate: 'create',
    };
  },
  computed: {
    ...mapGetters([
      'ADMIN_OBJECT_STATUSES_ROLES',
      'ADMIN_PERMISSIONS_ROLES_PROXIES',
      'ADMIN_AVAILABLE_STATUSES',
    ]),
  },
  watch: {
    ADMIN_AVAILABLE_STATUSES() {
      this.editedItem.availableStatuses = this.ADMIN_AVAILABLE_STATUSES.map(
        (el) => {
          return { id: el.id, name: el.name, access: false };
        }
      );
    },
  },
  methods: {
    onAddNewItem() {
      this.$emit('onCreateObjectStatusRoleAccess', this.editedItem);
      this.addDialog = false;
      this.selectedItem = null;
      this.editOrCreate = 'create';
    },
    onAddDialog() {
      this.editOrCreate = 'create';
      this.addDialog = true;
      this.$emit('onGetPermissionsRolesProxies');
      this.$emit('onGetAvailableStatuses');
    },
    rowValues(row) {
      if (row.value.dataList) {
        if (row.value.dataList.data) {
          if (row.value.dataList.data.name) {
            return row.value.dataList.data.name;
          } else {
            return row.value.dataList.data;
          }
        } else {
          return '';
        }
      } else {
        return '';
      }
    },
    showDeleteForm(item) {
      this.deletedItem = item;
      this.deleteDialog = true;
    },
    deleteAttribute() {
      const deletedItem = {
        id: this.deletedItem.id,
        name: null,
        templateId: this.deletedItem.templateId,
        roleObjectType: {
          id: this.deletedItem.roleObjectType.id,
          name: null,
          code: null,
          description: null,
          typeRole: null,
          creationDate: null,
        },
      };
      this.$emit('onDeleteObjectStatusRoleAccess', deletedItem);
      this.deleteDialog = false;
    },
    onEditDialog() {
      this.$emit('onGetPermissionsRolesProxies');
      console.log(303, this.selectedItem);
      this.editedItem = {
        id: this.selectedItem.id,
        name: null,
        templateId: this.selectedItem.templateId,
        roleObjectType: {
          id: this.selectedItem.roleObjectType.id,
          name: null,
          code: null,
          description: null,
          typeRole: null,
          creationDate: null,
        },
        availableStatuses: this.selectedItem.availableStatuses
          ? this.selectedItem.availableStatuses.map((el) => {
              return { id: el.id, name: el.name, access: el.access };
            })
          : [],
        accessType: this.selectedItem.accessType,
        creatorAccess: this.selectedItem.creatorAccess === 'true',
        access: true,
        notice: this.selectedItem.notice.length === 4 ? true : false,
      };
      this.editOrCreate = 'edit';
      this.addDialog = true;
    },
    onEditItem() {
      console.log('click');
      this.$emit('onUpdateObjectStatusRoleAccess', this.editedItem);
      this.addDialog = false;
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
.edit-view {
  height: 100%;
  display: flex;
  flex-direction: column;
  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }
  &__actions {
    display: flex;
    justify-content: flex-end;
    & .btn-close {
      margin-right: 8px;
      background-color: #f06548;
      color: #fff;
      padding: 10px 15px;
      border-radius: 5px;
      font-weight: 500;
    }
    & .btn-close:hover {
      background-color: #cc563d;
      transition: 0.3s all;
    }
    & .btn-save {
      margin-right: 8px;
      background-color: #299cdb;
      color: #fff;
      padding: 10px 15px;
      border-radius: 5px;
      font-weight: 500;
    }
    & .btn-save:hover {
      background-color: #2385ba;
      transition: 0.3s all;
    }
  }
}

.edit-view__container {
  padding: 20px;
}

.edit-view__item__title {
  font-weight: 500;
  width: 200px;
}

.edit-view__item__value {
  width: 100%;
}

.edit-view__item__input {
  width: 100%;
  padding: 5px;
}

.edit-view__item__textarea {
  width: 100%;
  padding: 5px;
  border: 1px solid black;
}

// .edit-view__item__table__container {
//     height: 400px;
// }

.object_tabs {
  height: 50px;
}

.atributes_table .v-data-table__wrapper {
  height: calc(100vh - 160px);
  overflow-y: scroll;
}

.atributes_table {
  height: calc(100vh - 160px);
  overflow-y: scroll;
}
</style>
