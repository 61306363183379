<template>
  <div
    :style="{
      height: item.type === 'statusButton' ? '100%' : 'auto',
      width: item.type === 'statusButton' ? '100%' : 'auto',
    }"
  >
    <div v-if="item.type === 'label'" :style="item.styles">
      <p
        class="grid-item__main__left__text"
        :style="{
          'font-weight': item.bold ? 'bold' : 'normal',
          'font-size': `${item.fontSize}px`,
          'text-decoration': item.underline ? 'underline' : 'none',
          'font-style': item.italic ? 'italic' : 'normal',
          color: item.color,
          'text-align': item.textAlign,
        }"
      >
        {{ item.text }}
      </p>
    </div>
    <div v-if="item.type === 'bottomLine'" class="bottom_line__block">
      <hr />
    </div>
    <div v-if="item.type === 'image'" style="width: 100%; height: 100%">
      <img
        v-if="item.bytes.startsWith('data:')"
        :src="item.bytes"
        class="grid-item__main__left__image"
      />
      <img
        v-else
        :src="'data:image/png;base64, ' + item.bytes"
        class="grid-item__main__left__image"
      />
    </div>
    <div
      v-if="item.type === 'statusButton'"
      style="
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
      "
    >
      <v-btn block style="text-overflow: ellipsis">{{ item.name }}</v-btn>
    </div>

    <div v-if="item.elementUIType === 'INPUT'">
      <div class="attributeItem__top">
        <span></span>
        <input
          v-model="item.index"
          type="number"
          class="attributeItem__top__input"
        />
      </div>
      <v-text-field
        dense
        class="attributeItem__top__elem"
        :label="item.name"
      ></v-text-field>
    </div>

    <div v-if="item.elementUIType === 'TEXT_AREA'">
      <div class="attributeItem__top">
        <span></span>
        <input
          v-model="item.index"
          type="number"
          class="attributeItem__top__input"
        />
      </div>
      <v-textarea
        outlined
        class="attributeItem__top__elem"
        :label="item.name"
        rows="3"
      ></v-textarea>
    </div>

    <div v-if="item.elementUIType === 'CHECK_BOX'">
      <div class="attributeItem__top">
        <span></span>
        <input
          v-model="item.index"
          type="number"
          class="attributeItem__top__input"
        />
      </div>
      <v-checkbox
        class="attributeItem__top__elem"
        id="collapse"
        :label="item.name"
      />
    </div>
    <div v-if="item.elementUIType === 'RADIO_BUTTONS'">
      <div class="attributeItem__top">
        <span></span>
        <input
          v-model="item.index"
          type="number"
          class="attributeItem__top__input"
        />
      </div>
      <v-select
        class="attributeItem__top__elem"
        v-model="item.booleanRadioButtonsDirection"
        :label="item.name"
        :items="[
          { value: 'vbox', name: 'горизонтально' },
          { value: 'hbox', name: 'вертикально' },
        ]"
        item-text="name"
        item-value="value"
      ></v-select>
      <v-radio-group :column="item.booleanRadioButtonsDirection === 'hbox'">
        <v-radio
          v-for="n in ['Истина', 'Ложь', 'Неопределено']"
          :key="n"
          :label="n"
          :value="n"
        ></v-radio>
      </v-radio-group>
    </div>
    <div v-if="item.elementUIType === 'FILE_UPLOAD'">
      <div class="attributeItem__top">
        <span></span>
        <input
          v-model="item.index"
          type="number"
          class="attributeItem__top__input"
        />
      </div>
      <div class="attribute_row attributeItem__top__elem">
        <v-text-field dense :label="item.name"></v-text-field>
        <v-btn small>Загрузить</v-btn>
        <v-btn small>Стереть</v-btn>
      </div>
    </div>

    <div v-if="item.elementUIType === 'DICTIONARY'">
      <div class="attributeItem__top">
        <span></span>
        <input
          v-model="item.index"
          type="number"
          class="attributeItem__top__input"
        />
      </div>
      <v-text-field
        dense
        class="attributeItem__top__elem"
        :label="item.name"
      ></v-text-field>
    </div>

    <div v-if="item.elementUIType === 'COUNTER'">
      <div class="attributeItem__top">
        <span></span>
        <input
          v-model="item.index"
          type="number"
          class="attributeItem__top__input"
        />
      </div>
      <v-text-field
        dense
        type="number"
        class="attributeItem__top__elem"
        :label="item.name"
      ></v-text-field>
    </div>

    <div v-if="item.elementUIType === 'LINK'">
      <div class="attributeItem__top">
        <span></span>
        <input
          v-model="item.index"
          type="number"
          class="attributeItem__top__input"
        />
      </div>
      <v-data-table
        class="attributeItem__top__elem"
        :footer-props="{ 'items-per-page-text': 'Записей на странице:' }"
        loading-text="Загрузка... Пожалуйста, подождите"
        no-data-text="Нет данных для отображения"
        style="width: 100%"
      >
        <template v-slot:top>
          <div class="text-start mx-3" style="font-size: 14px">
            {{ item.name }}:
          </div>
        </template>
      </v-data-table>
    </div>

    <div v-if="item.elementUIType === 'HINT'">
      <div class="attributeItem__top">
        <span></span>
      </div>
      <p class="attributeItem__top__elem">{{ item.name }}</p>
    </div>

    <div v-if="item.elementUIType === 'CALENDAR'">
      <div class="attributeItem__top">
        <span></span>
        <input
          v-model="item.index"
          type="number"
          class="attributeItem__top__input"
        />
      </div>
      <v-text-field
        :label="item.name"
        prepend-icon="mdi-calendar"
        readonly
        class="attributeItem__top__elem"
      ></v-text-field>
    </div>
    <div v-if="item.elementUIType === 'SELECTION_LIST_SINGLE_SELECT'">
      <div class="attributeItem__top">
        <span></span>
        <input
          v-model="item.index"
          type="number"
          class="attributeItem__top__input"
        />
      </div>
      <v-select
        dense
        class="attributeItem__top__elem"
        :label="item.name"
      ></v-select>
    </div>

    <div v-if="item.elementUIType === 'SELECTION_LIST_MULTI_SELECT'">
      <div class="attributeItem__top">
        <span></span>
        <input
          v-model="item.index"
          type="number"
          class="attributeItem__top__input"
        />
      </div>
      <v-select
        dense
        class="attributeItem__top__elem"
        :label="item.name"
      ></v-select>
    </div>

    <div v-if="item.elementUIType === 'INPUT_AUTO_COMPLETE'">
      <div class="attributeItem__top">
        <span></span>
        <input
          v-model="item.index"
          type="number"
          class="attributeItem__top__input"
        />
      </div>
      <v-combobox dense outlined :label="item.name"></v-combobox>
    </div>

    <div v-if="item.elementUIType === 'TABLE'">
      <div class="attributeItem__top">
        <span></span>
        <input
          v-model="item.index"
          type="number"
          class="attributeItem__top__input"
        />
      </div>
      <v-data-table
        :footer-props="{ 'items-per-page-text': 'Записей на странице:' }"
        loading-text="Загрузка... Пожалуйста, подождите"
        no-data-text="Нет данных для отображения"
        style="width: 100%"
        class="attributeItem__top__elem"
      >
        <template v-slot:top>
          <div class="text-start mx-3" style="font-size: 14px">
            {{ item.name }}:
          </div>
        </template>
      </v-data-table>
    </div>
    <!--
                                        <div v-else>
                                            <div class="attributeItem__top">
                                                <span></span>
                                                <input v-model="item.index" type="number" class="attributeItem__top__input">
                                            </div>
                                            <div class="attributeItem__top__elem">
                                                <span>{{item.elementUIType}}</span>
                                            </div>
                                        </div>
                                        --->
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    item: Object,
  },

  computed: {},
  methods: {
    show() {
      console.log(this.item);
    },
  },
};
</script>

<style lang="scss" scoped>
.grid-item__main__left__text {
  text-overflow: clip;
}

.bottom_line__block {
  padding: 5px;
}

.attributeItem__top {
  display: none;
  justify-content: space-between;
  align-items: center;
}

.attributeItem__top__elem {
  margin-top: 24px !important;
}

.grid-item:hover .attributeItem__top {
  display: flex;
}

.grid-item:hover .attributeItem__top__elem {
  margin-top: 0px !important;
}

.attributeItem__top__input {
  width: 30px;
  margin-right: 20px;
}

.attributeItem__input {
  border: 1px solid black;
  border-radius: 5px;
  width: calc(100% - 10px);
  margin: 5px;
  padding-left: 3px;
}

.attributeItem__top__text {
  margin-bottom: 0;
}
</style>
