<template>
    <div class="edit-view">
        <div class="edit-view__title">Добавление атрибута (Тип Объекта: {{editData.templateName}})</div>
        <div class="edit-view__container">
            <div class="edit-view__item">
                <p class="edit-view__item__title">Наименование:</p>
                <input v-model="editDataAttribute.metaAttributeObjectType.name"  type="text" class="edit-view__item__input">
            </div>
            <div class="edit-view__item">
                <p class="edit-view__item__title">Код:</p>
                <input v-model="editDataAttribute.metaAttributeObjectType.code"  type="text" class="edit-view__item__input">
            </div>
            <div class="edit-view__item">
                <p class="edit-view__item__title">Описание:</p>
                <textarea v-model="editDataAttribute.metaAttributeObjectType.description"  type="text" class="edit-view__item__textarea"></textarea>
            </div>

            <div class="edit-view__item">
                <p class="edit-view__item__title">Тип:</p>
                <v-select 
                    v-model="editDataAttribute.metaAttributeObjectType.attributeType" 
                    :items="OBJECTS_TYPES_ATRIBUTES"
                    item-text="name"
                    item-value="attributeType"
                    @change="onAttributeTypeChange"
                    :disabled="attribyteWindowType === 'edit'"
                ></v-select>
            </div>
            <div class="edit-item__wrapper">
                <div class="edit-view__item" v-if="editDataAttribute.value.dataType && editDataAttribute.value.dataType !== null">
                    <p class="edit-view__item__title">Признак поиска: </p>
                    <v-checkbox v-model="editDataAttribute.metaAttributeObjectType.search"  type="checkbox"></v-checkbox>
                </div>
                <div class="edit-view__item" v-if="editDataAttribute.value.dataType && editDataAttribute.value.dataType !== null && editDataAttribute.metaAttributeObjectType.attributeType !== 'MATRIX'">
                    <p class="edit-view__item__title">Признак сортировки:</p>
                    <v-checkbox v-model="editDataAttribute.metaAttributeObjectType.sort"  type="checkbox"></v-checkbox>
                </div>
                <div class="edit-view__item" v-if="editDataAttribute.value.dataType
                    && editDataAttribute.metaAttributeObjectType.attributeType !== null 
                    && editDataAttribute.metaAttributeObjectType.attributeType !== 'RELATION_DICTIONARY' 
                    && editDataAttribute.metaAttributeObjectType.attributeType !== 'RELATION' 
                    && editDataAttribute.metaAttributeObjectType.attributeType !== 'MATRIX'
                ">
                    <p class="edit-view__item__title">Признак массива: </p>
                    <v-checkbox v-model="editDataAttribute.metaAttributeObjectType.multiple"  type="checkbox" :disabled="attribyteWindowType === 'edit'"></v-checkbox>
                </div>
            </div>
            <div class="edit-item__wrapper">
                <div class="edit-view__item" v-if="editDataAttribute.value.dataType
                    && editDataAttribute.metaAttributeObjectType.attributeType !== null 
                    && editDataAttribute.metaAttributeObjectType.attributeType !== 'RELATION_DICTIONARY' 
                    && editDataAttribute.metaAttributeObjectType.attributeType !== 'RELATION'
                    && editDataAttribute.metaAttributeObjectType.attributeType !== 'MATRIX'
                ">
                    <p class="edit-view__item__title">Признак обязательности: </p>
                    <v-checkbox v-model="editDataAttribute.metaAttributeObjectType.mandatory" :disabled="editDataAttribute.metaAttributeObjectType.immutable"></v-checkbox>
                </div>
                <div class="edit-view__item" v-if="editDataAttribute.metaAttributeObjectType.attributeType 
                    && editDataAttribute.metaAttributeObjectType.attributeType !== null 
                    && editDataAttribute.metaAttributeObjectType.attributeType !== 'RELATION_DICTIONARY' 
                    && editDataAttribute.metaAttributeObjectType.attributeType !== 'RELATION'
                ">
                    <p class="edit-view__item__title">Признак константы: </p>
                    <v-checkbox v-model="editDataAttribute.metaAttributeObjectType.immutable" @change="onImmutableChange"></v-checkbox>
                </div>
                <div class="edit-view__item" v-if="editDataAttribute.value.dataType 
                    && editDataAttribute.metaAttributeObjectType.attributeType !== null 
                    && editDataAttribute.metaAttributeObjectType.attributeType !== 'RELATION_DICTIONARY' 
                    && editDataAttribute.metaAttributeObjectType.attributeType !== 'RELATION' 
                    && editDataAttribute.metaAttributeObjectType.attributeType !== 'MATRIX'
                ">
                    <p class="edit-view__item__title">Признак PrimaryKey:</p>
                    <v-checkbox v-model="editDataAttribute.metaAttributeObjectType.primaryKey"  type="checkbox"></v-checkbox>
                </div>
            </div>
            <div class="edit-view__wrapper">
                <div class="edit-view__item" v-if="
                    editDataAttribute.metaAttributeObjectType.attributeType === 'RELATION_DICTIONARY' 
                    || editDataAttribute.metaAttributeObjectType.attributeType === 'RELATION' 
                ">
                    <p class="edit-view__item__title">Учет категорий</p>
                    <v-checkbox v-model="editDataAttribute.metaAttributeObjectType.checkAccess"  type="checkbox"></v-checkbox>
                </div>
                <div class="edit-view__item" v-if="
                    editDataAttribute.metaAttributeObjectType.attributeType === 'RELATION_DICTIONARY' 
                    || editDataAttribute.metaAttributeObjectType.attributeType === 'RELATION' 
                ">
                    <p class="edit-view__item__title">Логическое удаление</p>
                    <v-checkbox v-model="editDataAttribute.relation.logicalDelete"  type="checkbox"></v-checkbox>
                </div>
            </div>
            <div class="edit-view__wrapper">
                <div class="edit-view__item" v-if="editDataAttribute.value.dataType && editDataAttribute.value.dataType !== null">
                    <p class="edit-view__item__title">Признак копирования:</p>
                    <v-select 
                        v-model="editDataAttribute.metaAttributeObjectType.copied" 
                        :items="OBJECTS_TYPES_COPIED"
                        item-text="name"
                        item-value="copiedType"
                    ></v-select>
                </div>

                <div class="edit-view__item" v-if="editDataAttribute.value.dataType && editDataAttribute.value.dataType !== null">
                    <p class="edit-view__item__title">Признак группового редактирования:</p>
                    <v-select 
                        v-model="editDataAttribute.metaAttributeObjectType.groupOperation" 
                        :items="OBJECTS_TYPES_GROUP_OPERATION"
                        item-text="name"
                        item-value="groupOperationType"
                    ></v-select>
                </div>
            </div>
            <div class="edit-view__item" v-if="
                editDataAttribute.metaAttributeObjectType.attributeType === 'RELATION' &&
                transitLink !== ''
            ">
                <p class="edit-view__item__title">Транзитивная связь</p>
                <span>{{transitLink}}</span>
            </div>
            <div class="edit-view__wrapper">
                <div class="edit-view__item" v-if="
                    editDataAttribute.metaAttributeObjectType.attributeType === 'RELATION_DICTIONARY' 
                    || editDataAttribute.metaAttributeObjectType.attributeType === 'RELATION'  
                ">
                    <p class="edit-view__item__title">Тип ссылки:</p>
                    <v-select 
                        v-model="editDataAttribute.relation.relation" 
                        :items="OBJECTS_REGISTER_RELATION_PARTS.relations"
                        item-text="label"
                        item-value="data"
                        :disabled="attribyteWindowType === 'edit'"
                    ></v-select>
                </div>
                <div class="edit-view__item" v-if="
                    editDataAttribute.metaAttributeObjectType.attributeType === 'RELATION_DICTIONARY' 
                    || editDataAttribute.metaAttributeObjectType.attributeType === 'RELATION'  
                    ">
                        <p class="edit-view__item__title">Обязательность для текущего:</p>
                        <v-select 
                            v-model="editDataAttribute.relation.ownerMandatory" 
                            :items="OBJECTS_REGISTER_RELATION_PARTS.mandatory"
                            item-text="label"
                            item-value="data"
                        ></v-select>
                    </div>
            </div>
            <div class="edit-view__wrapper">
                <div class="edit-view__item" v-if="
                editDataAttribute.metaAttributeObjectType.attributeType === 'RELATION_DICTIONARY' 
                || editDataAttribute.metaAttributeObjectType.attributeType === 'RELATION' 
                ">
                    <p class="edit-view__item__title">Направление:</p>
                    <v-select 
                        v-model="editDataAttribute.relation.direction" 
                        :items="OBJECTS_REGISTER_RELATION_PARTS.directions"
                        item-text="label"
                        item-value="data"
                        :disabled="editDataAttribute.metaAttributeObjectType.attributeType === 'RELATION_DICTIONARY' || attribyteWindowType === 'edit'"
                    ></v-select>
                </div>
            </div>

            <div class="edit-view__item" v-if="
            editDataAttribute.relation !== null && editDataAttribute.relation.direction.directionType === 'BIDIRECTIONAL' 
            ">
                <p class="edit-view__item__title">Обязательность для связанного:</p>
                <v-select 
                    v-model="editDataAttribute.relation.memberMandatory" 
                    :items="OBJECTS_REGISTER_RELATION_PARTS.mandatory"
                    item-text="label"
                    item-value="data"
                    :disabled="editDataAttribute.metaAttributeObjectType.attributeType === 'RELATION_DICTIONARY'"
                ></v-select>
            </div>
            <div class="edit-view__item" v-if="editDataAttribute.relation !== null && (editDataAttribute.relation.direction.directionType === 'BIDIRECTIONAL' || editDataAttribute.metaAttributeObjectType.attributeType === 'RELATION_DICTIONARY' )">
                <p class="edit-view__item__title">Имя аттрибута связанного</p>
                <input v-model="editDataAttribute.relation.reverseAttrName"  type="text" class="edit-view__item__input" :disabled="attribyteWindowType === 'edit'">
            </div>
            <div class="edit-view__item" v-if="editDataAttribute.relation !== null && (editDataAttribute.relation.direction.directionType === 'BIDIRECTIONAL' || editDataAttribute.metaAttributeObjectType.attributeType === 'RELATION_DICTIONARY' ) ">
                <p class="edit-view__item__title">Код атрибута связанного</p>
                <input v-model="editDataAttribute.relation.reverseAttrCode"  type="text" class="edit-view__item__input" :disabled="attribyteWindowType === 'edit'">
            </div>
            <div class="edit-view__item" v-if="editDataAttribute.relation !== null && (editDataAttribute.relation.direction.directionType === 'BIDIRECTIONAL' || editDataAttribute.metaAttributeObjectType.attributeType === 'RELATION_DICTIONARY' ) ">
                <p class="edit-view__item__title">Описание атрибута связанного</p>
                <input v-model="editDataAttribute.relation.reverseAttrDesc"  type="text" class="edit-view__item__input" :disabled="attribyteWindowType === 'edit'">
            </div>

            <v-spacer/>
            <div class="edit-view__actions">
                <button
                    class="btn-close"
                    @click="onClose"
                >Отмена</button>
                <button
                    v-if="attribyteWindowType === 'edit' && editDataAttribute.value.dataType === 'LINK'"
                    @click="onSave"
                    class="btn-save"
                >Сохранить</button>
                <button
                    v-else
                    @click="onNext"
                    class="btn-save"
                >Далее</button>
            </div>
        </div>
            <v-snackbar
                v-model="info"
                :color="infoColor"
                :timeout="10000"
            >
                {{infotext}}
            </v-snackbar>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
    props: {
        editDataAttribute: {
            type: Object,
            default: () => {}
        },
        title: {
            type: String,
            default: () => 'Редактирование'
        },
        editData: {
            type: Object,
            default: () => {}
        },
        attribyteWindowType: {
            type: String,
            default: () => 'create'
        },
    },
    components: {

    },
    data() {
        return {
            rusParams: {
                name: "Наименование",
                nodeType: "Тип объекта",
            },
            dictinaryManagedGroups: [],
            parentDialog: false,
            info: false,
            infotext: '',
            infoColor: '',
            parent: {},
        }
    },
    computed: {
        ...mapGetters(["OBJECTS_TYPES_ATRIBUTES", "OBJECTS_TYPES_COPIED", "OBJECTS_TYPES_GROUP_OPERATION", "OBJECTS_REGISTER_RELATION_PARTS"]),
        transitLink() {
            if (this.editDataAttribute.relation.transitiveRelation && this.editDataAttribute.relation.transitiveRelation !== null) {
                return `linkedAttributeId:${this.editDataAttribute.relation.transitiveRelation.linkedAttributeId}, linkedTemplateId:${this.editDataAttribute.relation.transitiveRelation.linkedTemplateId}`
            } else {
                return ''
            }
            
        }
    },
    methods: {
        ...mapActions([
            "getFirstLevelNodes",
        ]),
        onNext() {
            if (this.editDataAttribute.metaAttributeObjectType.attributeType === null) {
                this.infotext = 'Не заполнены обязательные поля: Тип';
                this.infoColor = 'error';
                this.info = true;
                return
            }
            if (!this.editDataAttribute.value.dataList) {
                this.editDataAttribute.value.dataList = [];
            }
            if (this.editDataAttribute.value.dataList.length === 0) {
                //добавил в дату 0, чтобы проходил запрос, но на клиенте из-за этого тоже отображается 0, а должно быть пусто
                this.editDataAttribute.value.dataList.push({data: '0', index: 1})
            }
            this.$emit('next-create_attribute')
        },
        onSave() {
            this.$emit('edit-attribute')
        },
        onImmutableChange(val) {
            this.editDataAttribute.metaAttributeObjectType.mandatory = val
        },
        onAttributeTypeChange(val) {
            const data = this.OBJECTS_TYPES_ATRIBUTES.find((el => el.attributeType === val))
            this.editDataAttribute.value.dataType = data.dataType
            this.editDataAttribute.value.xmlType = data.xmlType
            if (val === 'RELATION_DICTIONARY' || val === 'RELATION') {
                this.editDataAttribute.relation = {
                    relation: {},
                    direction: {},
                    ownerMandatory: {},
                    memberMandatory: null,
                    reverseAttrName: null,
                    reverseAttrCode: null,
                    reverseAttrDesc: null,
                    logicalDelete: false,
                    transitiveRelation: {}
                }
                if (val === 'RELATION_DICTIONARY') {
                    this.editDataAttribute.relation.direction = this.OBJECTS_REGISTER_RELATION_PARTS.directions.find((el) => el.label === 'Однонаправленная').data
                }
            } else {
                this.editDataAttribute.relation = null;
            }
        },
        onClose() {
            this.$emit('close-create_attribute')
        }

    },
    mounted() {

    }

}
</script>

<style lang="scss" scoped>
.edit-view {
    height: 100%;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    &__item {
        display: grid;
        grid-template-columns: 1fr 7fr;
        justify-content: space-between;
        align-items: center;
        &:not(:last-child) {
            margin-bottom: 10px;
        }
    }
    &__actions {
        display: flex;
        justify-content: flex-end;
        & .btn-close {
            margin-right: 8px;
            background-color:#f06548;
            color: #fff;
            padding: 10px 15px;
            border-radius: 5px;
            font-weight: 500;
        }
        & .btn-close:hover {
            background-color:#cc563d;
            transition: 0.3s all;
        }
        & .btn-save {
            margin-right: 8px;
            background-color:#299cdb;
            color: #fff;
            padding: 10px 15px;
            border-radius: 5px;
            font-weight: 500;
        }
        & .btn-save:hover {
            background-color:#2385ba;
            transition: 0.3s all;
        }
    }
}

.edit-view__container {
    padding: 20px;
}

.edit-view__item__title {
    font-weight: 500;
    width: 150px;
}

.edit-view__item__value {
    width: 100%;
}

.edit-view__item__input {
    width: 100%;
    padding: 5px;
}

.edit-view__item__textarea {
    width: 100%;
    padding: 5px;
    border: 1px solid black;
}

// .edit-view__item__table__container {
//     height: 400px;
// }
.edit-view__item__button {
    border-radius: 5px;
    background-color: deepskyblue;
    color: #fff;
    padding: 5px;
    margin-left: 10px;
}

.parents-fields {
    height: 600px;
    overflow-y: scroll;
}

.edit-view__item__textarea {
    width: 100%;
    padding: 5px;
    border: 1px solid black;
}

.edit-view__item__checkbox {
    width: 100%;
}
.edit-item__wrapper, .edit-view__wrapper {
    display: grid;
    grid-template-columns: 3fr 3fr 1fr;
    grid-column-gap: 4rem;
}

.edit-view__item {
    margin-bottom: 10px;
}
</style>