<template>
    <div
        class="reports"
    >
        <div class="table-conteiner">

        <v-data-table
            class="list_table"
            :headers="headers"
            :items="REPORT_VIEWS"
            :items-per-page="10000"
            hide-default-footer
            dense
            hide-default-header
        >

            <template v-slot:header="{props}">
                    <div ref="tr" class="reports__table__header">
                        <div 
                            v-for="(header, index) in props.headers" 
                            :key="header.text"
                            :style="{width: `${header.width}%`}"
                            class="table__header"
                        >
                            <span>{{header.text}}</span>
                            
                            <v-btn @click="onSort(header)" icon>
                                <template v-if="header.sort !== null">
                                    <v-icon v-if="header.sort === 'asc'" small>
                                        ri-arrow-up-s-fill
                                    </v-icon>
                                    <v-icon v-if="header.sort === 'desc'" small>
                                        ri-arrow-down-s-fill
                                    </v-icon>
                                </template>
                                <v-icon v-else small>ri-sort-desc</v-icon>
                            </v-btn>
                            <div class="table-divider"
                                v-if="index !== props.headers.length - 1"
                                @mousedown="onColumnMouseDown($event, header)"
                            >
                                <div class="table-divider__column"></div>
                            </div>
                        </div>
                    </div>
            </template>
            <template v-slot:body="{items}">
                    <div class="reports__table__body">
                        <div 
                            v-for="(item, index) in items"
                            :key="index"
                            class="reports__table__row"
                            @dblclick="rowClick(item)"
                        >
                            <div v-for="header in headers" :key="header.name" class="table__item__text" :style="{width: `${header.width}%`}">
                                <div v-if="header.value === 'actions'">
                                    <!-- <img
                                        style="cursor: pointer; margin-right: 10px;"
                                        :src="require('../assets/edit.png')"
                                        alt="delete"
                                        @click="showEditForm(item)"
                                    />
                                    <img
                                        style="cursor: pointer"
                                        :src="require('../assets/trash.png')"
                                        alt="delete"
                                        @click="showDeleteForm(item)"
                                    /> -->
                                    <i class="ri ri-pencil-fill" @click="showEditForm(item)"></i>
                                    <i class="ri ri-delete-bin-fill" @click="showDeleteForm(item)"></i>
                                </div>
                                <div v-else-if="header.value === 'versionDate'">
                                    {{new Date(item.versionDate).toLocaleDateString()}}
                                </div>
                                <div v-else>
                                    {{  item[header.value] }}
                                </div>
                            </div>
                        </div>
                    </div>
            </template>



        </v-data-table>

        </div>
        <div class="buttons_conteiner">
            <button class="buttons_conteiner__button" @click="showCreateDialog">Добавить</button>
        </div>
        <v-dialog
                v-model="createDialog"
                persistent
                max-width="1900"
            >
                <v-card>
                    <v-card-title></v-card-title>
                    <div class="report-view__fields">
                        <div class="report-view__fields__field" v-if="createdView.id !== '0'">
                            <label class="report-view__fields__label">ID:</label>
                            <input type="text" v-model="createdView.id" class="report-view__fields__input" disabled>
                        </div>
                        <div class="report-view__fields__field" v-if="createdView.id !== '0'">
                            <label class="report-view__fields__label">Дата изменения:</label>
                            <input type="text" v-model="createdView.versionDate" class="report-view__fields__input" disabled>
                        </div>
                        <div class="report-view__fields__field" v-if="createdView.id !== '0'">
                            <label class="report-view__fields__label">Версия:</label>
                            <input type="text" v-model="createdView.version" class="report-view__fields__input" disabled>
                        </div>
                        <div class="report-view__fields__field">
                            <label class="report-view__fields__label" for="report-view_aliasView">Код в БД:</label>
                            <input type="text" v-model="createdView.aliasView" class="report-view__fields__input" id="report-view_aliasView">
                        </div>
                        <div class="report-view__fields__field">
                            <label class="report-view__fields__label" for="report-view_description">Описание:</label>
                            <textarea v-model="createdView.description" class="report-view__fields__input" id="report-view_description"></textarea>
                        </div>
                        <div class="report-view__fields__field">
                            <label class="report-view__fields__label" for="report-view_code">Наименование:</label>
                            <input type="text" v-model="createdView.code" class="report-view__fields__input" id="report-view_code">
                        </div>
                        <div class="report-view__fields__field__with-table">
                            <p class="report-view__fields__title">Выбор объектов и аттрибутов для представления:</p>
                            <div class="report-view__fields__field__table__conteiner">
                                <v-data-table
                                    :headers="[{text: 'Объекты', value: 'templateName'}, {text: '', value: 'selected'},]"
                                    dense
                                    :items="TEMPLATES_FOR_REPORT_VIEWS"
                                    :single-expand="false"
                                    :expanded.sync="expanded"
                                    :items-per-page="10000"
                                    hide-default-footer
                                    item-key="id"
                                    show-expand
                                    class="report-view__fields__field__table"
                                    @item-expanded="expandTemplate"
                                >
                                <template v-slot:expanded-item="{ headers, item }">
                                    <td :colspan="headers.length">
                                        <v-data-table 
                                            :headers="[{text: 'Объекты', value: 'name',}, {text: '', value: 'selected'},]"
                                            dense
                                            :single-expand="false"
                                            :expanded.sync="item.expanded"
                                            :items="item.nodes"
                                            :items-per-page="1000"
                                            show-expand
                                            hide-default-footer
                                            item-key="id"
                                            class="report-view__fields__field__table"
                                            @item-expanded="expandInnerTemplate"
                                        >
                                            <template v-slot:item.data-table-expand="{ item, isExpanded, expand }" v-if="item.nodes">
                                                <v-btn
                                                    v-if="item.nodes"
                                                    icon
                                                    color="primary"
                                                    @click="expand(!isExpanded)"
                                                    >
                                                    <v-icon dark>
                                                        {{isExpanded ? 'ri-arrow-up-s-fill': 'ri-arrow-down-s-fill'}}
                                                    </v-icon>
                                                </v-btn>
                                            </template>
                                            <template v-slot:expanded-item="{ headers, item }">
                                                <td :colspan="headers.length">
                                                    <v-data-table 
                                                        :headers="[{text: 'Объекты', value: 'name',}, {text: '', value: 'selected'}]"
                                                        dense
                                                        :items="item.nodes"
                                                        :items-per-page="1000"
                                                        hide-default-footer
                                                        item-key="id"
                                                        class="report-view__fields__field__table"
                                                    >
                                                        <template v-slot:item.selected="{ item }">
                                                            <v-simple-checkbox
                                                                v-model="item.selected"
                                                                @input="selectTemplateItemInner(item)"
                                                            ></v-simple-checkbox>
                                                        </template>
                                                    </v-data-table>
                                                </td>
                                            </template>
                                            <template v-slot:item.selected="{ item }">
                                                <v-simple-checkbox
                                                    v-model="item.selected"
                                                    @input="selectTemplateItem(item)"
                                                ></v-simple-checkbox>
                                            </template>
                                        </v-data-table>
                                    </td>
                                </template>
                                <template v-slot:item.selected="{ item }">
                                    <v-simple-checkbox
                                        v-model="item.selected"
                                        @input="selectTemplate(item)"
                                    ></v-simple-checkbox>
                                </template>
                                </v-data-table>
                            </div>
                            <button @click="collapseAll">Свернуть всё</button>
                        </div>
                        <div class="report-view__fields__field__with-table">
                            <p class="report-view__fields__title">Структура представления в БД:</p>
                            <div>
                                <v-data-table
                                    :headers="structureHeaders"
                                    dense
                                    :items="structureItems"
                                    :items-per-page="4"
                                    hide-default-footer
                                    item-key="id"
                                    class="report-view__fields__field__table"
                                    hide-default-header
                                >

                                <template v-slot:header="{props}">
                                        <div ref="trStructure" class="reports__table__header">
                                            <div 
                                                v-for="(header, index) in structureHeaders" 
                                                :key="`${index}-${header.value}`"
                                                :style="{width: `${header.width}px`}"
                                                class="table__header"
                                                @dblclick="structureHeadersClick(header)"
                                            >
                                                <span v-if="!header.edit">{{header.text}}</span>
                                                <input v-else type="text" 
                                                    @input="onChangeHeaderName($event, header)"
                                                    :value="header.text"
                                                    class="reports__table__header__input">
                                                
                                                <div class="table-divider"
                                                    v-if="index !== props.headers.length - 1"
                                                    @mousedown="onColumnMouseDownStructure($event, header)"
                                                >
                                                    <div class="table-divider__column"></div>
                                                </div>
                                            </div>
                                        </div>
                                </template>
                                <template v-slot:body="{items}">
                                        <div class="reports__table__body">
                                            <div 
                                                v-for="(item, index) in items"
                                                :key="index"
                                                class="reports__table__row"
                                                @dblclick="rowClick(item)"
                                            >
                                                <div v-for="header in structureHeaders" :key="header.name" class="table__item__text" :style="{width: `${header.width}px`}">
                                                    {{  item[header.value] }}
                                                </div>
                                            </div>
                                        </div>
                                </template>

                                </v-data-table>
                            </div>
                        </div>
                    </div>
                    <v-card-actions>
                        <!-- <v-btn
                            v-if="createdView.id === '0'"
                            color="green darken-1"
                            text
                            @click="onCreateParameter"
                        >Сохранить
                        </v-btn> -->
                        <button 
                            v-if="createdView.id === '0'"
                            class="btn-save" 
                            @click="onCreateParameter"
                        >Сохранить
                        </button>

                        <!-- <v-btn
                            v-if="createdView.id !== '0'"
                            color="green darken-1"
                            text
                            @click="onEditParameter"
                        >Сохранить
                        </v-btn> -->
                        <button 
                            v-if="createdView.id !== '0'"
                            class="btn-save" 
                            @click="onEditParameter"
                        >Сохранить
                        </button>
                        <button 
                            class="btn-close" 
                            @click="createDialog = false"
                        >Закрыть
                        </button>

                        <!-- <v-btn
                            color="red darken-1"
                            text
                            @click="createDialog = false"
                        >Закрыть
                        </v-btn> -->
                    </v-card-actions>
                </v-card>
        </v-dialog>
        <v-dialog
            v-model="changeParentDialog"
            persistent
            max-width="290"
        >
            <v-card>
                <v-card-text>Возможно использовать атрибуты из одного шаблона. Вы уверены, что хотите удалить предыдущие атрибуты?</v-card-text>
                <v-card-actions>
                    <!-- <v-btn
                        color="red darken-1"
                        text
                        @click="onChangeParent"
                    >Да
                    </v-btn> -->
                    <button 
                        class="btn-save" 
                        @click="onChangeParent"
                    >Да
                    </button>
                    <!-- <v-btn
                        color="green darken-1"
                        text
                        @click="changeParentDialog = false"
                    >Нет
                    </v-btn> -->
                    <button 
                        class="btn-close" 
                        @click="changeParentDialog = false"
                    >Нет
                    </button>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
                v-model="deleteDialog"
                persistent
                max-width="290"
            >
            <v-card>
                <v-card-text>Вы действительно хотите удалить запись?</v-card-text>
                <v-card-actions>
                    <!-- <v-btn
                        color="red darken-1"
                        text
                        @click="onDelete"
                    >Да -->
                    <!-- </v-btn> -->
                    <button 
                        class="btn-save" 
                        @click="onDelete"
                    >Да
                    </button>
                    <!-- <v-btn
                        color="green darken-1"
                        text
                        @click="deleteDialog = false"
                    >Нет
                    </v-btn> -->
                    <button 
                        class="btn-close" 
                        @click="deleteDialog = false"
                    >Нет
                    </button>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-snackbar
            v-model="info"
            :color="infoColor"
            :timeout="10000"
        >
            {{infotext}}
        </v-snackbar>
        <v-overlay :value="loading">
            <v-progress-circular
                indeterminate
                size="64"
            ></v-progress-circular>
        </v-overlay>
    </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
export default {
    components: {

    },
    data() {
        return {
            headers: [
                {text: 'ID', value: 'id', width: 14.28 , sort: null, sortOrder: null}, 
                {text: 'Наименование', value: 'code', width: 14.28, sort: null, sortOrder: null},
                {text: 'Код в БД', value: 'aliasView', width: 14.28, sort: null, sortOrder: null },
                {text: 'Описание', value: 'description', width: 14.28, sort: null, sortOrder: null},
                {text: 'Дата изменения', value: 'versionDate', width: 14.28, sort: null, sortOrder: null},
                {text: 'Версия', value: 'version', width: 14.28, sort: null, sortOrder: null},
                {text: 'Действия', value: 'actions', width: 14.28, sort: null, sortOrder: null},
            ],
            structureHeaders: [
                {text: 'Поле', value: 'fieldName', width: 100}, 
            ],
            structureItems: [
                {fieldName: 'ID'}, 
                {fieldName: 'Наименование'}, 
                {fieldName: 'Код'}, 
                {fieldName: 'Тип'}, 
            ],
            deletedItem: null,
            deleteDialog: false,
            changedParent: null,
            changeParentDialog: false,
            info: false,
            infotext: '',
            infoColor: '',
            createDialog: false,
            expanded: [],
            expandedInner: [],
            changedParentItem: null,
            createdView: {
                id: "0",
                version: "0",
                versionDate: null,
                creationDate: null,
                aliasView: '',
                description: '',
                code: '',
                groupColumns: {
                    parentId: "",
                    columns: [],
                    joinedGroupsColumns: [],
                    linkAttributeId: "0",
                    reverseLink: "false",
                },
            },
            activeColumn: null,
            columnWidth: null,
            point: null,
            sort: {},
            pointStructure: null,
            activeColumnStructure: null,
            loading: true,
        }
    },
    computed: {
        ...mapGetters([
            "REPORT_VIEWS",
            "TEMPLATES_FOR_REPORT_VIEWS",
        ]),
    },
    methods: {
        ...mapActions([
            "fetchAllReportViews",
            "fetchTemplatesForReport",
            "getReportAttributesByTemplateId",
            "updateReportView",
            "createReportView",
            "deleteReportView",
        ]),
        ...mapMutations([
            "SELECT_TEMPLATE_FOR_REPORT_VIEWS",
            "RESET_ALL_SELECTION_TEMPLATE_FOR_REPORT_VIEWS",
            "COLLAPSE_ALL_TEMPLATES_FOR_REPORT_VIEWS",
            "SORT_REPORT_VIEWS"
        ]),
        onCreateParameter() {
            if (!this.createdView.aliasView || !/^[a-zA-Z]/.test(this.createdView.aliasView[0])) { 
                this.info= true;
                this.infotext= 'Не введён код в БД или строка кода начинается не с буквы. Проверьте правильность введённых данных.';
                this.infoColor= 'deep-purple accent-4';
                return
            }
            if (!this.createdView.groupColumns.parentId || !this.createdView.groupColumns.columns.length === 0) {
                this.info= true;
                this.infotext= 'Необходимо выбрать хотя бы один атрибут';
                this.infoColor= 'deep-purple accent-4';
                return
            }
            this.loading = true;
            this.createReportView(this.createdView).then(()=> {
                this.RESET_ALL_SELECTION_TEMPLATE_FOR_REPORT_VIEWS()
                this.collapseAll()
                this.createDialog = false;
                this.loading = false;
            }).catch((e) => {
                this.infoColor="error"
                this.infotext= e;
                this.info=true;
                this.loading = false;
            })
        },
        expandTemplate(item) {

            if (item.item.nodes.length === 0) {
                const node = {
                    linkAttributeId: item.item.parentId,
                    parentId: item.item.id,
                }
                this.loading = true;
                this.getReportAttributesByTemplateId({node}).then(()=>{
                    this.loading = false;
                }).catch((e) => {
                    console.log(e)
                    this.infoColor="error"
                    this.infotext= e;
                    this.info=true;
                    this.loading = false;
                })
            }
        },
        expandInnerTemplate(item) {

            if (item.item.nodes.length === 0) {
                const parent = this.TEMPLATES_FOR_REPORT_VIEWS.find((el) => {
                    if (el.nodes) {
                        return el.nodes.find((innerEl) => innerEl.id === item.item.id)
                    }
                })
                const node = {
                    linkAttributeId: item.item.id,
                    parentId: item.item.linkOwnerId,
                }
                this.loading = true;
                this.getReportAttributesByTemplateId({node, parentId: parent.id}).then(()=>{
                    this.loading = false;
                }).catch((e) => {
                    this.infoColor="error"
                    this.infotext= e;
                    this.info=true;
                    this.loading = false;
                })
            }
        },
        showEditForm(item) {

            this.RESET_ALL_SELECTION_TEMPLATE_FOR_REPORT_VIEWS()
            this.collapseAll()
            this.loading = true;
            this.getReportAttributesByTemplateId({node:item.groupColumns}).then(()=>{
                if (item.groupColumns.joinedGroupsColumns) {
                    const promiseArr = item.groupColumns.joinedGroupsColumns.map(
                        (el)=> this.getReportAttributesByTemplateId({node:el, parentId: item.groupColumns.parentId})
                    )
                    return Promise.all(promiseArr)
                } else return
            }).then(()=>{
                this.RESET_ALL_SELECTION_TEMPLATE_FOR_REPORT_VIEWS()
                this.structureHeaders = [
                    {text: 'Поле', value: 'fieldName', width: 100, edit: false}, 
                ],
                this.structureItems = [
                    {fieldName: 'ID'}, 
                    {fieldName: 'Наименование'}, 
                    {fieldName: 'Код'}, 
                    {fieldName: 'Тип'}, 
                ],
                this.createdView = {...item}
                const columns = [...this.createdView.groupColumns.columns]
                if (this.createdView.groupColumns.joinedGroupsColumns && this.createdView.groupColumns.joinedGroupsColumns.length >0) {
                    for (let joinedGroupsColumns of this.createdView.groupColumns.joinedGroupsColumns) {
                        columns.push(...joinedGroupsColumns.columns)
                    }
                }
                this.SELECT_TEMPLATE_FOR_REPORT_VIEWS(item.groupColumns)
                console.log(columns)
                for (let column of columns) {
                    this.structureItems[0][column.attributeId] = column.attributeId
                    this.structureItems[1][column.attributeId] = column.description
                    this.structureItems[2][column.attributeId] = column.code
                    this.structureItems[3][column.attributeId] = column.columnType
                    this.structureHeaders.push({text: column.columnAlias, value: column.attributeId, width: 100, edit: false})
                }
                console.log(this.structureItems)
                console.log(this.structureHeaders)
                this.createDialog = true;
                this.loading = false;
            }).catch((e) => {
                this.infoColor="error"
                this.infotext= e;
                this.info=true;
                this.loading = false;
            })
        },
        onEditParameter() {
            console.log(this.createdView)
            if (!this.createdView.aliasView || !/^[a-zA-Z]/.test(this.createdView.aliasView[0])) { 
                this.info= true;
                this.infotext= 'Не введён код в БД или строка кода начинается не с буквы. Проверьте правильность введённых данных.';
                this.infoColor= 'deep-purple accent-4';
                return
            }
            if (!this.createdView.groupColumns.parentId || !this.createdView.groupColumns.columns.length === 0) {
                this.info= true;
                this.infotext= 'Необходимо выбрать хотя бы один атрибут';
                this.infoColor= 'deep-purple accent-4';
                return
            }
            this.loading = true;
            this.updateReportView(this.createdView).then((res)=> {
                console.log(res)
                this.RESET_ALL_SELECTION_TEMPLATE_FOR_REPORT_VIEWS()
                this.collapseAll()
                this.createDialog = false;
                this.loading = false;
            }).catch((e) => {
                this.infoColor="error"
                this.infotext= e;
                this.info=true;
                this.loading = false;
            })
        },
        showCreateDialog() {
            this.RESET_ALL_SELECTION_TEMPLATE_FOR_REPORT_VIEWS()
            this.collapseAll()
            this.createdView = {
                id: "0",
                version: "0",
                versionDate: null,
                creationDate: null,
                aliasView: '',
                description: '',
                code: '',
                groupColumns: {
                    parentId: "",
                    columns: [],
                    joinedGroupsColumns: [],
                    linkAttributeId: "0",
                    reverseLink: "false",
                },
            },
            this.structureHeaders = [
                {text: 'Поле', value: 'fieldName', width: 100, edit: false}, 
            ],
            this.structureItems = [
                {fieldName: 'ID'}, 
                {fieldName: 'Наименование'}, 
                {fieldName: 'Код'}, 
                {fieldName: 'Тип'}, 
            ],
            this.createDialog = true;
        },
        selectTemplateItemInner(item) {

            const parent = this.TEMPLATES_FOR_REPORT_VIEWS.find((el)=>el.id === this.createdView.groupColumns.parentId)
            const newItem = {
                attributeId: item.id,
                code: item.code,
                columnAlias: `ATTRIBUTE_${item.id}_${item.code}`,
                columnType: item.dataType,
                description: item.name,
            }
            const parentNode = parent.nodes.find((node)=> {
                if (!node.nodes) {
                    return false
                } else {
                    const child = node.nodes.find((child) => child.id === item.id)
                    return child ? true : false
                }
            })

            if (!this.createdView.groupColumns.joinedGroupsColumns) {
                this.createdView.groupColumns.joinedGroupsColumns = []

            }
            const index = this.createdView.groupColumns.joinedGroupsColumns.findIndex((el) => el.linkAttributeId === parentNode.id)

            if (index !== -1) {
                if (item.selected) {
                    parentNode.selected = true;
                    this.createdView.groupColumns.joinedGroupsColumns[index].columns.push(newItem)
                    this.structureHeaders.push({text: newItem.columnAlias, value: newItem.attributeId, width: 100, edit: false})
                    this.structureItems[0][newItem.attributeId] = newItem.attributeId
                    this.structureItems[1][newItem.attributeId] = newItem.description
                    this.structureItems[2][newItem.attributeId] = newItem.code
                    this.structureItems[3][newItem.attributeId] = newItem.columnType
                } else {
                    this.createdView.groupColumns.joinedGroupsColumns[index].columns = this.createdView.groupColumns.joinedGroupsColumns[index].columns.filter((el)=>el.attributeId !== item.id)
                    if (this.createdView.groupColumns.joinedGroupsColumns[index].columns.length === 0) {
                        parentNode.selected = false;
                        this.createdView.groupColumns.joinedGroupsColumns = this.createdView.groupColumns.joinedGroupsColumns.filter((el => el.linkAttributeId !== parentNode.id))

                    } 
                    this.structureHeaders = this.structureHeaders.filter((el) => el.value !== newItem.attributeId)
                    delete this.structureItems[0][newItem.attributeId] 
                    delete this.structureItems[1][newItem.attributeId] 
                    delete this.structureItems[2][newItem.attributeId] 
                    delete this.structureItems[3][newItem.attributeId] 
                }
            } else {
                const newJoinedGroup = {
                    columns: [newItem],
                    linkAttributeId: parentNode.id,
                    parentId: parentNode.linkOwnerId,
                    reverseLink: "false",
                }
                parentNode.selected = true;
                this.createdView.groupColumns.joinedGroupsColumns.push(newJoinedGroup)
                this.structureHeaders.push({text: newItem.columnAlias, value: newItem.attributeId, width: 100, edit: false})
                this.structureItems[0][newItem.attributeId] = newItem.attributeId
                this.structureItems[1][newItem.attributeId] = newItem.description
                this.structureItems[2][newItem.attributeId] = newItem.code
                this.structureItems[3][newItem.attributeId] = newItem.columnType
            }

        },
        selectTemplateItem(item) {

            const parent = this.TEMPLATES_FOR_REPORT_VIEWS.find((el)=>{
                return el.nodes.find((child) => child.id === item.id) ? true : false
            })

            if (parent.id !== this.createdView.groupColumns.parentId) {
                if (!this.createdView.groupColumns.parentId) {
                    parent.selected = true
                    this.createdView.groupColumns.parentId = parent.id
                } else {
                    item.selected = false;
                    this.changedParent = parent
                    this.changedParentItem = item;
                    this.changeParentDialog = true
                }
            } 

            const newItem = {
                attributeId: item.id,
                columnAlias: `ATTRIBUTE_${item.id}_${item.code}`,
                description: item.name,
                code: item.code,
                columnType: item.dataType,
            }

            const index = parent.nodes.findIndex((el) => el.id === item.id)

            if (item.nodes) {
                this.loading = true;
                const node = {
                    linkAttributeId: item.id,
                    parentId: item.linkOwnerId,
                }
                this.getReportAttributesByTemplateId({node, parentId: this.createdView.groupColumns.parentId}).then(()=>{
                    const children = parent.nodes[index].nodes.filter((el)=>!el.nodes)
                    for (let child of children) {
                        child.selected = item.selected
                        this.selectTemplateItemInner(child) 
                    }
                    this.loading = false;
                }).catch((e) => {
                    this.infoColor="error"
                    this.infotext= e;
                    this.info=true;
                    this.loading = false;
                })
            } else {
                if (item.selected) {

                    this.createdView.groupColumns.columns.push(newItem)
                    this.structureHeaders.push({text: newItem.columnAlias, value: newItem.attributeId, width: 100, edit: false})
                    this.structureItems[0][newItem.attributeId] = newItem.attributeId
                    this.structureItems[1][newItem.attributeId] = newItem.description
                    this.structureItems[2][newItem.attributeId] = newItem.code
                    this.structureItems[3][newItem.attributeId] = newItem.columnType
                } else {

                    this.createdView.groupColumns.columns = this.createdView.groupColumns.columns.filter((el) => el.attributeId !== item.id)
                    this.structureHeaders = this.structureHeaders.filter((el) => el.value !== newItem.attributeId)
                    delete this.structureItems[0][newItem.attributeId] 
                    delete this.structureItems[1][newItem.attributeId] 
                    delete this.structureItems[2][newItem.attributeId] 
                    delete this.structureItems[3][newItem.attributeId]
                    const selectedItems = parent.nodes.filter((el) => el.selected)

                    if (selectedItems.length === 0) {
                        parent.selected = false
                        this.createdView.groupColumns.parentId = ""
                    } 
                }
            }

        },
        selectTemplate(item) {

            if (this.createdView.groupColumns.parentId === item.id) {
                if (item.selected) {

                    let children
                    if (this.changedParentItem && this.changedParentItem !== null) {
                        children = [this.changedParentItem]
                    } else {
                        children = item.nodes.filter((el)=>!el.nodes)
                    }
                    for (let child of children) {
                        child.selected = item.selected
                        this.selectTemplateItem(child)
                    }
                } else {
                    const children = item.nodes
                    for (let child of children) {
                        child.selected = item.selected
                    }
                    this.createdView.groupColumns.columns = []
                    this.createdView.groupColumns.joinedGroupsColumns = []
                    this.structureHeaders = [
                        {text: 'Поле', value: 'fieldName', width: 100, edit: false}, 
                    ];
                    this.structureItems = [
                        {fieldName: 'ID'}, 
                        {fieldName: 'Наименование'}, 
                        {fieldName: 'Код'}, 
                        {fieldName: 'Тип'}, 
                    ];
                }
            } else if (!this.createdView.groupColumns.parentId) {
                this.changedParent = item
                this.onChangeParent()
            } else {
                item.selected = false;
                this.changedParent = item
                this.changeParentDialog = true
            }
        },
        onChangeParent() {

            if (this.changedParent.nodes.length === 0) {
                this.loading = true;
                const node = {
                    linkAttributeId: this.changedParent.parentId,
                    parentId: this.changedParent.id,
                }
                this.getReportAttributesByTemplateId({node}).then(()=>{
                    this.RESET_ALL_SELECTION_TEMPLATE_FOR_REPORT_VIEWS()
                    this.changeParentDialog = false;
                    this.createdView.groupColumns.parentId = this.changedParent.id;
                    this.structureHeaders = [
                        {text: 'Поле', value: 'fieldName', width: 100, edit: false}, 
                    ];
                    this.structureItems = [
                        {fieldName: 'ID'}, 
                        {fieldName: 'Наименование'}, 
                        {fieldName: 'Код'}, 
                        {fieldName: 'Тип'}, 
                    ];
                    this.changedParent.selected = true;
                    this.createdView.groupColumns.columns = []
                    this.createdView.groupColumns.joinedGroupsColumns = []
                    this.selectTemplate(this.changedParent)
                    this.changedParent = null;
                    this.changeParentDialog = false;
                    this.changedParentItem = null
                    this.loading = false;
                }).catch((e) => {
                    console.log(e)
                    this.infoColor="error"
                    this.infotext= e;
                    this.info=true;
                    this.loading = false;
                })
            } else {
                this.RESET_ALL_SELECTION_TEMPLATE_FOR_REPORT_VIEWS()
                this.changeParentDialog = false;
                this.createdView.groupColumns.parentId = this.changedParent.id;
                this.structureHeaders = [
                    {text: 'Поле', value: 'fieldName', width: 100, edit: false}, 
                ];
                this.structureItems = [
                    {fieldName: 'ID'}, 
                    {fieldName: 'Наименование'}, 
                    {fieldName: 'Код'}, 
                    {fieldName: 'Тип'}, 
                ];
                this.changedParent.selected = true;
                this.createdView.groupColumns.columns = []
                this.createdView.groupColumns.joinedGroupsColumns = []
                this.selectTemplate(this.changedParent)
                this.changedParent = null;
                this.changeParentDialog = false;
            }
        },
        collapseAll() {
            this.expanded = []
            this.COLLAPSE_ALL_TEMPLATES_FOR_REPORT_VIEWS()
        },
        rowClick(row) {
            console.log()
            this.showEditForm(row)
        },
        showDeleteForm(item) {
            this.deleteDialog = true;
            this.deletedItem = item;
        },
        onDelete() {
            this.loading = true;
            this.deleteReportView(this.deletedItem).then(()=> {
                this.deleteDialog = false;
                this.deletedItem = null;
                this.loading = false;
            }).catch((e) => {
                this.deleteDialog = false;
                this.infoColor="error"
                this.infotext= e;
                this.info=true;
                this.loading = false;
            })
        },
        onColumnMouseDown(event, header) {
            event.preventDefault();
            this.point = event.clientX - 12;
            this.activeColumn = header;

            document.onmousemove = this.elementDrag;
            document.onmouseup = this.closeDragElement;
        },
        elementDrag(e) {
            e = e || window.event;
            e.preventDefault();
            const conteinerWidth = this.$refs.tr.clientWidth;

            const width =this.activeColumn.width * (conteinerWidth/100) + (e.clientX-12 - this.point)
            const index = this.headers.findIndex((el) => el.value === this.activeColumn.value)
            const widthInPercents = (width/conteinerWidth) * 100
            if (widthInPercents < 5) {
                return
            }
            if (this.headers[index + 1]) {
                const secondWidth = this.headers[index + 1].width * (conteinerWidth/100) - (e.clientX-12 - this.point)
                const secondWidthInPercents  = (secondWidth/conteinerWidth) * 100
                if (secondWidthInPercents < 5) {
                    return
                }
                this.headers[index + 1].width = (secondWidth/conteinerWidth) * 100
            }
            this.headers[index].width = widthInPercents


            this.point = e.clientX-12
        },
        closeDragElement() {
            document.onmouseup = null;
            document.onmousemove = null;
        },
        onColumnMouseDownStructure(event, header) {
            event.preventDefault();
            this.pointStructure = event.clientX - 39;
            this.activeColumnStructure = header;

            document.onmousemove = this.elementDragStructure;
            document.onmouseup = this.closeDragElement;
        },
        elementDragStructure(e) {
            e = e || window.event;
            e.preventDefault();
            //const conteinerWidth = this.$refs.trStructure.clientWidth;
            const width =this.activeColumnStructure.width  + (e.clientX-39 - this.pointStructure)
            const index = this.structureHeaders.findIndex((el) => el.value === this.activeColumnStructure.value)
            if (width < 20) return
            this.structureHeaders[index].width = width


            this.pointStructure = e.clientX-39
        },
        onSort(column) {
            const index = this.headers.findIndex((el) => el.value === column.value)
            if (column.sort !== null) {
                if (column.sort === 'asc') {
                    this.headers[index].sort = 'desc'
                    this.headers[index].sortOrder = 1
                } else if (column.sort === 'desc') {
                    this.headers[index].sort = 'asc'
                    this.headers[index].sortOrder = 1
                }
            } else {
                this.headers[index].sort = 'desc'
                this.headers[index].sortOrder = 1
            }
            for (let i=0; i<this.headers.length; i+=1) {
                if (i === index) continue
                this.headers[i].sortOrder=0
                this.headers[i].sort = null
            }
            const headersOrdered = this.headers.filter((el)=> el.sortOrder !== null).sort((a, b)=> {
                if (a.sortOrder > b.sortOrder) {
                    return 1;
                }
                if (a.sortOrder < b.sortOrder) {
                    return -1;
                }
                  // a должно быть равным b
                return 0;
            })
            let result = this.REPORT_VIEWS
            for (let header of headersOrdered) {
                result = result.sort((a, b)=> {
                        let first = a[header.value]
                        let second = b[header.value]
                        if (!Number.isNaN(first)) {
                            first = Number(first)
                        }
                        if (!Number.isNaN(second)) {
                            second = Number(second)
                        }
                        if (first > second) {
                            return this.headers[index].sort === 'asc' ? 1 : -1;
                        }
                        if (first < second) {
                            return this.headers[index].sort === 'asc' ? -1 : 1;
                        }
                        // a должно быть равным b
                        return 0;
                })
            }
            this.SORT_REPORT_VIEWS(result)
        },
        structureHeadersClick(header) {
            for (let structureHeader of this.structureHeaders) {
                if (header.value === structureHeader.value) {
                    structureHeader.edit = true
                } else {
                    structureHeader.edit = false
                }
            }
        },
        onChangeHeaderName(event, header) {
            header.text = event.target.value
            this.createdView.groupColumns.columns.find((el) => el.attributeId == header.value).columnAlias = event.target.value
        }
    },
    
    mounted() {
        this.loading = true;
        Promise.all([this.fetchAllReportViews(), this.fetchTemplatesForReport()]).then(()=> {
            this.loading = false;
        }).catch((e) => {
            this.infoColor="error"
            this.infotext= e;
            this.info=true;
            this.loading = false;
        })
    }
}
</script>

<style lang="scss" scoped>

.reports {
    margin-left: 15px;
    margin-top: 10px
}
.list_table {
    border: 1px solid #ced4da;
}
.report-view__fields {
    padding: 15px;
}
.report-view__fields__field {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 10px;
}
.report-view__fields__label {
    font-weight: bold;
    width: 150px;
}
.report-view__fields__input {
    border: 1px solid black;
    width: 100%;
    max-width: 40%;
}

.report-view__fields__field__table__conteiner {
    height: 300px;
    overflow-y: scroll;
}

.report-view__fields__title {
    font-weight: bold;
}
.report-view__fields__field__table__inner {
    width: 100%;
}

.reports__table__header {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    background: #daf4f0;
}

.reports__table__row {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    cursor: pointer;
}

    .table-divider {
        position: absolute;
        top: 0 !important;
        right: 0;
        height: 100%;
        width: 1px;
        cursor: col-resize;
        display: flex;
        justify-content: center;
    }

    .table-divider__column {
        height: 100%;
        width: 1px;
        background-color: #ced4da;
    }

    .table__item__text {
        transition: height .2s cubic-bezier(.4,0,.6,1);
        border-bottom: thin solid #ced4da;
        font-size: 0.8125rem; 
        padding-top: 12px !important;
        padding-bottom: 12px !important;
        border-right: 1px solid #ced4da;
        padding-left: 10px;
    }

    .table__header {
        position: relative;
        padding-top: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid #ced4da;
        padding-left: 10px;
        font-weight: 600;
    }

    .reports__table__header__input{
        width: 100%;
        border: 2px solid black;
        border-radius: 2px;
    }

</style>