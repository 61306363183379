<template>
    <div class="edit-view">
        <div class="edit-view__title">{{title}}</div>
        <div class="edit-view__container">
            <div class="edit-view__item">
                <p class="edit-view__item__title">Тип Объекта:</p>
                <v-select 
                    v-model="creationData.nodeType" 
                    :items="OBJECTS_TYPES_NODES"
                    @change="onCHangeNodeType"
                    item-text="name"
                    item-value="nodeType"
                    ></v-select>
            </div>
            <div class="edit-view__item">
                <p class="edit-view__item__title">Наименование:</p>
                <input v-model="creationData.name"  type="text" class="edit-view__item__input">
            </div>
            <div class="edit-view__item" v-if="creationData.nodeType === 'OBJECT_TEMPLATE'">
                <p class="edit-view__item__title">Наименование в журнале:</p>
                <input v-model="creationData.journalName"  type="text" class="edit-view__item__input">
            </div>
            <div class="edit-view__item" v-if="creationData.nodeType === 'OBJECT_TEMPLATE'">
                <p class="edit-view__item__title">Тип шаблона:</p>
                <v-select 
                    v-model="creationData.typeTemplate" 
                    :items="OBJECTS_TYPES_TEMPLATES"
                    item-text="name"
                    item-value="templateType"
                ></v-select>
            </div>
            <div class="edit-view__item">
                <p class="edit-view__item__title">Родитель:</p>
                <input v-model="parent.name"  type="text" class="edit-view__item__input" disabled>
                <button class="edit-view__item__button" @click="openParentDialog">Задать</button>
            </div>
            <div class="edit-view__item">
                <p class="edit-view__item__title">Владелец:</p>
                <input  type="text" class="edit-view__item__input" disabled>
                <button class="edit-view__item__button" disabled>Задать</button>
            </div>
            <v-spacer/>
            <div class="edit-view__actions">
                <button
                    class="btn-close"
                    @click="$emit('close-create')"
                >Закрыть</button>
                <button
                    @click="onSave"
                    class="btn-save"
                >Сохранить</button>
            </div>
        </div>
            <v-dialog
                v-model="parentDialog"
                persistent
                max-width="400"
            >
            <v-card>

                <v-card-title>Типы объектов: Наследование</v-card-title>
                <div class="parents-fields">
                    <tree-menu 
                        v-for="(node, index) in OBJECTS_FIRST_LEVEL_NODES" 
                        :key="index"
                        :label="node.label"
                        :nodes="node.nodes"
                        :nodeData="node.data"
                        :depth="0"
                        type="parent"
                        @label-clicked="onParentClick($event)"
                        :selectedItem="parent"
                    ></tree-menu>
                </div>
                <v-card-actions>
                    <!-- <v-btn
                        color="green darken-1"
                        text
                        @click="onSelectParent"
                    >Выбрать
                    </v-btn>
                    <v-btn
                        color="red darken-1"
                        text
                        @click="onCloseParentDialog"
                    >Закрыть
                    </v-btn> -->
                    <button
                        class="btn-save" 
                        @click="onSelectParent"
                    >Выбрать
                    </button>                           
                    <button 
                        class="btn-close" 
                        @click="onCloseParentDialog"
                        >Закрыть
                    </button>
                </v-card-actions>
            </v-card>
            </v-dialog>
            <v-snackbar
                v-model="info"
                :color="infoColor"
                :timeout="10000"
            >
                {{infotext}}
            </v-snackbar>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
    props: {
        creationData: {
            type: Object,
            default: () => {}
        },
        title: {
            type: String,
            default: () => 'Редактирование'
        }
    },
    components: {

    },
    data() {
        return {
            rusParams: {
                name: "Наименование",
                nodeType: "Тип объекта",
            },
            dictinaryManagedGroups: [],
            parentDialog: false,
            info: false,
            infotext: '',
            infoColor: '',
            parent: {name: 'ROOT', id: 1},
        }
    },
    computed: {
        ...mapGetters(["OBJECTS_TYPES_NODES", "OBJECTS_TYPES_TEMPLATES", "OBJECTS_FIRST_LEVEL_NODES"]),
    },
    methods: {
        ...mapActions([
            "getFirstLevelNodes",
        ]),
        onSave() {
            if (this.parent.id) {
                this.$emit('create-Object', this.creationData)
            } else {
                this.infoColor="error"
                this.infotext= 'Родитель не выбран!';
                this.info=true;
            }
        },
        onSelectParent() {

            this.creationData.parentId = this.parent.id;

            this.parentDialog = false;
        },
        onCloseParentDialog() {
            this.parentDialog = false;
            this.parent = {}
        },
        openParentDialog() {
            this.getFirstLevelNodes().then(()=>{
                this.parentDialog = true;
            }).catch((e) => {
                this.infoColor="error"
                this.infotext= e;
                this.info=true;
            })
        },
        onParentClick(parent) {
            this.parent = parent
        },
        onCHangeNodeType() {
            if (this.creationData.nodeType === 'OBJECT_TEMPLATE') {
                this.creationData.typeTemplate = "NONE"
            }
        }
    },

}
</script>

<style lang="scss" scoped>
.edit-view {
    height: 100%;
    display: flex;
    flex-direction: column;
    &__item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        &:not(:last-child) {
            margin-bottom: 10px;
        }
    }
    &__actions {
        display: flex;
        justify-content: flex-end;
        & .btn-close {
            margin-right: 8px;
            background-color:#f06548;
            color: #fff;
            padding: 10px 15px;
            border-radius: 5px;
            font-weight: 500;
        }
        & .btn-close:hover {
            background-color:#cc563d;
            transition: 0.3s all;
        }
        & .btn-save {
            margin-right: 8px;
            background-color:#299cdb;
            color: #fff;
            padding: 10px 15px;
            border-radius: 5px;
            font-weight: 500;
        }
        & .btn-save:hover {
            background-color:#2385ba;
            transition: 0.3s all;
        }
    }
}

.edit-view__container {
    padding: 20px;
}

.edit-view__item__title {
    font-weight: 500;
    width: 200px;
}

.edit-view__item__value {
    width: 100%;
}

.edit-view__item__input {
    width: 100%;
    padding: 5px;
}

.edit-view__item__textarea {
    width: 100%;
    padding: 5px;
    border: 1px solid black;
}

// .edit-view__item__table__container {
//     height: 400px;
// }

.edit-view__item__button {
    border-radius: 5px;
    background-color: #dff0fa;
    color: #299cdb;
    padding: 10px 15px;
    margin-left: 10px;
    font-weight: 500;
}

.edit-view__item__button:hover {
    background-color: #299cdb;
    color: rgb(255, 255, 255);
    transition: 0.3s all;
}

.parents-fields {
    height: 600px;
    overflow-y: scroll;
}
</style>