<template>
  <div class="types" ref="conteiner">
    <!-- Не вытаскивыть tree-menu из враппера, т.к компонент вызывает сам себя
        и конструкция сломается -->
    <v-navigation-drawer
      class="tree"
      v-model="showNavigation"
      :mini-variant.sync="mini"
      permanent
    >
      <div class="navigation-top__conteiner">
        <v-btn icon @click.stop="mini = !mini">
          <v-icon>{{ mini ? 'mdi-chevron-right' : 'mdi-chevron-left' }}</v-icon>
        </v-btn>
      </div>
      <tree-menu
        v-for="(node, index) in nodes"
        :key="index"
        :label="node.label"
        :nodes="node.nodes"
        :nodeData="node.data"
        :depth="0"
        @label-clicked="showEdit($event)"
        type="object"
        :showMainChildren="true"
        :selectedItem="activeItem"
      ></tree-menu>
    </v-navigation-drawer>
    <!--<div class="divider" @mousedown="onColumnMouseDown($event)"></div>--->

    <div class="view">
      <component
        :key="updateKey"
        :is="activeView"
        :activeItem="activeItem"
        :title="title"
        :editOrCreate="editOrCreate"
        @createRoleOpen="createRoleOpen"
        @onCreateRole="onCreateRole"
        @table-row-dblclick="onEditRoleOpen"
        @onEditRole="onEditRole"
        @delete-role-click="onOpenDelete"
      />
    </div>
    <v-dialog v-model="deleteDialog" persistent max-width="290">
      <v-card>
        <v-card-text>Вы действительно хотите удалить запись?</v-card-text>
        <v-card-actions>
          <!-- <v-btn
                            color="red darken-1"
                            text
                            @click="onDeleteRole"
                        >Да
                        </v-btn>
                        <v-btn
                            color="green darken-1"
                            text
                            @click="deleteDialog = false"
                        >Нет
                        </v-btn> -->
          <button class="btn-save" @click="onDeleteRole">Да</button>

          <button class="btn-close" @click="deleteDialog = false">Нет</button>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="info" :color="infoColor" :timeout="10000">
      {{ infotext }}
    </v-snackbar>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';

import AdminRolesList from '@/components/AdminRolesList.vue';
import CreateAdminRole from '@/components/CreateAdminRole.vue';

export default {
  components: {
    AdminRolesList,
    CreateAdminRole,
  },
  data() {
    return {
      updateKey: 1,
      activeView: 'AdminRolesList',
      showNavigation: true,
      mini: false,
      loading: true,
      activeItem: null,
      info: false,
      infotext: '',
      infoColor: '',
      nodes: [],
      title: 'Добавление роли',
      editOrCreate: 'create',
      deleteDialog: false,
      deleteditem: null,
    };
  },
  computed: {
    ...mapGetters(['ADMIN_ROLE_ACCOUNT_MENU', 'ADMIN_TYPES_ROLE']),
  },
  methods: {
    ...mapActions([
      'getRoleAccountMenu',
      'getTypesRoles',
      'createRole',
      'updateRole',
      'deleteRole',
    ]),
    ...mapMutations([]),
    showEdit(row) {
      this.activeItem = row;
    },
    onGetRoles() {
      this.loading = true;
      this.getRoleAccountMenu()
        .then((res) => {
          const treeCreator = (tree) => {
            const result = [];
            for (let item of tree) {
              result.push({
                label: item.name,
                data: item,
                nodes: item.roleAccounts ? treeCreator(item.roleAccounts) : [],
              });
            }
            return result;
          };
          this.nodes = [
            {
              label: 'Роли',
              data: {
                id: 1,
                label: 'Роли',
                register: null,
              },
              nodes: treeCreator(res),
            },
          ];
          this.loading = false;
        })
        .catch((e) => {
          this.infoColor = 'error';
          this.infotext = e;
          this.info = true;
          this.loading = false;
        });
    },
    createRoleOpen() {
      this.loading = true;
      this.getTypesRoles()
        .then(() => {
          this.title = 'Добавление роли';
          this.activeView = 'CreateAdminRole';
          this.loading = false;
        })
        .catch((e) => {
          this.infoColor = 'error';
          this.infotext = e;
          this.info = true;
          this.loading = false;
        });
    },
    onCreateRole(item) {
      this.loading = true;
      this.createRole(item)
        .then(() => {
          this.onGetRoles();
          this.activeView = 'AdminRolesList';
          this.loading = false;
        })
        .catch((e) => {
          this.infoColor = 'error';
          this.infotext = e;
          this.info = true;
          this.loading = false;
        });
    },
    onEditRoleOpen(item) {
      this.loading = true;
      this.activeItem = item;
      if (this.ADMIN_TYPES_ROLE.length === 0) {
        this.getTypesRoles()
          .then(() => {
            this.editOrCreate = 'edit';
            this.title = `Ректирование роли:${item.name}`;
            this.activeView = 'CreateAdminRole';
            this.loading = false;
          })
          .catch((e) => {
            this.infoColor = 'error';
            this.infotext = e;
            this.info = true;
            this.loading = false;
          });
      } else {
        this.activeView = 'CreateAdminRole';
        this.loading = false;
        this.title = `Ректирование роли:${item.name}`;
      }
    },
    onEditRole(item) {
      this.loading = true;
      this.updateRole(item)
        .then(() => {
          this.onGetRoles();
          this.activeView = 'AdminRolesList';
          this.loading = false;
        })
        .catch((e) => {
          this.infoColor = 'error';
          this.infotext = e;
          this.info = true;
          this.loading = false;
        });
    },
    onDeleteRole() {
      this.loading = true;
      this.deleteRole(this.deleteditem)
        .then(() => {
          this.onGetRoles();
          this.activeView = 'AdminRolesList';
          this.loading = false;
          this.deleteDialog = false;
        })
        .catch((e) => {
          this.infoColor = 'error';
          this.infotext = e;
          this.info = true;
          this.loading = false;
          this.deleteDialog = false;
        });
    },
    onOpenDelete(item) {
      this.deleteditem = item;
      this.deleteDialog = true;
    },
  },
  mounted() {
    this.onGetRoles();
  },
};
</script>

<style lang="scss" scoped>
.tree {
  width: 20%;
  // border: 1px solid #000;
  overflow: auto;
  background-color: #0ab39c !important;
  color: #daf4f0;
  font-weight: 400;
  font-size: 0.875rem;
  height: calc(100vh - 70px) !important;
  padding-top: 15px;
}
.view {
  width: 100%;
  margin-left: 20px;
  // border: 1px solid #000;
}
.types {
  display: flex;
  justify-content: flex-start;
  // border: 1px solid #000;
  padding: 10px 5px 5px 0;
  height: calc(100% - 48px); // 100% - 48px, где 48px - высота табов
  border-radius: 0px !important;
  // background: #f3f3f8;
}

.divider {
  background-color: black;
  height: 100%;
  width: 3px;
  cursor: col-resize;
}

.navigation-top__conteiner {
  position: sticky;
  top: 0;
  z-index: 2;
}
</style>
