<template>
  <div class="types" ref="conteiner">
    <!-- Не вытаскивыть tree-menu из враппера, т.к компонент вызывает сам себя
        и конструкция сломается -->
    <v-navigation-drawer
      class="tree"
      v-model="showNavigation"
      :mini-variant.sync="mini"
      permanent
    >
      <div class="navigation-top__conteiner">
        <v-btn icon @click.stop="mini = !mini">
          <v-icon>{{ mini ? 'mdi-chevron-right' : 'mdi-chevron-left' }}</v-icon>
        </v-btn>
      </div>
      <tree-menu
        v-for="(node, index) in nodes"
        :key="index"
        :label="node.label"
        :nodes="node.nodes"
        :nodeData="node.data"
        :depth="0"
        @label-clicked="showEdit($event)"
        type="object"
        :showMainChildren="true"
        :selectedItem="activeItem"
      ></tree-menu>
    </v-navigation-drawer>
    <!--<div class="divider" @mousedown="onColumnMouseDown($event)"></div>--->

    <div class="view">
      <component
        :key="updateKey"
        :is="activeView"
        :nodes="nodes"
        :editData="editData"
        :creationData="creationData"
        :headers="headers"
        :title="title"
        :dictinaryManagedGroups="dictinaryManagedGroups"
        :activeTab="activeTab"
        @createObjectOpen="createObjectOpen"
        @create-Object="createObject"
        @close-create="closeCreate"
        @table-row-dblclick="showEdit"
        @delete-dictionary-click="onOpenDeleteDialog"
        @close-edit="closeEdit"
        @edit-item="onEditItem"
        @create-attribute-open="onOpenCreateAttribute"
        @close-create_attribute="onCloseCreateAttribute"
        @next-create_attribute="onNextCreateAttribute"
        @previous-create-attribute="onPreviousCreateAttribute"
        @create-attribute="onCreateAttribute"
        @edit-attribute="onEditAttribute"
        @on_get_Relational_Object_Attributes="onGetRelationalObjectAttributes"
        @delete-attribute="onDeleteAttribute"
        @get-Template-Representation="onGetTemplateRepresentation"
        @edit-attribute-open="onEditAttributeOpen"
        @set-Template-Representation="onSetTemplateRepresentation"
        @get-Template-Regulations="onGetTemplateRegulations"
        @create_Status_Meta_Attributes="onCreateStatusMetaAttributes"
        @get-Detailed-Form-View-Meta-Attrs="onGetDetailedFormViewMetaAttrs"
        @get-Interface-Designer="onGetInterfaceDesigner"
        @get-Detailed-Form-View-Xml-Desc-Response="
          onGetDetailedFormViewXmlDescResponse
        "
        @save-Interface="onSaveInterface"
        @error="errorHandler"
        :editDataAttribute="editDataAttribute"
        :attribyteWindowType="attribyteWindowType"
      />
    </div>
    <v-dialog v-model="deleteDialog" persistent max-width="290">
      <v-card>
        <v-card-text>Вы действительно хотите удалить запись?</v-card-text>
        <v-card-actions>
          <!-- <v-btn color="red darken-1" text @click="deleteObject">Да </v-btn> -->
          <button 
                        class="btn-save" 
                        @click="deleteObject"
                    >Да
                    </button>
          <!-- <v-btn color="green darken-1" text @click="deleteDialog = false"
            >Нет
          </v-btn> -->
          <button 
                        class="btn-close" 
                        @click="deleteDialog = false"
                    >Нет
                    </button>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="info" :color="infoColor" :timeout="10000">
      {{ infotext }}
    </v-snackbar>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import ListTableObjects from '@/components/ListTableObjects.vue';
import EditViewObjects from '@/components/EditViewObjects.vue';
import CreateViewObjects from '@/components/CreateViewObjects.vue';
import CreateViewAttributes from '@/components/CreateViewAttributes.vue';
import CreateViewAttributesSecond from '@/components/CreateViewAttributesSecond.vue';

export default {
  components: {
    ListTableObjects,
    EditViewObjects,
    CreateViewObjects,
    CreateViewAttributes,
    CreateViewAttributesSecond,
  },
  data() {
    return {
      updateKey: 1,
      editData: null,
      activeView: 'ListTableObjects',
      deleteDialog: false,
      deletedItem: null,
      info: false,
      infotext: '',
      infoColor: '',
      nodes: [],
      title: '',
      activeItem: null,
      creationData: null,
      dictinaryManagedGroups: [],
      editDataAttribute: null,
      activeTab: 0,
      headers: [
        { text: 'id', value: 'id' },
        { text: 'Наименование', value: 'name' },
        { text: 'Дата изменения', value: 'versionDate' },
        { text: 'Версия', value: 'version' },
        { text: 'Реестр', value: 'register' },
        { text: 'Действия', value: 'actions' },
      ],
      attribyteWindowType: 'create',
      objectWidth: 20,
      viewWidth: 80,
      point: null,
      showNavigation: true,
      mini: false,
      loading: true,
    };
  },
  computed: {
    ...mapGetters([
      'OBJECTS',
      'EDITED_OBJECT',
      'OBJECTS_TYPES_TEMPLATES',
      'OBJECTS_TEMPLATE_REGULATIONS',
      'STATUS_META_ATTRIBUTES',
    ]),
  },
  methods: {
    ...mapActions([
      'fetchObjects',
      'createClassificationNode',
      'getClassificationNode',
      'getTypesNodes',
      'getTypesTemplates',
      'deleteClassificationNode',
      'updateClassificationNode',
      'getObjectAttributes',
      'getTypesAttributes',
      'getTypesCopied',
      'getTypesGroupOperation',
      'getRegisterRelationParts',
      'createMetaAttribute',
      'getRelationalObjectAttributes',
      'fetchDictionaries',
      'deleteMetaAttribute',
      'getMetaAttributesObjectValue',
      'updateMetaAttribute',
      'getTemplateRepresentation',
      'setTemplateRepresentation',
      'getObjectTemplateRegulations',
      'getStatusMetaAttributes',
      'createStatusMetaAttributes',
      'updateStatusMetaAttributes',
      'getDetailedFormViewMetaAttrs',
      'getDetailedFormViewXmlDescResponse',
      'setDetailedFormViewXmlDesc',
    ]),
    getItems() {
      const requests = [
        this.fetchObjects(),
        this.getTypesNodes(),
        this.getTypesTemplates(),
        this.getTypesAttributes(),
        this.getTypesCopied(),
        this.getTypesGroupOperation(),
        this.getRegisterRelationParts(),
      ];
      Promise.all(requests)
        .then(() => {
          this.nodes = [
            {
              label: 'Наследование',
              data: {
                id: 1,
                label: 'Наследование',
                changeDate: '11.12.1314',
                version: 1,
                register: null,
              },
              nodes: this.OBJECTS,
            },
          ];
          this.loading = false;
        })
        .catch((e) => {
          this.infoColor = 'error';
          this.infotext = e;
          this.info = true;
          this.loading = false;
        });
    },
    closeEdit() {
      this.activeTab = 0;
      this.editData = null;
      this.activeView = 'ListTableObjects';
    },
    createObjectOpen() {
      this.creationData = {
        id: '0',
        version: '0',
        versionDate: '',
        creationDate: null,
        parentId: '1',
        name: '',
        modelClassesType: null,
        hasChilds: 'false',
        level: '0',
        hasMembers: 'false',
        registerName: null,
        code: null,
        journalName: null,
        nodeType: '',
        templateName: null,
        typeTemplate: null,
      };
      this.title = 'Добавление объекта';
      this.activeView = 'CreateViewObjects';
      this.updateKey++;
    },
    createObject(data) {
      this.loading = true;
      data.versionDate = new Date(Date.now()).toISOString();
      if (data.nodeType === 'OBJECT_TEMPLATE') {
        data.templateName = data.name;
        if (data.typeTemplate && data.typeTemplate !== null) {
          data.typeTemplate = this.OBJECTS_TYPES_TEMPLATES.find(
            (el) => el.templateType === data.typeTemplate
          );
        }
      }
      this.createClassificationNode(data)
        .then(() => {
          this.activeView = 'ListTableObjects';
          this.getItems();
          this.updateKey++;
          this.loading = false;
        })
        .catch((e) => {
          this.infoColor = 'error';
          this.infotext = e;
          this.info = true;
          this.loading = false;
        });
    },
    showEdit(node) {
        this.activeItem = node;
      this.loading = true;
      this.getClassificationNode(node)
        .then(() => {
          return this.getObjectAttributes(node);
        })
        .then(() => {
          this.title = 'Редактирование объекта';
          this.editData = this.EDITED_OBJECT;
          this.updateKey++;
          this.activeView = 'EditViewObjects';
          this.loading = false;
          console.log(this.title)
        })
        .catch((e) => {
          this.infoColor = 'error';
          this.infotext = e;
          this.info = true;
          this.loading = false;
        });
    },
    onOpenDeleteDialog(item) {
      this.deletedItem = item;
      this.deleteDialog = true;
    },
    deleteObject() {
      this.loading = true;
      this.deleteDialog = false;
      this.deleteClassificationNode(this.deletedItem)
        .then(() => {
          this.deletedItem = null;
          this.getItems();
          this.updateKey++;
          this.loading = false;
        })
        .catch((e) => {
          this.deletedItem = null;
          this.infoColor = 'error';
          this.infotext = e;
          this.info = true;
          this.loading = false;
        });
    },
    onEditItem(item) {
      this.loading = true;
      if (item.nodeType === 'OBJECT_TEMPLATE') {
        item.templateName = item.name;
      }
      this.updateClassificationNode(item)
        .then(() => {
          this.updateKey++;
          this.activeView = 'ListTableObjects';
          this.editData = null;
          this.infoColor = 'success';
          this.infotext = 'Изменения сохранены';
          this.info = true;
          this.loading = false;
        })
        .catch((e) => {
          this.infoColor = 'error';
          this.infotext = e;
          this.info = true;
          this.loading = false;
        });
    },
    closeCreate() {
      this.updateKey++;
      this.activeView = 'ListTableObjects';
    },
    onOpenCreateAttribute() {
      this.updateKey++;
      this.editDataAttribute = {
        metaAttributeObjectType: {
          id: '0',
          version: '0',
          versionDate: null,
          creationDate: null,
          parentId: '0',
          name: '',
          modelClassesType: null,
          hasChilds: false,
          level: '0',
          hasMembers: false,
          registerName: null,
          attributeId: '0',
          code: '',
          order: '0',
          attributeType: null,
          dataType: null,
          elementUIType: null,
          mandatory: false,
          immutable: false,
          multiple: false,
          fixedSize: false,
          maxSize: 0,
          primaryKey: false,
          search: false,
          sort: false,
          copied: 'NONE',
          createLinkedCopy: false,
          groupOperation: 'NONE',
          checkAccess: false,
          description: '',
        },
        templateId: this.editData.id,
        relation: null,
        value: {
          id: '0',
          dataType: null,
          valueStatus: null,
          dataList: [],
        },
      };
      this.attribyteWindowType = 'create';
      this.activeView = 'CreateViewAttributes';
    },
    onCloseCreateAttribute() {
      this.updateKey++;
      this.activeTab = 1;
      this.activeView = 'EditViewObjects';
    },
    onNextCreateAttribute() {
      if (
        this.editDataAttribute.metaAttributeObjectType.attributeType ===
          'RELATION_DICTIONARY' ||
        this.editDataAttribute.metaAttributeObjectType.attributeType ===
          'USER_ORG_ACCOUNT_USER_ACCOUNT' ||
        this.editDataAttribute.metaAttributeObjectType.attributeType ===
          'ORGANIZATION_BIR_ADDRESS_LEGAL'
      ) {
        this.loading = true;
        this.fetchDictionaries()
          .then(() => {
            this.updateKey++;
            this.activeView = 'CreateViewAttributesSecond';
            this.loading = false;
          })
          .catch((e) => {
            this.infoColor = 'error';
            this.infotext = e;
            this.info = true;
            this.loading = false;
          });
      } else {
        this.updateKey++;
        this.activeView = 'CreateViewAttributesSecond';
      }
    },
    onPreviousCreateAttribute() {
      this.updateKey++;
      this.activeView = 'CreateViewAttributes';
    },
    onCreateAttribute() {
      this.loading = true;
      this.updateKey++;
      this.activeTab = 1;
      this.activeView = 'EditViewObjects';
      this.createMetaAttribute(this.editDataAttribute)
        .then(() => {
          return this.getObjectAttributes(this.editData);
        })
        .then(() => {
          this.updateKey++;
          this.activeTab = 1;
          this.activeView = 'EditViewObjects';
          this.loading = false;
        })
        .catch((e) => {
          this.infoColor = 'error';
          this.infotext = e;
          this.info = true;
          this.loading = false;
        });
    },
    onEditAttribute() {
      this.loading = true;
      this.updateKey++;
      this.activeTab = 1;
      this.activeView = 'EditViewObjects';
      this.updateMetaAttribute(this.editDataAttribute)
        .then(() => {
          return this.getObjectAttributes(this.editData);
        })
        .then(() => {
          this.updateKey++;
          this.activeTab = 1;
          this.activeView = 'EditViewObjects';
          this.loading = false;
        })
        .catch((e) => {
          this.infoColor = 'error';
          this.infotext = e;
          this.info = true;
          this.loading = false;
        });
    },
    onGetRelationalObjectAttributes(item) {
      this.loading = true;
      this.getRelationalObjectAttributes(item)
        .then(() => {
          this.loading = false;
        })
        .catch((e) => {
          this.infoColor = 'error';
          this.infotext = e;
          this.info = true;
          this.loading = false;
        });
    },
    onDeleteAttribute(item) {
      this.loading = true;
      this.deleteMetaAttribute(item)
        .then(() => {
          this.loading = false;
        })
        .catch((e) => {
          this.infoColor = 'error';
          this.infotext = e;
          this.info = true;
          this.loading = false;
        });
    },
    onEditAttributeOpen(item) {
      this.loading = true;
      item.objectId = this.editData.id;
      this.getMetaAttributesObjectValue(item)
        .then((res) => {
          this.updateKey++;
          this.editDataAttribute = res;
          this.attribyteWindowType = 'edit';
          this.activeView = 'CreateViewAttributes';
          this.loading = false;
        })
        .catch((e) => {
          this.infoColor = 'error';
          this.infotext = e;
          this.info = true;
          this.loading = false;
        });
    },
    onGetTemplateRepresentation(item) {
      this.loading = true;
      this.getTemplateRepresentation(item)
        .then(() => {
          this.loading = false;
        })
        .catch((e) => {
          this.infoColor = 'error';
          this.infotext = e;
          this.info = true;
          this.loading = false;
        });
    },
    onSetTemplateRepresentation(item) {
      this.loading = true;
      this.setTemplateRepresentation(item)
        .then(() => {
          this.infoColor = 'success';
          this.infotext = 'Изменения сохранены';
          this.info = true;
          this.loading = false;
        })
        .catch((e) => {
          this.infoColor = 'error';
          this.infotext = e;
          this.info = true;
          this.loading = false;
        });
    },
    onGetTemplateRegulations() {
      this.loading = true;
      this.getObjectTemplateRegulations(this.editData)
        .then(() => {
          this.loading = false;
        })
        .catch((e) => {
          this.infoColor = 'error';
          this.infotext = e;
          this.info = true;
          this.loading = false;
        });
    },
    onGetDetailedFormViewMetaAttrs() {
      this.loading = true;
      this.getDetailedFormViewMetaAttrs(this.editData)
        .then(() => {
          this.loading = false;
        })
        .catch((e) => {
          this.infoColor = 'error';
          this.infotext = e;
          this.info = true;
          this.loading = false;
        });
    },
    onGetDetailedFormViewXmlDescResponse() {
      this.loading = true;
      this.getDetailedFormViewXmlDescResponse(this.editData)
        .then(() => {
          this.loading = false;
        })
        .catch((e) => {
          this.infoColor = 'error';
          this.infotext = e;
          this.info = true;
          this.loading = false;
        });
    },
    onCreateStatusMetaAttributes() {
      this.loading = true;
      if (this.STATUS_META_ATTRIBUTES.id !== '0') {
        this.updateStatusMetaAttributes(this.STATUS_META_ATTRIBUTES)
          .then(() => {
            return this.getObjectTemplateRegulations(this.editData);
          })
          .then(() => {
            this.loading = false;
          })
          .catch((e) => {
            this.infoColor = 'error';
            this.infotext = e;
            this.info = true;
            this.loading = false;
          });
      } else {
        this.createStatusMetaAttributes(this.STATUS_META_ATTRIBUTES)
          .then(() => {
            return this.getObjectTemplateRegulations(this.editData);
          })
          .then(() => {
            this.loading = false;
          })
          .catch((e) => {
            this.infoColor = 'error';
            this.infotext = e;
            this.info = true;
            this.loading = false;
          });
      }
    },
    onSaveInterface() {
      this.loading = true;
      console.log(this.editData)
      this.setDetailedFormViewXmlDesc(this.editData)
        .then(() => {
          alert('Данные успешно сохранены');
          this.loading = false;
        })
        .catch((e) => {
          this.infoColor = 'error';
          this.infotext = e;
          this.info = true;
          this.loading = false;
        });
    },
    errorHandler(e) {
      this.infoColor = 'error';
      this.infotext = e;
      this.info = true;
    },
    onColumnMouseDown(event) {
      event.preventDefault();
      this.point = event.clientX - 18;
      document.onmousemove = this.elementDrag;
      document.onmouseup = this.closeDragElement;
    },
    elementDrag(e) {
      e = e || window.event;
      e.preventDefault();
      const conteinerWidth = this.$refs.conteiner.clientWidth;
      const leftWidth =
        this.objectWidth * (conteinerWidth / 100) +
        (e.clientX - 18 - this.point);
      this.point = e.clientX - 18;
      const rightWidth = conteinerWidth - leftWidth;
      const objectWidth = (leftWidth / conteinerWidth) * 100;
      const viewWidth = (rightWidth / conteinerWidth) * 100;
      if (objectWidth < 5 || viewWidth < 5) {
        return;
      }
      this.objectWidth = objectWidth;
      this.viewWidth = viewWidth;
    },
    closeDragElement() {
      document.onmouseup = null;
      document.onmousemove = null;
    },
    onGetInterfaceDesigner() {
      this.loading = true;
      this.getObjectTemplateRegulations(this.editData)
        .then(() => {
          return this.getDetailedFormViewMetaAttrs(this.editData);
        })
        .then(() => {
          return this.getDetailedFormViewXmlDescResponse(this.editData);
        })
        .then(() => {
          this.loading = false;
        })
        .catch((e) => {
          console.log(e);
          this.infoColor = 'error';
          this.infotext = e;
          this.info = true;
          this.loading = false;
        });
    },
  },
  mounted() {
    this.getItems();
    /*
        this.fetchObjects().then(()=>{
            this.nodes = [
                {
                    label: 'Наследование',
                    data: {
                        id: 1,
                        label: 'Наследование',
                        changeDate: "11.12.1314",
                        version: 1,
                        register: null
                    },
                    nodes: this.OBJECTS,
                },
            ]
        }).catch((e) => {
            this.infoColor="error"
            this.infotext= e;
            this.info=true;
        })

        this.getTypesNodes().catch((e) => {
            this.infoColor="error"
            this.infotext= e;
            this.info=true;
        })

        this.getTypesTemplates().catch((e) => {
            this.infoColor="error"
            this.infotext= e;
            this.info=true;
        })

        this.getTypesAttributes().catch((e) => {
            this.infoColor="error"
            this.infotext= e;
            this.info=true;
        })

        this.getTypesCopied().catch((e) => {
            this.infoColor="error"
            this.infotext= e;
            this.info=true;
        })

        this.getTypesGroupOperation().catch((e) => {
            this.infoColor="error"
            this.infotext= e;
            this.info=true;
        })

        this.getRegisterRelationParts().catch((e) => {
            this.infoColor="error"
            this.infotext= e;
            this.info=true;
        })
        */
  },
};
</script>

<style lang="scss" scoped>
    .tree {
      width:20%;
        // border: 1px solid #000;
        overflow: auto;
        background-color: #0ab39c!important;
        color: #daf4f0;
        font-weight: 400;
        font-size: 0.875rem;
        height: calc(100vh - 70px)!important;
        padding-top: 15px
    }
    .view {
        width: 100%;
        // border: 1px solid #000;
        padding: 4px 8px;
    }
    .types {
        display: flex;
        justify-content: flex-start;
        // border: 1px solid #000;
        padding: 10px 5px 5px 0;
        height: calc(100% - 48px); // 100% - 48px, где 48px - высота табов
        border-radius: 0px !important;
    }

    .divider {
        background-color: black;
        height: 100%;
        width: 3px;
        cursor: col-resize;
    }

.navigation-top__conteiner {
  position: sticky;
  top: 0;
  z-index: 2;
}
</style>
