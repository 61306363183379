<template>
    <div
        class="reports"
    >
        <div class="table-conteiner">
        <v-data-table
            class="list_table"
            :headers="headers"
            :items="REPORTS_LIST"
            :items-per-page="10000"
            hide-default-footer
            dense
            @dblclick:row="rowClick"
        >

            <template v-slot:item.actions="{ item }">
            <div>
                <!-- <img
                    style="cursor: pointer; margin-right: 10px;"
                    :src="require('../assets/edit.png')"
                    alt="delete"
                    @click="showEditForm(item)"
                />
                <img
                    style="cursor: pointer"
                    :src="require('../assets/trash.png')"
                    alt="delete"
                    @click="showDeleteForm(item)"
                /> -->
                <i class="ri ri-pencil-fill" @click="showEditForm(item)"></i>
                <i class="ri ri-delete-bin-fill" @click="showDeleteForm(item)"></i>
            </div>
            </template>
        </v-data-table>
        </div>
        <div class="buttons_conteiner">
            <button class="buttons_conteiner__button" @click="createDialog = true">Добавить</button>
        </div>
            <v-dialog
                v-model="createDialog"
                persistent
                max-width="1200"
            >
            <v-card>

                <v-card-title>Отчет</v-card-title>
                <div class="report-fields">
                    <div v-for="(value, key) in createdItem" :key="key" class="report-field" :class="key !== 'templateRegulations' && labels[key] ? 'text_input' : ''">
                        <label class="report-field__label">{{labels[key]}}</label>
                        <v-select 
                            v-model="createdItem[key]" 
                            :items="REPORTS_SOURCES"
                            item-text="label"
                            item-value="data"
                            v-if="key === 'reportSource'"></v-select>
                        <v-select 
                            v-model="createdItem[key]"
                            @change="onChangeTemplate"
                            :items="REPORTS_TEMPLATES"
                            item-text="label"
                            item-value="data"
                            v-else-if="key === 'reportTemplate'"></v-select>
                        <div v-else-if="key === 'templateRegulations'" class="report-fields__checkbox__container">
                            <div class="report-field__forAll">
                                <p class="report-field__forAll__title">в статусах:</p>
                                <v-checkbox
                                    v-model="forAll"
                                    @change="onChangeforAll"
                                    class="report-fields__checkbox"
                                    label="во всех"
                                ></v-checkbox>
                            </div>
                            <v-checkbox
                                v-for="item in REPORTS_TEMPLATES_REGULATIONS" :key="item.name"
                                v-model="item.linked"
                                :label="item.name"
                                class="report-fields__checkbox"
                            ></v-checkbox>
                        </div>
                        <input v-else-if="key === 'urlOuterSystem' || key === 'nameReport'" type="text" v-model="createdItem[key]" class="report-field__input">
                    </div>
                    <div class="report-fields__parameters__conteiner">
                        <p class="report-fields__parameters__label">Параметры отчета:</p>
                        <button class="report-fields__parameters__button" @click="parameterDialog=true">Добавить параметр</button>
                    </div>
                    <div class="report-fields__parameters__teble__conteiner">
                        <v-data-table
                            class="report-fields__parameters__teble"
                            :headers="parametersHeaders"
                            :items="REPORTS_PARAMETERS_PROXIES"
                            :items-per-page="10000"
                            hide-default-footer
                            dense
                        >
                            <template v-slot:item.actions="{ item }">
                                <div>
                                    <!-- <img
                                        style="cursor: pointer; margin-right: 10px;"
                                        :src="require('../assets/edit.png')"
                                        alt="delete"
                                        @click="showEditFormParameter(item)"
                                    />
                                    <img
                                        style="cursor: pointer"
                                        :src="require('../assets/trash.png')"
                                        alt="delete"
                                        @click="showDeleteParameterDialog(item)"
                                    /> -->
                                    <i class="ri ri-pencil-fill" @click="showEditFormParameter(item)"></i>
                                    <i class="ri ri-delete-bin-fill" @click="showDeleteParameterDialog(item)"></i>
                                </div>
                            </template>
                            <template v-slot:item.defaultValue="{ item }">
                                <span v-if="item.paramType === 'DICTIONARY'">{{item.templateName}}->{{item.instanceName}}</span>
                                <span v-if="item.paramType === 'INPUT'">{{item.defaultValue}}</span>
                                <span v-if="item.paramType === 'CALENDAR'">{{new Date(item.defaultValue).toLocaleDateString()}}</span>
                            </template>    
                        </v-data-table>
                    </div>
                </div>
                <v-card-actions>
                    <!-- <v-btn
                        v-if="windowType === 'create'"
                        color="green darken-1"
                        text
                        @click="onCreateReport"
                    >Сохранить
                    </v-btn> -->
                    <button
                        v-if="windowType === 'create'"
                        @click="onCreateReport"
                        class="btn-save"
                    >Сохранить</button>
                    <!-- <v-btn
                        v-if="windowType === 'edit'"
                        color="green darken-1"
                        text
                        @click="onEditReport"
                    >Сохранить
                    </v-btn> -->
                    <button
                        v-if="windowType === 'edit'"
                        @click="onEditReport"
                        class="btn-save"
                    >Сохранить</button>
                    <!-- <v-btn
                        color="red darken-1"
                        text
                        @click="createDialog = false"
                    >Закрыть
                    </v-btn> -->
                    <button 
                        class="btn-close" 
                        @click="createDialog = false"
                    >Закрыть
                    </button>
                </v-card-actions>
            </v-card>
            </v-dialog>
            <v-dialog
                v-model="deleteDialog"
                persistent
                max-width="290"
            >
            <v-card>

                <v-card-text>Вы действительно хотите удалить запись?</v-card-text>
                <v-card-actions>
                    <!-- <v-btn
                        color="red darken-1"
                        text
                        @click="onDeleteReport"
                    >Да
                    </v-btn> -->
                    <button 
                        class="btn-save" 
                        @click="onDeleteReport"
                    >Да
                    </button>
                    <!-- <v-btn
                        color="green darken-1"
                        text
                        @click="deleteDialog = false"
                    >Нет
                    </v-btn> -->
                    <button 
                        class="btn-close" 
                        @click="deleteDialog = false"
                    >Нет
                    </button>
                </v-card-actions>
            </v-card>
            </v-dialog>
            <v-dialog
                v-model="deleteParameterDialog"
                persistent
                max-width="290"
            >
            <v-card>
                <v-card-text>Вы действительно хотите удалить запись?</v-card-text>
                <v-card-actions>
                    <!-- <v-btn
                        color="red darken-1"
                        text
                        @click="onDeleteParameter"
                    >Да
                    </v-btn> -->
                    <button 
                        class="btn-save" 
                        @click="onDeleteParameter"
                    >Да
                    </button>
                    <!-- <v-btn
                        color="green darken-1"
                        text
                        @click="deleteParameterDialog = false"
                    >Нет
                    </v-btn> -->
                    <button 
                        class="btn-close" 
                        @click="deleteParameterDialog = false"
                    >Нет
                    </button>
                </v-card-actions>
            </v-card>
            </v-dialog>
            <v-dialog
                v-model="parameterDialog"
                persistent
                max-width="800"
            >
                <v-card>
                    <v-card-title>Параметр</v-card-title>
                    <div class="report-fields">
                        <div class="report-field">
                            <label class="report-field__label" for="parameter_name">Наименование параметра:</label>
                            <input type="text" v-model="createdParameter.name" class="report-field__input" id="parameter_name">
                        </div>
                        <div class="report-field">
                            <label class="report-field__label" for="parameter_label">Техническое наименование параметра:</label>
                            <input type="text" v-model="createdParameter.label" class="report-field__input" id="parameter_label">
                        </div>
                        <div class="report-field">
                            <label class="report-field__label">Тип параметра:</label>
                                <v-radio-group
                                    v-model="createdParameter.paramType"
                                    row
                                    >
                                    <v-radio
                                        label="Справочник"
                                        value="DICTIONARY"
                                    ></v-radio>
                                    <v-radio
                                        label="Поле ввода"
                                        value="INPUT"
                                    ></v-radio>
                                    <v-radio
                                        label="Календарь"
                                        value="CALENDAR"
                                    ></v-radio>
                                </v-radio-group>
                        </div>
                        <div class="report-field" v-if="createdParameter.paramType === 'DICTIONARY'">
                            <label class="report-field__label" for="parameter_dictionary">Справочник:</label>
                            <v-select 
                            v-model="createdParameter.templateName"
                            :items="dictionaries"
                            item-text="name"
                            item-value="id"
                            id="parameter_dictionary"
                            ></v-select>
                        </div>
                        <div class="report-field" v-if="createdParameter.paramType === 'DICTIONARY' || createdParameter.paramType === 'INPUT'">
                            <label class="report-field__label" >Мультивыбор:</label>
                            <div class="report-field__delimiter">
                                <v-checkbox
                                    v-model="createdParameter.multiValue"
                                ></v-checkbox>
                                <label class="" for="parameter_delimiter">Разделитель:</label>
                                <input type="text" v-model="createdParameter.delimiter" class="report-field__delimiter__field" id="parameter_delimiter">
                            </div>
                        </div>
                        <div class="report-field">
                            <label class="report-field__label" for="parameter_defaultValue">Значение по умолчанию:</label>
                            <input type="text" v-model="createdParameter.defaultValue" class="report-field__input" id="parameter_defaultValue" :disabled="createdParameter.paramType === 'DICTIONARY'" v-if="createdParameter.paramType !== 'CALENDAR'"> 
                            <button class="report-field__default__button" @click="openDefaultDialog" v-if="createdParameter.paramType === 'DICTIONARY'">Выбрать</button>
                                <v-menu
                                    v-if="createdParameter.paramType === 'CALENDAR'"
                                    ref="datePicker"
                                    v-model="datePicker"
                                    :close-on-content-click="false"
                                    :return-value.sync="createdParameter.defaultValue"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="290px"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                    <v-combobox
                                        v-model="createdParameter.defaultValue"
                                        chips
                                        small-chips
                                        prepend-icon="mdi-calendar"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                    ></v-combobox>
                                    </template>
                                    <v-date-picker
                                    v-model="createdParameter.defaultValue"
                                    no-title
                                    scrollable
                                    >
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        text
                                        color="primary"
                                        @click="datePicker = false"
                                    >
                                        Cancel
                                    </v-btn>
                                    <v-btn
                                        text
                                        color="primary"
                                        @click="$refs.datePicker.save(createdParameter.defaultValue)"
                                    >
                                        OK
                                    </v-btn>
                                    </v-date-picker>
                                </v-menu>
                        </div>    
                    </div>
                    <v-card-actions>
                        <button
                            v-if="createdParameter.id === '0'"
                            @click="onCreateParameter"
                            class="btn-save"
                        >Сохранить</button>
                        <!-- <v-btn
                            v-if="createdParameter.id === '0'"
                            color="green darken-1"
                            text
                            @click="onCreateParameter"
                        >Сохранить
                        </v-btn> -->
                        
                        <!-- <v-btn
                            v-if="createdParameter.id !== '0'"
                            color="green darken-1"
                            text
                            @click="onEditParameter"
                        >Сохранить
                        </v-btn> -->
                        <button
                            v-if="createdParameter.id !== '0'"
                            @click="onEditParameter"
                            class="btn-save"
                        >Сохранить</button>
                        <!-- <v-btn
                            color="red darken-1"
                            text
                            @click="parameterDialog = false"
                        >Закрыть
                        </v-btn> -->
                        <button 
                            class="btn-close" 
                            @click="parameterDialog = false"
                        >Закрыть
                        </button>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog
                v-model="defaultDialog"
                persistent
                max-width="800"
            >
            <v-card>
                <v-card-title>{{ dictionaryTitle }}</v-card-title>
                <div>
                    <v-data-table
                        class="report-fields__parameters__teble"
                        :headers="dictionaryItemsHeaders"
                        :items="DICTIONARY_ITEMS"
                        :items-per-page="10000"
                        hide-default-footer
                        dense
                        @click:row="onChangeDefaultParameter"
                    > 
                    </v-data-table>
                </div>
                <v-card-actions>
                    <!-- <v-btn
                        color="green darken-1"
                        text
                        @click="defaultDialog = false"
                    >Закрыть
                    </v-btn> -->
                    <button 
                        class="btn-close" 
                        @click="defaultDialog = false"
                    >Закрыть
                    </button>
                </v-card-actions>
            </v-card>
            </v-dialog>
            <v-snackbar
                v-model="info"
                :color="infoColor"
                :timeout="10000"
            >
                {{infotext}}
            </v-snackbar>
            <v-overlay :value="loading">
                <v-progress-circular
                    indeterminate
                    size="64"
                ></v-progress-circular>
            </v-overlay>
    </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
export default {
    components: {

    },
    data() {
        return {
            headers: [
                {text: 'ID', value: 'id'}, 
                {text: 'Наименование', value: 'nameReport'},
                {text: 'URL внешней системы', value: 'urlOuterSystem'},
                {text: 'Действия', value: 'actions'},
            ],
            parametersHeaders: [
                {text: 'Наименование', value: 'name'},
                {text: 'Техническое наименование', value: 'label'},
                {text: 'Значение по умолчанию', value: 'defaultValue'},
                {text: 'Мультивыбор', value: 'multiValue'},
                {text: 'Разделитель', value: 'delimiter'},
                {text: 'Действия', value: 'actions'},
            ],
            createDialog: false,
            deleteDialog: false,
            parameterDialog: false,
            defaultDialog: false,
            datePicker: false,
            deleteParameterDialog: false,
            deletedParameter: null,
            deletedItem: null,
            info: false,
            infotext: '',
            infoColor: '',
            windowType: 'create',
            forAll: false,
            dictionaries: [],
            dictionaryItemsHeaders: [],
            dictionaryTitle: '',
            labels: {
                nameReport: 'Наименование отчета',
                urlOuterSystem: 'URL внешней системы',
                reportSource: 'Источник',
                reportTemplate: 'Привязать к шаблону',
                templateRegulations: '',
            },
            createdItem: {
                nameReport: '',
                urlOuterSystem: '',
                reportSource: null,
                reportTemplate: null,
                templateRegulations: '',
            },
            createdParameter: {
                id: '0',
                name: '',
                label: '',
                paramType: 'DICTIONARY',
                multiValue: false,
                defaultValue: '',
                delimiter: '',
                templateName : '',
                instanceName: '',
            },
            loading: true,
        }
    },
    computed: {
        ...mapGetters([
            "REPORTS_LIST", 
            "REPORTS_SOURCES", 
            "REPORTS_TEMPLATES", 
            "REPORTS_TEMPLATES_REGULATIONS",
            "REPORTS_PARAMETERS_PROXIES",
            "DICTIONARY_LIST",
            "DICTIONARY_ITEMS",
            ]),
    },
    methods: {
        ...mapActions([
            "fetchReports", 
            "fetchReportsSources", 
            "fetchReportsTemplates", 
            "createReport", 
            "deleteReport", 
            "getTemplateRegulations",
            "getReport",
            "editItem",
            "fetchDictionaries",
            "getParametersProxiesByReportId",
            "createReportParameter",
            "deleteReportParameter",
            "editReportParameter",
            "fetchDictionaryItemsHeaders",
            "fetchDictionaryItems",
            ]),
        ...mapMutations([
            "SET_REPORTS_TEMPLATES_REGULATIONS_ALL_TRUE",
            "SET_REPORTS_TEMPLATES_REGULATIONS_ALL_FALSE",
        ]),
        closeEdit() {
            this.editData = null;
            this.activeView = 'ListTable';
        },
        showEdit(node) {
            this.editData = node;
            this.activeView = 'EditView';
        },
        rowClick(e, row) {
            this.showEditForm(row.item)
        },
        onCreateReport() {
            const data = {...this.createdItem}
            if (data.reportSource === null) return
            if (data.reportTemplate !== null) {
                data.reportTemplate.reportStatuses = [...this.REPORTS_TEMPLATES_REGULATIONS]
            }
            delete data.templateRegulations
            this.loading = true;
            this.createReport(data).then(() => {
                this.createDialog = false;
                this.forAll = false;
                this.createdItem = {
                    nameReport: '',
                    urlOuterSystem: '',
                    reportSource: null,
                    reportTemplate: null,
                    templateRegulations: '',
                }
                this.loading = false;
            }).catch((e) => {
                this.createDialog = false;
                this.infoColor="error"
                this.infotext= e;
                this.info=true;
                this.loading = false;
            })
        },
        showDeleteForm(item) {
            this.deleteDialog = true;
            this.deletedItem = item;
        },
        onDeleteReport() {
            this.loading = true;
            this.deleteReport(this.deletedItem).then(()=> {
                this.deleteDialog = false;
                this.deletedItem = null;
                this.loading = false;
            }).catch((e) => {
                this.deleteDialog = false;
                this.infoColor="error"
                this.infotext= e;
                this.info=true;
                this.loading = false;
            })
        },
        onChangeTemplate(val) {
            this.loading = true;
            this.getTemplateRegulations(val).then(()=> {
                //this.createdItem.reportTemplate = val
                this.loading = false;
            }).catch((e) => {
                this.deleteDialog = false;
                this.infoColor="error"
                this.infotext= e;
                this.info=true;
                this.loading = false;
            })
        },
        showEditForm(item) {
            this.loading = true;
            this.getReport(item).then((res)=> {

                this.createdItem = {...res};
                if (!res.reportTemplate.id) {
                    return
                } else {
                    return this.getTemplateRegulations(res.reportTemplate)
                }
            }).then(()=> {
                return this.getParametersProxiesByReportId(item)
            }).then(()=> {
                this.forAll = false;
                this.windowType = 'edit';
                this.createDialog = true;
                this.loading = false;
            }).catch((e) => {
                this.deleteDialog = false;
                this.infoColor="error"
                this.infotext= e;
                this.info=true;
                this.loading = false;
            })
        },
        onEditReport() {
            const data = {...this.createdItem}
            delete data.templateRegulations
            delete data.reportStatuses
            if (!data.reportTemplate.id) {
                delete data.reportTemplate
            } else {
                data.reportTemplate.reportStatuses = [...this.REPORTS_TEMPLATES_REGULATIONS]
            }
            this.loading = true;
            this.editItem(data).then(() => {
                this.createDialog = false;
                this.forAll = false;
                this.createdItem = {
                    nameReport: '',
                    urlOuterSystem: '',
                    reportSource: null,
                    reportTemplate: null,
                    templateRegulations: '',
                }
                this.loading = false;
            }).catch((e) => {
                this.createDialog = false;
                this.infoColor="error"
                this.infotext= e;
                this.info=true;
                this.loading = false;
            })
        },
        onChangeforAll(val) {
            if (val) {
                this.SET_REPORTS_TEMPLATES_REGULATIONS_ALL_TRUE()
            } else {
                this.SET_REPORTS_TEMPLATES_REGULATIONS_ALL_FALSE()
            }
        },
        onCreateParameter() {
            const data = {...this.createdParameter}
            const defaultValue = data.defaultValue
            const dictionaryParams = {}
            if (!data.name || !data.label || !data.defaultValue) return
            if (data.paramType === 'CALENDAR') {
                data.defaultValue = `${data.defaultValue}T21:00:00-00:00`
            }
            if (data.paramType === 'DICTIONARY') {
                data.defaultValue = `${data.templateName}:${data.instanceName}`
                dictionaryParams.templateName = this.dictionaries.find((dict)=>dict.id ===  data.templateName).name
                dictionaryParams.instanceName = defaultValue
            }
            this.loading = true;
            this.createReportParameter({
                newParameter: data, 
                reportId: this.createdItem.id,
                ...dictionaryParams}).then(() => {
                this.parameterDialog = false;
                this.createdParameter = {
                    id: '0',
                    name: '',
                    label: '',
                    paramType: 'DICTIONARY',
                    multiValue: false,
                    defaultValue: '',
                    delimiter: '',
                    templateName : '',
                    instanceName: '',
                }
                this.loading = false;
            }).catch((e) => {
                this.parameterDialog = false;
                this.infoColor="error"
                this.infotext= e;
                this.info=true;
                this.loading = false;
            })
        },
        showDeleteParameterDialog(item) {

            this.deleteParameterDialog = true;
            this.deletedParameter = item;
        },
        onDeleteParameter() {
            this.loading = false;
            this.deleteReportParameter({reportId: this.createdItem.id, reportParameterId: this.deletedParameter.id}).then(()=> {
                this.deleteParameterDialog = false;
                this.deletedParameter = null;
                this.loading = false;
            }).catch((e) => {
                this.deleteParameterDialog = false;
                this.infoColor="error"
                this.infotext= e;
                this.info=true;
                this.loading = false;
            })
        },
        showEditFormParameter(item) {

            this.createdParameter = {...item};
            if (this.createdParameter.paramType === 'DICTIONARY') {

                if (this.createdParameter.defaultValue.split(':')[1]) {
                    this.createdParameter.templateName = this.createdParameter.defaultValue.split(':')[0]
                    this.createdParameter.defaultValue = this.createdParameter.instanceName
                    this.createdParameter.instanceName = ''
                }
            }

            this.parameterDialog = true;
        },
        onEditParameter() {
            const data = {...this.createdParameter}
            const defaultValue = data.defaultValue
            const dictionaryParams = {}
            if (!data.name || !data.label || !data.defaultValue) return
            if (data.paramType === 'CALENDAR') {
                data.defaultValue = `${data.defaultValue}T21:00:00-00:00`
            }
            if (data.paramType === 'DICTIONARY') {
                data.defaultValue = `${data.templateName}:${data.instanceName}`
                dictionaryParams.templateName = this.dictionaries.find((dict)=>dict.id ===  data.templateName).name
                dictionaryParams.instanceName = defaultValue
            }
            this.loading = true;
            this.editReportParameter({
                newParameter: data, 
                reportId: this.createdItem.id, ...dictionaryParams}).then(() => {
                this.parameterDialog = false;
                this.createdParameter = {
                    id: '0',
                    name: '',
                    label: '',
                    paramType: 'DICTIONARY',
                    multiValue: false,
                    defaultValue: '',
                    delimiter: '',
                    templateName : '',
                    instanceName: '',
                }
            }).then(()=>{
                this.loading = false;
            }).catch((e) => {
                this.parameterDialog = false;
                this.infoColor="error"
                this.infotext= e;
                this.info=true;
                this.loading = false;
            })
        },
        onChangeDefaultParameter(item) {
            const field = this.dictionaryItemsHeaders.find((el) => el.code === 'name' || el.code === 'description').value
            this.createdParameter.defaultValue = item[field]
            this.createdParameter.instanceName = item.id
            this.defaultDialog = false;
        },
        openDefaultDialog() {
            this.loading = true;
            this.fetchDictionaryItemsHeaders(this.createdParameter.templateName).then((res) => {
                this.dictionaryItemsHeaders = res.map((el)=>{
                    return {text: el.name, value:el.attributeId, code: el.code}
                })
                return this.fetchDictionaryItems(this.createdParameter.templateName)
            }).then(() => {
                this.dictionaryTitle = this.dictionaries.find((dict)=>dict.id ===  this.createdParameter.templateName).name
                this.defaultDialog = true;
                this.loading = false;
            }).catch((e) => {
                this.infoColor="error"
                this.infotext= e;
                this.info=true;
                this.loading = false;
            })
        },
    },
    mounted() {
        this.loading = true;
        Promise.all([this.fetchReports(), this.fetchReportsSources(), this.fetchReportsTemplates()]).then(()=> {
            return this.fetchDictionaries()
        }).then((res)=>{
            this.dictionaries = res.map((el)=> {
                return {name: el.data.name, id: el.data.id}
            })
            this.loading = false;
        }).catch((e) => {
            this.infoColor="error"
            this.infotext= e;
            this.info=true;
            this.loading = false;
        })
    }
}
</script>

<style lang="scss" scoped>
    .report-fields {
        padding: 15px;
        padding-top: 0
    }

    .report-field {
        display: grid;
        grid-template-columns: 1fr 4fr;
        justify-content: flex-start;
        align-items: center;
    }

    .report-field__label {
        font-weight: 500;
        width: 200px;
    }

    .report-field__input {
        width: 100%;
        padding: 5px;
        border: 1px solid black;
    }

    .report-fields__checkbox {
        margin-top: 0;
    }

    .report-fields__checkbox__container {
        width: 100%;
        padding: 10px;
        border: 1px solid #ced4da;
        height: 150px;
        overflow-y: scroll;
        border-radius: 5px;
    }

    .report-field__forAll {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    .report-field__forAll__title {
        font-weight: 500;
        margin-right: 10px;
    }

    .report-fields__parameters__conteiner {
        display: flex;
        justify-content: space-between;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .report-fields__parameters__label {
        font-weight: bold;
    }

    .report-fields__parameters__button {
        padding: 10px 15px;
        font-weight: 500;
        background: #dff0fa;
        color: #299cdb;
        border-radius: 5px;
    }

    .report-fields__parameters__button:hover {
        background: #299cdb;
        color: #ffffff;
        transition: 0.3s all;
    }

    .report-fields__parameters__teble{
        height: auto;
        overflow-y: scroll;
        max-height: 400px;
    }
    .report-field__delimiter {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    .report-field__delimiter__field {
        border: 1px solid black;
        margin-left: 10px;
    }

    .report-field__default__button {
        padding: 5px;
        border: 1px solid black;
        margin-left: 10px;
    }

    .text_input {
        height: 35px;
        margin-bottom: 10px;
    }

    .reports {
        margin-left: 15px;
        margin-top: 10px;
    }

</style>