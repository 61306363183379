<template>
<v-app>
    <div class="edit-view">
        <div class="edit-view__title">{{title}}</div>
        <div class="edit-view__container">
            <div 
                class="edit-view__item"
                v-for="(value, key, index) in filtetedData" :key="index"
            >
                <p class="edit-view__item__title">{{rusParams[key]}}:</p>
                <p v-if="key==='id' || key==='version'" class="edit-view__item__value">{{value}}</p>
                <p v-else-if="key==='versionDate'" class="edit-view__item__value">{{new Date(value).toLocaleDateString()}}</p>
                <textarea v-model="creationData[key]" v-else-if="key === 'description'" class="edit-view__item__textarea"></textarea>
                <v-select 
                    v-model="creationData[key]" 
                    :items="DICTIONARY_MANAGED_GROUPS"
                    item-text="name"
                    item-value="id"
                    v-else-if="key === 'managedGroupId'"></v-select>
                <input v-model="creationData[key]"  type="text" v-else class="edit-view__item__input">
            </div>
            <div class="edit-view__item__table__container">
                <group-dictionaries-edit :creationData="creationData" v-if="creationData.hasChildDictionaries === 'true'"></group-dictionaries-edit>
            </div>
            <v-spacer/>
            <div class="edit-view__actions">
                <button
                    class="btn-close"
                    @click="$emit('close-create')"
                >Закрыть</button>
                <button
                    @click="saveEdit"
                    class="btn-save"
                >Сохранить</button>
            </div>
        </div>
    </div>
</v-app>
</template>

<script>
import { mapGetters } from "vuex";
import GroupDictionariesEdit from '@/components/GroupDictionariesEdit.vue';
export default {
    props: {
        creationData: {
            type: Object,
            default: () => {}
        },
        title: {
            type: String,
            default: () => 'Редактирование'
        }
    },
    components: {
        GroupDictionariesEdit,
    },
    data() {
        return {
            rusParams: {
                id: "ID",
                code: "Код",
                name: "Наименование",
                versionDate: "Дата изменения",
                version: "Версия",
                register: "Реестр",
                managedGroupId: 'Управляемая группа',
                description: 'Описание',
            },
            invisibleParams: ['creationDate', 'dictionaryType', 'hasChildDictionaries', 'hasManagedGroup', 'hasManagingDictionary', 'version'],

            /**
             * Пустой объект который будет автоматически заполнятся
             * на стадии маунта объекта
            */
            dictinaryManagedGroups: [],
        }
    },
    computed: {
        ...mapGetters(["DICTIONARY_LIST", "DICTIONARY_MANAGED_GROUPS"]),
        filtetedData() {
            const data = {...this.creationData}
            for (let prop in data) {
                if (this.invisibleParams.includes(prop) ) {
                    delete data[prop]
                }
            }
            return data
        }
    },
    methods: {
        saveEdit() {
            this.$emit('create-dictionary', this.creationData)
        },
    },

}
</script>

<style lang="scss" scoped>
.edit-view {
    height: 100%;
    display: flex;
    flex-direction: column;
    &__item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        &:not(:last-child) {
            margin-bottom: 10px;
        }
    }
    &__actions {
        display: flex;
        justify-content: flex-end;
        & .btn-close {
            margin-right: 8px;
            background-color:#f06548;
            color: #fff;
            padding: 10px 15px;
            border-radius: 5px;
            font-weight: 500;
        }
        & .btn-close:hover {
            background-color:#cc563d;
            transition: 0.3s all;
        }
        & .btn-save {
            margin-right: 8px;
            background-color:#299cdb;
            color: #fff;
            padding: 10px 15px;
            border-radius: 5px;
            font-weight: 500;
        }
        & .btn-save:hover {
            background-color:#2385ba;
            transition: 0.3s all;
        }
    }
}

.edit-view__container {
    padding: 20px;
}

.edit-view__item {
    display: grid!important;
    grid-template-columns: 1fr 4fr;
}
.edit-view__item__title {
    font-weight: 500;
    width: 200px;
}

.edit-view__item__value {
    width: 100%;
}

.edit-view__item__input {
    width: 100%;
    padding: 5px;
}

.edit-view__item__textarea {
    width: 100%;
    padding: 5px;
    border: 1px solid black;
}

// .edit-view__item__table__container {
//     height: 400px;
// }

</style>