<template>
    <div class="edit-view">
        <div class="edit-view__title">Добавление атрибута (Тип Объекта: {{editData.templateName}})</div>
        <div class="edit-view__container">
            <div class="edit-view__item" v-if="!editDataAttribute.metaAttributeObjectType.multiple && 
            (editDataAttribute.metaAttributeObjectType.attributeType === 'STRING' || editDataAttribute.metaAttributeObjectType.attributeType === 'ADDRESS_BIR_APARTMENT' || editDataAttribute.value.dataType === 'STRING')">
                <p class="edit-view__item__title">Значение:</p>
                <textarea v-model="editDataAttribute.value.dataList[0].data"  type="text" class="edit-view__item__textarea"></textarea>
            </div>
            <div class="edit-view__item" v-if="!editDataAttribute.metaAttributeObjectType.multiple && (editDataAttribute.metaAttributeObjectType.attributeType === 'LONG' || editDataAttribute.metaAttributeObjectType.attributeType === 'DOUBLE' || editDataAttribute.value.dataType === 'LONG')">
                <p class="edit-view__item__title">Значение:</p>
                <input v-model="editDataAttribute.value.dataList[0].data"  type="number" class="edit-view__item__input">
            </div>
            <div class="edit-view__item" v-if="!editDataAttribute.metaAttributeObjectType.multiple && (editDataAttribute.metaAttributeObjectType.attributeType === 'BOOLEAN' || editDataAttribute.value.dataType === 'BOOLEAN')">
                <p class="edit-view__item__title">Значение:</p>
                <v-radio-group v-model="editDataAttribute.value.dataList[0].data">
                    <v-radio
                        v-for="item in booleanRadioButtons"
                        :key="item.label"
                        :label="item.label"
                        :value="item.value"
                    ></v-radio>
                </v-radio-group>
            </div>
            <div class="edit-view__item" v-if="!editDataAttribute.metaAttributeObjectType.multiple && (editDataAttribute.metaAttributeObjectType.attributeType === 'DATE' || editDataAttribute.metaAttributeObjectType.attributeType ==='CURRENT_DATE' || editDataAttribute.value.dataType === 'DATE')">
                <v-menu
                    ref="date_menu"
                    v-model="date_picker"
                    :close-on-content-click="false"
                    :return-value.sync="editDataAttribute.value.dataList[0].data"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="editDataAttribute.value.dataList[0].data"
                            label="Выберите дату"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                        ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="editDataAttribute.value.dataList[0].data"
                        no-title
                        scrollable
                    >
                        <v-spacer></v-spacer>
                        <button class="btn-close" @click="date_picker = false">Cancel</button>
                        <button class="btn-save" @click="$refs.date_menu.save(editDataAttribute.value.dataList[0].data)">OK</button>
                    </v-date-picker>
                </v-menu>
                <v-checkbox v-model="editDataAttribute.value.undefinedValue" label="Значение не задано"></v-checkbox>
            </div>
            <div class="edit-view__item" v-if="editDataAttribute.metaAttributeObjectType.attributeType === 'RELATION' 
            || editDataAttribute.metaAttributeObjectType.attributeType === 'RELATION_DICTIONARY'
            || editDataAttribute.metaAttributeObjectType.attributeType === 'MATRIX'
            || editDataAttribute.metaAttributeObjectType.attributeType === 'USER_ORG_ACCOUNT_USER_ACCOUNT'
            || this.editDataAttribute.metaAttributeObjectType.attributeType === 'ORGANIZATION_BIR_ADDRESS_LEGAL'
            ">
                <p class="edit-view__item__title">Значение:</p>
                <input disabled v-model="editDataAttribute.value.dataList[0].data"  type="number" class="edit-view__item__input">
            </div>
            <div class="edit-view__item" v-if="editDataAttribute.metaAttributeObjectType.attributeType === 'RELATION' || editDataAttribute.metaAttributeObjectType.attributeType === 'MATRIX'">
                <div class="edit-view__item__tree">
                    <tree-menu 
                        v-for="(node, index) in nodes" 
                        :key="index"
                        :label="node.label"
                        :nodes="node.nodes"
                        :nodeData="node.data"
                        :depth="0"
                        @label-clicked="onItemClick($event)"
                        type = 'object'
                    ></tree-menu>
                </div>
            </div>
            <div class="edit-view__item" v-if="editDataAttribute.metaAttributeObjectType.attributeType === 'RELATION_DICTIONARY' || editDataAttribute.metaAttributeObjectType.attributeType === 'USER_ORG_ACCOUNT_USER_ACCOUNT' || editDataAttribute.metaAttributeObjectType.attributeType === 'ORGANIZATION_BIR_ADDRESS_LEGAL'">
                <div class="edit-view__item__tree">
                    <tree-menu 
                        v-for="(node, index) in nodes" 
                        :key="index"
                        :label="node.label"
                        :nodes="node.nodes"
                        :nodeData="node.data"
                        :depth="0"
                        @label-clicked="onItemClick($event)"
                        type = 'dictionary'
                    ></tree-menu>
                </div>
            </div>
            <div class="edit-view__item" v-if="editDataAttribute.metaAttributeObjectType.attributeType === 'RELATION' || editDataAttribute.metaAttributeObjectType.attributeType === 'MATRIX'">
                <v-select 
                    v-model="selectedRelatedType" 
                    :items="OBJECT_RELATIONAL_OBJECT_ATTRIBUTES"
                    item-text="label"
                    item-value="data"
                    label="Задать типизацию связи"
                ></v-select>
            </div>
            <div class="edit-view__item" v-if="!editDataAttribute.metaAttributeObjectType.multiple && editDataAttribute.value.dataType === 'BLOB'">
                <p class="edit-view__item__title">Значение:</p>
                <input @change="onFileInput" type="file" ref="file" class="edit-view__item__input" :accept="acceptedTypes">
            </div>

            <div class="edit-view__item__multiple__container" v-if="editDataAttribute.metaAttributeObjectType.multiple">
                <div class="edit-view__item__multiple">
                    <v-text-field v-model="editDataAttribute.metaAttributeObjectType.maxSize" type="number" label="Максимальный размер" class="view__item__multiple__top-item" @change="onMaxSizeChange"></v-text-field>
                    <v-checkbox v-model="editDataAttribute.metaAttributeObjectType.fixedSize"  type="checkbox" label="Признак фиксированного размера"></v-checkbox>
                </div>
                <v-data-table
                    class="atributes_table"
                    :headers="headers"
                    fixed-header
                    height="100%"
                    :items="editDataAttribute.value.dataList"
                    :items-per-page="10000"
                    hide-default-footer
                    dense
                    @dblclick:row="rowClick"
                >
                    <template v-slot:item.data="{ item }">
                        <span v-if="editDataAttribute.value.dataType === 'BLOB'">{{item.data.name}}</span>
                        <span v-else>{{item.data}}</span>
                    </template>
                    <template v-slot:item.actions="{ item }">
                        <div>
                          <i class="ri ri-pencil-fill" @click="showEditForm(item)"></i>
                            <i class="ri ri-delete-bin-fill" @click="onDeleteItem(item)"></i>
                        </div>
                    </template>
                </v-data-table>
                <div>
                    <button @click="showAddForm" class="btn-save">Добавить элемент</button>
                </div>
            </div>

      <v-spacer />
      <div class="edit-view__actions">
        <button class="btn-close" @click="onPrevious">Назад</button>
        <button class="btn-close" @click="onClose">Отмена</button>
        <button @click="onSave" class="btn-save">Сохранить</button>
      </div>
    </div>

        <v-dialog
                v-model="editForm"
                persistent
                max-width="600"
            >
            <v-card>
                <v-card-title>{{windowType === 'edit' ? 'Редактирование элемента' : 'Добавление элемента'}}</v-card-title>
                <div class="edit-view__multiple__item" v-if="editDataAttribute.metaAttributeObjectType.attributeType === 'STRING' || editDataAttribute.metaAttributeObjectType.attributeType === 'ADDRESS_BIR_APARTMENT' || editDataAttribute.value.dataType === 'STRING'">
                    <p class="edit-view__item__title">Значение:</p>
                    <textarea v-model="editedItem.data"  type="text" class="edit-view__item__textarea"></textarea>
                </div>
                <div class="edit-view__multiple__item" v-if="editDataAttribute.metaAttributeObjectType.attributeType === 'LONG' || editDataAttribute.metaAttributeObjectType.attributeType === 'DOUBLE' || editDataAttribute.value.dataType === 'LONG'">
                    <p class="edit-view__item__title">Значение:</p>
                    <input v-model="editedItem.data"  type="number" class="edit-view__item__input">
                </div>
                <div class="edit-view__multiple__item" v-if="editDataAttribute.metaAttributeObjectType.attributeType === 'BOOLEAN' || editDataAttribute.value.dataType === 'BOOLEAN'">
                    <p class="edit-view__item__title">Значение:</p>
                    <v-radio-group v-model="editedItem.data">
                        <v-radio
                            v-for="item in booleanRadioButtons"
                            :key="item.label"
                            :label="item.label"
                            :value="item.value"
                        ></v-radio>
                    </v-radio-group>
                </div>
                <div class="edit-view__multiple__item" v-if="editDataAttribute.metaAttributeObjectType.attributeType === 'DATE' || editDataAttribute.metaAttributeObjectType.attributeType ==='CURRENT_DATE' || editDataAttribute.value.dataType === 'DATE'">
                    <v-menu
                        ref="date_menu"
                        v-model="date_picker"
                        :close-on-content-click="false"
                        :return-value.sync="editedItem.data"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="editedItem.data"
                                label="Выберите дату"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                            ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="editedItem.data"
                            no-title
                            scrollable
                        >
                            <v-spacer></v-spacer>
                            <button class="btn-close" @click="date_picker = false">Cancel</button>
                            <button class="btn-save" @click="$refs.date_menu.save(editedItem.data)">OK</button>
                        </v-date-picker>
                    </v-menu>
                </div>
                <div class="edit-view__multiple__item" v-if="editDataAttribute.value.dataType === 'BLOB' || editDataAttribute.value.dataType === 'BLOB'">
                    <p class="edit-view__item__title">Значение:</p>
                    <input @change="onFileInputMultiple" type="file" ref="fileMultiple" :accept="acceptedTypes" class="edit-view__item__input">
                </div>
                <v-card-actions>
                  <button v-if="windowType === 'edit'" class="btn-save" @click="onEditItem">ОК</button>
                    <button v-if="windowType === 'add'" class="btn-save" @click="onAddElement">OK</button>                            <button class="btn-close" @click="date_picker = false">Cancel</button>
                    <button class="btn-close" @click="editForm = false">Отмена</button>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  props: {
    editDataAttribute: {
      type: Object,
      default: () => {},
    },
    title: {
      type: String,
      default: () => 'Редактирование',
    },
    editData: {
      type: Object,
      default: () => {},
    },
    attribyteWindowType: {
      type: String,
      default: () => 'create',
    },
  },
  components: {},
  data() {
    return {
      booleanRadioButtons: [
        { label: 'Истина', value: 'true' },
        { label: 'Ложь', value: 'false' },
        { label: 'Неопределено', value: 'undefined' },
      ],
      headers: [
        { text: '№', value: 'index' },
        { text: 'Значение', value: 'data' },
        { text: 'По умолчанию', value: '' },
        { text: 'Действия', value: 'actions' },
      ],
      editedItem: {},
      editForm: false,
      windowType: 'edit',
      date_picker: false,
      nodes: [],
      selectedRelatedType: null,
    };
  },
  computed: {
    ...mapGetters([
      'OBJECTS',
      'OBJECT_RELATIONAL_OBJECT_ATTRIBUTES',
      'DICTIONARY_LIST',
    ]),
    acceptedTypes() {
      if (
        this.editDataAttribute.metaAttributeObjectType.attributeType ===
        'DOCUMENT'
      ) {
        return 'application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf';
      } else if (
        this.editDataAttribute.metaAttributeObjectType.attributeType ===
        'DOCUMENT_TXT'
      ) {
        return 'text/plain';
      } else if (
        this.editDataAttribute.metaAttributeObjectType.attributeType ===
        'DOCUMENT_XML'
      ) {
        return 'text/xml';
      } else if (
        this.editDataAttribute.metaAttributeObjectType.attributeType ===
        'DOCUMENT_HTML'
      ) {
        return 'text/html';
      } else if (
        this.editDataAttribute.metaAttributeObjectType.attributeType ===
        'DOCUMENT_RTF'
      ) {
        return 'application/rtf';
      } else if (
        this.editDataAttribute.metaAttributeObjectType.attributeType ===
        'DOCUMENT_ODT'
      ) {
        return 'application/vnd.oasis.opendocument.text';
      } else if (
        this.editDataAttribute.metaAttributeObjectType.attributeType ===
        'DOCUMENT_DOC'
      ) {
        return '.doc';
      } else if (
        this.editDataAttribute.metaAttributeObjectType.attributeType ===
        'DOCUMENT_XLS'
      ) {
        return '.xls';
      } else if (
        this.editDataAttribute.metaAttributeObjectType.attributeType === 'IMAGE'
      ) {
        return 'image/*';
      } else if (
        this.editDataAttribute.metaAttributeObjectType.attributeType === 'ICON'
      ) {
        return 'image/*';
      } else if (
        this.editDataAttribute.metaAttributeObjectType.attributeType ===
        'IMAGE_BMP'
      ) {
        return '.bmp';
      } else if (
        this.editDataAttribute.metaAttributeObjectType.attributeType ===
        'IMAGE_JPG'
      ) {
        return '.jpg, .jpeg';
      } else if (
        this.editDataAttribute.metaAttributeObjectType.attributeType ===
        'IMAGE_GIF'
      ) {
        return '.gif';
      } else if (
        this.editDataAttribute.metaAttributeObjectType.attributeType ===
        'IMAGE_PNG'
      ) {
        return '.png';
      } else if (
        this.editDataAttribute.metaAttributeObjectType.attributeType ===
        'IMAGE_WMF'
      ) {
        return '.wmf';
      } else {
        return '';
      }
    },
  },
  methods: {
    ...mapActions([]),
    onSave() {
      if (
        this.editDataAttribute.value.dataList.find(
          (el) => el.data === 'undefined'
        ) &&
        this.editDataAttribute.metaAttributeObjectType.attributeType ===
          'BOOLEAN'
      ) {
        if (this.editDataAttribute.metaAttributeObjectType.immutable) {
          alert(
            'Установлен признак константы. Задайте значение атрибута или снимите признак.'
          );
          return;
        }
        this.editDataAttribute.value.dataList = null;
      }
      if (
        this.editDataAttribute.metaAttributeObjectType.attributeType ===
          'DATE' ||
        this.editDataAttribute.metaAttributeObjectType.attributeType ===
          'CURRENT_DATE'
      ) {
        if (this.editDataAttribute.value.undefinedValue) {
          if (this.editDataAttribute.metaAttributeObjectType.immutable) {
            alert(
              'Установлен признак константы. Задайте значение атрибута или снимите признак.'
            );
            return;
          }
          this.editDataAttribute.value.dataList = null;
        } else {
          for (
            let i = 0;
            i < this.editDataAttribute.value.dataList.length;
            i += 1
          ) {
            this.editDataAttribute.value.dataList[i].data = new Date(
              this.editDataAttribute.value.dataList[i].data
            ).toISOString();
          }
        }
        delete this.editDataAttribute.value.undefinedValue;
      }
      if (
        this.editDataAttribute.metaAttributeObjectType.attributeType ===
        'RELATION'
      ) {
        if (this.selectedRelatedType) {
          this.editDataAttribute.relation.transitiveRelation.linkedAttributeId =
            this.selectedRelatedType.value.dataList[0].linkedTemplateId;
          this.editDataAttribute.relation.transitiveRelation.linkedTemplateId =
            this.selectedRelatedType.attributeObjectType.id;
        } else {
          this.editDataAttribute.relation.transitiveRelation.linkedAttributeId = 0;
          this.editDataAttribute.relation.transitiveRelation.linkedTemplateId = 0;
        }
        this.editDataAttribute.relation.transitiveRelation.sourceLinkingAttributeId =
          '0';
        this.editDataAttribute.relation.transitiveRelation.targetLinkingAttributeId =
          '0';
        this.editDataAttribute.relation.transitiveRelation.linkedAttributeName =
          null;
      }
      if (
        this.editDataAttribute.metaAttributeObjectType.attributeType ===
        'RELATION_DICTIONARY'
      ) {
        this.editDataAttribute.relation.transitiveRelation.linkedAttributeId =
          '0';
        this.editDataAttribute.relation.transitiveRelation.linkedTemplateId =
          '0';
        this.editDataAttribute.relation.transitiveRelation.sourceLinkingAttributeId =
          '0';
        this.editDataAttribute.relation.transitiveRelation.targetLinkingAttributeId =
          '0';
        this.editDataAttribute.relation.transitiveRelation.linkedAttributeName =
          null;
      }
      if (this.editDataAttribute.value.dataList !== null) {
        for (
          let i = 0;
          i < this.editDataAttribute.value.dataList.length;
          i += 1
        ) {
          delete this.editDataAttribute.value.dataList[i].index;
        }
      }

      if (this.attribyteWindowType === 'create') {
        this.$emit('create-attribute');
      } else {
        if (
          !this.editDataAttribute.value.dataList[0].data &&
          this.editDataAttribute.value.dataList.length === 1
        ) {
          delete this.editDataAttribute.value.dataList;
        }
        if (this.editDataAttribute.metaAttributeObjectType.maxSize === 0) {
          delete this.editDataAttribute.value.dataList;
        }
        this.$emit('edit-attribute');
      }
    },
    onPrevious() {
      this.$emit('previous-create-attribute');
    },
    onClose() {
      this.$emit('close-create_attribute');
    },
    rowClick(e, item) {
      this.showEditForm(item.item);
    },
    showEditForm(item) {
      this.editedItem = { ...item };
      this.windowType = 'edit';
      this.editForm = true;
    },
    onEditItem() {
      const editedIndex = this.editDataAttribute.value.dataList.findIndex(
        (el) => el.index === this.editedItem.index
      );
      this.editDataAttribute.value.dataList[editedIndex].data =
        this.editedItem.data;
      this.editForm = false;
    },
    showAddForm() {
      if (
        this.editDataAttribute.value.dataList.length <
        this.editDataAttribute.metaAttributeObjectType.maxSize
      ) {
        this.editedItem = {
          data: '',
          index: this.editDataAttribute.value.dataList.length + 1,
        };
        this.windowType = 'add';
        this.editForm = true;
      }
    },
    onAddElement() {
      this.editForm = false;
      this.editDataAttribute.value.dataList.push({ ...this.editedItem });
    },
    onMaxSizeChange() {
      if (
        this.editDataAttribute.metaAttributeObjectType.maxSize <
        this.editDataAttribute.value.dataList.length
      ) {
        alert(
          `В массиве больше ${this.editDataAttribute.metaAttributeObjectType.maxSize} элементов. Увеличьте максимальный размер до X или удалите лишние элементы.`
        );
        this.editDataAttribute.metaAttributeObjectType.maxSize =
          this.editDataAttribute.value.dataList.length;
      }
    },
    onDeleteItem(item) {
      let index = 0;
      const filteredDataList = this.editDataAttribute.value.dataList
        .filter((el) => el.index !== item.index)
        .map((el) => {
          index += 1;
          return { data: el.data, index };
        });
      this.editDataAttribute.value.dataList = filteredDataList;
    },
    onItemClick(item) {
      this.editDataAttribute.value.dataList[0].data = item.id;
      if (
        this.editDataAttribute.metaAttributeObjectType.attributeType ===
          'RELATION' ||
        this.editDataAttribute.metaAttributeObjectType.attributeType ===
          'MATRIX'
      ) {
        this.$emit('on_get_Relational_Object_Attributes', item);
      }
    },
    onFileInput() {
      console.dir(this.$refs.file.files);
      this.editDataAttribute.value.dataList[0].data = {
        name: this.$refs.file.files[0].name,
        bytes: '',
      };

      const toBase64 = (file) =>
        new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = (error) => reject(error);
        });

      toBase64(this.$refs.file.files[0])
        .then((data) => {
          this.editDataAttribute.value.dataList[0].data.bytes = data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    onFileInputMultiple(e) {
      console.dir(e.target.files);
      this.editedItem.data = {
        name: e.target.files[0].name,
        bytes: '',
      };

      const toBase64 = (file) =>
        new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = (error) => reject(error);
        });

      toBase64(e.target.files[0])
        .then((data) => {
          this.editedItem.data.bytes = data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  mounted() {
    if (
      this.editDataAttribute.metaAttributeObjectType.multiple &&
      this.editDataAttribute.metaAttributeObjectType.maxSize === 0
    ) {
      this.editDataAttribute.metaAttributeObjectType.maxSize = 10;
    }

    if (
      this.editDataAttribute.metaAttributeObjectType.attributeType ===
        'RELATION' ||
      this.editDataAttribute.metaAttributeObjectType.attributeType === 'MATRIX'
    ) {
      this.nodes = [
        {
          label: 'Наследование',
          data: {
            id: 1,
            label: 'Наследование',
          },
          nodes: this.OBJECTS,
        },
      ];
    }
    if (
      this.editDataAttribute.metaAttributeObjectType.attributeType ===
        'RELATION_DICTIONARY' ||
      this.editDataAttribute.metaAttributeObjectType.attributeType ===
        'USER_ORG_ACCOUNT_USER_ACCOUNT' ||
      this.editDataAttribute.metaAttributeObjectType.attributeType ===
        'ORGANIZATION_BIR_ADDRESS_LEGAL'
    ) {
      this.nodes = [
        {
          label: 'Справочники',
          data: {
            id: 1,
            label: 'Наследование',
          },
          nodes: this.DICTIONARY_LIST,
        },
      ];
    }
  },
};
</script>

<style lang="scss" scoped>
.edit-view {
    height: 100%;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    &__item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        &:not(:last-child) {
            margin-bottom: 10px;
        }
    }
    &__actions {
        display: flex;
        justify-content: flex-end;
        & .btn-close {
            margin-right: 8px;
            background-color:red;
            color: #fff;
            padding: 5px 10px;
            border-radius: 5px;
        }
        & .btn-close:hover {
            background-color:lightcoral;
        }
        & .btn-save {
            margin-right: 8px;
            background-color:deepskyblue;
            color: #fff;
            padding: 5px 10px;
            border-radius: 5px;
        }
        & .btn-save:hover {
            background-color:lightblue;
        }
    }
}

.edit-view__container {
  padding: 20px;
}

.edit-view__item__title {
    font-weight: 500;
    width: 200px;
}

.edit-view__item__value {
  width: 100%;
}

.edit-view__item__input {
  width: 100%;
  padding: 5px;
}

.edit-view__item__textarea {
  width: 100%;
  padding: 5px;
  border: 1px solid black;
}

// .edit-view__item__table__container {
//   height: 400px;
// }

.edit-view__item__button {
  border-radius: 5px;
  background-color: deepskyblue;
  color: #fff;
  padding: 5px;
  margin-left: 10px;
}

.parents-fields {
  height: 600px;
  overflow-y: scroll;
}

.edit-view__item__textarea {
  width: 100%;
  padding: 5px;
  border: 1px solid black;
}

.edit-view__item__checkbox {
  width: 100%;
}

.edit-view__item__multiple {
  display: flex;
  justify-content: flex-start;
}

.view__item__multiple__top-item {
  margin-right: 20px;
}

.edit-view__multiple__item {
  padding: 10px;
}

.edit-view__item__multiple__container .btn-save {
            margin-right: 8px;
            background-color:deepskyblue;
            color: #fff;
            padding: 5px 10px;
            border-radius: 5px;
}

.edit-view__item__tree {
  height: 400px;
  border: 1px solid black;
  width: 100%;
  overflow-y: scroll;
}
</style>
