<template>
    <div class="edit-view">
        <div class="edit-view__title">{{title}}</div>
        <div class="edit-view__container">
            <div class="edit-view__item">
                <div class="edit-view__item__inner" style="width: 60%;">
                    <p class="edit-view__item__title" style="width: 150px;">Код:</p>
                    <input :disabled="editOrCreate === 'edit' && !edit" v-model="creationData.code"  type="text" class="edit-view__item__input" style="width: 400px;">
                </div>
                <div class="edit-view__item__inner" style="width: 40%;">
                    <p class="edit-view__item__title">Тип роли:</p>
                    <v-select :disabled="editOrCreate === 'edit'" v-model="creationData.typeRole" :items="ADMIN_TYPES_ROLE" item-text="name" return-object class="edit-view__item__input" style="width: 50%;"></v-select>
                </div>
            </div>
            <div class="edit-view__item">
                <div class="edit-view__item__inner" style="width: 50%;">
                    <p class="edit-view__item__title" style="width: 150px;">Наименование:</p>
                    <input :disabled="editOrCreate === 'edit' && !edit" v-model="creationData.name"  type="text" class="edit-view__item__input" style="width: 350px;">
                </div>
                <div class="edit-view__item__inner" style="width: 50%;">
                    <p class="edit-view__item__title" style="width: 150px;">Дата создания:</p>
                    <input disabled :value="creationData.creationDate !== null ? new Date(creationData.creationDate).toLocaleString() : null"  type="text" class="edit-view__item__input">
                </div>
            </div>
            <div class="edit-view__item">
                <p class="edit-view__item__title" style="width: 150px;">Описание:</p>
                <textarea :disabled="editOrCreate === 'edit' && !edit" v-model="creationData.description" rows="5" class="edit-view__item__textarea" style="width: calc(100% - 150px);"></textarea>
            </div> 
            <div class="edit-view__item">
                <v-data-table
                    class="users_list_table"
                    :headers="headers"
                    :items="creationData.roleAccounts"
                    :items-per-page="10000"
                    hide-default-footer
                    dense
                > 
                </v-data-table>
            </div>   
            <v-spacer/>
            <div class="edit-view__actions">
                <button
                    class="btn-close"
                    @click="$emit('close-create')"
                >Закрыть</button>
                <button
                    v-if="editOrCreate === 'create'"
                    @click="onCreate"
                    class="btn-save"
                >Сохранить</button>
                <button
                    v-if="editOrCreate === 'edit' && !edit"
                    @click="edit = true"
                    class="btn-save"
                >Редактировать</button>
                <button
                    v-if="editOrCreate === 'edit' && edit"
                    @click="onEdit"
                    class="btn-save"
                >Сохранить</button>
            </div>
        </div>
            <v-snackbar
                v-model="info"
                :color="infoColor"
                :timeout="10000"
            >
                {{infotext}}
            </v-snackbar>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
    props: ['title', 'activeItem', 'editOrCreate'],
    components: {

    },
    data() {
        return {
            info: false,
            infotext: '',
            infoColor: '',
            headers: [{text: 'Пользователи', value: 'name', sortable: false,}],
            creationData: {
                code: null,
                creationDate: null,
                description: null,
                id: 0,
                name: null,
                roleAccounts: [],
                typeRole: null,
            },
            edit: false,
        }
    },
    computed: {
        ...mapGetters(["ADMIN_TYPES_ROLE"]),
    },
    methods: {
        ...mapActions([

        ]),
        onCreate() {
            const creationData = {...this.creationData};
            delete creationData.roleAccounts;
            this.$emit('onCreateRole', creationData);
        },
        onEdit() {
            const creationData = {...this.creationData};
            delete creationData.roleAccounts;
            delete creationData.flex;
            creationData.creationDate= null;
            this.$emit('onEditRole', creationData);
        },
    },
    created() {
        console.log(this.activeItem)
        if (this.editOrCreate) {
            this.creationData = {...this.activeItem}
        }
    }

}
</script>

<style lang="scss" scoped>
.edit-view {
    height: 100%;
    display: flex;
    flex-direction: column;
    &__item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        &:not(:last-child) {
            margin-bottom: 10px;
        }
    }
    &__actions {
        display: flex;
        justify-content: flex-end;
        & .btn-close {
            margin-right: 8px;
            background-color:#f06548;
            color: #fff;
            padding: 10px 15px;
            border-radius: 5px;
            font-weight: 500;
        }
        & .btn-close:hover {
            background-color:#cc563d;
            transition: 0.3s all;
        }
        & .btn-save {
            margin-right: 8px;
            background-color:#299cdb;
            color: #fff;
            padding: 10px 15px;
            border-radius: 5px;
            font-weight: 500;
        }
        & .btn-save:hover {
            background-color:#2385ba;
            transition: 0.3s all;
        }
    }
}

.edit-view__container {
    padding: 20px;
}

.edit-view__item__title {
    font-weight: 500;
    width: 200px;
}

.edit-view__item__value {
    width: 100%;
}

.edit-view__item__input {
    width: 100%;
    padding: 5px;
}

.edit-view__item__textarea {
    width: 100%;
    padding: 5px;
    border: 1px solid black;
}

// .edit-view__item__table__container {
//     height: 400px;
// }

.edit-view__item__button {
    border-radius: 5px;
    background-color: deepskyblue;
    color: #fff;
    padding: 5px;
    margin-left: 10px;
}

.parents-fields {
    height: 600px;
    overflow-y: scroll;
}

.edit-view__item__inner {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.users_list_table {
    width: 100%;
    height: 400px;
    overflow-y: scroll;
}
</style>