<template>

    <div
        class="types"
        ref="conteiner"
    >
        <!-- Не вытаскивыть tree-menu из враппера, т.к компонент вызывает сам себя
        и конструкция сломается -->
        <v-navigation-drawer class="tree" v-model="showNavigation" :mini-variant.sync="mini" permanent>
            <div class="navigation-top__conteiner">
                <v-btn
                    icon
                    @click.stop="mini = !mini"
                    >
                    <v-icon>{{mini ? 'mdi-chevron-right' : 'mdi-chevron-left'}}</v-icon>
                </v-btn>
            </div>
            <tree-menu 
                v-for="(node, index) in nodes" 
                :key="index"
                :label="node.label"
                :nodes="node.nodes"
                :nodeData="node.data"
                :depth="0"
                type = 'object'
                @label-clicked="showEdit($event)"
                :showMainChildren="true"
                :showMainChildrenSecond="true"
                :selectedItem="activeItem"
            ></tree-menu>
        </v-navigation-drawer>
        <!--<div class="divider" @mousedown="onColumnMouseDown($event)"></div>--->
        <div class="view">
            <component
                :key="updateKey"
                :is="activeView"
                :nodes="nodes"
                :headers="headers"
                :creationData="creationData"
                :title="title"
                @create-Register-Open="createRegisterOpen"
                @close-edit="closeEdit"
                @create-Register="onCreateRegister"
                @table-row-dblclick="showEdit"
                @initialize-Register="onInitializeRegister"
                @update-Register="onUpdateRegister"
                @get-Unregistered-Templates="onGetUnregisteredTemplates"
            />
        </div>
            <v-dialog
                v-model="deleteDialog"
                persistent
                max-width="290"
            >
            <v-card>

                <v-card-text>Вы действительно хотите удалить запись?</v-card-text>
                <v-card-actions>
                    <!-- <v-btn
                        color="red darken-1"
                        text
                        @click="deleteRegister"
                    >Да
                    </v-btn> -->
                    <button 
                        class="btn-save" 
                        @click="deleteRegister"
                    >Да
                    </button>
                    <!-- <v-btn
                        color="green darken-1"
                        text
                        @click="deleteDialog = false"
                    >Нет
                    </v-btn> -->
                    <button 
                        class="btn-close" 
                        @click="deleteDialog = false"
                    >Нет
                    </button>
                </v-card-actions>
            </v-card>
            </v-dialog>
            <v-snackbar
                v-model="info"
                :color="infoColor"
                :timeout="10000"
            >
                {{infotext}}
            </v-snackbar>
            <v-overlay :value="loading">
                <v-progress-circular
                    indeterminate
                    size="64"
                ></v-progress-circular>
            </v-overlay>
    </div>

</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ListTableRegisters from '@/components/ListTableRegisters.vue';
import CreateViewRegisters from '@/components/CreateViewRegisters.vue';

export default {
    components: {
        ListTableRegisters,
        CreateViewRegisters
    },
    data() {
        return {
            updateKey: 1,
            editData: null,
            activeView: 'ListTableRegisters',
            nodes: [],
            deleteDialog: false,
            info: false,
            infotext: '',
            infoColor: '',
            activeItem: null,
            headers: [
                {text: 'id', value: 'id'},  
                {text: 'Наименование', value: 'name'},
                {text: 'Основная организация', value: 'organization'},
                {text: 'Версия', value: 'version'},
                {text: 'Дата создания', value: 'dateCreation'},
                {text: 'Статус', value: 'status.name'},
            ],
            creationData: {},
            title: 'Создание реестра',
            objectWidth: 20,
            viewWidth: 80,
            point: null,
            showNavigation: true,
            mini: false,
            loading: true,
        }
    },
    computed: {
        ...mapGetters(["REGISTER_MENU"]),
    },
    methods: {
        ...mapActions([
            "getRegisterMenu",
            "createRegister",
            "initializeRegister",
            "getUnregisteredTemplates",
            "addTemplatesToRegister",
            "removeTemplatesFromRegister",
            "updateRegister",
        ]),
        closeEdit() {
            this.editData = null;
            this.activeView = 'ListTableRegisters';
        },
        showEdit(node) {
            this.activeItem = node;
            if (!node.templates) return
            this.creationData = node;
            if (this.creationData.role === null) {
                this.creationData.role = {
                    id: "0",
                    name: null,
                    code: '',
                    description: null,
                    typeRole: null,
                    creationDate: null,
                }
            }
            if (this.creationData.templates && this.creationData.templates.length > 0) {
                for (let template of this.creationData.templates) {
                    template.selected = true
                    template.new = false
                }
            }

            this.updateKey++;
            this.activeView = 'CreateViewRegisters';
        },
        deleteRegister() {

        },
        createRegisterOpen() {
            this.updateKey++;
            this.creationData = {
                id: "0",
                name: '',
                organization: '',
                status: null,
                version: "0",
                dateCreation: null,
                role: {
                    id: "0",
                    name: null,
                    code: '',
                    description: null,
                    typeRole: null,
                    creationDate: null,
                },
                templates: null,
            }
            this.activeView = 'CreateViewRegisters';
        },
        onCreateRegister(item) {
            this.loading = true;
            this.createRegister(item).then(()=>{
                return this.getRegisterMenu()
            }).then(()=>{
                this.nodes = [
                    {
                        label: 'Реестры',
                        data: {
                            id: "0"
                        },
                        nodes: this.REGISTER_MENU,
                    },
                ]
                this.updateKey++;
                this.activeView = 'ListTableRegisters';     
                this.loading = false;           
            }).catch((e) => {
                this.infoColor="error"
                this.infotext= e;
                this.info=true;
                this.loading = false;
            })
        },
        onInitializeRegister() {
            this.loading = true;
            this.initializeRegister(this.creationData).then(()=>{
                this.loading = false;
            }).catch((e) => {
                this.infoColor="error"
                this.infotext= e;
                this.info=true;
                this.loading = false;
            })
        },
        onUpdateRegister() {
            this.loading = true;
            this.creationData.role = null;
            const deletedTemplates = this.creationData.templates.filter((el) => !el.selected && !el.new)
            const newTemplates = this.creationData.templates.filter((el) => el.new)
            this.creationData.templates = this.creationData.templates.filter((el) => el.selected)
            for (let template of this.creationData.templates) {
                delete template.selected;
                delete template.new;
            }
            this.addTemplatesToRegister({templates: newTemplates, registerId: this.creationData.id}).then(()=>{
                return this.removeTemplatesFromRegister({templates: deletedTemplates, registerId: this.creationData.id})
            }).then(()=>{
                return this.updateRegister(this.creationData)
            }).then(()=>{
                return this.getRegisterMenu()
            }).then(()=>{
                this.nodes = [
                    {
                        label: 'Реестры',
                        data: {
                            id: "0"
                        },
                        nodes: this.REGISTER_MENU,
                    },
                ]
                this.updateKey++;
                this.activeView = 'ListTableRegisters';  
                this.loading = false;              
            }).catch((e) => {
                this.infoColor="error"
                this.infotext= e;
                this.info=true;
                this.loading = false;
            })
        },
        onGetUnregisteredTemplates() {
            this.loading = true;
            this.getUnregisteredTemplates().then(()=>{
                this.loading = false;
            }).catch((e) => {
                this.infoColor="error"
                this.infotext= e;
                this.info=true;
                this.loading = false;
            })
        },
        onColumnMouseDown(event) {
            event.preventDefault();
            this.point = event.clientX - 18;
            document.onmousemove = this.elementDrag;
            document.onmouseup = this.closeDragElement;
        },
        elementDrag(e) {
            e = e || window.event;
            e.preventDefault();
            const conteinerWidth = this.$refs.conteiner.clientWidth;
            const leftWidth = this.objectWidth * (conteinerWidth/100)  + (e.clientX-18 - this.point);
            this.point = e.clientX-18;
            const rightWidth = conteinerWidth - leftWidth;
            const objectWidth = (leftWidth/conteinerWidth)*100;
            const viewWidth = ((rightWidth)/conteinerWidth)*100;
            if (objectWidth < 20 || viewWidth < 20) {
                return
            }
            this.objectWidth = objectWidth;
            this.viewWidth = viewWidth;
            },
        closeDragElement() {
            document.onmouseup = null;
            document.onmousemove = null;
        },
    },
    mounted() {
        this.getRegisterMenu().then(()=>{
            this.nodes = [
                {
                    label: 'Реестры',
                    data: {
                        id: "0"
                    },
                    nodes: this.REGISTER_MENU,
                },
            ]
            this.loading = false;
        }).catch((e) => {
            this.infoColor="error"
            this.infotext= e;
            this.info=true;
            this.loading = false;
        })
    }
}
</script>

<style lang="scss" scoped>
    .tree {
        width:20%;
        // border: 1px solid #000;
        overflow: auto;
        background-color: #0ab39c!important;
        color: #daf4f0;
        font-weight: 400;
        font-size: 0.875rem;
        height: calc(100vh - 70px)!important;
        padding-top: 15px
    }
    .view {
        width: 100%;
        margin-left: 20px
        // border: 1px solid #000;
    }
    .types {
        display: flex;
        // border: 1px solid #000;
        // background: #f3f3f8;
        padding: 10px 5px 5px 0;
        height: calc(100% - 48px); // 100% - 48px, где 48px - высота табов
        border-radius: 0px !important;
    }

    
    .divider {
        background-color: black;
        height: 100%;
        width: 3px;
        cursor: col-resize;
    }
    
</style>