<template>
<div class="table-conteiner">
  <v-data-table
    class="list_table"
    :headers="headers"
    :items="getItems"
    :items-per-page="10000"
    hide-default-footer
    dense
    @dblclick:row="rowClick"
  >
    <template v-slot:item.dateCreation="{ item }">
      {{new Date(item.dateCreation).toLocaleString()}}
    </template>
  </v-data-table>
  <div class="buttons_conteiner">
    <button class="buttons_conteiner__button" @click="$emit('create-Register-Open')">Создать реестр</button>
  </div>
</div>
</template>

<script>
export default {
    props: {
      nodes: {
        type: Array,
        default: () => []
      },
      headers: {
        type: Array,
        default: () => []
      }
    },
    data() {
      return {
        availableHeaders: {
          id: "ID",
          code: 'Код',
          label: "Наименование",
          hasChildDictionaries: 'Признак группы',
          changeDate: "Дата изменения",
          version: "Версия",
          register: "Реестр",
        }
      }
    },
    methods: { 
      rowClick(e, item) {
        this.$emit('table-row-dblclick', item.item)
      },
    },
    computed: {
      getItems() {
        const items = [];
        this.nodes.forEach( el => {
          el.nodes && el.nodes.forEach(item => {
            items.push(item.data)
          })
        });
        return items;
      }
    },
}
</script>
<style lang="scss">
  .list_table .v-data-table__wrapper{
      height: calc(100vh - 140px);
      overflow-y: scroll;
  }

  .buttons_conteiner {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .buttons_conteiner__button {
    margin-right: 8px;
    background-color:#daf4f0;
    color: #099885ff;
    padding: 10px 15px;
    border-radius: 5px;
    font-weight: 500;
  }

  .buttons_conteiner__button:hover {
    background-color:#099885;
    color: white;
    transition: 0.3s all;
  }
</style>