<template>
    <div class="object_representation">
        <div class="object_representation__table__container">
            <v-data-table
                class="object_representation__table"
                :headers="headers"
                :items="OBJECTS_TEMPLATE_REPRESENTATION.metaAttributeLinkTypes"
                :items-per-page="10000"
                hide-default-footer
                fixed-header
                height="100%"
                dense
            >
                <template v-slot:item.order="{ item }">
                    <div class="object_representation__table__order">
                        <span class="object_representation__table__order__input">{{item.order}}</span>
                        <v-btn icon color="success" @click="changeOrder('up', item)">
                            <i class="remix-icon ri-arrow-up-circle-line"></i>
                        </v-btn>
                        <v-btn icon color="success" @click="changeOrder('down', item)">
                            <i class="remix-icon ri-arrow-down-circle-line"></i>
                        </v-btn>
                        <v-btn icon color="success" @click="changeOrder('first', item)">
                            <i class="remix-icon ri-skip-up-line"></i>
                        </v-btn>
                        <v-btn icon color="success" @click="changeOrder('last', item)">
                            <i class="remix-icon ri-skip-down-line"></i>
                        </v-btn>

                    </div>
                </template>
                <template v-slot:item.multiple="{ item }">
                    <v-checkbox v-model="item.multiple" disabled></v-checkbox>
                </template>
                <template v-slot:item.includeDetailedForm="{ item }">
                    <v-checkbox v-model="item.includeDetailedForm"></v-checkbox>
                </template>
                <template v-slot:item.includeShortForm="{ item }">
                    <v-checkbox v-model="item.includeShortForm"></v-checkbox>
                </template>
                <template v-slot:item.includeTableView="{ item }">
                    <v-checkbox v-model="item.includeTableView"></v-checkbox>
                </template>
                <template v-slot:item.includeShortTableView="{ item }">
                    <v-checkbox v-model="item.includeShortTableView"></v-checkbox>
                </template>
                <template v-slot:item.availableTypesUISelect="{ item }">
                    <v-select 
                        v-model="item.typeElementUI.code" 
                        :items="item.availableTypesUI"
                        item-text="name"
                        item-value="code"
                        @change="onAvailableTypesUI($event, item)"
                    ></v-select>
                </template>
            </v-data-table>
            <div class="buttons_conteiner">
                <button class="buttons_conteiner__button" @click="$emit('set-Template-Representation', OBJECTS_TEMPLATE_REPRESENTATION)">Сохранить</button>
                <button class="buttons_conteiner__button">Пересчитать</button>
                <button class="buttons_conteiner__button" @click="$emit('close-edit')">Отмена</button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    props: {
        editData: {
            type: Object,
            default: () => {}
        },
    },
    data() {
        return {
            headers: [
                {text: 'Код', value: 'metaAttributeCode'},  
                {text: 'Наим.', value: 'metaAttributeName'},
                {text: '№ в отобр.', value: 'order'},
                {text: 'Массив', value: 'multiple'},
                {text: 'Полное опис.', value: 'includeDetailedForm'},
                {text: 'Краткое опис.', value: 'includeShortForm'},
                {text: 'Табличное опис.', value: 'includeTableView'},
                {text: 'Краткое табличное представление', value: 'includeShortTableView'},
                {text: 'UI', value: 'availableTypesUISelect'},
            ],
        }
    },
    computed: {
        ...mapGetters(['OBJECTS_TEMPLATE_REPRESENTATION']),
    },
    methods: {
        changeOrder(type, item) {
            if (type === 'up') {
                if (item.order === 0) {
                    return 
                } else {
                    const element = this.OBJECTS_TEMPLATE_REPRESENTATION.metaAttributeLinkTypes.find((el) => el.order === item.order - 1)
                    if (element) {
                        element.order += 1
                        item.order -= 1
                    }
                }
            } else if (type === 'down') {
                const element = this.OBJECTS_TEMPLATE_REPRESENTATION.metaAttributeLinkTypes.find((el) => el.order === item.order + 1)
                if (element) {
                        element.order -= 1
                        item.order += 1
                }
            } else if (type === 'last') {
                const maxOrder = Math.max(...this.OBJECTS_TEMPLATE_REPRESENTATION.metaAttributeLinkTypes.map((atr) => atr.order))
                this.OBJECTS_TEMPLATE_REPRESENTATION.metaAttributeLinkTypes.find((el) => el.order === maxOrder).order = item.order
                item.order = maxOrder
            }
        },
        onAvailableTypesUI(code, item) {

            item.typeElementUI = item.availableTypesUI.find((el) => el.code === code)

        }
    },
    mounted() {
        this.$emit('get-Template-Representation', this.editData)
    }

}
</script>

<style lang="scss" scoped>
.object_representation__table {
    height: calc(100vh - 240px);
    overflow-y: scroll;
}

.object_representation__table__order {
    display:flex;
    justify-content: flex-start;
    padding-left: 10px;
    padding-right: 10px;
}

.object_representation__table__order__input {
    width: 30px;
}

.remix-icon {
    color: #0ab39c;
    font-size: 17px;
}
</style>