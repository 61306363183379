<template>
  <div class="interface-designer">
    <div class="interface-designer__container" ref="conteiner">
      <div
        class="interface-designer__container__left"
        :style="{ width: `${leftWidth}%` }"
      >
        <v-expansion-panels v-model="panel">
          <v-expansion-panel v-for="(item, i) in panels" :key="i">
            <v-expansion-panel-header>{{ item }}</v-expansion-panel-header>
            <v-expansion-panel-content class="expansion-panel-content">
              <div v-if="item === 'Вкладки'">
                <div class="grooup__buttons__conteiner">
                  <button class="grooup__buttons" @click="addNewSystemTab">
                    +
                  </button>
                  <button class="grooup__buttons" @click="addNewTab">+</button>
                  <button
                    class="grooup__buttons"
                    :disabled="choosedTab === null"
                    @click="removeTab"
                  >
                    -
                  </button>
                </div>
                <div
                  @click="choosedTab = element.id"
                  :class="{
                    'dragable__text-block': true,
                    active__group:
                      choosedTab !== null && element.id === choosedTab,
                  }"
                  v-for="element in DETAILED_FORM_VIEW_XML_DESC"
                  :key="`tab-${element.id}`"
                >
                  <input
                    type="text"
                    v-if="element.id === choosedTab"
                    v-model="element.name"
                  />
                  <span v-else>{{ element.name }}</span>
                </div>
              </div>
              <div v-if="item === 'Компоненты'">
                <div
                  @drag="drag(element, 'content')"
                  @dragend="dragend(element)"
                  class="dragable__text-block"
                  draggable="true"
                  v-for="element in components"
                  :key="`component-${element.name}`"
                  unselectable="on"
                >
                  {{ element.name }}
                </div>
              </div>
              <div v-if="item === 'Группы'">
                <div class="grooup__buttons__conteiner">
                  <button class="grooup__buttons" @click="addNewGroup">
                    +
                  </button>
                  <button
                    class="grooup__buttons"
                    :disabled="activeGroup === null"
                    @click="removeGroup"
                  >
                    -
                  </button>
                </div>
                <div
                  @drag="drag(element, 'content')"
                  @dragend="dragend(element)"
                  @click="activeGroup = element"
                  :class="{
                    'dragable__text-block': true,
                    active__group:
                      activeGroup !== null && element.id === activeGroup.id,
                  }"
                  draggable="true"
                  v-for="(element, id) in groups"
                  :key="`box-${id}`"
                  unselectable="on"
                >
                  {{ element.name }}
                </div>
              </div>
              <div v-if="item === 'Кнопки статусов'">
                <div
                  @drag="drag(element, 'content')"
                  @dragend="dragend(element)"
                  class="dragable__text-block"
                  draggable="true"
                  v-for="element in statusButtons"
                  :key="`button-${element.id}`"
                  unselectable="on"
                >
                  <button class="btn-soft-save" small>
                    {{ element.name }}
                  </button>
                </div>
              </div>
              <div v-if="item === 'Атрибуты'">
                <div
                  v-for="attribute in attributesList"
                  :key="`attribute-${attribute.id}`"
                >
                  <div>
                    <p
                      :class="{
                        'attribute-title': true,
                        attribute_active:
                          SELECTED_FORM_VIEW_XML_DESC_MAIN.includes(
                            attribute.attributeId
                          ),
                      }"
                    >
                      {{ attribute.name }}
                    </p>
                    <p
                      :class="{
                        'attribute-title': true,
                        attribute_active:
                          SELECTED_FORM_VIEW_XML_DESC_MAIN.includes(
                            attribute.attributeId
                          ),
                      }"
                    >
                      [{{ attribute.code }}]
                    </p>
                    <div>
                      <div
                        @drag="drag(field, 'content')"
                        @dragend="dragend(field)"
                        :draggable="
                          !SELECTED_FORM_VIEW_XML_DESC_MAIN.includes(
                            attribute.attributeId
                          )
                        "
                        :class="{
                          'dragable__text-block': true,
                          'attribute-field': true,
                          attribute_active:
                            SELECTED_FORM_VIEW_XML_DESC_MAIN.includes(
                              attribute.attributeId
                            ),
                        }"
                        v-for="(field, i) in attribute.availableTypesUI"
                        :key="`${attribute.id}-${field.attributeCode}-${i}`"
                      >
                        -{{ atriburesNames[field.elementUIType] }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
      <div class="divider" @mousedown="onColumnMouseDown($event)"></div>
      <div
        class="interface-designer__container__right"
        :style="{ width: `${rightWidth}%` }"
      >
        <div class="interface-designer__container__right__buttons">
          <div class="interface-designer__container__right__buttons__tabs">
            <v-tabs
              v-model="activeTab"
              slider-color="blue"
              height="40px"
              fixed-tabs
            >
              <v-tab v-for="tab in DETAILED_FORM_VIEW_XML_DESC" :key="tab.id">
                {{ tab.name }}
              </v-tab>
            </v-tabs>
          </div>
          <!--
                    <div class="interface-designer__container__right__buttons__tabs">
                        <button class="interface-designer__container__right__button">Назад</button>
                        <button class="interface-designer__container__right__button">Вперед</button>
                    </div>  
                    --->
        </div>
        <div
          class="interface-designer__container__right__interface"
          id="content"
          :style="{
            width: `${scale.width}%`,
            height: `${scale.height}%`,
            transform: `scale(${scale.transform / 100})`,
            transformOrigin: 'top left',
          }"
        >
          <v-tabs-items v-model="activeTab">
            <v-tab-item
              v-for="(tab, tabI) in DETAILED_FORM_VIEW_XML_DESC"
              :key="`${tab.i}-${tabI}`"
            >
              <grid-layout
                ref="gridlayout"
                :layout.sync="tab.components"
                :col-num="32"
                :row-height="40"
                :is-draggable="true"
                :is-resizable="true"
                :vertical-compact="true"
                :use-css-transforms="true"
              >
                <div
                  v-for="(item, index) in tab.components"
                  :key="`${item.i}-${index}`"
                >
                                  <!-- {{ item }} -->
                  <grid-item
                    v-if="item.x || item.y || item.w || item.h || item.i"
                    :x="item.x"
                    :y="item.y"
                    :w="item.w"
                    :h="item.h"
                    :i="item.i"
                    :id="`${item.code || item.containerCode}`"
                    class="grid-item"
                    @move="move(item, 'content')"
                    @moved="moveEnd(item, 'content')"
                  >
                    <div class="grid-item__main" style="height: 100%">
                      <div class="grid-item__main__left" style="height: 100%">
                        <interface-designer-item
                          :item="item"
                        ></interface-designer-item>

                        <div
                          v-if="item.type === 'group' || item.type === 'box'"
                          :id="`groupcontent${item.i}`"
                          style="height: 100%"
                        >
                          <p
                            style="height: 10px; margin-bottom: 0px"
                            v-if="item.type === 'group'"
                          >
                            {{ item.name }}
                          </p>
                          <grid-layout
                            :ref="`layout-${item.i}`"
                            :layout.sync="item.components"
                            :col-num="32"
                            :row-height="30"
                            :is-draggable="true"
                            :is-resizable="true"
                            :vertical-compact="true"
                            :use-css-transforms="true"
                          >
                            <grid-item
                              v-for="(innerItem, innerIndex) in item.components"
                              :x="innerItem.x"
                              :y="innerItem.y"
                              :w="innerItem.w"
                              :h="innerItem.h"
                              :i="innerItem.i"
                              :id="`${
                                innerItem.code || innerItem.containerCode
                              }`"
                              :key="`innerItem-${innerItem.i}-${innerIndex}`"
                              class="grid-item"
                              @moved="
                                moveEnd(
                                  innerItem,
                                  `groupcontent${item.i}`,
                                  index
                                )
                              "
                              @move="
                                moveEnd(item, `groupcontent${item.i}`, index)
                              "
                            >
                              <div class="grid-item__main" style="height: 100%">
                                <div
                                  class="grid-item__main__left"
                                  style="height: 100%"
                                >
                                  <interface-designer-item
                                    :item="innerItem"
                                  ></interface-designer-item>

                                  <div
                                    v-if="
                                      innerItem.type === 'group' ||
                                      innerItem.type === 'box'
                                    "
                                    :id="`groupcontent${innerItem.i}`"
                                    style="height: 100%"
                                  >
                                    <p
                                      style="height: 10px; margin-bottom: 0px"
                                      v-if="innerItem.type === 'group'"
                                    >
                                      {{ innerItem.name }}
                                    </p>
                                    <grid-layout
                                      :ref="`layout-${innerItem.i}`"
                                      :layout.sync="innerItem.components"
                                      :col-num="32"
                                      :row-height="30"
                                      :is-draggable="true"
                                      :is-resizable="true"
                                      :vertical-compact="true"
                                      :use-css-transforms="true"
                                    >
                                      <grid-item
                                        v-for="(
                                          secondInnerItem, secondI
                                        ) in innerItem.components"
                                        :x="secondInnerItem.x"
                                        :y="secondInnerItem.y"
                                        :w="secondInnerItem.w"
                                        :h="secondInnerItem.h"
                                        :i="secondInnerItem.i"
                                        :id="`${secondInnerItem.code}`"
                                        :key="`secondInnerItem-${secondInnerItem.i}-${secondI}`"
                                        class="grid-item"
                                        @moved="
                                          moveEnd(
                                            innerItem,
                                            `groupcontent${innerItem.i}`,
                                            index,
                                            innerIndex
                                          )
                                        "
                                        @move="
                                          moveEnd(
                                            item,
                                            `groupcontent${innerItem.i}`,
                                            index,
                                            innerIndex
                                          )
                                        "
                                      >
                                        <div
                                          class="grid-item__main"
                                          style="height: 100%"
                                        >
                                          <div
                                            class="grid-item__main__left"
                                            style="height: 100%"
                                          >
                                            <interface-designer-item
                                              :item="secondInnerItem"
                                            ></interface-designer-item>
                                            <div
                                              v-if="
                                                secondInnerItem.type ===
                                                  'group' ||
                                                secondInnerItem.type === 'box'
                                              "
                                              :id="`groupcontent${secondInnerItem.i}`"
                                              style="height: 100%"
                                            >
                                              <p
                                                style="
                                                  height: 10px;
                                                  margin-bottom: 0px;
                                                "
                                                v-if="
                                                  secondInnerItem.type ===
                                                  'group'
                                                "
                                              >
                                                {{ secondInnerItem.name }}
                                              </p>
                                              <grid-layout
                                                :ref="`layout-${secondInnerItem.i}`"
                                                :layout.sync="
                                                  secondInnerItem.components
                                                "
                                                :col-num="32"
                                                :row-height="30"
                                                :is-draggable="true"
                                                :is-resizable="true"
                                                :vertical-compact="true"
                                                :use-css-transforms="true"
                                              >
                                                <grid-item
                                                  v-for="trirdInnerItem in secondInnerItem.components"
                                                  :x="trirdInnerItem.x"
                                                  :y="trirdInnerItem.y"
                                                  :w="trirdInnerItem.w"
                                                  :h="trirdInnerItem.h"
                                                  :i="trirdInnerItem.i"
                                                  :id="`${trirdInnerItem.code}`"
                                                  :key="`trirdInnerItem-${trirdInnerItem.i}`"
                                                  class="grid-item"
                                                  @moved="
                                                    moveEnd(
                                                      secondInnerItem,
                                                      `groupcontent${secondInnerItem.i}`,
                                                      index,
                                                      innerIndex
                                                    )
                                                  "
                                                  @move="
                                                    moveEnd(
                                                      item,
                                                      `groupcontent${secondInnerItem.i}`,
                                                      index,
                                                      innerIndex
                                                    )
                                                  "
                                                >
                                                  <div
                                                    class="grid-item__main"
                                                    style="height: 100%"
                                                  >
                                                    <div
                                                      class="grid-item__main__left"
                                                      style="height: 100%"
                                                    >
                                                      <interface-designer-item
                                                        :item="trirdInnerItem"
                                                      ></interface-designer-item>
                                                      <div
                                                        v-if="
                                                          trirdInnerItem.type ===
                                                            'group' ||
                                                          trirdInnerItem.type ===
                                                            'box'
                                                        "
                                                        :id="`groupcontent${trirdInnerItem.i}`"
                                                        style="height: 100%"
                                                      >
                                                        <p
                                                          style="
                                                            height: 10px;
                                                            margin-bottom: 0px;
                                                          "
                                                          v-if="
                                                            trirdInnerItem.type ===
                                                            'group'
                                                          "
                                                        >
                                                          {{
                                                            trirdInnerItem.name
                                                          }}
                                                        </p>
                                                        <grid-layout
                                                          :ref="`layout-${trirdInnerItem.i}`"
                                                          :layout.sync="
                                                            trirdInnerItem.components
                                                          "
                                                          :col-num="32"
                                                          :row-height="30"
                                                          :is-draggable="true"
                                                          :is-resizable="true"
                                                          :vertical-compact="
                                                            true
                                                          "
                                                          :use-css-transforms="
                                                            true
                                                          "
                                                        >
                                                          <grid-item
                                                            v-for="fourthInnerItem in trirdInnerItem.components"
                                                            :x="
                                                              fourthInnerItem.x
                                                            "
                                                            :y="
                                                              fourthInnerItem.y
                                                            "
                                                            :w="
                                                              fourthInnerItem.w
                                                            "
                                                            :h="
                                                              fourthInnerItem.h
                                                            "
                                                            :i="
                                                              fourthInnerItem.i
                                                            "
                                                            :key="`fourthInnerItem-${fourthInnerItem.i}`"
                                                            class="grid-item"
                                                            @moved="
                                                              moveEnd(
                                                                trirdInnerItem,
                                                                `groupcontent${trirdInnerItem.i}`,
                                                                index,
                                                                innerIndex
                                                              )
                                                            "
                                                            @move="
                                                              moveEnd(
                                                                item,
                                                                `groupcontent${trirdInnerItem.i}`,
                                                                index,
                                                                innerIndex
                                                              )
                                                            "
                                                          >
                                                            <div
                                                              class="grid-item__main"
                                                              style="
                                                                height: 100%;
                                                              "
                                                            >
                                                              <div
                                                                class="grid-item__main__left"
                                                                style="
                                                                  height: 100%;
                                                                "
                                                              >
                                                                <interface-designer-item
                                                                  :item="
                                                                    fourthInnerItem
                                                                  "
                                                                ></interface-designer-item>
                                                                <div
                                                                  v-if="
                                                                    fourthInnerItem.type ===
                                                                      'group' ||
                                                                    fourthInnerItem.type ===
                                                                      'box'
                                                                  "
                                                                  :id="`groupcontent${fourthInnerItem.i}`"
                                                                  style="
                                                                    height: 100%;
                                                                  "
                                                                >
                                                                  <p
                                                                    style="
                                                                      height: 10px;
                                                                      margin-bottom: 0px;
                                                                    "
                                                                    v-if="
                                                                      fourthInnerItem.type ===
                                                                      'group'
                                                                    "
                                                                  >
                                                                    {{
                                                                      fourthInnerItem.name
                                                                    }}
                                                                  </p>
                                                                  <grid-layout
                                                                    :ref="`layout-${fourthInnerItem.i}`"
                                                                    :layout.sync="
                                                                      fourthInnerItem.components
                                                                    "
                                                                    :col-num="
                                                                      32
                                                                    "
                                                                    :row-height="
                                                                      30
                                                                    "
                                                                    :is-draggable="
                                                                      true
                                                                    "
                                                                    :is-resizable="
                                                                      true
                                                                    "
                                                                    :vertical-compact="
                                                                      true
                                                                    "
                                                                    :use-css-transforms="
                                                                      true
                                                                    "
                                                                  >
                                                                    <grid-item
                                                                      v-for="fifthInnerItem in fourthInnerItem.components"
                                                                      :x="
                                                                        fifthInnerItem.x
                                                                      "
                                                                      :y="
                                                                        fifthInnerItem.y
                                                                      "
                                                                      :w="
                                                                        fifthInnerItem.w
                                                                      "
                                                                      :h="
                                                                        fifthInnerItem.h
                                                                      "
                                                                      :i="
                                                                        fifthInnerItem.i
                                                                      "
                                                                      :key="`fifthInnerItem-${fifthInnerItem.i}`"
                                                                      class="grid-item"
                                                                      @moved="
                                                                        moveEnd(
                                                                          fourthInnerItem,
                                                                          `groupcontent${fourthInnerItem.i}`,
                                                                          index,
                                                                          innerIndex
                                                                        )
                                                                      "
                                                                      @move="
                                                                        moveEnd(
                                                                          item,
                                                                          `groupcontent${fourthInnerItem.i}`,
                                                                          index,
                                                                          innerIndex
                                                                        )
                                                                      "
                                                                    >
                                                                      <div
                                                                        class="grid-item__main"
                                                                        style="
                                                                          height: 100%;
                                                                        "
                                                                      >
                                                                        <div
                                                                          class="grid-item__main__left"
                                                                          style="
                                                                            height: 100%;
                                                                          "
                                                                        >
                                                                          <interface-designer-item
                                                                            :item="
                                                                              fifthInnerItem
                                                                            "
                                                                          ></interface-designer-item>
                                                                          <div
                                                                            v-if="
                                                                              fifthInnerItem.type ===
                                                                                'group' ||
                                                                              fifthInnerItem.type ===
                                                                                'box'
                                                                            "
                                                                            :id="`groupcontent${fifthInnerItem.i}`"
                                                                            style="
                                                                              height: 100%;
                                                                            "
                                                                          >
                                                                            <p
                                                                              style="
                                                                                height: 10px;
                                                                                margin-bottom: 0px;
                                                                              "
                                                                              v-if="
                                                                                fifthInnerItem.type ===
                                                                                'group'
                                                                              "
                                                                            >
                                                                              {{
                                                                                fifthInnerItem.name
                                                                              }}
                                                                            </p>
                                                                            <grid-layout
                                                                              :ref="`layout-${fifthInnerItem.i}`"
                                                                              :layout.sync="
                                                                                fifthInnerItem.components
                                                                              "
                                                                              :col-num="
                                                                                32
                                                                              "
                                                                              :row-height="
                                                                                30
                                                                              "
                                                                              :is-draggable="
                                                                                true
                                                                              "
                                                                              :is-resizable="
                                                                                true
                                                                              "
                                                                              :vertical-compact="
                                                                                true
                                                                              "
                                                                              :use-css-transforms="
                                                                                true
                                                                              "
                                                                            >
                                                                              <grid-item
                                                                                v-for="sixInnerItem in fifthInnerItem.components"
                                                                                :x="
                                                                                  sixInnerItem.x
                                                                                "
                                                                                :y="
                                                                                  sixInnerItem.y
                                                                                "
                                                                                :w="
                                                                                  sixInnerItem.w
                                                                                "
                                                                                :h="
                                                                                  sixInnerItem.h
                                                                                "
                                                                                :i="
                                                                                  sixInnerItem.i
                                                                                "
                                                                                :key="`sixInnerItem-${sixInnerItem.i}`"
                                                                                class="grid-item"
                                                                                @moved="
                                                                                  moveEnd(
                                                                                    fifthInnerItem,
                                                                                    `groupcontent${fifthInnerItem.i}`,
                                                                                    index,
                                                                                    innerIndex
                                                                                  )
                                                                                "
                                                                                @move="
                                                                                  moveEnd(
                                                                                    item,
                                                                                    `groupcontent${fifthInnerItem.i}`,
                                                                                    index,
                                                                                    innerIndex
                                                                                  )
                                                                                "
                                                                              >
                                                                                <div
                                                                                  class="grid-item__main"
                                                                                  style="
                                                                                    height: 100%;
                                                                                  "
                                                                                >
                                                                                  <div
                                                                                    class="grid-item__main__left"
                                                                                    style="
                                                                                      height: 100%;
                                                                                    "
                                                                                  >
                                                                                    <interface-designer-item
                                                                                      :item="
                                                                                        sixInnerItem
                                                                                      "
                                                                                    ></interface-designer-item>
                                                                                    <div
                                                                                      v-if="
                                                                                        sixInnerItem.type ===
                                                                                          'group' ||
                                                                                        sixInnerItem.type ===
                                                                                          'box'
                                                                                      "
                                                                                      :id="`groupcontent${sixInnerItem.i}`"
                                                                                      style="
                                                                                        height: 100%;
                                                                                      "
                                                                                    >
                                                                                      <p
                                                                                        style="
                                                                                          height: 10px;
                                                                                          margin-bottom: 0px;
                                                                                        "
                                                                                        v-if="
                                                                                          sixInnerItem.type ===
                                                                                          'group'
                                                                                        "
                                                                                      >
                                                                                        {{
                                                                                          fifthInnerItem.name
                                                                                        }}
                                                                                      </p>
                                                                                      <grid-layout
                                                                                        :ref="`layout-${sixInnerItem.i}`"
                                                                                        :layout.sync="
                                                                                          sixInnerItem.components
                                                                                        "
                                                                                        :col-num="
                                                                                          32
                                                                                        "
                                                                                        :row-height="
                                                                                          30
                                                                                        "
                                                                                        :is-draggable="
                                                                                          true
                                                                                        "
                                                                                        :is-resizable="
                                                                                          true
                                                                                        "
                                                                                        :vertical-compact="
                                                                                          true
                                                                                        "
                                                                                        :use-css-transforms="
                                                                                          true
                                                                                        "
                                                                                      >
                                                                                        <grid-item
                                                                                          v-for="seventhInnerItem in sixInnerItem.components"
                                                                                          :x="
                                                                                            seventhInnerItem.x
                                                                                          "
                                                                                          :y="
                                                                                            seventhInnerItem.y
                                                                                          "
                                                                                          :w="
                                                                                            seventhInnerItem.w
                                                                                          "
                                                                                          :h="
                                                                                            seventhInnerItem.h
                                                                                          "
                                                                                          :i="
                                                                                            seventhInnerItem.i
                                                                                          "
                                                                                          :key="`seventhInnerItem-${seventhInnerItem.i}`"
                                                                                          class="grid-item"
                                                                                          @moved="
                                                                                            moveEnd(
                                                                                              sixInnerItem,
                                                                                              `groupcontent${sixInnerItem.i}`,
                                                                                              index,
                                                                                              innerIndex
                                                                                            )
                                                                                          "
                                                                                          @move="
                                                                                            moveEnd(
                                                                                              item,
                                                                                              `groupcontent${sixInnerItem.i}`,
                                                                                              index,
                                                                                              innerIndex
                                                                                            )
                                                                                          "
                                                                                        >
                                                                                          <div
                                                                                            class="grid-item__main"
                                                                                            style="
                                                                                              height: 100%;
                                                                                            "
                                                                                          >
                                                                                            <div
                                                                                              class="grid-item__main__left"
                                                                                              style="
                                                                                                height: 100%;
                                                                                              "
                                                                                            >
                                                                                              <interface-designer-item
                                                                                                :item="
                                                                                                  seventhInnerItem
                                                                                                "
                                                                                              ></interface-designer-item>
                                                                                            </div>
                                                                                            <div
                                                                                              class="grid-item__main__right"
                                                                                            >
                                                                                              <button
                                                                                                @click="
                                                                                                  deleteItem(
                                                                                                    seventhInnerItem,
                                                                                                    item
                                                                                                  )
                                                                                                "
                                                                                              >
                                                                                                x
                                                                                              </button>
                                                                                              <button
                                                                                                v-if="
                                                                                                  editableTypes.includes(
                                                                                                    seventhInnerItem.type
                                                                                                  ) ||
                                                                                                  fsixInnerItem.attributeId
                                                                                                "
                                                                                                @click="
                                                                                                  openEditWindow(
                                                                                                    seventhInnerItem
                                                                                                  )
                                                                                                "
                                                                                              >
                                                                                                o
                                                                                              </button>
                                                                                              <v-tooltip
                                                                                                top
                                                                                              >
                                                                                                <template
                                                                                                  v-slot:activator="{
                                                                                                    on,
                                                                                                    attrs,
                                                                                                  }"
                                                                                                >
                                                                                                  <button
                                                                                                    @click="
                                                                                                      toUpperLevel(
                                                                                                        seventhInnerItem
                                                                                                      )
                                                                                                    "
                                                                                                    v-bind="
                                                                                                      attrs
                                                                                                    "
                                                                                                    v-on="
                                                                                                      on
                                                                                                    "
                                                                                                  >
                                                                                                    &#8593;
                                                                                                  </button>
                                                                                                </template>
                                                                                                <span
                                                                                                  >На
                                                                                                  верхний
                                                                                                  уровень</span
                                                                                                >
                                                                                              </v-tooltip>
                                                                                            </div>
                                                                                          </div>
                                                                                        </grid-item>
                                                                                      </grid-layout>
                                                                                    </div>
                                                                                  </div>
                                                                                  <div
                                                                                    class="grid-item__main__right"
                                                                                  >
                                                                                    <button
                                                                                      @click="
                                                                                        deleteItem(
                                                                                          sixInnerItem,
                                                                                          item
                                                                                        )
                                                                                      "
                                                                                    >
                                                                                      x
                                                                                    </button>
                                                                                    <button
                                                                                      v-if="
                                                                                        editableTypes.includes(
                                                                                          sixInnerItem.type
                                                                                        ) ||
                                                                                        fsixInnerItem.attributeId
                                                                                      "
                                                                                      @click="
                                                                                        openEditWindow(
                                                                                          sixInnerItem
                                                                                        )
                                                                                      "
                                                                                    >
                                                                                      o
                                                                                    </button>
                                                                                    <v-tooltip
                                                                                      top
                                                                                    >
                                                                                      <template
                                                                                        v-slot:activator="{
                                                                                          on,
                                                                                          attrs,
                                                                                        }"
                                                                                      >
                                                                                        <button
                                                                                          @click="
                                                                                            toUpperLevel(
                                                                                              sixInnerItem
                                                                                            )
                                                                                          "
                                                                                          v-bind="
                                                                                            attrs
                                                                                          "
                                                                                          v-on="
                                                                                            on
                                                                                          "
                                                                                        >
                                                                                          &#8593;
                                                                                        </button>
                                                                                      </template>
                                                                                      <span
                                                                                        >На
                                                                                        верхний
                                                                                        уровень</span
                                                                                      >
                                                                                    </v-tooltip>
                                                                                  </div>
                                                                                </div>
                                                                              </grid-item>
                                                                            </grid-layout>
                                                                          </div>
                                                                        </div>
                                                                        <div
                                                                          class="grid-item__main__right"
                                                                        >
                                                                          <button
                                                                            @click="
                                                                              deleteItem(
                                                                                fifthInnerItem,
                                                                                item
                                                                              )
                                                                            "
                                                                          >
                                                                            x
                                                                          </button>
                                                                          <button
                                                                            v-if="
                                                                              editableTypes.includes(
                                                                                fifthInnerItem.type
                                                                              ) ||
                                                                              fifthInnerItem.attributeId
                                                                            "
                                                                            @click="
                                                                              openEditWindow(
                                                                                fifthInnerItem
                                                                              )
                                                                            "
                                                                          >
                                                                            o
                                                                          </button>
                                                                          <v-tooltip
                                                                            top
                                                                          >
                                                                            <template
                                                                              v-slot:activator="{
                                                                                on,
                                                                                attrs,
                                                                              }"
                                                                            >
                                                                              <button
                                                                                @click="
                                                                                  toUpperLevel(
                                                                                    fifthInnerItem
                                                                                  )
                                                                                "
                                                                                v-bind="
                                                                                  attrs
                                                                                "
                                                                                v-on="
                                                                                  on
                                                                                "
                                                                              >
                                                                                &#8593;
                                                                              </button>
                                                                            </template>
                                                                            <span
                                                                              >На
                                                                              верхний
                                                                              уровень</span
                                                                            >
                                                                          </v-tooltip>
                                                                        </div>
                                                                      </div>
                                                                    </grid-item>
                                                                  </grid-layout>
                                                                </div>
                                                              </div>
                                                              <div
                                                                class="grid-item__main__right"
                                                              >
                                                                <button
                                                                  @click="
                                                                    deleteItem(
                                                                      fourthInnerItem,
                                                                      item
                                                                    )
                                                                  "
                                                                >
                                                                  x
                                                                </button>
                                                                <button
                                                                  v-if="
                                                                    editableTypes.includes(
                                                                      fourthInnerItem.type
                                                                    ) ||
                                                                    fourthInnerItem.attributeId
                                                                  "
                                                                  @click="
                                                                    openEditWindow(
                                                                      fourthInnerItem
                                                                    )
                                                                  "
                                                                >
                                                                  o
                                                                </button>
                                                                <v-tooltip top>
                                                                  <template
                                                                    v-slot:activator="{
                                                                      on,
                                                                      attrs,
                                                                    }"
                                                                  >
                                                                    <button
                                                                      @click="
                                                                        toUpperLevel(
                                                                          fourthInnerItem
                                                                        )
                                                                      "
                                                                      v-bind="
                                                                        attrs
                                                                      "
                                                                      v-on="on"
                                                                    >
                                                                      &#8593;
                                                                    </button>
                                                                  </template>
                                                                  <span
                                                                    >На верхний
                                                                    уровень</span
                                                                  >
                                                                </v-tooltip>
                                                              </div>
                                                            </div>
                                                          </grid-item>
                                                        </grid-layout>
                                                      </div>
                                                    </div>
                                                    <div
                                                      class="grid-item__main__right"
                                                    >
                                                      <button
                                                        @click="
                                                          deleteItem(
                                                            trirdInnerItem,
                                                            item
                                                          )
                                                        "
                                                      >
                                                        x
                                                      </button>
                                                      <button
                                                        v-if="
                                                          editableTypes.includes(
                                                            trirdInnerItem.type
                                                          ) ||
                                                          trirdInnerItem.attributeId
                                                        "
                                                        @click="
                                                          openEditWindow(
                                                            trirdInnerItem
                                                          )
                                                        "
                                                      >
                                                        o
                                                      </button>
                                                      <v-tooltip top>
                                                        <template
                                                          v-slot:activator="{
                                                            on,
                                                            attrs,
                                                          }"
                                                        >
                                                          <button
                                                            @click="
                                                              toUpperLevel(
                                                                trirdInnerItem
                                                              )
                                                            "
                                                            v-bind="attrs"
                                                            v-on="on"
                                                          >
                                                            &#8593;
                                                          </button>
                                                        </template>
                                                        <span
                                                          >На верхний
                                                          уровень</span
                                                        >
                                                      </v-tooltip>
                                                    </div>
                                                  </div>
                                                </grid-item>
                                              </grid-layout>
                                            </div>
                                          </div>
                                          <div class="grid-item__main__right">
                                            <button
                                              @click="
                                                deleteItem(
                                                  secondInnerItem,
                                                  item
                                                )
                                              "
                                            >
                                              x
                                            </button>
                                            <button
                                              v-if="
                                                editableTypes.includes(
                                                  secondInnerItem.type
                                                ) || secondInnerItem.attributeId
                                              "
                                              @click="
                                                openEditWindow(secondInnerItem)
                                              "
                                            >
                                              o
                                            </button>
                                            <v-tooltip top>
                                              <template
                                                v-slot:activator="{ on, attrs }"
                                              >
                                                <button
                                                  @click="
                                                    toUpperLevel(
                                                      secondInnerItem
                                                    )
                                                  "
                                                  v-bind="attrs"
                                                  v-on="on"
                                                >
                                                  &#8593;
                                                </button>
                                              </template>
                                              <span>На верхний уровень</span>
                                            </v-tooltip>
                                          </div>
                                        </div>
                                      </grid-item>
                                    </grid-layout>
                                  </div>
                                </div>
                                <div class="grid-item__main__right">
                                  <button @click="deleteItem(innerItem)">
                                    x
                                  </button>
                                  <button
                                    v-if="
                                      editableTypes.includes(innerItem.type) ||
                                      innerItem.attributeId
                                    "
                                    @click="openEditWindow(innerItem)"
                                  >
                                    o
                                  </button>
                                  <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                      <button
                                        @click="toUpperLevel(innerItem)"
                                        v-bind="attrs"
                                        v-on="on"
                                      >
                                        &#8593;
                                      </button>
                                    </template>
                                    <span>На верхний уровень</span>
                                  </v-tooltip>
                                </div>
                              </div>
                            </grid-item>
                          </grid-layout>
                        </div>
                      </div>
                      <div class="grid-item__main__right">
                        <button @click="deleteItem(item)">x</button>
                        <button
                          v-if="
                            editableTypes.includes(item.type) ||
                            item.attributeId
                          "
                          @click="openEditWindow(item)"
                        >
                          o
                        </button>
                      </div>
                    </div>
                  </grid-item>
                  <div
                    v-else
                    class="grid-item"
                    @move="moveEnd(item, 'content')"
                    @moved="moveEnd(item, 'content')"
                  >
                    <div class="grid-item__main" style="height: 100%">
                      <div class="grid-item__main__left" style="height: 100%">
                        <!-- {{ item }} -->
                        <interface-designer-item
                          :item="item"
                        ></interface-designer-item>
                        <div
                          v-if="item.type === 'group' || item.type === 'box'"
                          :id="`groupcontent${item.layout}`"
                          style="height: 100%"
                        >
                          <p
                            style="height: 10px; margin-bottom: 0px"
                            v-if="item.type === 'group'"
                          >
                            {{ item.name }}
                          </p>
                          <grid-layout
                            :ref="`layout-${item.layout}`"
                            :layout.sync="item.components"
                            :col-num="32"
                            :row-height="30"
                            :is-draggable="true"
                            :is-resizable="true"
                            :vertical-compact="true"
                            :use-css-transforms="true"
                          >
                            <div
                              v-for="(innerItem, innerIndex) in item.components"
                              :key="`innerItem-${innerItem.layout}-${innerIndex}`"
                              class="grid-item"
                              @moved="
                                moveEnd(
                                  innerItem,
                                  `groupcontent${item.layout}`,
                                  index
                                )
                              "
                              @move="
                                moveEnd(
                                  item,
                                  `groupcontent${item.layout}`,
                                  index
                                )
                              "
                            >
                              <div class="grid-item__main" style="height: 100%">
                                <div
                                  class="grid-item__main__left"
                                  style="height: 100%"
                                >
                                  <!-- {{ item }} -->
                                  <interface-designer-item
                                    :item="innerItem"
                                  ></interface-designer-item>

                                  <div
                                    v-if="
                                      innerItem.type === 'group' ||
                                      innerItem.type === 'box'
                                    "
                                    :id="`groupcontent${innerItem.layout}`"
                                    style="height: 100%"
                                  >
                                    <p
                                      style="height: 10px; margin-bottom: 0px"
                                      v-if="innerItem.type === 'group'"
                                    >
                                      {{ innerItem.name }}
                                    </p>
                                    <grid-layout
                                      :ref="`layout-${innerItem.layout}`"
                                      :layout.sync="innerItem.components"
                                      :col-num="32"
                                      :row-height="30"
                                      :is-draggable="true"
                                      :is-resizable="true"
                                      :vertical-compact="true"
                                      :use-css-transforms="true"
                                    >
                                      <div
                                        v-for="(
                                          secondInnerItem, secondI
                                        ) in innerItem.components"
                                        :key="`secondInnerItem-${secondInnerItem.layout}-${secondI}`"
                                        class="grid-item"
                                        @moved="
                                          moveEnd(
                                            innerItem,
                                            `groupcontent${innerItem.layout}`,
                                            index,
                                            innerIndex
                                          )
                                        "
                                        @move="
                                          moveEnd(
                                            item,
                                            `groupcontent${innerItem.layout}`,
                                            index,
                                            innerIndex
                                          )
                                        "
                                      >
                                        <div
                                          class="grid-item__main"
                                          style="height: 100%"
                                        >
                                          <div
                                            class="grid-item__main__left"
                                            style="height: 100%"
                                          >
                                            <!-- {{ item }} -->
                                            <interface-designer-item
                                              :item="secondInnerItem"
                                            ></interface-designer-item>
                                            <div
                                              v-if="
                                                secondInnerItem.type ===
                                                  'group' ||
                                                secondInnerItem.type === 'box'
                                              "
                                              :id="`groupcontent${secondInnerItem.layout}`"
                                              style="height: 100%"
                                            >
                                              <p
                                                style="
                                                  height: 10px;
                                                  margin-bottom: 0px;
                                                "
                                                v-if="
                                                  secondInnerItem.type ===
                                                  'group'
                                                "
                                              >
                                                {{ secondInnerItem.name }}
                                              </p>
                                              <grid-layout
                                                :ref="`layout-${secondInnerItem.layout}`"
                                                :layout.sync="
                                                  secondInnerItem.components
                                                "
                                                :col-num="32"
                                                :row-height="30"
                                                :is-draggable="true"
                                                :is-resizable="true"
                                                :vertical-compact="true"
                                                :use-css-transforms="true"
                                              >
                                                <div
                                                  v-for="trirdInnerItem in secondInnerItem.components"
                                                  :key="`trirdInnerItem-${trirdInnerItem.layout}`"
                                                  class="grid-item"
                                                  @moved="
                                                    moveEnd(
                                                      secondInnerItem,
                                                      `groupcontent${secondInnerItem.i}`,
                                                      index,
                                                      innerIndex
                                                    )
                                                  "
                                                  @move="
                                                    moveEnd(
                                                      item,
                                                      `groupcontent${secondInnerItem.layout}`,
                                                      index,
                                                      innerIndex
                                                    )
                                                  "
                                                >
                                                  <div
                                                    class="grid-item__main"
                                                    style="height: 100%"
                                                  >
                                                    <div
                                                      class="grid-item__main__left"
                                                      style="height: 100%"
                                                    >
                                                      <!-- {{ item }} -->
                                                      <interface-designer-item
                                                        :item="trirdInnerItem"
                                                      ></interface-designer-item>
                                                      <div
                                                        v-if="
                                                          trirdInnerItem.type ===
                                                            'group' ||
                                                          trirdInnerItem.type ===
                                                            'box'
                                                        "
                                                        :id="`groupcontent${trirdInnerItem.layout}`"
                                                        style="height: 100%"
                                                      >
                                                        <p
                                                          style="
                                                            height: 10px;
                                                            margin-bottom: 0px;
                                                          "
                                                          v-if="
                                                            trirdInnerItem.type ===
                                                            'group'
                                                          "
                                                        >
                                                          {{
                                                            trirdInnerItem.name
                                                          }}
                                                        </p>
                                                        <grid-layout
                                                          :ref="`layout-${trirdInnerItem.layout}`"
                                                          :layout.sync="
                                                            trirdInnerItem.components
                                                          "
                                                          :col-num="32"
                                                          :row-height="30"
                                                          :is-draggable="true"
                                                          :is-resizable="true"
                                                          :vertical-compact="
                                                            true
                                                          "
                                                          :use-css-transforms="
                                                            true
                                                          "
                                                        >
                                                          <div
                                                            v-for="fourthInnerItem in trirdInnerItem.components"
                                                            :key="`fourthInnerItem-${fourthInnerItem.layout}`"
                                                            class="grid-item"
                                                            @moved="
                                                              moveEnd(
                                                                trirdInnerItem,
                                                                `groupcontent${trirdInnerItem.layout}`,
                                                                index,
                                                                innerIndex
                                                              )
                                                            "
                                                            @move="
                                                              moveEnd(
                                                                item,
                                                                `groupcontent${trirdInnerItem.layout}`,
                                                                index,
                                                                innerIndex
                                                              )
                                                            "
                                                          >
                                                            <div
                                                              class="grid-item__main"
                                                              style="
                                                                height: 100%;
                                                              "
                                                            >
                                                              <div
                                                                class="grid-item__main__left"
                                                                style="
                                                                  height: 100%;
                                                                "
                                                              >
                                                                <!-- {{ item }} -->
                                                                <interface-designer-item
                                                                  :item="
                                                                    fourthInnerItem
                                                                  "
                                                                ></interface-designer-item>
                                                                <div
                                                                  v-if="
                                                                    fourthInnerItem.type ===
                                                                      'group' ||
                                                                    fourthInnerItem.type ===
                                                                      'box'
                                                                  "
                                                                  :id="`groupcontent${fourthInnerItem.layout}`"
                                                                  style="
                                                                    height: 100%;
                                                                  "
                                                                >
                                                                  <p
                                                                    style="
                                                                      height: 10px;
                                                                      margin-bottom: 0px;
                                                                    "
                                                                    v-if="
                                                                      fourthInnerItem.type ===
                                                                      'group'
                                                                    "
                                                                  >
                                                                    {{
                                                                      fourthInnerItem.name
                                                                    }}
                                                                  </p>
                                                                  <grid-layout
                                                                    :ref="`layout-${fourthInnerItem.layout}`"
                                                                    :layout.sync="
                                                                      fourthInnerItem.components
                                                                    "
                                                                    :col-num="
                                                                      32
                                                                    "
                                                                    :row-height="
                                                                      30
                                                                    "
                                                                    :is-draggable="
                                                                      true
                                                                    "
                                                                    :is-resizable="
                                                                      true
                                                                    "
                                                                    :vertical-compact="
                                                                      true
                                                                    "
                                                                    :use-css-transforms="
                                                                      true
                                                                    "
                                                                  >
                                                                    <div
                                                                      v-for="fifthInnerItem in fourthInnerItem.components"
                                                                      :key="`fifthInnerItem-${fifthInnerItem.layout}`"
                                                                      class="grid-item"
                                                                      @moved="
                                                                        moveEnd(
                                                                          fourthInnerItem,
                                                                          `groupcontent${fourthInnerItem.layout}`,
                                                                          index,
                                                                          innerIndex
                                                                        )
                                                                      "
                                                                      @move="
                                                                        moveEnd(
                                                                          item,
                                                                          `groupcontent${fourthInnerItem.layout}`,
                                                                          index,
                                                                          innerIndex
                                                                        )
                                                                      "
                                                                    >
                                                                      <div
                                                                        class="grid-item__main"
                                                                        style="
                                                                          height: 100%;
                                                                        "
                                                                      >
                                                                        <div
                                                                          class="grid-item__main__left"
                                                                          style="
                                                                            height: 100%;
                                                                          "
                                                                        >
                                                                          <!-- {{
                                                                            item
                                                                          }} -->
                                                                          <interface-designer-item
                                                                            :item="
                                                                              fifthInnerItem
                                                                            "
                                                                          ></interface-designer-item>
                                                                          <div
                                                                            v-if="
                                                                              fifthInnerItem.type ===
                                                                                'group' ||
                                                                              fifthInnerItem.type ===
                                                                                'box'
                                                                            "
                                                                            :id="`groupcontent${fifthInnerItem.layout}`"
                                                                            style="
                                                                              height: 100%;
                                                                            "
                                                                          >
                                                                            <p
                                                                              style="
                                                                                height: 10px;
                                                                                margin-bottom: 0px;
                                                                              "
                                                                              v-if="
                                                                                fifthInnerItem.type ===
                                                                                'group'
                                                                              "
                                                                            >
                                                                              {{
                                                                                fifthInnerItem.name
                                                                              }}
                                                                            </p>
                                                                            <grid-layout
                                                                              :ref="`layout-${fifthInnerItem.layout}`"
                                                                              :layout.sync="
                                                                                fifthInnerItem.components
                                                                              "
                                                                              :col-num="
                                                                                32
                                                                              "
                                                                              :row-height="
                                                                                30
                                                                              "
                                                                              :is-draggable="
                                                                                true
                                                                              "
                                                                              :is-resizable="
                                                                                true
                                                                              "
                                                                              :vertical-compact="
                                                                                true
                                                                              "
                                                                              :use-css-transforms="
                                                                                true
                                                                              "
                                                                            >
                                                                              <div
                                                                                v-for="sixInnerItem in fifthInnerItem.components"
                                                                                :key="`sixInnerItem-${sixInnerItem.layout}`"
                                                                                class="grid-item"
                                                                                @moved="
                                                                                  moveEnd(
                                                                                    fifthInnerItem,
                                                                                    `groupcontent${fifthInnerItem.layout}`,
                                                                                    index,
                                                                                    innerIndex
                                                                                  )
                                                                                "
                                                                                @move="
                                                                                  moveEnd(
                                                                                    item,
                                                                                    `groupcontent${fifthInnerItem.layout}`,
                                                                                    index,
                                                                                    innerIndex
                                                                                  )
                                                                                "
                                                                              >
                                                                                <div
                                                                                  class="grid-item__main"
                                                                                  style="
                                                                                    height: 100%;
                                                                                  "
                                                                                >
                                                                                  <div
                                                                                    class="grid-item__main__left"
                                                                                    style="
                                                                                      height: 100%;
                                                                                    "
                                                                                  >
                                                                                    <!-- {{
                                                                                      item
                                                                                    }} -->
                                                                                    <interface-designer-item
                                                                                      :item="
                                                                                        sixInnerItem
                                                                                      "
                                                                                    ></interface-designer-item>
                                                                                    <div
                                                                                      v-if="
                                                                                        sixInnerItem.type ===
                                                                                          'group' ||
                                                                                        sixInnerItem.type ===
                                                                                          'box'
                                                                                      "
                                                                                      :id="`groupcontent${sixInnerItem.layout}`"
                                                                                      style="
                                                                                        height: 100%;
                                                                                      "
                                                                                    >
                                                                                      <p
                                                                                        style="
                                                                                          height: 10px;
                                                                                          margin-bottom: 0px;
                                                                                        "
                                                                                        v-if="
                                                                                          sixInnerItem.type ===
                                                                                          'group'
                                                                                        "
                                                                                      >
                                                                                        {{
                                                                                          fifthInnerItem.name
                                                                                        }}
                                                                                      </p>
                                                                                      <grid-layout
                                                                                        :ref="`layout-${sixInnerItem.layout}`"
                                                                                        :layout.sync="
                                                                                          sixInnerItem.components
                                                                                        "
                                                                                        :col-num="
                                                                                          32
                                                                                        "
                                                                                        :row-height="
                                                                                          30
                                                                                        "
                                                                                        :is-draggable="
                                                                                          true
                                                                                        "
                                                                                        :is-resizable="
                                                                                          true
                                                                                        "
                                                                                        :vertical-compact="
                                                                                          true
                                                                                        "
                                                                                        :use-css-transforms="
                                                                                          true
                                                                                        "
                                                                                      >
                                                                                        <div
                                                                                          v-for="seventhInnerItem in sixInnerItem.components"
                                                                                          :key="`seventhInnerItem-${seventhInnerItem.layout}`"
                                                                                          class="grid-item"
                                                                                          @moved="
                                                                                            moveEnd(
                                                                                              sixInnerItem,
                                                                                              `groupcontent${sixInnerItem.layout}`,
                                                                                              index,
                                                                                              innerIndex
                                                                                            )
                                                                                          "
                                                                                          @move="
                                                                                            moveEnd(
                                                                                              item,
                                                                                              `groupcontent${sixInnerItem.layout}`,
                                                                                              index,
                                                                                              innerIndex
                                                                                            )
                                                                                          "
                                                                                        >
                                                                                          <div
                                                                                            class="grid-item__main"
                                                                                            style="
                                                                                              height: 100%;
                                                                                            "
                                                                                          >
                                                                                            <div
                                                                                              class="grid-item__main__left"
                                                                                              style="
                                                                                                height: 100%;
                                                                                              "
                                                                                            >
                                                                                              <!-- {{
                                                                                                item
                                                                                              }} -->
                                                                                              <interface-designer-item
                                                                                                :item="
                                                                                                  seventhInnerItem
                                                                                                "
                                                                                              ></interface-designer-item>
                                                                                            </div>
                                                                                            <div
                                                                                              class="grid-item__main__right"
                                                                                            >
                                                                                              <button
                                                                                                @click="
                                                                                                  deleteItem(
                                                                                                    seventhInnerItem,
                                                                                                    item
                                                                                                  )
                                                                                                "
                                                                                              >
                                                                                                x
                                                                                              </button>
                                                                                              <button
                                                                                                v-if="
                                                                                                  editableTypes.includes(
                                                                                                    seventhInnerItem.type
                                                                                                  ) ||
                                                                                                  fsixInnerItem.attributeId
                                                                                                "
                                                                                                @click="
                                                                                                  openEditWindow(
                                                                                                    seventhInnerItem
                                                                                                  )
                                                                                                "
                                                                                              >
                                                                                                o
                                                                                              </button>
                                                                                              <v-tooltip
                                                                                                top
                                                                                              >
                                                                                                <template
                                                                                                  v-slot:activator="{
                                                                                                    on,
                                                                                                    attrs,
                                                                                                  }"
                                                                                                >
                                                                                                  <button
                                                                                                    @click="
                                                                                                      toUpperLevel(
                                                                                                        seventhInnerItem
                                                                                                      )
                                                                                                    "
                                                                                                    v-bind="
                                                                                                      attrs
                                                                                                    "
                                                                                                    v-on="
                                                                                                      on
                                                                                                    "
                                                                                                  >
                                                                                                    &#8593;
                                                                                                  </button>
                                                                                                </template>
                                                                                                <span
                                                                                                  >На
                                                                                                  верхний
                                                                                                  уровень</span
                                                                                                >
                                                                                              </v-tooltip>
                                                                                            </div>
                                                                                          </div>
                                                                                        </div>
                                                                                      </grid-layout>
                                                                                    </div>
                                                                                  </div>
                                                                                  <div
                                                                                    class="grid-item__main__right"
                                                                                  >
                                                                                    <button
                                                                                      @click="
                                                                                        deleteItem(
                                                                                          sixInnerItem,
                                                                                          item
                                                                                        )
                                                                                      "
                                                                                    >
                                                                                      x
                                                                                    </button>
                                                                                    <button
                                                                                      v-if="
                                                                                        editableTypes.includes(
                                                                                          sixInnerItem.type
                                                                                        ) ||
                                                                                        fsixInnerItem.attributeId
                                                                                      "
                                                                                      @click="
                                                                                        openEditWindow(
                                                                                          sixInnerItem
                                                                                        )
                                                                                      "
                                                                                    >
                                                                                      o
                                                                                    </button>
                                                                                    <v-tooltip
                                                                                      top
                                                                                    >
                                                                                      <template
                                                                                        v-slot:activator="{
                                                                                          on,
                                                                                          attrs,
                                                                                        }"
                                                                                      >
                                                                                        <button
                                                                                          @click="
                                                                                            toUpperLevel(
                                                                                              sixInnerItem
                                                                                            )
                                                                                          "
                                                                                          v-bind="
                                                                                            attrs
                                                                                          "
                                                                                          v-on="
                                                                                            on
                                                                                          "
                                                                                        >
                                                                                          &#8593;
                                                                                        </button>
                                                                                      </template>
                                                                                      <span
                                                                                        >На
                                                                                        верхний
                                                                                        уровень</span
                                                                                      >
                                                                                    </v-tooltip>
                                                                                  </div>
                                                                                </div>
                                                                              </div>
                                                                            </grid-layout>
                                                                          </div>
                                                                        </div>
                                                                        <div
                                                                          class="grid-item__main__right"
                                                                        >
                                                                          <button
                                                                            @click="
                                                                              deleteItem(
                                                                                fifthInnerItem,
                                                                                item
                                                                              )
                                                                            "
                                                                          >
                                                                            x
                                                                          </button>
                                                                          <button
                                                                            v-if="
                                                                              editableTypes.includes(
                                                                                fifthInnerItem.type
                                                                              ) ||
                                                                              fifthInnerItem.attributeId
                                                                            "
                                                                            @click="
                                                                              openEditWindow(
                                                                                fifthInnerItem
                                                                              )
                                                                            "
                                                                          >
                                                                            o
                                                                          </button>
                                                                          <v-tooltip
                                                                            top
                                                                          >
                                                                            <template
                                                                              v-slot:activator="{
                                                                                on,
                                                                                attrs,
                                                                              }"
                                                                            >
                                                                              <button
                                                                                @click="
                                                                                  toUpperLevel(
                                                                                    fifthInnerItem
                                                                                  )
                                                                                "
                                                                                v-bind="
                                                                                  attrs
                                                                                "
                                                                                v-on="
                                                                                  on
                                                                                "
                                                                              >
                                                                                &#8593;
                                                                              </button>
                                                                            </template>
                                                                            <span
                                                                              >На
                                                                              верхний
                                                                              уровень</span
                                                                            >
                                                                          </v-tooltip>
                                                                        </div>
                                                                      </div>
                                                                    </div>
                                                                  </grid-layout>
                                                                </div>
                                                              </div>
                                                              <div
                                                                class="grid-item__main__right"
                                                              >
                                                                <button
                                                                  @click="
                                                                    deleteItem(
                                                                      fourthInnerItem,
                                                                      item
                                                                    )
                                                                  "
                                                                >
                                                                  x
                                                                </button>
                                                                <button
                                                                  v-if="
                                                                    editableTypes.includes(
                                                                      fourthInnerItem.type
                                                                    ) ||
                                                                    fourthInnerItem.attributeId
                                                                  "
                                                                  @click="
                                                                    openEditWindow(
                                                                      fourthInnerItem
                                                                    )
                                                                  "
                                                                >
                                                                  o
                                                                </button>
                                                                <v-tooltip top>
                                                                  <template
                                                                    v-slot:activator="{
                                                                      on,
                                                                      attrs,
                                                                    }"
                                                                  >
                                                                    <button
                                                                      @click="
                                                                        toUpperLevel(
                                                                          fourthInnerItem
                                                                        )
                                                                      "
                                                                      v-bind="
                                                                        attrs
                                                                      "
                                                                      v-on="on"
                                                                    >
                                                                      &#8593;
                                                                    </button>
                                                                  </template>
                                                                  <span
                                                                    >На верхний
                                                                    уровень</span
                                                                  >
                                                                </v-tooltip>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </grid-layout>
                                                      </div>
                                                    </div>
                                                    <div
                                                      class="grid-item__main__right"
                                                    >
                                                      <button
                                                        @click="
                                                          deleteItem(
                                                            trirdInnerItem,
                                                            item
                                                          )
                                                        "
                                                      >
                                                        x
                                                      </button>
                                                      <button
                                                        v-if="
                                                          editableTypes.includes(
                                                            trirdInnerItem.type
                                                          ) ||
                                                          trirdInnerItem.attributeId
                                                        "
                                                        @click="
                                                          openEditWindow(
                                                            trirdInnerItem
                                                          )
                                                        "
                                                      >
                                                        o
                                                      </button>
                                                      <v-tooltip top>
                                                        <template
                                                          v-slot:activator="{
                                                            on,
                                                            attrs,
                                                          }"
                                                        >
                                                          <button
                                                            @click="
                                                              toUpperLevel(
                                                                trirdInnerItem
                                                              )
                                                            "
                                                            v-bind="attrs"
                                                            v-on="on"
                                                          >
                                                            &#8593;
                                                          </button>
                                                        </template>
                                                        <span
                                                          >На верхний
                                                          уровень</span
                                                        >
                                                      </v-tooltip>
                                                    </div>
                                                  </div>
                                                </div>
                                              </grid-layout>
                                            </div>
                                          </div>
                                          <div class="grid-item__main__right">
                                            <button
                                              @click="
                                                deleteItem(
                                                  secondInnerItem,
                                                  item
                                                )
                                              "
                                            >
                                              x
                                            </button>
                                            <button
                                              v-if="
                                                editableTypes.includes(
                                                  secondInnerItem.type
                                                ) || secondInnerItem.attributeId
                                              "
                                              @click="
                                                openEditWindow(secondInnerItem)
                                              "
                                            >
                                              o
                                            </button>
                                            <v-tooltip top>
                                              <template
                                                v-slot:activator="{ on, attrs }"
                                              >
                                                <button
                                                  @click="
                                                    toUpperLevel(
                                                      secondInnerItem
                                                    )
                                                  "
                                                  v-bind="attrs"
                                                  v-on="on"
                                                >
                                                  &#8593;
                                                </button>
                                              </template>
                                              <span>На верхний уровень</span>
                                            </v-tooltip>
                                          </div>
                                        </div>
                                      </div>
                                    </grid-layout>
                                  </div>
                                </div>
                                <div class="grid-item__main__right">
                                  <button @click="deleteItem(innerItem)">
                                    x
                                  </button>
                                  <button
                                    v-if="
                                      editableTypes.includes(innerItem.type) ||
                                      innerItem.attributeId
                                    "
                                    @click="openEditWindow(innerItem)"
                                  >
                                    o
                                  </button>
                                  <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                      <button
                                        @click="toUpperLevel(innerItem)"
                                        v-bind="attrs"
                                        v-on="on"
                                      >
                                        &#8593;
                                      </button>
                                    </template>
                                    <span>На верхний уровень</span>
                                  </v-tooltip>
                                </div>
                              </div>
                            </div>
                          </grid-layout>
                        </div>
                      </div>
                      <div class="grid-item__main__right">
                        <button @click="deleteItem(item)">x</button>
                        <button
                          v-if="
                            editableTypes.includes(item.type) ||
                            item.attributeId
                          "
                          @click="openEditWindow(item)"
                        >
                          o
                        </button>
                      </div>
                    </div>
                  </div>
                  <!-- end -->
                </div>
              </grid-layout>
            </v-tab-item>
          </v-tabs-items>
        </div>
      </div>
    </div>
    <div class="interface-designer__button__container">
      <button @click="onSave" class="btn-soft-save">Сохранить</button>
      <button @click="onTopLevelAll" class="btn-soft-save">
        Все элементы на верхний уровень
      </button>
      <div class="interface-designer__button__container__scale">
        <v-btn
          class="mx-2"
          fab
          dark
          x-small
          color="primary"
          @click="scale.transform -= 10"
        >
          <v-icon dark> mdi-minus </v-icon>
        </v-btn>
        <v-text-field
          dense
          hide-details
          label="Масштаб"
          v-model="scale.transform"
          type="number"
        ></v-text-field>
        <v-btn
          class="mx-2"
          fab
          dark
          x-small
          color="primary"
          @click="scale.transform += 10"
        >
          <v-icon dark> mdi-plus </v-icon>
        </v-btn>
      </div>
    </div>
    <v-dialog v-model="editDialog" persistent max-width="600">
      <v-card>
        <v-card-title
          >Параметры атрибута:
          {{
            editedAttibute !== null && editedAttibute.type === 'label'
              ? 'Подпись'
              : ''
          }}</v-card-title
        >
        <div class="edited_fields">
          <div class="edited_field" v-if="editedAttibute.type === 'label'">
            <div class="edited_field__conteiner">
              <label for="text" class="edited_field__label">Текст:</label>
              <textarea id="text" v-model="editedAttibute.text"></textarea>
            </div>
            <div
              class="edited_field"
              v-for="(value, name) of editedAttibute"
              :key="name"
            >
              <div v-if="name === 'fontSize'" class="edited_field__conteiner">
                <label :for="name" class="edited_field__label">{{
                  labels[name]
                }}</label>
                <input
                  :id="name"
                  type="number"
                  v-model="editedAttibute[name]"
                />
              </div>

              <div v-else-if="name === 'color'" class="edited_field__conteiner">
                <label :for="name" class="edited_field__label">{{
                  labels[name]
                }}</label>
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn color="primary" dark v-bind="attrs" v-on="on">
                      выбрать цвет
                    </v-btn>
                  </template>
                  <v-color-picker
                    v-model="editedAttibute[name]"
                  ></v-color-picker>
                </v-menu>
              </div>
              <div
                v-else-if="name === 'textAlign'"
                class="edited_field__conteiner"
              >
                <label :for="name" class="edited_field__label">{{
                  labels[name]
                }}</label>
                <v-select
                  v-model="editedAttibute[name]"
                  :items="textAlignList"
                  item-text="name"
                  item-value="value"
                ></v-select>
              </div>
              <!--
                            <div v-else-if="name === 'width'" class="edited_field__conteiner">
                                <label :for="name" class="edited_field__label">{{labels[name]}}</label>
                                <v-checkbox v-model="editedAttibute.fixedWidth"></v-checkbox>
                                <input :id="name" type="number" :disabled="!editedAttibute.fixedWidth"  v-model="editedAttibute[name]">
                            </div>
                            --->

              <div v-else-if="name === 'bold'" class="edited_field__conteiner">
                <label :for="name" class="edited_field__label">{{
                  labels[name]
                }}</label>
                <input
                  :id="name"
                  type="checkbox"
                  v-model="editedAttibute[name]"
                />
              </div>
              <div
                v-else-if="name === 'italic'"
                class="edited_field__conteiner"
              >
                <label :for="name" class="edited_field__label">{{
                  labels[name]
                }}</label>
                <input
                  :id="name"
                  type="checkbox"
                  v-model="editedAttibute[name]"
                />
              </div>
              <div
                v-else-if="name === 'underline'"
                class="edited_field__conteiner"
              >
                <label :for="name" class="edited_field__label">{{
                  labels[name]
                }}</label>
                <input
                  :id="name"
                  type="checkbox"
                  v-model="editedAttibute[name]"
                />
              </div>
            </div>
          </div>
          <div class="edited_field" v-if="editedAttibute.type === 'group'">
            <div class="edited_field__conteiner">
              <label for="name" class="edited_field__label"
                >Наименование:</label
              >
              <textarea id="name" v-model="editedAttibute.name"></textarea>
            </div>
          </div>
          <div
            class="edited_field"
            v-if="
              editedAttibute.type === 'box' || editedAttibute.type === 'group'
            "
          >
            <div class="edited_field__conteiner">
              <label for="code" class="edited_field__label">Код:</label>
              <textarea
                id="code"
                v-model="editedAttibute.containerCode"
              ></textarea>
            </div>
          </div>
          <div
            class="edited_field"
            v-if="
              editedAttibute.type === 'box' || editedAttibute.type === 'group'
            "
          >
            <div class="edited_field__conteiner">
              <label for="collapse" class="edited_field__label"
                >Сворачиваемая:</label
              >
              <input
                id="collapse"
                type="checkbox"
                v-model="editedAttibute.collapsible"
              />
            </div>
          </div>
          <div
            class="edited_field"
            v-if="
              editedAttibute.type === 'box' || editedAttibute.type === 'group'
            "
          >
            <div class="edited_field__conteiner">
              <label for="typeContainer" class="edited_field__label"
                >Контейнер:</label
              >
              <v-select
                v-model="editedAttibute.type"
                :items="[
                  { name: 'группа', value: 'group' },
                  { name: 'контейнер', value: 'box' },
                ]"
                item-text="name"
                item-value="value"
                id="typeContainer"
              ></v-select>
            </div>
          </div>
          <div class="edited_field" v-if="editedAttibute.type === 'image'">
            <div class="edited_field__conteiner">
              <label for="text" class="edited_field__label"
                >Загрузите изображение:</label
              >
              <input type="file" @change="onFileChange" />
            </div>
          </div>
          <div class="edited_field" v-if="editedAttibute.attributeId">
            <div class="edited_field__conteiner">
              <label for="showLabel" class="edited_field__label"
                >Отображать подпись компонента:</label
              >
              <input
                id="showLabel"
                type="checkbox"
                v-model="editedAttibute.showLabel"
              />
            </div>
          </div>
          <div
            class="edited_field"
            v-if="editedAttibute.elementUIType === 'CHECK_BOX'"
          >
            <div class="edited_field__conteiner">
              <label
                for="showCheckboxBooleanReadonly"
                class="edited_field__label"
                >В режиме "Только чтение" отображать чекбокс:</label
              >
              <input
                id="showCheckboxBooleanReadonly"
                type="checkbox"
                v-model="editedAttibute.showCheckboxBooleanReadonly"
              />
            </div>
          </div>
        </div>
        <v-card-actions>
          <v-btn color="green darken-1" text @click="onEditItem"
            >Выбрать
          </v-btn>
          <v-btn color="red darken-1" text @click="editDialog = false"
            >Закрыть
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import VueGridLayout from 'vue-grid-layout';
import InterfaceDesignerItem from '@/components/InterfaceDesignerItem.vue';

let idGlobal = 3;
let mouseXY = { x: null, y: null };
let DragPos = { x: null, y: null, w: 3, h: 2, i: null };

export default {
  data() {
    return {
      editDialog: false,
      editableTypes: ['box', 'group', 'image', 'label'],
      editedAttibute: {},
      panel: [],
      panels: [
        'Атрибуты',
        'Вкладки',
        'Группы',
        'Кнопки статусов',
        'Компоненты',
      ],
      atriburesNames: {
        CHECK_BOX: 'Флаг',
        RADIO_BUTTONS: 'Переключатель',
        INPUT: 'Поле ввода',
        COUNTER: 'Счетчик',
        FILE_UPLOAD: 'Загрузка файла',
        LINK: 'Таблица с одной записью',
        TEXT_AREA: 'Текст',
        HINT: 'Подсказка',
        SELECTION_LIST_SINGLE_SELECT: 'Список выбора единственного значения',
        SELECTION_LIST_MULTI_SELECT: 'Список выбора множественных значений',
        CALENDAR: 'Дата',
        INPUT_AUTO_COMPLETE: 'Поле ввода с автозаполнением',
        DICTIONARY: 'Выбор из справочника',
        TABLE: 'Таблица',
      },
      components: [
        //{ name: "Подпись", typeId: 1, type: 'text', editable: true, styles:{"fixedWidth": false, "text-align": "left", "width": "100%" }, textStyles: {"font-size": '12px', "font-weight": 'auto', "font-style": "normal", "text-decoration": "none", "color": "black" }},
        { name: 'Изображение', type: 'image', editable: true, image: '' },
        { name: 'Нижняя линия', type: 'bottomLine', editable: false },
        {
          bold: false,
          color: 0,
          fontSize: 12,
          id: null,
          italic: false,
          text: 'Текст',
          textAlign: 'left',
          type: 'label',
          underline: false,
          name: 'Подпись',
        },
      ],
      groups: [],
      groupsInLayout: [],
      activeGroup: null,
      finalList: [],
      layout: [],
      activeTab: 0,
      groupIndex: 1,
      mainIndex: 0,
      statusButtonId: 1,
      tabId: 1,
      choosedTab: null,
      mouseInObject: false,
      labels: {
        fontSize: 'Размер шрифта',
        bold: 'Жирный',
        italic: 'Курсив',
        underline: 'Подчеркнутый',
        color: 'Цвет',
        textAlign: 'Выравнивание',
        width: 'Фиксированная ширина',
      },
      textAlignList: [
        { name: 'по левому краю', value: 'left' },
        { name: 'по правому краю', value: 'right' },
        { name: 'по центру', value: 'center' },
        { name: 'по обоим краям', value: 'justify' },
      ],
      rightWidth: 80,
      leftWidth: 20,
      point: null,
      scale: {
        width: 100,
        height: 79,
        transform: 100,
      },
    };
  },
  components: {
    GridLayout: VueGridLayout.GridLayout,
    GridItem: VueGridLayout.GridItem,
    InterfaceDesignerItem: InterfaceDesignerItem,
  },
  watch: {
    'scale.transform': function (val, oldval) {
      const scale = parseInt(val, 10) / 100;
      if (scale > 2 || scale <= 0) {
        this.scale.transform = oldval;
      } else {
        this.scale.width = 100 / scale;
        this.scale.height = 79 / scale;
      }
    },
  },
  computed: {
    ...mapGetters([
      'OBJECTS_TEMPLATE_REGULATIONS',
      'DETAILED_FORM_VIEW_META_ATTRS',
      'DETAILED_FORM_VIEW_XML_DESC',
      'SELECTED_FORM_VIEW_XML_DESC_MAIN',
    ]),
    statusButtons() {
      if (this.OBJECTS_TEMPLATE_REGULATIONS.linkedStatuses) {
        return this.OBJECTS_TEMPLATE_REGULATIONS.linkedStatuses.map((el) => {
          this.statusButtonId += 1;
          return {
            name: el.name,
            type: 'statusButton',
            buttonId: el.id,
            id: this.statusButtonId,
          };
        });
      } else {
        return [];
      }
    },
    attributesList() {
      return this.DETAILED_FORM_VIEW_META_ATTRS.map((el) => {
        return {
          availableTypesUI: el.availableTypesUI.map((elem) => {
            const result = {
              ...el,
              elementUIType: elem.elementUIType,
              index: 1,
              showLabel: true,
            };

            if (result.elementUIType === 'CHECK_BOX') {
              result.showCheckboxBooleanReadonly = false;
            }
            if (result.elementUIType === 'RADIO_BUTTONS') {
              result.booleanRadioButtonsDirection = 'hbox';
            }
            delete result.availableTypesUI;
            delete result.typeElementUI;
            return result;
          }),
          name: el.name,
          id: el.id,
          code: el.code,
          attributeId: el.attributeId,
        };
      });
    },
  },
  methods: {
    ...mapMutations([
      'REMOVE_DETAILED_FORM_VIEW_XML_DESC',
      'REMOVE_SELECTED_FORM_VIEW_XML_DESC_MAIN',
    ]),
    clone(item) {
      const result = {
        id: idGlobal++,
        name: item.name,
        type: item.type,
      };
      return result;
    },
    fileReader(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    onFileChange(e) {
      const file = e.target.files[0];
      this.fileReader(file).then((data) => {
        this.editedAttibute.image = URL.createObjectURL(file);
        this.editedAttibute.bytes = data;
      });
    },
    deleteItem(element) {
      if (element.attributeId) {
        this.REMOVE_SELECTED_FORM_VIEW_XML_DESC_MAIN(element.attributeId);
      }
      const cleaElement = (components) => {
        components = components.filter((obj) => {
          return obj.i !== element.i;
        });
        for (let element of components) {
          if (element.type === 'box' || element.type === 'group') {
            element.components = cleaElement(element.components);
          }
        }
        return components;
      };
      this.DETAILED_FORM_VIEW_XML_DESC[this.activeTab].components = cleaElement(
        this.DETAILED_FORM_VIEW_XML_DESC[this.activeTab].components
      );
    },
    addNewGroup() {
      const groupIndex = this.DETAILED_FORM_VIEW_XML_DESC[this.activeTab]
        .components
        ? this.DETAILED_FORM_VIEW_XML_DESC[this.activeTab].components.length + 1
        : 1;
      this.groups.push({
        name: `Новая группа ${groupIndex}`,
        id: groupIndex,
        type: 'group',
        containerCode: `container_${groupIndex}`,
        collapsible: false,
        align: 'begin',
        layout: 'vbox',
        components: [],
      });
      this.groupIndex += 1;
    },
    removeGroup() {
      this.groups = this.groups.filter(
        (el) => el.name !== this.activeGroup.name
      );
    },
    openEditWindow(element) {
      //this.editedAttibute = {...element, styles: {...element.styles}, textStyles: {...element.textStyles},}

      this.editedAttibute = { ...element };
      /*
            if (this.editedAttibute.textStyles["font-size"]) {
                this.editedAttibute.fontSize = parseInt(this.editedAttibute.fontSize, 10)
            }
            */
      this.editDialog = true;
    },
    indexFinder(editedAttibute, components, path = []) {
      for (let i = 0; i < components.length; i += 1) {
        if (components[i].i === editedAttibute.i) {
          path.push(i);
          return path;
        }
        if (components[i].components) {
          const newPath = [...path];
          newPath.push(i);
          newPath.push('components');
          const innerItem = this.indexFinder(
            editedAttibute,
            components[i].components,
            [...newPath]
          );
          if (innerItem) {
            return innerItem;
          }
        }
      }
    },
    onEditItem() {
      this.editDialog = false;
      const editedItemPath = this.indexFinder(
        this.editedAttibute,
        this.DETAILED_FORM_VIEW_XML_DESC[this.activeTab].components,
        [this.activeTab, 'components']
      );
      let editedItem = this.DETAILED_FORM_VIEW_XML_DESC;
      for (let step of editedItemPath) {
        editedItem = editedItem[step];
      }
      if (this.editedAttibute.text) {
        editedItem.text = this.editedAttibute.text;
      }
      if (this.editedAttibute.name) {
        editedItem.name = this.editedAttibute.name;
      }
      if (this.editedAttibute.code) {
        editedItem.code = this.editedAttibute.code;
      }
      if (this.editedAttibute.containerCode) {
        editedItem.containerCode = this.editedAttibute.containerCode;
      }
      if (this.editedAttibute.type) {
        editedItem.type = this.editedAttibute.type;
      }
      if ('collapse' in this.editedAttibute) {
        editedItem.collapse = this.editedAttibute.collapse;
      }
      if ('box' in this.editedAttibute) {
        editedItem.box = this.editedAttibute.box;
      }
      if (this.editedAttibute.image) {
        editedItem.image = this.editedAttibute.image;
      }
      if (this.editedAttibute.bytes) {
        editedItem.bytes = this.editedAttibute.bytes;
      }
      if (editedItem.fontSize) {
        editedItem.fontSize = this.editedAttibute.fontSize;
      }
      if ('color' in this.editedAttibute) {
        editedItem.color =
          typeof this.editedAttibute.color === 'object'
            ? this.editedAttibute.color.hex
            : '#00000';
      }
      if (editedItem['bold'] || editedItem['bold'] === false) {
        editedItem['bold'] = this.editedAttibute['bold'];
      }
      if (editedItem['textAlign'] || editedItem['textAlign'] === false) {
        editedItem['textAlign'] = this.editedAttibute['textAlign'];
      }
      if (editedItem['underline'] || editedItem['underline'] === false) {
        editedItem['underline'] = this.editedAttibute['underline'];
      }
      if (editedItem['italic'] || editedItem['italic'] === false) {
        editedItem['italic'] = this.editedAttibute['italic'];
      }
      if (editedItem.showLabel === true || editedItem.showLabel === false) {
        editedItem.showLabel = this.editedAttibute.showLabel;
      }
    },
    addNewTab() {
      const tabId =
        this.DETAILED_FORM_VIEW_XML_DESC[
          this.DETAILED_FORM_VIEW_XML_DESC.length - 1
        ].id + 1;

      this.DETAILED_FORM_VIEW_XML_DESC.push({
        name: `Новая вкладка ${tabId}`,
        id: tabId,
        systemTab: false,
        components: [],
      });
    },
    addNewSystemTab() {
      if (this.DETAILED_FORM_VIEW_XML_DESC.find((el) => el.systemTab)) {
        alert('Системная вкладка уже создана.');
        return;
      }
      this.DETAILED_FORM_VIEW_XML_DESC.push({
        name: 'Новая системная вкладка',
        id: this.tabId,
        systemTab: true,
        components: [],
      });
      this.tabId += 1;
    },
    removeTab() {
      if (this.DETAILED_FORM_VIEW_XML_DESC.length === 1) {
        alert('Это последняя вкладка');
      }
      this.REMOVE_DETAILED_FORM_VIEW_XML_DESC(this.choosedTab);
    },
    move(element) {
      try {
        console.log(element);
        /*
                console.log(element)
                const possibleParents = ['content']
                for (let component of this.DETAILED_FORM_VIEW_XML_DESC[this.activeTab].components) {
                    if (component.type === 'box' || component.type === 'group') {
                        possibleParents.push(`groupcontent${component.i}`)
                        for (let innerComponent of component.components) {
                            if (innerComponent.type === 'box' || innerComponent.type === 'group') {
                                possibleParents.push(`groupcontent${innerComponent.i}`)
                            }
                        }
                    }
                }
                let parentName = null
                let parentActive = null
                let mouseInGrid = false;
                for (let parent of possibleParents) {
                    const parentRect = document.getElementById(parent).getBoundingClientRect();
                    if (((mouseXY.x > parentRect.left) && (mouseXY.x < parentRect.right)) && ((mouseXY.y > parentRect.top) && (mouseXY.y < parentRect.bottom))) {
                        parentActive = parentRect
                        mouseInGrid = true
                        parentName = parent
                    }
                }
                if (mouseInGrid) {
                    console.log(parentName)
                    console.log(parentActive)

                }
                */
      } catch (error) {
        if (error.message !== "Cannot read property '$refs' of undefined") {
          console.log(error);
        }
      }
    },
    drag(
      element,
      conteinerId = 'content',
      components = this.DETAILED_FORM_VIEW_XML_DESC[this.activeTab].components,
      gridlayout = 'gridlayout'
    ) {
      try {
        const now = new Date().getMilliseconds();
        if (now % 2) return;
        let mouseOnObject = [];
        let parentRect = document
          .getElementById(conteinerId)
          .getBoundingClientRect();
        let mouseInGrid = false;
        const types = ['box', 'group'];
        if (
          mouseXY.x > parentRect.left &&
          mouseXY.x < parentRect.right &&
          mouseXY.y > parentRect.top &&
          mouseXY.y < parentRect.bottom
        ) {
          mouseInGrid = true;
          for (let box of components.filter(
            (el) => types.includes(el.type) && el.i !== 'drop'
          )) {
            const result = this.drag(
              element,
              `groupcontent${box.i}`,
              box.components,
              `layout-${box.i}`
            );

            if (result && result.find((el) => el)) {
              mouseInGrid = false;
              mouseOnObject = result;
            }
          }
        }
        if (
          mouseInGrid === true &&
          components.findIndex((item) => item.i === 'drop') === -1
        ) {
          const newItem = {
            ...element,
            x: (components.length * 2) % (this.colNum || 32),
            y: components.length + (this.colNum || 32), // puts it at the bottom
            w: 3,
            h: 2,
            i: 'drop',
            type: element.type,
            name: element.name,
          };
          if (element.type === 'text') {
            newItem.text = element.name;
          }
          if (element.type === 'image') {
            newItem.image = element.image;
          }
          if (element.type === 'attribute') {
            newItem.name = element.attributeName;
            newItem.attributeId = element.attributeId;
            newItem.attributeType = element.attributeType;
            newItem.code = element.code;
            newItem.dataType = element.dataType;
            newItem.elementUIType = element.elementUIType;
            newItem.attributeCode = element.attributeCode;
          }
          if (element.type === 'box') {
            newItem.components = element.components;
            newItem.id = element.id;
            newItem.collapsible = element.collapsible;
            newItem.layout = element.layout;
            newItem.containerCode = element.containerCode;
            newItem.align = element.align;
          }
          if (element.type === 'group') {
            newItem.components = element.components;
            newItem.id = element.id;
            newItem.collapsible = element.collapsible;
            newItem.layout = element.layout;
            newItem.containerCode = element.containerCode;
            newItem.align = element.align;
          }
          components.push(newItem);
        }
        let index = components.findIndex((item) => item.i === 'drop');
        if (index !== -1) {
          if (Array.isArray(this.$refs[gridlayout])) {
            // this.$refs[gridlayout][0].$children[
            //   components.length
            // ].$refs.item.style.display = 'none';
            let el = this.$refs[gridlayout][0].$children[index];
            el.dragging = {
              top: mouseXY.y - parentRect.top,
              left: mouseXY.x - parentRect.left,
            };
            let new_pos = el.calcXY(
              mouseXY.y - parentRect.top,
              mouseXY.x - parentRect.left
            );
            if (mouseInGrid === true) {
              this.$refs[gridlayout][0].dragEvent(
                'dragstart',
                'drop',
                new_pos.x,
                new_pos.y,
                2,
                3
              );
              DragPos.i = String(this.mainIndex);
              this.mainIndex += 1;
              DragPos.x = components[index].x;
              DragPos.y = components[index].y;
            }
            if (mouseInGrid === false) {
              this.$refs[gridlayout][0].dragEvent(
                'dragend',
                'drop',
                new_pos.x,
                new_pos.y,
                2,
                3
              );
              components = components.filter((obj) => obj.i !== 'drop');
            }
          } else {
            this.$refs[gridlayout].$children[
              components.length
            ].$refs.item.style.display = 'none';
            let el = this.$refs[gridlayout].$children[index];
            el.dragging = {
              top: mouseXY.y - parentRect.top,
              left: mouseXY.x - parentRect.left,
            };
            let new_pos = el.calcXY(
              mouseXY.y - parentRect.top,
              mouseXY.x - parentRect.left
            );
            if (mouseInGrid === true) {
              this.$refs[gridlayout].dragEvent(
                'dragstart',
                'drop',
                new_pos.x,
                new_pos.y,
                2,
                3
              );
              DragPos.i = String(this.mainIndex);
              this.mainIndex += 1;
              DragPos.x = components[index].x;
              DragPos.y = components[index].y;
            }
            if (mouseInGrid === false) {
              this.$refs[gridlayout].dragEvent(
                'dragend',
                'drop',
                new_pos.x,
                new_pos.y,
                2,
                3
              );
              components = components.filter((obj) => obj.i !== 'drop');
            }
          }
          mouseOnObject.push(mouseInGrid);
          return mouseOnObject;
        }
      } catch (error) {
        if (error.message !== "Cannot read property '$refs' of undefined") {
          console.log(error);
        }
      }
    },
    clearDrop(components) {
      components = components.filter((obj) => obj.i !== 'drop');
      for (let element of components) {
        if (element.type === 'box' || element.type === 'group') {
          element.components = this.clearDrop(element.components);
        }
      }
      return components;
    },
    dragend(
      element,
      conteinerId = 'content',
      components = this.DETAILED_FORM_VIEW_XML_DESC[this.activeTab].components,
      gridlayout = 'gridlayout',
      path = []
    ) {
      try {
        let parentRect = document
          .getElementById(conteinerId)
          .getBoundingClientRect();
        let mouseInGrid = false;
        if (
          mouseXY.x > parentRect.left &&
          mouseXY.x < parentRect.right &&
          mouseXY.y > parentRect.top &&
          mouseXY.y < parentRect.bottom
        ) {
          mouseInGrid = true;
          const types = ['box', 'group'];
          for (let box of components.filter(
            (el) => types.includes(el.type) && el.i !== 'drop'
          )) {
            const groupIndex = components.findIndex((el) => el.i === box.i);
            const end = this.dragend(
              element,
              `groupcontent${box.i}`,
              box.components,
              `layout-${box.i}`,
              [...path, groupIndex, 'components']
            );
            if (end) return true;
          }
        }
        if (mouseInGrid === true) {
          let ref = Array.isArray(this.$refs[gridlayout])
            ? this.$refs[gridlayout][0]
            : this.$refs[gridlayout];
          ref.dragEvent('dragend', 'drop', DragPos.x, DragPos.y, 2, 3);
          this.DETAILED_FORM_VIEW_XML_DESC[this.activeTab].components =
            this.clearDrop(
              this.DETAILED_FORM_VIEW_XML_DESC[this.activeTab].components
            );
          const newItem = {
            ...element,
            x: DragPos.x,
            y: DragPos.y,
            w: 3,
            h: 2,
            i: DragPos.i,
            type: element.type,
            name: element.name,
          };
          if (element.type === 'text') {
            newItem.text = element.name;
          }
          if (element.type === 'image') {
            newItem.image = element.image;
          }
          if (element.type === 'box') {
            newItem.components = element.components;
            newItem.id = element.id;
            newItem.collapse = element.collapse;
            newItem.layout = element.layout;
            newItem.containerCode = element.containerCode;
            newItem.align = element.align;
            this.groups = this.groups.filter((el) => el.id !== element.id);
          }
          if (element.type === 'group') {
            newItem.components = element.components;
            newItem.id = element.id;
            newItem.collapse = element.collapse;
            newItem.layout = element.layout;
            newItem.containerCode = element.containerCode;
            newItem.align = element.align;
            this.groups = this.groups.filter((el) => el.id !== element.id);
          }
          components =
            this.DETAILED_FORM_VIEW_XML_DESC[this.activeTab].components;

          for (let step of path) {
            components = components[step];
          }
          newItem.id = components.length + 1;
          components.push(newItem);
          ref.dragEvent('dragend', DragPos.i, DragPos.x, DragPos.y, 2, 3);

          // ref.$children[components.length].$refs.item.style.display = 'block';

          if (newItem.attributeId) {
            this.SELECTED_FORM_VIEW_XML_DESC_MAIN.push(newItem.attributeId);
          }
          return true;
        }
      } catch (error) {
        if (error.message !== "Cannot read property 'dragEvent' of undefined") {
          console.log(error);
        }
      }
    },
    moveEnd(element, currentConteiner, mainIndex, innerIndex) {
      try {
        if (
          element.type &&
          (element.type === 'group' || element.type === 'box')
        ) {
          return;
        }
        const possibleParents = [
          {
            name: 'content',
            component: this.DETAILED_FORM_VIEW_XML_DESC[this.activeTab],
          },
        ];
        for (let [index, component] of this.DETAILED_FORM_VIEW_XML_DESC[
          this.activeTab
        ].components.entries()) {
          if (component.type === 'box' || component.type === 'group') {
            possibleParents.push({
              name: `groupcontent${component.i}`,
              component,
              index,
            });
            for (let [
              innerIndex,
              innerComponent,
            ] of component.components.entries()) {
              if (
                innerComponent.type === 'box' ||
                innerComponent.type === 'group'
              ) {
                possibleParents.push({
                  name: `groupcontent${innerComponent.i}`,
                  component: innerComponent,
                  parentId: component.id,
                  index,
                  innerIndex,
                });
              }
            }
          }
        }
        let parentObj = null;
        let mouseInGrid = false;
        for (let parent of possibleParents) {
          let newElement =
            currentConteiner === 'content'
              ? document
                  .getElementById(`${element.code}`)
                  .getBoundingClientRect()
              : document
                  .getElementById(`${element.code}`)
                  .getBoundingClientRect();
          const parentRect = document
            .getElementById(parent.name)
            .getBoundingClientRect();
          //для атрибутов главного уровня
          if (
            currentConteiner === 'content' &&
            newElement.x > parentRect.left &&
            newElement.x < parentRect.right &&
            newElement.y > parentRect.top &&
            newElement.y < parentRect.bottom
          ) {
            mouseInGrid = true;
            parentObj = parent;
          } else if (currentConteiner !== 'content') {
            let currentParent = possibleParents.find(
              (el) => el.name === currentConteiner
            );
            const currentParentRect = document
              .getElementById(currentParent.component.containerCode)
              .getBoundingClientRect();

            if (
              newElement.top > currentParentRect.bottom ||
              newElement.right < currentParentRect.left ||
              newElement.bottom < currentParentRect.top ||
              newElement.left > currentParentRect.right
            ) {
              mouseInGrid = true;
              parentObj = possibleParents.find(
                (el) =>
                  el.component.components.length > 0 &&
                  el.component.name !== currentParent.component.name &&
                  el.component.components.find(
                    (el) => el.name === currentParent.component.name
                  )
              );
            }
          }
        }
        if (mouseInGrid) {
          if (currentConteiner === parentObj.name) {
            return;
          }
          if (mainIndex) {
            if (innerIndex) {
              this.DETAILED_FORM_VIEW_XML_DESC[this.activeTab].components[
                mainIndex
              ].components[innerIndex].components =
                this.DETAILED_FORM_VIEW_XML_DESC[this.activeTab].components[
                  mainIndex
                ].components[innerIndex].components.filter(
                  (el) => el.i !== element.i
                );
              parentObj.component.components.push(element);
            } else {
              this.DETAILED_FORM_VIEW_XML_DESC[this.activeTab].components[
                mainIndex
              ].components = this.DETAILED_FORM_VIEW_XML_DESC[
                this.activeTab
              ].components[mainIndex].components.filter(
                (el) => el.i !== element.i
              );
              parentObj.component.components.push(element);
            }
          } else {
            let actualNameParent = possibleParents.find(
              (el) => el.name === currentConteiner
            ).component.name;
            if (
              this.DETAILED_FORM_VIEW_XML_DESC[this.activeTab].components.find(
                (el) => el.name === actualNameParent
              )
            ) {
              this.DETAILED_FORM_VIEW_XML_DESC[this.activeTab].components.find(
                (el) => el.name === actualNameParent
              ).components = this.DETAILED_FORM_VIEW_XML_DESC[
                this.activeTab
              ].components
                .find((el) => el.name === actualNameParent)
                .components.filter((el) => el.name !== element.name);
            }
            this.DETAILED_FORM_VIEW_XML_DESC[this.activeTab].components =
              this.DETAILED_FORM_VIEW_XML_DESC[
                this.activeTab
              ].components.filter((el) => el.i !== element.i);
            parentObj.component.components.push(element);
          }
        }
      } catch (error) {
        if (error.message !== "Cannot read property '$refs' of undefined") {
          console.log(error);
        }
      }
    },
    onSave() {
      this.$emit('save-Interface');
    },
    onColumnMouseDown(event) {
      event.preventDefault();
      this.point = event.clientX - 18;
      document.onmousemove = this.elementDrag;
      document.onmouseup = this.closeDragElement;
    },
    elementDrag(e) {
      e = e || window.event;
      e.preventDefault();
      const conteinerWidth = this.$refs.conteiner.clientWidth;
      const leftWidth =
        this.leftWidth * (conteinerWidth / 100) + (e.clientX - 18 - this.point);
      this.point = e.clientX - 18;
      const rightWidth = conteinerWidth - leftWidth;
      const objectWidth = (leftWidth / conteinerWidth) * 100;
      const viewWidth = (rightWidth / conteinerWidth) * 100;
      if (objectWidth < 10 || viewWidth < 10) {
        return;
      }
      this.leftWidth = objectWidth;
      this.rightWidth = viewWidth;
    },
    closeDragElement() {
      document.onmouseup = null;
      document.onmousemove = null;
    },
    toUpperLevel(item) {
      for (let [i1, lvl1] of this.DETAILED_FORM_VIEW_XML_DESC[
        this.activeTab
      ].components.entries()) {
        if (lvl1.components) {
          for (let [i2, lvl2] of lvl1.components.entries()) {
            if (lvl2.id === item.id && lvl2.i === item.i) {
              this.DETAILED_FORM_VIEW_XML_DESC[this.activeTab].components.push({
                ...item,
                x: 0,
                y: 0,
              });
              this.DETAILED_FORM_VIEW_XML_DESC[this.activeTab].components[
                i1
              ].components = this.DETAILED_FORM_VIEW_XML_DESC[
                this.activeTab
              ].components[i1].components.filter((el) => el.id !== item.id);
              return;
            }
            if (lvl2.components) {
              for (let [i3, lvl3] of lvl2.components.entries()) {
                if (lvl3.id === item.id && lvl3.i === item.i) {
                  this.DETAILED_FORM_VIEW_XML_DESC[this.activeTab].components[
                    i1
                  ].components.push({
                    ...item,
                    x: 0,
                    y: 0,
                  });
                  this.DETAILED_FORM_VIEW_XML_DESC[this.activeTab].components[
                    i1
                  ].components[i2].components =
                    this.DETAILED_FORM_VIEW_XML_DESC[this.activeTab].components[
                      i1
                    ].components[i2].components.filter(
                      (el) => el.id !== item.id
                    );
                  return;
                }
                if (lvl3.components) {
                  for (let [i4, lvl4] of lvl3.components.entries()) {
                    if (lvl4.id === item.id && lvl4.i === item.i) {
                      this.DETAILED_FORM_VIEW_XML_DESC[
                        this.activeTab
                      ].components[i1].components[i2].components.push({
                        ...item,
                        x: 0,
                        y: 0,
                      });
                      this.DETAILED_FORM_VIEW_XML_DESC[
                        this.activeTab
                      ].components[i1].components[i2].components[
                        i3
                      ].components = this.DETAILED_FORM_VIEW_XML_DESC[
                        this.activeTab
                      ].components[i1].components[i2].components[
                        i3
                      ].components.filter((el) => el.id !== item.id);
                      return;
                    }
                    if (lvl4.components) {
                      for (let [i5, lvl5] of lvl4.components.entries()) {
                        if (lvl5.id === item.id && lvl5.i === item.i) {
                          this.DETAILED_FORM_VIEW_XML_DESC[
                            this.activeTab
                          ].components[i1].components[i2].components[
                            i3
                          ].components.push({
                            ...item,
                            x: 0,
                            y: 0,
                          });
                          this.DETAILED_FORM_VIEW_XML_DESC[
                            this.activeTab
                          ].components[i1].components[i2].components[
                            i3
                          ].components[i4].components =
                            this.DETAILED_FORM_VIEW_XML_DESC[
                              this.activeTab
                            ].components[i1].components[i2].components[
                              i3
                            ].components[i4].components.filter(
                              (el) => el.id !== item.id
                            );
                          return;
                        }
                        if (lvl5.components) {
                          for (let [i6, lvl6] of lvl5.components.entries()) {
                            if (lvl6.id === item.id && lvl6.i === item.i) {
                              this.DETAILED_FORM_VIEW_XML_DESC[
                                this.activeTab
                              ].components[i1].components[i2].components[
                                i3
                              ].components[i4].components.push({
                                ...item,
                                x: 0,
                                y: 0,
                              });
                              this.DETAILED_FORM_VIEW_XML_DESC[
                                this.activeTab
                              ].components[i1].components[i2].components[
                                i3
                              ].components[i4].components[i5].components =
                                this.DETAILED_FORM_VIEW_XML_DESC[
                                  this.activeTab
                                ].components[i1].components[i2].components[
                                  i3
                                ].components[i4].components[
                                  i5
                                ].components.filter((el) => el.id !== item.id);
                              return;
                            }
                            if (lvl6.components) {
                              for (let lvl7 of lvl6.components) {
                                if (lvl7.id === item.id && lvl7.i === item.i) {
                                  this.DETAILED_FORM_VIEW_XML_DESC[
                                    this.activeTab
                                  ].components[i1].components[i2].components[
                                    i3
                                  ].components[i4].components[
                                    i5
                                  ].components.push({
                                    ...item,
                                    x: 0,
                                    y: 0,
                                  });
                                  this.DETAILED_FORM_VIEW_XML_DESC[
                                    this.activeTab
                                  ].components[i1].components[i2].components[
                                    i3
                                  ].components[i4].components[i5].components[
                                    i6
                                  ].components =
                                    this.DETAILED_FORM_VIEW_XML_DESC[
                                      this.activeTab
                                    ].components[i1].components[i2].components[
                                      i3
                                    ].components[i4].components[i5].components[
                                      i6
                                    ].components.filter(
                                      (el) => el.id !== item.id
                                    );
                                  return;
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    onTopLevelAll() {
      const newComponents = [];
      for (let lvl1 of this.DETAILED_FORM_VIEW_XML_DESC[this.activeTab]
        .components) {
        if (lvl1.type !== 'box' && lvl1.type !== 'group') {
          newComponents.push(lvl1);
        } else {
          if (lvl1.type === 'group') {
            const newTitle = {
              bold: false,
              color: 0,
              fontSize: 16,
              id: null,
              italic: false,
              text: lvl1.name,
              textAlign: 'left',
              type: 'label',
              underline: false,
              name: 'Подпись',
              x: lvl1.x,
              y: lvl1.y,
              w: lvl1.w,
              h: 1,
              i: `${lvl1.i}-title`,
            };
            newComponents.push(newTitle);
          }
          for (let lvl2 of lvl1.components) {
            if (lvl2.type !== 'box' && lvl2.type !== 'group') {
              const x = lvl1.x + (lvl1.w / 32) * lvl2.x;
              const y = lvl1.y + lvl2.y;
              const w = (lvl1.w / 32) * lvl2.w;
              newComponents.push({ ...lvl2, x, y, w });
            } else {
              if (lvl2.type === 'group') {
                const newTitle = {
                  bold: false,
                  color: 0,
                  fontSize: 16,
                  id: null,
                  italic: false,
                  text: lvl2.name,
                  textAlign: 'left',
                  type: 'label',
                  underline: false,
                  name: 'Подпись',
                  x: lvl2.x,
                  y: lvl2.y,
                  w: lvl2.w,
                  h: 1,
                  i: `${lvl2.i}-title`,
                };
                newComponents.push(newTitle);
              }
              for (let lvl3 of lvl2.components) {
                if (lvl3.type !== 'box' && lvl3.type !== 'group') {
                  const x =
                    lvl1.x +
                    (lvl1.w / 32) * lvl2.x +
                    (lvl1.w / 32) * (lvl2.w / 32) * lvl3.x;
                  const y = lvl1.y + lvl2.y + lvl3.y;
                  const w = (lvl1.w / 32) * (lvl2.w / 32) * lvl3.w;
                  newComponents.push({ ...lvl3, x, y, w });
                } else {
                  if (lvl3.type === 'group') {
                    const newTitle = {
                      bold: false,
                      color: 0,
                      fontSize: 16,
                      id: null,
                      italic: false,
                      text: lvl3.name,
                      textAlign: 'left',
                      type: 'label',
                      underline: false,
                      name: 'Подпись',
                      x: lvl3.x,
                      y: lvl3.y,
                      w: lvl3.w,
                      h: 1,
                      i: `${lvl3.i}-title`,
                    };
                    newComponents.push(newTitle);
                  }
                  for (let lvl4 of lvl3.components) {
                    if (lvl4.type !== 'box' && lvl4.type !== 'group') {
                      const x =
                        lvl1.x +
                        (lvl1.w / 32) * lvl2.x +
                        (lvl1.w / 32) * (lvl2.w / 32) * lvl3.x +
                        (lvl1.w / 32) * (lvl2.w / 32) * (lvl3.w / 32) * lvl4.x;
                      const y = lvl1.y + lvl2.y + lvl3.y + lvl4.y;
                      const w =
                        (lvl1.w / 32) * (lvl2.w / 32) * (lvl3.w / 32) * lvl4.w;
                      newComponents.push({ ...lvl4, x, y, w });
                    } else {
                      if (lvl4.type === 'group') {
                        const newTitle = {
                          bold: false,
                          color: 0,
                          fontSize: 16,
                          id: null,
                          italic: false,
                          text: lvl4.name,
                          textAlign: 'left',
                          type: 'label',
                          underline: false,
                          name: 'Подпись',
                          x: lvl4.x,
                          y: lvl4.y,
                          w: lvl4.w,
                          h: 1,
                          i: `${lvl4.i}-title`,
                        };
                        newComponents.push(newTitle);
                      }
                      for (let lvl5 of lvl4.components) {
                        if (lvl5.type !== 'box' && lvl5.type !== 'group') {
                          const x =
                            lvl1.x +
                            (lvl1.w / 32) * lvl2.x +
                            (lvl1.w / 32) * (lvl2.w / 32) * lvl3.x +
                            (lvl1.w / 32) *
                              (lvl2.w / 32) *
                              (lvl3.w / 32) *
                              lvl4.x +
                            (lvl1.w / 32) *
                              (lvl2.w / 32) *
                              (lvl3.w / 32) *
                              (lvl4.w / 32) *
                              lvl5.x;
                          const y = lvl1.y + lvl2.y + lvl3.y + lvl4.y + lvl5.y;
                          const w =
                            (lvl1.w / 32) *
                            (lvl2.w / 32) *
                            (lvl3.w / 32) *
                            (lvl4.w / 32) *
                            lvl5.w;
                          newComponents.push({ ...lvl5, x, y, w });
                        } else {
                          if (lvl5.type === 'group') {
                            const newTitle = {
                              bold: false,
                              color: 0,
                              fontSize: 16,
                              id: null,
                              italic: false,
                              text: lvl5.name,
                              textAlign: 'left',
                              type: 'label',
                              underline: false,
                              name: 'Подпись',
                              x: lvl5.x,
                              y: lvl5.y,
                              w: lvl5.w,
                              h: 1,
                              i: `${lvl5.i}-title`,
                            };
                            newComponents.push(newTitle);
                          }
                          for (let lvl6 of lvl5.components) {
                            if (lvl6.type !== 'box' && lvl6.type !== 'group') {
                              const x =
                                lvl1.x +
                                (lvl1.w / 32) * lvl2.x +
                                (lvl1.w / 32) * (lvl2.w / 32) * lvl3.x +
                                (lvl1.w / 32) *
                                  (lvl2.w / 32) *
                                  (lvl3.w / 32) *
                                  lvl4.x +
                                (lvl1.w / 32) *
                                  (lvl2.w / 32) *
                                  (lvl3.w / 32) *
                                  (lvl4.w / 32) *
                                  lvl5.x +
                                (lvl1.w / 32) *
                                  (lvl2.w / 32) *
                                  (lvl3.w / 32) *
                                  (lvl4.w / 32) *
                                  (lvl5.w / 32) *
                                  lvl6.x;
                              const y =
                                lvl1.y +
                                lvl2.y +
                                lvl3.y +
                                lvl4.y +
                                lvl5.y +
                                lvl6.y;
                              const w =
                                (lvl1.w / 32) *
                                (lvl2.w / 32) *
                                (lvl3.w / 32) *
                                (lvl4.w / 32) *
                                (lvl5.w / 32) *
                                lvl6.w;
                              newComponents.push({ ...lvl6, x, y, w });
                            } else {
                              if (lvl6.type === 'group') {
                                const newTitle = {
                                  bold: false,
                                  color: 0,
                                  fontSize: 16,
                                  id: null,
                                  italic: false,
                                  text: lvl6.name,
                                  textAlign: 'left',
                                  type: 'label',
                                  underline: false,
                                  name: 'Подпись',
                                  x: lvl6.x,
                                  y: lvl6.y,
                                  w: lvl6.w,
                                  h: 1,
                                  i: `${lvl6.i}-title`,
                                };
                                newComponents.push(newTitle);
                              }
                              for (let lvl7 of lvl6.components) {
                                if (
                                  lvl7.type !== 'box' &&
                                  lvl7.type !== 'group'
                                ) {
                                  const x =
                                    lvl1.x +
                                    (lvl1.w / 32) * lvl2.x +
                                    (lvl1.w / 32) * (lvl2.w / 32) * lvl3.x +
                                    (lvl1.w / 32) *
                                      (lvl2.w / 32) *
                                      (lvl3.w / 32) *
                                      lvl4.x +
                                    (lvl1.w / 32) *
                                      (lvl2.w / 32) *
                                      (lvl3.w / 32) *
                                      (lvl4.w / 32) *
                                      lvl5.x +
                                    (lvl1.w / 32) *
                                      (lvl2.w / 32) *
                                      (lvl3.w / 32) *
                                      (lvl4.w / 32) *
                                      (lvl5.w / 32) *
                                      lvl6.x +
                                    (lvl1.w / 32) *
                                      (lvl2.w / 32) *
                                      (lvl3.w / 32) *
                                      (lvl4.w / 32) *
                                      (lvl5.w / 32) *
                                      (lvl6.w / 32) *
                                      lvl7.x;
                                  const y =
                                    lvl1.y +
                                    lvl2.y +
                                    lvl3.y +
                                    lvl4.y +
                                    lvl5.y +
                                    lvl6.y +
                                    lvl7.y;
                                  const w =
                                    (lvl1.w / 32) *
                                    (lvl2.w / 32) *
                                    (lvl3.w / 32) *
                                    (lvl4.w / 32) *
                                    (lvl5.w / 32) *
                                    (lvl6.w / 32) *
                                    lvl7.w;
                                  newComponents.push({ ...lvl7, x, y, w });
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      this.DETAILED_FORM_VIEW_XML_DESC[this.activeTab].components =
        newComponents;
    },
  },
  mounted() {
    document.addEventListener(
      'dragover',
      function (e) {
        mouseXY.x = e.clientX;
        mouseXY.y = e.clientY;
      },
      false
    );
  },
  created() {
    this.tabId += 1;
    this.$emit('get-Interface-Designer');
    this.$emit('get-Template-Regulations');
    this.$emit('get-Detailed-Form-View-Meta-Attrs');
    this.$emit('get-Detailed-Form-View-Xml-Desc-Response');
    setTimeout(() => {
      console.log(this.DETAILED_FORM_VIEW_XML_DESC);
    }, 2000);
  },
};
</script>

<style lang="scss" scoped>
.interface-designer {
  height: calc(100vh - 220px);
}

.interface-designer__container {
  display: flex;
  justify-content: flex-start;
  height: 100%;
}

.interface-designer__container__left {
  height: 100%;
}

.interface-designer__container__right {
  height: 100%;
}

.interface-designer__container__right__buttons {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

.interface-designer__container__right__buttons__tabs {
  display: flex;
  justify-content: flex-start;
  width: 100%;
}

.interface-designer__container__right__button {
  padding: 5px;
  background-color: burlywood;
  margin-left: 2.5px;
  margin-right: 2.5px;
}

.interface-designer__container__right__interface {
  border: 1px solid #ced4da;
  height: 79%;
  overflow-y: scroll;
  border-radius: 5px;
}

.dragable {
  width: 100%;
}

.dragable__text-block {
  width: 100%;
  margin-top: 5px;
  margin-bottom: 5px;
  cursor: pointer;
}

.dragable__button {
  background-color: red;
  color: #fff;
  border: 1px solid black;
  margin-top: 5px;
  margin-bottom: 5px;
}

.grid-item {
  border-radius: 5px;
  overflow: hidden;
  padding: 2px;
}

.grid-item__main__right {
  position: absolute;
  top: 0;
  right: 0;
  width: 30px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  display: none;
}

.grid-item:hover {
  border: 1px solid #b3b9d0;
}

.grid-item:hover .grid-item__main__right {
  display: flex;
}

.grid-item__main {
  display: flex;
  justify-content: flex-start;
  position: relative;
}

.bottom_line__block {
  padding: 5px;
}

.grid-item__main__left {
  width: 100%;
}

.edited_fields {
  padding: 20px;
}

.edited_field__label {
  width: 150px;
  margin-right: 10px;
}

.edited_field__conteiner {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-top: 10px;
}

.grid-item__main__left__text {
  text-overflow: clip;
}

.grid-item__main__left__image {
  width: 100%;
  height: 100%;
}

.grooup__buttons {
  width: 25px;
  height: 25px;
  margin-left: 10px;
  margin-right: 10px;
  font-size: 25px;
  background-color: lightgrey;
  text-align: center;
  border-radius: 10px;
  line-height: 25px;
}

.grooup__buttons__conteiner {
  display: flex;
  justify-content: flex-end;
}

.active__group input {
  border: none !important;
  padding: 0 !important;
  height: auto;
}

.active__group {
  border: 1px solid #ced4da;
  border-radius: 5px;
  padding: 5px;
  overflow: hidden;
}

.expansion-panel-content {
  max-height: 300px;
  overflow-y: scroll;
}

.attribute-field {
  margin: 0;
  margin-left: 10px;
  cursor: pointer;
}

.attribute-title {
  margin: 0;
}

.attributeItem__top {
  display: none;
  justify-content: space-between;
  align-items: center;
}

.attributeItem__top__elem {
  margin-top: 24px !important;
}

.grid-item:hover .attributeItem__top {
  display: flex;
}

.grid-item:hover .attributeItem__top__elem {
  margin-top: 0px !important;
}

.attributeItem__top__input {
  width: 30px;
  margin-right: 20px;
}

.attributeItem__input {
  border: 1px solid black;
  border-radius: 5px;
  width: calc(100% - 10px);
  margin: 5px;
  padding-left: 3px;
}

.attributeItem__top__text {
  margin-bottom: 0;
}

.attribute_active {
  color: rgb(87, 87, 87);
}

.attribute_row {
  display: flex;
  justify-content: space-between;
  margin-right: 15px;
}

.divider {
  background-color: #0ab39c;
  height: 100%;
  width: 1px;
  cursor: col-resize;
  margin-right: 5px;
}

.interface-designer__button__container {
  display: flex;
  justify-content: space-between;
  height: 50px;
  margin-top: -50px;
}

.interface-designer__button__container__scale {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.vue-grid-item.cssTransforms {
  transition-property: inherit !important;
}
.vue-resizable.resizing {
  pointer-events: none;
}
.vue-draggable-dragging {
  pointer-events: none;
}
.vue-grid-item.vue-draggable-dragging {
  transition: none;
  z-index: 3;
}

.btn-soft-save {
  margin-right: 8px;
  background-color: #dff0fa;
  color: #299cdb;
  padding: 10px 15px;
  border-radius: 5px;
  font-weight: 500;
  height: 40px;
}

.btn-soft-save:hover {
  transition: 0.3s all;
  background: #299cdb;
  color: rgb(255, 255, 255);
}
</style>
