<template>
    <div class="edit-view">
        <div class="edit-view__title">{{title}}</div>
        <div class="edit-view__container">
            <div class="edit-view__item" v-if="creationData.id !== '0'">
                <p class="edit-view__item__title">Статус:</p>
                <input v-model="creationData.status.name" disabled type="text" class="edit-view__item__input">
            </div>
            <div class="edit-view__item" v-if="creationData.id !== '0'">
                <p class="edit-view__item__title">Дата создания:</p>
                <div style="display: flex; align-items: center;">
                    <input :value="new Date(creationData.dateCreation).toLocaleString()" disabled type="text" class="edit-view__item__input">
                    <p class="edit-view__item__title" style="margin-left: 5px">Версия:</p>
                    <input v-model="creationData.version" disabled type="text" class="edit-view__item__input">
                </div>
            </div>
            <div class="edit-view__item">
                <p class="edit-view__item__title">Наименование реестра:</p>
                <input v-model="creationData.name" :disabled="creationData.id !== '0' && type==='view'" type="text" class="edit-view__item__input">
            </div>
            <div class="edit-view__item">
                <p class="edit-view__item__title">Наименование основной организации:</p>
                <textarea v-model="creationData.organization" :disabled="creationData.id !== '0' && type==='view'" type="text" class="edit-view__item__textarea"></textarea>
            </div>
            <div class="edit-view__item">
                <p class="edit-view__item__title">Администратор:</p>
                <input v-model="creationData.role.code" v-if="creationData.id !== '0' && type==='view'" :disabled="creationData.id !== '0'" type="text" class="edit-view__item__input">
                <button v-if="type==='edit'" @click="$emit('initialize-Register')">Инициализировать</button>
            </div>
            <div class="edit-view__item__table__container" v-if="creationData.id !== '0'">
                <p class="edit-view__item__title">Объекты реестра:</p>
                <v-data-table
                    class="edit-view__item__table createViewRegisters"
                    :headers="headers"
                    :items="creationData.templates"
                    :items-per-page="10000"
                    hide-default-footer
                    dense
                    fixed-header
                    height="100%"
                    @dblclick:row="rowClick"
                >
                    <template v-slot:item.select="{ item }">
                        <v-checkbox
                            v-model="item.selected"
                        ></v-checkbox>
                    </template>
                </v-data-table>
            </div>
            <v-spacer/>
            <div class="edit-view__actions" v-if="creationData.id === '0'">
                <button
                    class="btn-close"
                    @click="$emit('close-edit')"
                >Закрыть</button>
                <button
                    @click="onSave"
                    class="btn-save"
                >Сохранить</button>
            </div>
            <div class="edit-view__actions" v-else-if="type === 'view'">
                <button
                    class="btn-close"
                    @click="$emit('close-edit')"
                >Отмена</button>
                <button
                    @click="onEdit"
                    class="btn-save"
                >Редактировать</button>
            </div>
            <div class="edit-view__actions" v-else-if="type === 'edit'">
                <button
                    class="btn-save"
                    @click="onOpenUnregisteredTemplates"
                >Добавить</button>
                <button
                    class="btn-save"
                    @click="$emit('update-Register')"
                >Сохранить</button>
                <button
                    class="btn-close"
                    @click="type = 'view'"
                >Отмена</button>
            </div>
        </div>
        <v-dialog
            v-model="unregisteredTemplatesWindow"
            persistent
            max-width="700"
        >
            <v-card>
                <div class="parents-fields">
                    <v-data-table
                        class="unregistered-templates__table"
                        :headers="headersUnregisteredTemplates"
                        :items="UNREGISTERED_TEMPLATES"
                        :items-per-page="10000"
                        hide-default-footer
                        dense
                        show-select
                        v-model="selectedUnregisteredTemplates"
                    >
                    </v-data-table>
                </div>
                <v-card-actions>
                    <!-- <v-btn
                        color="green darken-1"
                        text
                        @click="onSelectUnregisteredTemplate"
                    >OK
                    </v-btn>
                    <v-btn
                        color="red darken-1"
                        text
                        @click="unregisteredTemplatesWindow = false"
                    >Закрыть
                    </v-btn> -->
                    <button
                        class="btn-save" 
                        @click="onSelectUnregisteredTemplate"
                    >OK
                    </button>                           
                    <button 
                        class="btn-close" 
                        @click="unregisteredTemplatesWindow = false"
                    >Закрыть
                    </button>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
    props: {
        creationData: {
            type: Object,
            default: () => {}
        },
        title: {
            type: String,
            default: () => 'Редактирование'
        },
    },
    components: {

    },
    data() {
        return {
            info: false,
            infotext: '',
            infoColor: '',
            headers: [
                {text: 'id', value: 'id'},  
                {text: 'Наименование', value: 'name'},
                {text: 'Версия', value: 'version'},
                {text: 'Связи', value: 'links'},
            ],
            type: 'view',
            unregisteredTemplatesWindow: false,
            headersUnregisteredTemplates: [
                {text: 'id', value: 'id'},  
                {text: 'Наименование', value: 'name'},
                {text: 'Версия', value: 'version'},
            ],
            selectedUnregisteredTemplates: [],
        }
    },
    computed: {
        ...mapGetters(["UNREGISTERED_TEMPLATES"]),
    },
    methods: {
        ...mapActions([

        ]),
        onSave() {
            this.$emit('create-Register', this.creationData)
        },
        rowClick(item) {
            console.log(item)
        },
        onEdit() {
            this.type = 'edit';
            this.headers.push({text: '', value: 'select'},)
        },
        onOpenUnregisteredTemplates() {
            this.$emit('get-Unregistered-Templates')
            this.unregisteredTemplatesWindow = true
        },
        onSelectUnregisteredTemplate() {
            const selectedUnregisteredTemplates = this.selectedUnregisteredTemplates.map((el) => {
                return {...el, selected: true, new: true}
            })
            this.creationData.templates.push(...selectedUnregisteredTemplates);

            this.selectedUnregisteredTemplates = [];
            this.unregisteredTemplatesWindow = false;
        },
    },

}
</script>

<style lang="scss" scoped>
.edit-view {
    height: 100%;
    display: flex;
    flex-direction: column;
    &__item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        &:not(:last-child) {
            margin-bottom: 10px;
        }
    }
    &__actions {
        display: flex;
        justify-content: flex-end;
        & .btn-close {
            margin-right: 8px;
            background-color:#f06548;
            color: #fff;
            padding: 10px 15px;
            border-radius: 5px;
            font-weight: 500;
        }
        & .btn-close:hover {
            background-color:#cc563d;
            transition: 0.3s all;
        }
        & .btn-save {
            margin-right: 8px;
            background-color:#299cdb;
            color: #fff;
            padding: 10px 15px;
            border-radius: 5px;
            font-weight: 500;
        }
        & .btn-save:hover {
            background-color:#2385ba;
            transition: 0.3s all;
        }
    }
}

.edit-view__container {
    padding: 20px;
}

.edit-view__item__title {
    font-weight: 500;
    width: 200px;
}

.edit-view__item__value {
    width: 100%;
}

.edit-view__item__input {
    width: 100%;
    padding: 5px;
}

.edit-view__item__textarea {
    width: 100%;
    padding: 5px;
    border: 1px solid black;
}

// .edit-view__item__table__container {
//     height: 400px;
// }
.edit-view__item__button {
    border-radius: 5px;
    background-color: deepskyblue;
    color: #fff;
    padding: 5px;
    margin-left: 10px;
}

.parents-fields {
    height: 600px;
    overflow-y: scroll;
}

.edit-view__item__table {
    height: 300px;
    overflow-y: scroll;
}

.edit-view__item {
    display: grid;
    grid-template-columns: 1fr 6fr
}
</style>