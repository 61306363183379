<template>
  <div id="app">
    <v-app>
      <v-card class="elevation-0 wrapper">
        <div class="header">
          <v-tabs v-model="selectedTabIndex">
            <!-- @click="selectedTabIndex = tab.componentName" -->
            <v-tab v-for="(tab, index) in tabs" :key="index">
              {{ tab.value }}
            </v-tab>
          </v-tabs>
          <v-btn @click="onLogOut"> выйти </v-btn>
        </div>
        <component :is="tabs[selectedTabIndex].componentName" />
      </v-card>
    </v-app>
  </div>
</template>

<script>
import Main from './pages/Main.vue';
import ObjectsTypes from './pages/TheObjectsTypes.vue';
import TableOfAccess from './pages/TableOfAccess.vue';
import AdminRoles from './pages/AdminRoles.vue';
import Registers from './pages/Registers.vue';
import Handbook from './pages/Handbook.vue';
import Reports from './pages/Reports.vue';
import DBViews from './pages/DBViews.vue';

export default {
  name: 'App',
  components: {
    Main,
    ObjectsTypes,
    Registers,
    Handbook,
    Reports,
    DBViews,
    TableOfAccess,
    AdminRoles,
  },
  data() {
    return {
      selectedTabIndex: 0,
      tabs: [
        {
          componentName: 'ObjectsTypes',
          value: 'Типы Объектов',
        },
        {
          componentName: 'Registers',
          value: 'Реестры',
        },
        {
          componentName: 'Handbook',
          value: 'Справочники',
        },
        {
          componentName: 'Reports',
          value: 'Отчёты',
        },
        {
          componentName: 'DBViews',
          value: 'Представлениe ДБ',
        },
        {
          componentName: 'TableOfAccess',
          value: 'Таблица доступа',
        },
        {
          componentName: 'AdminRoles',
          value: 'Роли',
        },
      ],
    };
  },

  methods: {
    onLogOut() {
      this.LOGOUT();
    },
  },
  mounted() {
    if (this.TOKEN) {
      this.getAdminData();
    }
  },
};
</script>

<style>
@import './assets/scss/main.scss';
.wrapper {
  height: 100%;
  padding: 12px;
  padding-left: 0;
}

.header {
  display: flex;
  justify-content: space-between;
  padding-left: 10px
}
</style>
