<template>
<div class="tree-menu">
    <div 
        class="tree-menu__wrap"
    >
        <div 
            :style="{
                paddingLeft: `${this.depth * 24}px`
            }"
            :class="{'parent-node': !!nodes.length, 'selected': selectedItem && nodeData.id === selectedItem.id}"
            class="label"
            @click="$emit('label-clicked', nodeData)"
        >{{ label }}</div>

        <div 
            class="tree-menu__icon" 
            v-if="nodes.length || nodeData.hasChildDictionaries === 'true' ||  nodeData.hasChilds === 'true'"
            @click="toggleChildren"
        >
            <ion-icon 
                v-if="!showChildren"
                name="caret-forward-outline"
                @click="fetchChildren(nodeData)"
            ></ion-icon>
            <ion-icon 
                v-if="showChildren"  
                name="caret-down-outline"
            ></ion-icon>

        </div>
    </div>

    <transition name="slide-fade">
        <div v-if="showChildren">
            <tree-menu 
                @label-clicked="$emit('label-clicked', $event)"
                :type="type"
                v-for="(node, index) in nodes" 
                :nodeData="node.data"
                :nodes="node.nodes" 
                :label="node.label"
                :depth="depth + 1"
                :key="index"
                title="Редактирование одиночного справочника: Коэффициенты"
                :selectedItem="selectedItem"
                :showMainChildren="showMainChildrenSecond"
            >
            </tree-menu>
        </div>
    </transition>
</div>
</template>
<script>
import { mapActions } from "vuex";

export default { 
    props: [ 'label', 'nodes', 'depth', 'nodeData', 'type', 'selectedItem', 'showMainChildren', 'showMainChildrenSecond'],
    data() {
        return { showChildren: false }
    },
    name: 'tree-menu',
    methods: {
        ...mapActions(["fetchInnerDictionaries", "getChildObjects", "getChildNodes"]),
        toggleChildren() {
            this.showChildren = !this.showChildren;
        },
        fetchChildren(node) {
            if (this.nodes.length > 0 ) return
            if (this.type === 'parent') {
                this.getChildNodes(node)
            }
            if (this.type === 'object' && node.modelClassesType === 'CLASSIFICATION_NODE') {
                this.getChildObjects(node)
            }
            if (this.type === 'dictionary' && node.dictionaryType === "DICTIONARY_GROUP") {
                this.fetchInnerDictionaries(node)
            }
        }
    },
    created() {
        if (this.showMainChildren) {
            this.showChildren = true
        }
    }
}
</script>

<style lang="scss" scoped>
    .tree-menu {
        &__wrap {
            display: flex;
            align-items: center;
            user-select: none;
            cursor: pointer;
            padding: 0 4px;
        }
        &__icon {
            padding: 3px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .label {
        margin-right: 5px;
    }

    .parent-node {
        font-weight: bold;
    }

    .selected {
        color: rgb(255, 255, 255);
        font-weight: 600;
    }

    .label:hover {
        color: white
    }
</style>