import Vue from 'vue';
import App from './App.vue';
import vuetify from '@/plugins/vuetify'; // path to vuetify export
import store from "./store";

import TreeMenu from './components/TreeMenu.vue';

Vue.config.productionTip = false;

Vue.component('tree-menu', TreeMenu);
//фикс бага с варнами на ion-icons
Vue.config.ignoredElements = [/^ion-/];

new Vue({
  render: h => h(App),
  vuetify,
  store,
}).$mount('#app')
