<template>
<div class="table-conteiner">
    <p>{{step === 'view' ? 'Роли':'Выберите роли доступа, которые необходимо добавить.'}}</p>
    <v-data-table
        class="atributes_table"
        :headers="headers"
        :items="step === 'view' ? (activeItem !== null ? activeItem.accessRoles : []) : ADMIN_UN_ACCESSED_TEMPLATE_ROLES"
        :items-per-page="10000"
        hide-default-footer
        fixed-header
        height="100%"
        dense
    >
        <template v-slot:item.creationDate="{ item }">
            {{new Date(item.creationDate).toLocaleString()}}
        </template>
        <template v-slot:item.action="{ item }">
            <div v-if="step === 'add'">
                <v-checkbox v-model="item.selected"></v-checkbox>
            </div>
            <div v-else>
                <!-- <img
                    style="cursor: pointer"
                    :src="require('../assets/trash.png')"
                    alt="delete"
                    @click="showDeleteForm(item)"
                /> -->
                <i class="ri ri-delete-bin-fill" @click="showDeleteForm(item)"></i>
            </div>
        </template>
    </v-data-table>
    <div class="buttons_conteiner" v-if="step === 'view'">
        <button class="buttons_conteiner__button" @click="onAddRole">Добавить</button>
        <button class="buttons_conteiner__button" v-if="save" @click="onSave">Сохранить</button>
    </div>
    <div class="buttons_conteiner" v-else>
        <button class="buttons_conteiner__button" @click="onSelect">ОК</button>
        <button class="buttons_conteiner__button" @click="step = 'view'">Отмена</button>
    </div>

    <v-dialog
        v-model="deleteDialog"
        persistent
        max-width="290"
    >
        <v-card>
            <v-card-text>Вы действительно хотите удалить выбранный элемент?</v-card-text>
            <v-card-actions>
                <!-- <v-btn
                    color="red darken-1"
                    text
                    @click="deleteElement"
                >Да
                </v-btn>
                <v-btn
                    color="green darken-1"
                    text
                    @click="deleteDialog = false"
                >Нет
                </v-btn> -->
                <button 
                    class="btn-save" 
                    @click="deleteElement"
                >Да
                </button>

                <button
                    class="btn-close" 
                    @click="deleteDialog = false"
                >Нет
                </button>
            </v-card-actions>
        </v-card>
    </v-dialog>

</div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    props: {
        activeItem: {
            type: Object,
            default: () => {}
        },
    },
    data() {
        return {
            headers: [
                {text: 'Код', value: 'code'},  
                {text: 'Наименование', value: 'name'},
                {text: 'Описание', value: 'description'},
                {text: 'Дата создания', value: 'creationDate'},
                {text: '', value: 'action'},
            ],
            deleteDialog: false,
            deletedItem: null,
            step: 'view',
            newRoles: [],
            deletedRoles: [],
            save: false,
        }
    },
    watch: { 
        activeItem: function(newVal, oldVal) { 
            if (newVal !== oldVal) {
                this.deleteDialog= false;
                this.deletedItem= null;
                this.step= 'view';
                this.newRoles= [];
                this.deletedRoles= [];
                this.save= false;
            }
        }
    },
    computed: {
        ...mapGetters(["ADMIN_OBJECT_STATUSES_ROLES", "ADMIN_UN_ACCESSED_TEMPLATE_ROLES"]),
    },
    methods: {

        deleteElement() {
            this.activeItem.accessRoles = this.activeItem.accessRoles.filter((el)=>el.id !== this.deletedItem.id)
            this.deleteDialog = false;
        },
        showDeleteForm(item) {
            this.deletedItem = item
            this.deleteDialog = true;
        },
        onAddRole() {
            this.$emit('getUnAccessedTemplateRoles')
            this.step = 'add'
        },
        onSelect() {
            const selected = this.ADMIN_UN_ACCESSED_TEMPLATE_ROLES.filter((el)=> el.selected)
            if (!this.activeItem.accessRoles) this.activeItem.accessRoles = []
            this.activeItem.accessRoles.push(...selected)
            const newArr = this.activeItem.accessRoles.filter((value, index, self) => {
                return self.findIndex((el)=>el.id ===value.id) === index;
            })
            console.log(newArr)
            this.activeItem.accessRoles = newArr
            this.step = 'view'
            this.save = true
        },
        onSave() {
            this.activeItem.accessRoles = this.activeItem.accessRoles.map((el)=> {
                delete el.selected
                return el
            })
            this.$emit('setTemplateRoleAccess')
        }
    },
    mounted() {

    }

}
</script>

<style lang="scss" scoped>
.edit-view {
    height: 100%;
    display: flex;
    flex-direction: column;
    &__item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        &:not(:last-child) {
            margin-bottom: 10px;
        }
    }
    &__actions {
        display: flex;
        justify-content: flex-end;
        & .btn-close {
            margin-right: 8px;
            background-color:#f06548;
            color: #fff;
            padding: 10px 15px;
            border-radius: 5px;
            font-weight: 500;
        }
        & .btn-close:hover {
            background-color:#cc563d;
            transition: 0.3s all;
        }
        & .btn-save {
            margin-right: 8px;
            background-color:#299cdb;
            color: #fff;
            padding: 10px 15px;
            border-radius: 5px;
            font-weight: 500;
        }
        & .btn-save:hover {
            background-color:#2385ba;
            transition: 0.3s all;
        }
    }
}

.edit-view__container {
    padding: 20px;
}

.edit-view__item__title {
    font-weight: 500;
    width: 200px;
}

.edit-view__item__value {
    width: 100%;
}

.edit-view__item__input {
    width: 100%;
    padding: 5px;
}

.edit-view__item__textarea {
    width: 100%;
    padding: 5px;
    border: 1px solid black;
}

// .edit-view__item__table__container {
//     height: 400px;
// }

.object_tabs {
    height: 50px;
}

.atributes_table .v-data-table__wrapper {
    height: calc(100vh - 160px);
    overflow-y: scroll;
}

.atributes_table {
    height: calc(100vh - 160px);
    overflow-y: scroll;
    box-shadow: 0px 0px 8px 1px rgba(34, 60, 80, 0.2);
}
</style>