<template>
  <div class="types" ref="conteiner">
    <!-- Не вытаскивыть tree-menu из враппера, т.к компонент вызывает сам себя
        и конструкция сломается -->
    <v-navigation-drawer
      class="tree"
      v-model="showNavigation"
      :mini-variant.sync="mini"
      permanent
    >
      <div class="navigation-top__conteiner">
        <v-btn icon @click.stop="mini = !mini">
          <v-icon>{{ mini ? 'mdi-chevron-right' : 'mdi-chevron-left' }}</v-icon>
        </v-btn>
      </div>
      <tree-menu
        v-for="(node, index) in nodes"
        :key="index"
        :label="node.label"
        :nodes="node.nodes"
        :nodeData="node.data"
        :depth="0"
        @label-clicked="showEdit($event)"
        type="object"
        :showMainChildren="true"
        :selectedItem="activeItem"
      ></tree-menu>
    </v-navigation-drawer>
    <!--<div class="divider" @mousedown="onColumnMouseDown($event)"></div>--->

    <div class="view">
      <component
        :key="updateKey"
        :is="activeView"
        :activeItem="activeItem"
        @getUnAccessedTemplateRoles="onGetUnAccessedTemplateRoles"
        @setTemplateRoleAccess="onSetTemplateRoleAccess"
        @onGetPermissionsRolesProxies="onGetPermissionsRolesProxies"
        @onGetAvailableStatuses="onGetAvailableStatuses"
        @onCreateObjectStatusRoleAccess="onCreateObjectStatusRoleAccess"
        @onDeleteObjectStatusRoleAccess="onDeleteObjectStatusRoleAccess"
        @onUpdateObjectStatusRoleAccess="onUpdateObjectStatusRoleAccess"
      />
    </div>

    <v-snackbar v-model="info" :color="infoColor" :timeout="10000">
      {{ infotext }}
    </v-snackbar>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';

import TableOfAccessObjectList from '@/components/TableOfAccessObjectList.vue';
import TableOfAccessRoles from '@/components/TableOfAccessRoles.vue';

export default {
  components: {
    TableOfAccessObjectList,
    TableOfAccessRoles,
  },
  data() {
    return {
      updateKey: 1,
      activeView: 'TableOfAccessRoles',
      headers: [
        { text: 'id', value: 'id' },
        { text: 'Наименование', value: 'name' },
        { text: 'Дата изменения', value: 'versionDate' },
        { text: 'Версия', value: 'version' },
        { text: 'Реестр', value: 'register' },
        { text: 'Действия', value: 'actions' },
      ],
      showNavigation: true,
      mini: false,
      loading: true,
      activeItem: null,
      info: false,
      infotext: '',
      infoColor: '',
      nodes: [],
    };
  },
  computed: {
    ...mapGetters(['ADMIN_MENU']),
  },
  methods: {
    ...mapActions([
      'getAdminMenu',
      'getObjectStatusesRoles',
      'getUnAccessedTemplateRoles',
      'setTemplateRoleAccess',
      'getPermissionsRolesProxies',
      'getAvailableStatuses',
      'createObjectStatusRoleAccess',
      'deleteObjectStatusRoleAccess',
      'updateObjectStatusRoleAccess',
    ]),
    ...mapMutations(['SET_ADMIN_OBJECT_STATUSES_ROLES']),
    showEdit(row) {
      this.activeItem = row;
      if (row.menuType === 'TEMPLATE') {
        this.activeView = 'TableOfAccessRoles';
      } else if (row.menuType === 'STATUS') {
        const item = { ...row };
        if (item.childMenuItems) {
          item.childMenuItems = [];
        }
        this.loading = true;
        this.getObjectStatusesRoles(item)
          .then(() => {
            this.activeView = 'TableOfAccessObjectList';
            this.loading = false;
          })
          .catch((e) => {
            this.infoColor = 'error';
            this.infotext = e;
            this.info = true;
            this.loading = false;
          });
      } else if (row.menuType === 'ROLE') {
        this.SET_ADMIN_OBJECT_STATUSES_ROLES([]);
        this.activeView = 'TableOfAccessObjectList';
      }
    },
    onGetUnAccessedTemplateRoles() {
      this.loading = true;
      console.log(this.activeItem);
      this.getUnAccessedTemplateRoles(this.activeItem)
        .then(() => {
          this.loading = false;
        })
        .catch((e) => {
          this.infoColor = 'error';
          this.infotext = e;
          this.info = true;
          this.loading = false;
        });
    },
    onSetTemplateRoleAccess() {
      this.loading = true;
      this.setTemplateRoleAccess(this.activeItem)
        .then(() => {
          this.loading = false;
        })
        .catch((e) => {
          this.infoColor = 'error';
          this.infotext = e;
          this.info = true;
          this.loading = false;
        });
    },
    onGetPermissionsRolesProxies() {
      this.loading = true;
      this.getPermissionsRolesProxies()
        .then(() => {
          this.loading = false;
        })
        .catch((e) => {
          this.infoColor = 'error';
          this.infotext = e;
          this.info = true;
          this.loading = false;
        });
    },
    onGetAvailableStatuses() {
      this.loading = true;
      const item = { ...this.activeItem };
      item.childMenuItems = null;
      this.getAvailableStatuses(item)
        .then(() => {
          this.loading = false;
        })
        .catch((e) => {
          this.infoColor = 'error';
          this.infotext = e;
          this.info = true;
          this.loading = false;
        });
    },
    onCreateObjectStatusRoleAccess(item) {
      this.loading = true;
      this.createObjectStatusRoleAccess(item)
        .then(() => {
          const activeItem = { ...this.activeItem };
          if (activeItem.childMenuItems) {
            activeItem.childMenuItems = [];
          }
          this.getObjectStatusesRoles(activeItem)
            .then(() => {
              this.loading = false;
            })
            .catch((e) => {
              this.infoColor = 'error';
              this.infotext = e;
              this.info = true;
              this.loading = false;
            });
          this.onGetAdminMenu();
        })
        .catch((e) => {
          this.infoColor = 'error';
          this.infotext = e;
          this.info = true;
          this.loading = false;
        });
    },
    onDeleteObjectStatusRoleAccess(item) {
      this.loading = true;
      this.updateObjectStatusRoleAccess(item)
        .then(() => {
          const activeItem = { ...this.activeItem };
          if (activeItem.childMenuItems) {
            activeItem.childMenuItems = [];
          }
          this.getObjectStatusesRoles(activeItem)
            .then(() => {
              this.loading = false;
            })
            .catch((e) => {
              this.infoColor = 'error';
              this.infotext = e;
              this.info = true;
              this.loading = false;
            });
          this.onGetAdminMenu();
        })
        .catch((e) => {
          this.infoColor = 'error';
          this.infotext = e;
          this.info = true;
          this.loading = false;
        });
    },
    onUpdateObjectStatusRoleAccess(item) {
      this.loading = true;
      this.updateObjectStatusRoleAccess(item)
        .then(() => {
          const activeItem = { ...this.activeItem };
          if (activeItem.childMenuItems) {
            activeItem.childMenuItems = [];
          }
          this.getObjectStatusesRoles(activeItem)
            .then(() => {
              this.loading = false;
            })
            .catch((e) => {
              this.infoColor = 'error';
              this.infotext = e;
              this.info = true;
              this.loading = false;
            });
          this.onGetAdminMenu();
        })
        .catch((e) => {
          this.infoColor = 'error';
          this.infotext = e;
          this.info = true;
          this.loading = false;
        });
    },
    onGetAdminMenu() {
      this.getAdminMenu()
        .then(() => {
          console.log(this.ADMIN_MENU);
          this.nodes = [
            {
              label: 'Объекты',
              data: {
                id: 1,
                label: 'Объекты',
                register: null,
              },
              nodes: this.ADMIN_MENU,
            },
          ];
          this.loading = false;
        })
        .catch((e) => {
          this.infoColor = 'error';
          this.infotext = e;
          this.info = true;
          this.loading = false;
        });
    },
  },
  mounted() {
    this.onGetAdminMenu();
  },
};
</script>

<style lang="scss" scoped>
.tree {
  width: 20%;
  // border: 1px solid #000;
  overflow: auto;
  background-color: #0ab39c !important;
  color: #daf4f0;
  font-weight: 400;
  font-size: 0.875rem;
  height: calc(100vh - 70px) !important;
  padding-top: 15px;
}
.view {
  width: 100%;
  margin-left: 20px;
  // border: 1px solid #000;
}
.types {
  display: flex;
  justify-content: flex-start;
  // border: 1px solid #000;
  // background: #f3f3f8;
  padding: 10px 5px 5px 0;
  height: calc(100% - 48px); // 100% - 48px, где 48px - высота табов
  border-radius: 0px !important;
}

.divider {
  background-color: black;
  height: 100%;
  width: 3px;
  cursor: col-resize;
}

.navigation-top__conteiner {
  position: sticky;
  top: 0;
  z-index: 2;
}
</style>
