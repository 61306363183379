<template>    
  <v-data-table
    @item-selected="itemSelect"
    v-model="selected"
    :headers="headers"
    :items="getItems"
    :items-per-page="10000"
    :single-select="false"
    item-key="id"
    hide-default-footer
    show-select
    height="400"
    dense
    @click:row="$emit('table-row-dblclick', $event)"
  >

  </v-data-table>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
export default {
    props: {
      editData: {
        type: Object,
          default: () => {}
      },
    },
    data() {
      return {
        headers: [
            {text: 'id', value: 'id'},
            {text: 'Код', value: 'code'},
            {text: 'Наменование', value: 'name'},
            {text: 'Описание', value: 'description'},
        ],
      }
    },
    methods: {
      ...mapActions(["getGroupedDictionaries"]),
      ...mapMutations(["SET_GROUPED_DICTIONARIES_NEW"]),
      itemSelect(item) {
        console.log(item)
      }
    },
    computed: {
        ...mapGetters(["DICTIONARY_LIST", "GROUPED_DICTIONARIES", "GROUPED_DICTIONARIES_NEW"]),
        getItems() {
            return this.DICTIONARY_LIST.map((el)=> el.data);
        },
        selected: {
          get () {
            return this.GROUPED_DICTIONARIES_NEW
          },
          set (value) {
            this.SET_GROUPED_DICTIONARIES_NEW(value)
          }
        }
    }, 
    mounted() {
      this.getGroupedDictionaries(this.editData).then((res) => {
        this.selected = [...res];
      }).catch((e) => {
        console.log(e)
      })
    }
}
</script>
<style lang="scss">


</style>