<template>
    <div class="interface-designer">
        <div class="edit-view__title">Основные параметры:</div>
        <div class="edit-view__container">
            <div class="edit-view__item">
                <p class="edit-view__item__title">Наименование:</p>
                <input v-model="editData.name"  type="text" class="edit-view__item__input">
            </div>
            <div class="edit-view__item" v-if="editData.nodeType === 'OBJECT_TEMPLATE'">
                <p class="edit-view__item__title">Наименование в журнале:</p>
                <input v-model="editData.journalName"  type="text" class="edit-view__item__input">
            </div>
            <div class="edit-view__wrapper">
                <div class="edit-view__item">
                    <p class="edit-view__item__title">ID:</p>
                    <input v-model="editData.id"  type="text" class="edit-view__item__input" disabled>
                </div>
                <div class="edit-view__item">
                    <p class="edit-view__item__title" style="width: 130px;">Код:</p>
                    <input v-model="editData.code"  type="text" class="edit-view__item__input">
                </div>
            </div>
            <div class="edit-view__wrapper">
                <div class="edit-view__item">
                    <p class="edit-view__item__title">Дата изменения:</p>
                    <input :value="new Date(editData.versionDate).toLocaleString()"  type="text" class="edit-view__item__input" disabled>
                </div>
                <div class="edit-view__item">
                    <p class="edit-view__item__title" style="width: 130px;">Версия:</p>
                    <input v-model="editData.version"  type="text" class="edit-view__item__input" disabled>
                </div>
            </div>
            <div class="edit-view__wrapper">
                <div class="edit-view__item">
                    <p class="edit-view__item__title">Родитель:</p>
                    <div class="edit-view__item__wrapper">
                        <input v-model="editData.parentId"  type="text" class="edit-view__item__input" disabled>
                        <button class="edit-view__item__button" disabled>Задать</button>
                    </div>
                </div>
                <div class="edit-view__item">
                    <p class="edit-view__item__title" style="width: 130px">Владелец:</p>
                    <div class="edit-view__item__wrapper">
                        <input  type="text" class="edit-view__item__input" disabled>
                        <button class="edit-view__item__button" disabled>Задать</button>
                    </div>
                </div>
            </div>
            <div class="edit-view__wrapper">
                <div class="edit-view__item" v-if="editData.nodeType === 'OBJECT_TEMPLATE'">
                    <p class="edit-view__item__title">Тип шаблона:</p>
                    <input v-model="editData.typeTemplate.name"  type="text" class="edit-view__item__input" disabled>
                </div>
            </div>
            <v-spacer class="spacer"/>
            <div class="edit-view__actions">
                <button
                    class="btn-close"
                    @click="$emit('close-edit')"
                >Закрыть</button>
                <button
                    class="btn-save"
                    @click="onSave"
                >Сохранить</button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
        props: {
            editData: {
                type: Object,
                default: () => {}
            },
    },
    data() {
        return {

        }
    },
    computed: {
        ...mapGetters([]),
    },
    methods: {
        onSave() {
            this.$emit('edit-item', this.editData)
        }
    },
    mounted() {

    }

}
</script>

<style lang="scss" scoped>
.edit-view {
    height: 100%;
    display: flex;
    flex-direction: column;
    &__item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        &:not(:last-child) {
            margin-bottom: 10px;
        }
    }
    &__actions {
        display: flex;
        justify-content: flex-end;
        & .btn-close {
            margin-right: 8px;
            background-color:#f06548;
            color: #fff;
            padding: 10px 15px;
            border-radius: 5px;
            font-weight: 500;
        }
        & .btn-close:hover {
            background-color:#cc563d;
            transition: 0.3s all;
        }
        & .btn-save {
            margin-right: 8px;
            background-color:#299cdb;
            color: #fff;
            padding: 10px 15px;
            border-radius: 5px;
            font-weight: 500;
        }
        & .btn-save:hover {
            background-color:#2385ba;
            transition: 0.3s all;
        }
    }
}

.edit-view__container {
    padding: 20px;
}

.edit-view__item__title {
    font-weight: 500;
    width: 190px;
    margin-bottom: 0;
}

.edit-view__item__value {
    width: 100%;
}

.edit-view__item__input {
    width: 100%;
    padding: 5px;
}

.edit-view__item {
    display: grid;
    grid-template-columns: 1fr 7fr;
}

.edit-view__item__wrapper {
    display: flex;
}

.edit-view__item__textarea {
    width: 100%;
    padding: 5px;
    border: 1px solid black;
}

// .edit-view__item__table__container {
//     height: 400px;
// }

.object_tabs {
    height: 50px;
}

.edit-view__title {
    font-weight: bold;
    padding-left: 20px;
}

.edit-view__item__button {
    border-radius: 5px;
    background-color: #dff0fa;
    color: #299cdb;
    padding: 5px 15px;
    margin-left: 10px;
    font-weight: 500;
}

.edit-view__item__button:hover {
    background-color: #299cdb;
    color: rgb(255, 255, 255);
    transition: 0.3s all;
}

.edit-view__wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    height: 45px;
    grid-column-gap: 3.6rem;
}

.edit-view__wrapper-full {
    display: grid;
    grid-template-columns: 1fr;
    height: 45px;
}

.spacer {
    height: calc(100vh - 530px)
}
</style>