import { render, staticRenderFns } from "./CreateAdminRole.vue?vue&type=template&id=b24c477c&scoped=true&"
import script from "./CreateAdminRole.vue?vue&type=script&lang=js&"
export * from "./CreateAdminRole.vue?vue&type=script&lang=js&"
import style0 from "./CreateAdminRole.vue?vue&type=style&index=0&id=b24c477c&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b24c477c",
  null
  
)

export default component.exports